import React from 'react'
import ChangeEmail from './ChangeEmail'
import ChangePhone from './ChangePhone'

const Security = () => {
  return (
    <>
    <ChangeEmail />
    <ChangePhone />
    </>
  )
}

export default Security