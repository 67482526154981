import React from "react";
import { Box, Typography } from "@mui/material";
export default function LeftPanelListBox({
  icon,
  crypto,
  cryptoShortName,
  svg,
  onClick,
  active,
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        p: "5px 10px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
        textDecoration: "none",
        cursor: "pointer",
        background: active && "#F7F6FE",
        borderRadius: "8px",
        border: active && "0.9px solid #6F89F5",
        justifyContent: "center",
      }}
    >
      {svg ? (
        svg
      ) : (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent:"center"
          }}
        >
          <Box>
            <img src={icon} alt={icon} height="20px" />
          </Box>
          <Typography
            sx={{
              color: "var(--Neutral-400, #9C9CAB)",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20.6px",
              display: {
                md: "none",
              },
              mt: "-8px",
              width: "max-content"
            }}
          >
            {cryptoShortName}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: {
            md: "flex",
            xs: "none",
          },
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            color: "var(--Neutral-900, #131316)",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            // lineHeight: "25.6px",
            letterSpacing: "0.08px",
          }}
        >
          {crypto}
        </Typography>

        <Typography
          sx={{
            color: "var(--Neutral-400, #9C9CAB)",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20.6px",
            mt:"-4px"
          }}
        >
          {cryptoShortName}
        </Typography>
      </Box>
    </Box>
  );
}
