import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { CircularProgress } from "@mui/material";

const steps = ["Processing", "Confirmed"];

export default function HorizontalGafahQRPageStepper({ status }) {
  const [loading, setLoading] = React.useState(status !== "Completed");
  React.useEffect(() => {
    setLoading(status !== "Completed");
  }, [status]);
  const activeStep = status === "Completed" ? 2 : status === "Processing" ? 1 : 0;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "30px",
      }}
    >
      <Stepper
        activeStep={activeStep}
        sx={{ maxWidth: "480px", widht: "100%" }}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={index < activeStep}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {!loading && <CircularProgress sx={{ color: "#495ECA" }} size="1.7rem" />}
    </Box>
  );
}
