import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const PendingAmountShow = ({ pendingAmount, paymentStatus, token, network }) => {
  const [pendingAmountChanged, setPendingAmountChanged] = useState(false);

  useEffect(() => {
    setPendingAmountChanged(true);
    const timeoutId = setTimeout(() => {
      setPendingAmountChanged(false);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [pendingAmount]);

  const message = /Internal Transaction Rejected/.test(paymentStatus)
    ? "Looks like a transaction was rejected, please send the pending amount before the transaction expires."
    : "The whole payment was not received, please send the pending amount before the transaction expires.";

  return (
    <Box
      sx={{
        mt: "15px",
        background: pendingAmountChanged ? "#FF8C00" : "#495ECA",
        color: "#fff",
        p: "5px",
        borderRadius: "5px",
        fontSize: "12px",
        transition: "background 0.3s",
      }}
      className={`partial ${pendingAmountChanged ? "animate" : ""}`}
    >
      {message}
      <Typography
        sx={{
          fontSize: "12px",
          mt: "5px",
        }}
      >
        Pending Amount{" "}
        <span style={{ fontWeight: 600 }}>
          {pendingAmount || 0} {token} ({network})
        </span>
      </Typography>
    </Box>
  );
};

export default PendingAmountShow;
