import axios from "axios"
import config from "./config"

const http = axios.create({
    baseURL: config.API_URL,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
})

http.interceptors.request.use(function (config) {
  const token = typeof window !== "undefined" && localStorage.getItem("paymentToken")
  config.headers.Authorization = token ? `Bearer ${token}` : ""
  return config
})

// http.interceptors.response.use(
//   response => response,
//   async error => {
//     const originalRequest = error.config
//     if (error?.response?.status === 403 && !originalRequest._retry) {
//       originalRequest._retry = true

//       // Perform token refresh and update the access token
//       const refreshToken = localStorage.getItem('refreshToken') // Replace with your actual refresh token
//       const newAccessToken = await refreshAccessToken(refreshToken)

//       // Retry the original request with the new access token
//       originalRequest.headers.Authorization =  newAccessToken? `Bearer ${newAccessToken}` : ""
//       return http(originalRequest)
//     }
//     return Promise.reject(error)
//   }
// )

// Define a function to refresh the access token
// async function refreshAccessToken(refreshToken) {
//   try {
//     const response = await http.post(api.refreshToken, { refreshToken })
//     const newAccessToken = response.data.data.access_token

//     // Update the access token in Redux state
//     // setAccessToken(newAccessToken)
//     localStorage.setItem('accessToken', newAccessToken)
//     localStorage.setItem('refreshToken', response.data.data.refresh_token)

//     return newAccessToken
//   } catch (error) {
//     // Handle the error, e.g., logout the user
//     console.error("Error refreshing token:", error)
//     // window.location.replace("/login/");
//     throw error
//   }
// }

// // ... Rest of your code ...

const Network2 = {
    http: http,
    get: (url, params, header) => http.get(url, { params: params }, header),
    post: (url, data, header) => http.post(url, data, header)
}
export default Network2
