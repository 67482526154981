import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  CircularProgress,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AuthHeader,
  AuthP,
  LogoText,
} from "../../components/Helpers/helperComponents";
import { PostHandler } from "../../Redux/RequestHandlers";
import { ButtonLoader, PrimaryButton } from "../../components/Buttons";
import RightComponent1 from "./components/RightComponent1";
export default function OtpVerification() {
  const { email, submerchantVerification } = useSelector((state) => state?.auth);
  const [inputs, setInputs] = useState({});
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (e) => {
    if (e.target.name === "otp" && isNaN(e.target.value)) return;
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "otpVerification",
        payload: inputs,
        action: "ACTION_VERIFIED_OTP",
      };
      const response = await dispatch(PostHandler(req));
      setIsLoading(false);
      if (response?.data?.success) {
        window.localStorage.setItem(
          "accessToken",
          response?.data?.data?.access_token
        );
        return navigate("/account/setup/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleMerchantOtpSubmit = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "otpVerificationSubmerchant",
        payload: inputs,
        action: "ACTION_VERIFIED_SUB_MERCHANT_OTP",
      };
      const res = await dispatch(PostHandler(req));
      setIsLoading(false);
      if (res?.data?.success) {
        window.localStorage.setItem(
          "accessToken",
          res?.data?.data?.token?.access_token
        );
        if (res?.data?.data?.token?.refresh_token)
          window.localStorage.setItem(
            "refreshToken",
            res?.data?.data?.token?.refresh_token
          );
        navigate("/dashboard/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleResendOtp = async () => {
    try {
      setIsOtpLoading(true);
      const req = {
        url: "resendOtp",
      };
      await dispatch(PostHandler(req));
      setIsOtpLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisable(inputs?.otp ? false : true);
  }, [inputs]);

  return (
    <Box className="main" sx={{ height: "100vh!important" }}>
      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          md={6}
          lg={5}
          sx={{
            p: "80px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            "@media (max-width:600px)": { p: "40px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              //   justifyContent: "center",
              width: "100%",
              maxWidth: "508px",
            }}
          >
            <LogoText />

            <AuthHeader text="Verify Email" mt="100px" />
            <AuthP
              text={`We emailed 6 digit OTP to verify your email to ${email}`}
              mt="30px"
            />

            {!submerchantVerification &&
              <Box sx={{ mt: "30px" }}>
                {!isOtpLoading ? (
                  <Box
                    onClick={handleResendOtp}
                    sx={{
                      color: "var(--main-colors-primary, #5235E8)",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Resend OTP
                  </Box>
                ) : (
                  <CircularProgress />
                )}
              </Box>
            }

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mt: "30px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "7px",
                }}
              >
                <TextField
                  id="otp"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  sx={{ width: "100%", mt: "30px", mb: "10px" }}
                  name="otp"
                  onChange={handleChange}
                  value={inputs?.otp || ""}
                  placeholder="Enter six digit otp here"
                  required
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mt: "20px",
                width: "100%",
              }}
            >
              {isLoading ? (
                <ButtonLoader />
              ) : (
                <PrimaryButton
                  title="Continue"
                  onClick={submerchantVerification ? handleMerchantOtpSubmit : handleSubmit}
                  disabled={isDisable}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          sx={{
            borderRadius: "20px 0px 0px 20px",
            background:
              "linear-gradient(270deg, #495ECA 0%, rgba(73, 94, 202, 0.00) 100%)",
            "@media (max-width:900px)": { display: "none" },
          }}
        >
          <RightComponent1 />
        </Grid>
      </Grid>
    </Box>
  );
}
