import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Img from "../../../assets/img/home/ComponentEight/1.png";
import { Link } from "react-router-dom";
import { checkLogin } from "../../../Redux/RequestHandlers/Auth.action";
export default function RevenueComponentEight(props) {
  const isLogin = checkLogin();
  return (
    <Box
      id="HomeSignInComponent"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        // minHeight: "400px",
        padding: "88px 64px 0px 64px",
        alignItems: "flex-start",
        flexShrink: 0,
        alignSelf: "stretch",
        background: "#000",
        borderRadius: "24px",
        "@media (max-width:800px)": {
          p: "70px 30px 0px",
        },
        "@media (max-width:1300px)": {
          pb: "60px",
        },
        "@media (max-width:1200px)": {
          justifyContent: "center",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          "@media (max-width:1200px)": {
            alignItems: "center",
          },
        }}
      >
        <Typography
          sx={{
            color: "var(--main-colors-white, #FFF)",
            fontFeatureSettings: " 'ss06' on",
            fontFamily: "SF Pro Display",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "56px",
            letterSpacing: "-0.4px",
            "@media (max-width:1200px)": {
              textAlign: "center",
            },
            "@media (max-width:800px)": {
              fontSize: "2.075rem",
              lineHeight: "50px",
            },
          }}
        >
          Get more access to revenue,{" "}
          <span style={{ color: " var(--primary-shades-400, #D6D1FA)" }}>
            <br /> while lowering your transaction fees
          </span>
        </Typography>
        {isLogin ? (
          <Button
            component={Link}
            to="/dashboard/"
            sx={{
              borderRadius: " 8px",
              border: "1px solid var(--primary-shades-400, #D6D1FA)",
              background: "#fff",
              color: " var(--main-colors-primary, #5235E8)",
              mt: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "none",
              fontFamily: "SF Pro Display",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "25.56px",
              p: "11px 24px",
              "&:hover": {
                border: "1px solid var(--primary-shades-400, #D6D1FA)",
                background: "#fff",
                color: " var(--main-colors-primary, #5235E8)",
              },
            }}
          >
            Go to Dashboard
          </Button>
        ) : (
          <Button
            component={Link}
            to="/signup/"
            sx={{
              borderRadius: " 8px",
              border: "1px solid var(--primary-shades-400, #D6D1FA)",
              background: "#fff",
              color: " var(--main-colors-primary, #5235E8)",
              mt: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "none",
              fontFamily: "SF Pro Display",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "25.56px",
              p: "11px 24px",
              "&:hover": {
                border: "1px solid var(--primary-shades-400, #D6D1FA)",
                background: "#fff",
                color: " var(--main-colors-primary, #5235E8)",
              },
            }}
          >
            Get Started Now
          </Button>
        )}
      </Box>
      <Box
        component="img"
        src={Img}
        sx={{
          height: "336px!important",
          display: { xs: "none", sm: "none", md: "none", lg: "block" },
        }}
      />
    </Box>
  );
}
