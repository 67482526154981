import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Container from "@mui/material/Container";
import { MainListItems, ReferralList, SettingList } from "./listItems";
import Backdrop from "@mui/material/Backdrop";
import { CircularProgress } from "@mui/material";
import DashboardDrawer from "./drawer";
import { LogoText } from "../Helpers/helperComponents";
import { useLocation } from "react-router-dom";
const drawerWidth = 308;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(5),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function DashboardLayout(props) {
  // const { role } = useSelector((state) => state?.auth);

  const { component } = props;
  const [open] = React.useState(true);

  // Backdrop usestates and functions
  const [openbackdrop, setOpenbackdrop] = React.useState(false);
  const handleClose = () => {
    setOpenbackdrop(false);
  };
  const { pathname } = useLocation();
  return (
    <ThemeProvider theme={defaultTheme}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackdrop}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: "flex", background: "#F5F5F5" }}>
        <CssBaseline />
        <DashboardDrawer />
        <Drawer
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            overflow: "hidden",
          }}
          variant="permanent"
          open={open}
        >
          <Box
            sx={{
              // display: 'flex',
              // justifyContent:'center',
              p: "20px",
            }}
          >
            <LogoText />
          </Box>

          <List
            component="nav"
            sx={{
              padding: "0px",
              // pt: "32px",
            }}
          >
            {/referral/.test(pathname) ? (
              <ReferralList />
            ) : /settings/.test(pathname) ? (
              <SettingList />
            ) : (
              <MainListItems />
            )}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: "#FFF",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar sx={{ height: "80px" }} />
          <Container
            maxWidth="false"
            sx={{ padding: "0!important", background: "#fff" }}
          >
            {component}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
