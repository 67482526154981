import { PDFViewer, pdf } from "@react-pdf/renderer";
import React, { useState } from "react";
import { Box, CircularProgress, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import WithDrawalStatement from "../../components/Pdf/WithDrawalStatement";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler } from "../../Redux/RequestHandlers";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const WithdrawalInvoice = ({ data }) => {
  const { withdrawalRequestList } = useSelector((state) => state?.transactions);
  const [open, setOpen] = useState(false);
  const [statements, setStatements] = useState([]);
  const dispatch = useDispatch();

  const init = async () => {
    if (!data?.id) return;
    const req = {
      url: "withdrawalStatement",
      params: {
        wrid: data?.id,
      },
    };

    const res = await dispatch(GetHandler(req));
    if (res?.data?.success) {
      setStatements(res?.data?.data);
      return res;
    }
    return false;
  };

  const handleClickOpen = async() => {
    const res = await init();
    setOpen(res?.data?.success ? true : false);
  };
  const handleClose = () => setOpen(false);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const generatePdfBlob = async (statements) => {
    const doc = (
      <WithDrawalStatement
        wrDetails={data}
        statements={statements}
        kycinfo={withdrawalRequestList?.userDetails}
      />
    );
    const asBlob = await pdf(doc).toBlob();
    const url = URL.createObjectURL(asBlob);
    return url;
  };

  const handleDownload = async () => {
    setDownloadLoading(true);
    // getting data
    const res = await init();
    if (res?.data?.success) {
      const blobUrl = await generatePdfBlob(res?.data?.data);
      if (blobUrl) {
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `${data?.id}_withdrawal_statement.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setDownloadLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: 'center'}}>
        <IconButton onClick={handleClickOpen} color="success">
          <PreviewOutlinedIcon />
        </IconButton>
        {downloadLoading ? (
          <CircularProgress size="1.3rem" />
        ) : (
          <IconButton onClick={handleDownload} color="primary">
            <FileDownloadOutlinedIcon />
          </IconButton>
        )}
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <Box
          sx={{
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PDFViewer
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <WithDrawalStatement
              wrDetails={data}
              statements={statements}
              kycInfo={withdrawalRequestList?.userDetails}
            />
          </PDFViewer>
        </Box>
      </Dialog>
    </>
  );
};

export default WithdrawalInvoice;
