import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  MenuItem,
  
  IconButton,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CloseIcon2 } from "../../components/Helpers/svg";
import moment from "moment";
import { CopyToClipboardButton } from "../../components/Helpers/helperComponents";

// import { CopyToClipboardButton } from "../../components";
const Tcell = ({ title }) => (
  <Typography
    sx={{
      color: "var(--text-active, #1D283D)",
      fontFamily: "SF Pro Display",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px" /* 125% */,
      minWidth: "100px",
    }}
    className="hide-scroll"
  >
    {title}
  </Typography>
);

const InnerTableCell = ({ content }) => (
  <Typography
    style={{
      color: "var(--text-active, #1D283D)",
      fontFamily: "SF Pro Display",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px" /* 125% */,
      textAlign: "right",
      maxWidth: "380px",
      wordWrap: "break-word",
    }}
    className="hide-scroll"
  >
    {content}
  </Typography>
);

const ContentRow = ({ left, right, isCopy }) => (
  <Box
    sx={{
      my: "20px",
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    }}
  >
    <Tcell title={left} />
    <Box
      sx={{
        maxWidth: "100%",
        display: "flex",
        gap: "5px",
        wordWrap: "break-word",
        overflow: "auto",
      }}
    >
      <InnerTableCell content={right} />
      {isCopy && <CopyToClipboardButton content={right} />}
    </Box>
  </Box>
);

const DetailsModal = (props) => {
  const { accountInfo, opens, close, isShow } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (props?.close) close();
  };

  useEffect(() => {
    setOpen(opens);
  }, [opens]);

  return (
    <>
      {!isShow && (
        <MenuItem variant="contained" onClick={handleClickOpen}>
          Account Info{" "}
        </MenuItem>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
       
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            // width: 500px
            height: "60px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "var(--mono-white, #FFF)",
            boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.03)",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              textAlign: "left",
              fontSize: "14px",
            }}
          >
            See Details
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon2 />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              borderRadius: "5px",
              p: {
                xs: "0px",
                md: "15px",
              },
              my: "4rem",
            }}
          >
            <ContentRow left="Name" right={accountInfo?.name} />
            <ContentRow left="Type" right={accountInfo?.type} />
            <ContentRow
              left="Generated At"
              right={moment(accountInfo?.createdAt).format("llll")}
            />
            <ContentRow left="Api Key" right={accountInfo?.key} isCopy={true} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              px: "15px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                background: "#495ECA",
                textTransform: "none",
                //   width: "253px",
                height: "39px",
                color: "var(--mono-white, #FFF)",
                textAlign: "center",
                fontFamily: "SF Pro Display",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                width: "100%",
              }}
            >
              Done
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DetailsModal;
