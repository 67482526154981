import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uploadHandler } from "../../../Redux/RequestHandlers";
import { InputLabels } from "../../../components/Helpers/label";
import { PrimaryInputComponents } from "../../../components/Style";
import { ButtonLoader, PrimaryButton2 } from "../../../components/Buttons";
import KYCPending from "./Pending";
import StepMenu from "./StepMenu";

const CompanyVerification = () => {
  const { kycStatus } = useSelector((state) => state?.dashboard);

  const [inputs, setInputs] = useState({
    companydocumenttype: "Trade/Company Licence",
  });
  const [images, setImages] = useState({});
  const [rawFiles, setRawFiles] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setRawFiles((prev) => ({
        ...prev,
        [e.target.name]: e.target.files[0],
      }));
      setImages((prev) => ({
        ...prev,
        [e.target.name]: URL.createObjectURL(e.target.files[0]),
      }));
    }
  };

  useEffect(() => {
    setIsDisable(
      inputs?.companyname && rawFiles?.companydocument ? false : true
    );
  }, [inputs, rawFiles]);

  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (isDisable) return setLoading(false);
      const data = new FormData();
      data.append("companyname", inputs?.companyname);
      data.append("companydocument", rawFiles?.companydocument);
      const req = {
        url: "companyVerification",
        payload: data,
        action: "ACTION_CHANGE_KYC_COMPANY",
      };

      const response = await dispatch(uploadHandler(req));
      setLoading(false);
      if (response?.data?.success)
        return navigate("/settings/kyc/company-verification/");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!kycStatus?.identity) navigate("/settings/kyc/identity-verification/");
  }, [kycStatus, navigate]);
  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            py: "20px",
          }}
        >
          <Grid container>
            <Grid item lg="3" sx={{ pr: "15px" }}>
              <StepMenu />
            </Grid>

            <Grid item xs="12" lg="9">
              {/* get apis */}
              <Box
                sx={{
                  p: {
                    xs: "15px",
                    md: "30px",
                  },
                  borderRadius: "8px",
                  border: "1px solid var(--neutral-200, #E3E3E8)",
                  background: "var(--main-colors-white, #FFF)",
                }}
              >
                {/Requested|Pending|Completed/.test(kycStatus?.status) ||
                kycStatus?.company ? (
                  <>
                    <Box>
                      <KYCPending
                        kyc={kycStatus?.status}
                        to="/settings/kyc/withdrawal-verification/"
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <InputLabels label="Company Name" mb="5px" />
                        <PrimaryInputComponents
                          id="companyname"
                          type="text"
                          onChange={handleChange}
                          placeholder="Enter company name"
                          name="companyname"
                          value={inputs?.companyname || ""}
                        />
                      </Grid>
                    </Grid>

                    <InputLabels
                      label="Upload Trade/Company Licence"
                      mt="25px"
                    />
                    <Box
                      sx={{
                        mt: "15px",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Box
                          className="modal_input_image"
                          sx={{
                            height: "400px",
                            borderRadius: "40px",
                            border: "1px dashed var(--Gray-4, #BDBDBD)",
                            background: "#F4F4F4",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "hidden",
                            // placeContent: "center",
                          }}
                        >
                          <label className="mt-3">
                            {images?.companydocument ? (
                              <Box
                                sx={{
                                  maxHeight: "100%",
                                  backgroundSize: "contain",
                                }}
                                component={"img"}
                                src={images?.companydocument}
                              />
                            ) : (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="62"
                                  height="62"
                                  viewBox="0 0 62 62"
                                  fill="none"
                                >
                                  <path
                                    d="M3.875 15.5H17.4375L23.25 7.75H38.75L44.5625 15.5H58.125V50.375H3.875V15.5Z"
                                    stroke="#005EFE"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M31 40.6875C36.3503 40.6875 40.6875 36.3503 40.6875 31C40.6875 25.6497 36.3503 21.3125 31 21.3125C25.6497 21.3125 21.3125 25.6497 21.3125 31C21.3125 36.3503 25.6497 40.6875 31 40.6875Z"
                                    stroke="#005EFE"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                Click to upload image
                              </>
                            )}
                            <input
                              type="file"
                              name="companydocument"
                              onChange={onImageChange}
                              // value={fileInputState}
                              // accept=""
                              accept="image/*"
                              required
                            />
                          </label>
                        </Box>
                        <Typography
                          sx={{
                            mt: "20px",
                            color: "#404B7C",
                            fontFamily: "SF Pro Display",
                            fontSize: "16px",
                            fontWeight: 400,
                          }}
                        >
                          Provide files in JPG or PDF format, 10 MB maximum
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ mt: "20px" }}>
                      {loading ? (
                        <ButtonLoader maxWidth="100px" />
                      ) : (
                        <PrimaryButton2
                          title="Save"
                          onClick={handleSubmit}
                          disabled={isDisable}
                        />
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default CompanyVerification;
