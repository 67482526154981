import React, { useState } from "react";
import {
  signInWithPopup,
  GoogleAuthProvider,
  // OAuthProvider,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from ".";
import { SocialButton } from "../Buttons";
import { GoogleLogoSvg } from "../Helpers/svg";
import { useDispatch } from "react-redux";
import { PostHandler } from "../../Redux/RequestHandlers";
import { Box, CircularProgress } from "@mui/material";

function SocialAppLogin(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async (payload) => {
    try {
      let data = payload;
      if (props?.refCode) data.refCode = props?.refCode;
      setIsSubmitting(true);
      let req = {
        url: "sso",
        payload: data,
        action: "ACTION_LOGIN",
      };
      const res = await dispatch(PostHandler(req));
      setIsSubmitting(false);
      if (res?.data?.success) {
        window.localStorage.setItem(
          "accessToken",
          res?.data?.data?.access_token
        );
        if (res?.data?.data?.refresh_token)
          window.localStorage.setItem(
            "refreshToken",
            res?.data?.data?.refresh_token
          );

        navigate(
          res?.data?.data?.detailsExistance ? "/dashboard/" : "/account/setup/"
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const signInWithGoogle = async () => {
    try {
      setIsSubmitting(true);
      const provider = new GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/userinfo.email");
      const result = await signInWithPopup(auth, provider);
      if (!result?.user) return;
      // The signed-in user info.

      const user = result.user;
      // Get the ID token
      const idToken = await user.getIdToken();
      let params = {
        idToken: idToken,
      };

      // sending to the backend for login or registration
      handleSubmit(params);
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);

      console.error(errorCode, errorMessage, email, credential);
    }
  };

  // const signInWithApple = async () => {
  //   try {
  //     const provider = new OAuthProvider('apple.com');
  //     // Additional scopes can be added if required
  //     provider.addScope('email');

  //     // Sign in with Apple using Firebase
  //     const result = await signInWithPopup(auth, provider);

  //     // Check if user is signed in
  //     if (!result?.user) return;

  //     // Get the user's Firebase ID token
  //     const user = result.user;
  //     const idToken = await user.getIdToken();

  //     // Now you can use this ID token for authentication and authorization

  //     // Additional user info
  //     const providerData = user.providerData.length > 0 && user.providerData[0];
  //     let params = {
  //       ssoid: providerData?.uid,
  //       ssotype: providerData?.providerId,
  //       email: providerData?.email,
  //       // Add the Firebase ID token to your params if needed
  //       idToken: idToken
  //     };

  //     // Sending to the backend for login or registration
  //     handleSubmit(params);
  //   } catch (error) {
  //     console.error("Firebase error", error);
  //     // Handle errors
  //   }
  // };

  return !isSubmitting ? (
    <>
      <SocialButton
        icon={<GoogleLogoSvg />}
        title="Continue with Google"
        onClick={signInWithGoogle}
      />
      {/* <SocialButton
        icon={<GoogleLogoSvg />}
        title="Sign up with Apple"
        onClick={signInWithApple}
      /> */}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default SocialAppLogin;
