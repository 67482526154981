import React from "react";
import { Box } from "@mui/material";
import WalletComponentOne from "./components/WalletComponentOne";
import Chart from "../Dashboard/components/Left/chart";
import WalletComponentTwo from "./components/WalletComponentTwo";

export default function WalletComponent() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: "32px" }}>
      <WalletComponentOne />
      <Box sx={{mb:"40px"}}>
        <Chart />
      </Box>
      <WalletComponentTwo />
    </Box>
  );
}
