// eslint-disable-next-line import/no-anonymous-default-export
export default {
    user: {
        registration: 'auth/merchant/register',
        login: 'auth/merchant/login',
        sso: 'auth/merchant/registersso',
        otpVerification: 'auth/merchant/verify-otp',
        otpVerificationSubmerchant: 'auth/merchant/submerchant-verify-otp',
        resendOtp: 'auth/merchant/send-otp',
        updateProfile: 'auth/merchant/update-profile',
        refreshToken: 'auth/merchant/generate-access-token',
        deleteRequest: 'auth/merchant/delete-request',

        // forget password
        initiateForgetPassword: 'merchant/forgot-password/initiate',
        verifyForgetPassOtp: 'merchant/forgot-password/verify',
        resendForgetPassOtp: 'merchant/forgot-password/resend-otp',
        resetPassword: 'merchant/forgot-password/reset',

        withdrawalRequest: 'merchant/withdrawal-request',
        withdrawalStatement: 'merchant/withdrawal-request/statement',
        // createWR: 'merchant/create-withdrawal-request',

        externalCurrency: 'merchant/configuration/external-currencies',

        // userapi-key
        apiKey: 'merchant/apikey',
        updateApiKeyCallback: 'merchant/update-api-key-callback',
        lockApiKey: 'merchant/apikey/lock',
        deleteApiKey: 'merchant/apikey/delete',

        // user 
        dashboard: 'merchant/dashboard',
        initiateEmailChange: 'merchant/initiate-change-email',
        resendEmailOtp: 'merchant/resend-change-email-otp',
        changeEmail: 'merchant/verify-change-email',
        initiatePhoneChange: 'merchant/initiate-change-phone',
        resendPhoneChangeOTP: 'merchant/resend-change-phone-otp',
        changePhone: 'merchant/verify-change-phone',
        
        // settings
        profile: 'merchant/settings/profile',
        changePassword: 'merchant/settings/change-password',

        // editMarchant: 'merchant/edit',

        contactUs: 'merchant/contact',

        // Tickets
        tickets: 'merchant/ticket',
        // getTicket: 'ticket/get',

        transactions: 'merchant/transactions',
        prices: 'merchant/prices',

        // kyc 
        identityVerification: 'merchant/identity-verification',
        companyVerification: 'merchant/company-verification',
        bankVerification: 'merchant/bank-verification',
        cryptoVerification: 'merchant/add-crypto-address',
        getKycInfo: 'merchant/kyc-info',

        // vat
        vat: 'merchant/configuration/vat',
        updateVat: 'merchant/configuration/vat/update',

        // smartlink
        smartLink: 'merchant/smartlink',
        getSmartLinks: 'merchant/smartlink',
        removeSmartLink: 'merchant/smartlink/delete',
        tokenList: "token-list",

        // referral
        referralDashboard: 'referral/dashboard',

        // Roles 
        roles: 'merchant/roles',
        rolesUpdate: 'merchant/roles/update',
        rolesDelete: 'merchant/roles/delete',

        merchantUsers: 'merchant/user',
        merchantUsersVerify: 'merchant/user/verify',
        merchantUsersGetOtp: 'merchant/user/resend-otp',
        merchantUsersUpdate: 'merchant/user/update',
        merchantUsersDelete: 'merchant/user/delete',

        // accessibilityPage: 'merchant/user/accessibility-page',
        accessibilityPage: 'merchant/user/accessibility-page',

        rolePermissions: 'merchant/rolespermissons',
        rolePermissionsUpdate: 'merchant/rolespermissons/update',

        permissions: 'merchant/permissions',
        permissionsUpdate: 'merchant/permissions/update',
    },
    external: {
        invoiceDetails: 'paymentgateway/getInvoiceDetails',
        initiateRefundDetails: 'paymentgateway/insertRefundDetails',
        initiateInvoicePayment: 'paymentgateway/initiateInvoicePayment',
        getCurrencies: "paymentgateway/getCurrencies",
        getRealTimePrice: "paymentgateway/getRealTimePrice",
        cancelTransaction: "paymentgateway/rejectInvoicePayment",
        refund: "v1/insertRefundTransaction",
        initiateInvoice: "v1/initiateInvoice",
        expireTransaction: "paymentgateway/expireTransaction",
        getRefundDetails: "paymentgateway/getRefundDetails",
        transactionPolling: "paymentgateway/transaction-polling-status",
        smartlinkDetails: "paymentgateway/smartlinkDetails",
        smartlinkInitiatePayment: "paymentgateway/smartlinkInitiatePayment",
    }
}
