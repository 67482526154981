import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AuthHeader,
  AuthP,
  LogoText,
} from "../../components/Helpers/helperComponents";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PostHandler } from "../../Redux/RequestHandlers";
import { ButtonLoader, PrimaryButton } from "../../components/Buttons";
import { PrimaryInputComponents } from "../../components/Style";

const VerifyForgetPassword = () => {
  const { email } = useSelector((state) => state.auth);
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (e) => {
    if (
      e.target.name === "otp" &&
      (isNaN(e.target.value) || e.target.value.length > 6)
    )
      return;
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "verifyForgetPassOtp",
        payload: inputs,
        action: "ACTION_FORGETPASS_VERIFY",
      };
      const res = await dispatch(PostHandler(req));
      setIsLoading(false);
      if (res?.data?.success) {
        window.localStorage.setItem(
          "accessToken",
          res?.data?.data?.access_token
        );
        navigate("/reset-password/set-password/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleResendOtp = async () => {
    try {
      setIsOtpLoading(true);
      const req = {
        url: "resendForgetPassOtp",
      };
      await dispatch(PostHandler(req));
      setIsOtpLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisable(inputs?.otp ? false : true);
  }, [inputs]);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          p: {
            xs: "40px",
            md: "80px",
          },
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          borderRadius: "8px",
          border: "1px solid var(--neutral-200, #E3E3E8)",
          background: "var(--main-colors-white, #FFF)",
          boxShadow: "10px 4px 30px 0px rgba(161, 145, 243, 0.10)",
          maxWidth: "400px",
        }}
      >
        <LogoText />
        <AuthHeader text="Check your email" />
        <AuthP
          text={`We emailed 6 digit OTP to reset your password to ${email}`}
        />
        {!isOtpLoading ? (
          <Box
            onClick={handleResendOtp}
            sx={{
              color: "var(--main-colors-primary, #5235E8)",
              textDecoration: "none",
              cursor: "pointer",
              mb: "30px",
            }}
          >
            Resend OTP
          </Box>
        ) : (
          <CircularProgress />
        )}
        <PrimaryInputComponents
          id="otp"
          name="otp"
          type="number"
          onChange={handleChange}
          value={inputs?.otp || ""}
          placeholder="Enter six digit otp here"
        />

        {isLoading ? (
          <ButtonLoader />
        ) : (
          <PrimaryButton
            title="Verify Email"
            onClick={handleSubmit}
            disabled={isDisable}
            mt="15px"
          />
        )}

        <Typography
          sx={{
            mt: "20px",
            color: "#0E0637",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20.6px",
            lineSpacing: "0.08px",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Link
            to="/reset-password/initiate/"
            style={{
              color: "var(--main-colors-primary, #5235E8)",
              textDecoration: "none",
            }}
          >
            Go Back
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default VerifyForgetPassword;
