import { PDFViewer, pdf } from "@react-pdf/renderer";
import InvoicePDF from "./InvoicePdf";
import React, { useState, useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
const TransactionPdfViewer = (props) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [blobUrl, setBlobUrl] = useState(null);

  const handleDownload = () => {
    if (blobUrl) {
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${props?.data?.id}_invoice.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    const generatePdfBlob = async () => {
      const doc = <InvoicePDF data={props?.data} />;
      const asBlob = await pdf(doc).toBlob();
      const url = URL.createObjectURL(asBlob);
      setBlobUrl(url);
    };

    if (props?.data) generatePdfBlob();
  }, [props?.data]);
  return (
    <>
      <IconButton onClick={handleClickOpen} color="success">
        <PreviewOutlinedIcon />
      </IconButton>
      {/* <Button onClick={handleClickOpen}>Download</Button> */}
      {blobUrl && (
        <IconButton onClick={handleDownload} color="primary">
          <FileDownloadOutlinedIcon />
        </IconButton>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <Box
          sx={{
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PDFViewer
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <InvoicePDF data={props?.data} />
          </PDFViewer>
        </Box>
      </Dialog>
    </>
  );
};

export default TransactionPdfViewer;
