import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import PortfolioComponent from "./portfolioComponent";
export default function LeftGridComponentThree() {
  
  return (
    <Box
      sx={{
        py: "23px",
        borderTop: "1px solid var(--neutral-200, #E3E3E8)",
        // borderBottom: "1px solid var(--neutral-200, #E3E3E8)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          mb:"15px"
        }}
      >
        <Typography
          sx={{
            color: "var(--neutral-900, #131316)",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "27.9px",
          }}
        >
          My Portfolio 
        </Typography>
        <Typography
        component={Link}
          sx={{
            color: "#495ECA",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "16.2px",
            letterSpacing:"0.06px", textDecoration:"none"
          }}
        >
          See all
        </Typography>
      </Box>
      <PortfolioComponent />
    </Box>
  );
}
