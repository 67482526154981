import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import BasicTableWithBody, {
  NewBasicTableWithBody,
  NoRecordInTable,
  StyledTableCell,
  StyledTableRow,
  TableDataLoad,
} from "../../components/Tables";
import AddIcon from "@mui/icons-material/Add";

import { useDispatch, useSelector } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
// import InnerLayout from "../../components/Layout/InnerLayout";
import AddRoles from "./AddRoles";
// import { CommonAlertDialog } from "../../components/Modal";
// import { CustomizedIconButton } from "../../components/Buttons/IconButtons";
import DeleteIcon from "@mui/icons-material/Delete";
import { getTotalPage, hasAccess } from "../../utilities";
import AlertDialog from "../../components/Dialog";
import LeftToRightModal from "../../components/Dialog/LeftToRightModal";
import GetPermissions from "./GetPermissions";
import AddUser from "./Users/AddUser";
import EditSubMerchant from "./Users/EditUser";
import VerifySubMerchantOTP from "./Users/VerifySubMerchantOTP";
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';

export default function Roles() {
  const { roles, users } = useSelector((state) => state?.roles);
  const { accessLists } = useSelector((state) => state.auth);
  const [totalPages, setTotalPages] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(1);
  const perPage = useState(10);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // const [isDisable, setIsDisable] = useState(false);

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      //   setIsDisable()
      const req = {
        url: "rolesDelete",
        payload: { rid: id },
      };

      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) init();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUserDelete = async (id) => {
    try {
      setIsLoading(true);
      const req = {
        url: "merchantUsersDelete",
        payload: { subid: id },
        action: "ACTION_GET_USERS_RETURNED",
      };
      await dispatch(PostHandler(req));
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const init = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "accessibilityPage",
        action: "ACTION_GET_ROLES_PAGE",
      };
      const res = await dispatch(GetHandler(req));
      if (res?.data?.data?.users?.count)
        setTotalPages(
          parseInt(getTotalPage(res?.data?.data?.users?.count, perPage))
        );
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getRoles = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "roles",
        action: "ACTION_GET_ROLES",
        params: { pageNumber: 1 },
      };
      const res = await dispatch(GetHandler(req));
      if (res?.data?.data?.count)
        setTotalPages(parseInt(getTotalPage(res?.data?.data?.count, perPage)));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getUsers = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "merchantUsers",
        action: "ACTION_GET_SUBUSERS",
        params: { pageNumber: 1 },
      };
      const res = await dispatch(GetHandler(req));
      if (res?.data?.data?.count)
        setTotalPages(parseInt(getTotalPage(res?.data?.data?.count, perPage)));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (page > 1) getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (page === 1 && page > 0) init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} lg={8} sx={{ p: "15px" }}>
          {hasAccess(["User-create"], accessLists) && (
            <Box sx={{ maxWidth: "120px" }}>
              <LeftToRightModal
                openButton={
                  <Tooltip title="Add new role" arrow>
                    <Button
                      variant="contained"
                      sx={{ textTransform: "capitalize" }}
                    >
                      <AddIcon /> Add User
                    </Button>
                  </Tooltip>
                }
                modalBody={<AddUser roles={roles} />}
                width="40%"
                modalTitle="Add new user"
              />
            </Box>
          )}
          <Box sx={{ mt: "15px" }}>
            <BasicTableWithBody
              minWid="100%"
              manWid="100%"
              tableHead={["Email", "Role", "Created At", "Action"]}
              tableBody={
                <>
                  {isFetching ? (
                    <TableDataLoad />
                  ) : users?.length > 0 ? (
                    users.map((row) => (
                      <StyledTableRow key={row?.id}>
                        <StyledTableCell component="th" scope="row">
                          {row?.email}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.role?.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {moment(row?.createdAt).format("DD-MM-YY")}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Box sx={{ display: "flex", alignItems: 'center' }}>
                            {hasAccess(["User-update"], accessLists) && row?.isverified && (

                              <VerifiedOutlinedIcon color="success" sx={{ mr: '5px' }} />
                            )}

                            {hasAccess(["User-update"], accessLists) && !row?.isverified && (

                              <LeftToRightModal
                                openButton={
                                  <Tooltip title="Verify User" arrow>
                                    <IconButton color="primary">
                                      <AppRegistrationOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                }
                                modalTitle={`Verify User ${row?.email}`}
                                modalBody={<VerifySubMerchantOTP uid={row?.id} mail={row?.email} otpMsgShow={false} />}
                              />
                            )}
                            {hasAccess(["User-update"], accessLists) && (
                              <LeftToRightModal
                                openButton={
                                  <Tooltip title="Edit User" arrow>
                                    <IconButton color="primary">
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                }
                                modalTitle={`Manage Permissions for ${row?.email}`}
                                modalBody={<EditSubMerchant data={row} />}
                              />
                            )}
                            {hasAccess(["User-delete"], accessLists) && (
                              <AlertDialog
                                customComponent={
                                  isLoading ? (
                                    <CircularProgress size="2.4rem" />
                                  ) : (
                                    <Tooltip title="Delete User" arrow>
                                      <IconButton color="error">
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                }
                                execute={() => handleUserDelete(row?.id)}
                                executeLoader={isLoading}
                                // disabled={isDisable}
                                title={"Please confirm to delete user."}
                              />
                            )}
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <NoRecordInTable />
                  )}
                </>
              }
              pages={totalPages}
              page={page}
              setPage={setPage}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={4} sx={{ p: "15px" }}>
          {hasAccess(["Role-create"], accessLists) && (
            <AddRoles init={getRoles} />
          )}
          <NewBasicTableWithBody
            minWid="100%"
            maxWid="100%"
            tableHead={["Name", "Created At", "Action"]}
            tableBody={
              <>
                {isFetching ? (
                  <TableDataLoad />
                ) : roles?.length > 0 ? (
                  roles.map((row) => (
                    <StyledTableRow key={row?.id}>
                      <StyledTableCell component="th" scope="row">
                        {row?.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {moment(row?.createdAt).format("DD/MM/YY")}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"

                      >
                        <Box sx={{ display: "flex" }}>
                          {hasAccess(["Role-update"], accessLists) && (
                            <LeftToRightModal
                              openButton={
                                <Tooltip title="Edit Permissions" arrow>
                                  <IconButton color="primary">
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              }
                              modalTitle={`Manage Permissions for ${row?.name}`}
                              modalBody={<GetPermissions id={row?.id} />}
                            />
                          )}
                          {hasAccess(["Role-delete"], accessLists) &&
                            !row?.readonly && (
                              <AlertDialog
                                customComponent={
                                  isLoading ? (
                                    <CircularProgress size="2.4rem" />
                                  ) : (
                                    <Tooltip title="Delete Role" arrow>
                                      <IconButton color="error">
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                }
                                execute={() => handleDelete(row?.id)}
                                executeLoader={isLoading}
                                // disabled={isDisable}
                                title={"Please confirm to delete role."}
                              />
                            )}
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <NoRecordInTable />
                )}
              </>
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
