import { Box, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import StatusShow from "../../../../components/Tables/StatusShow";

export const CheckedStep = () => (
  <Box
    sx={{
      zIndex: 1,
      color: "#fff",
      width: 45,
      minHeight: 45,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      background: "var(--primary-shades-50, #F7F6FE)",
    }}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 2.1665C5.6741 2.1665 2.16699 5.67361 2.16699 9.99984C2.16699 12.8986 3.74133 15.4303 6.08402 16.7855C6.32305 16.9238 6.40473 17.2297 6.26646 17.4687C6.12819 17.7077 5.82233 17.7894 5.58329 17.6511C2.94431 16.1246 1.16699 13.27 1.16699 9.99984C1.16699 5.12132 5.12181 1.1665 10.0003 1.1665C14.8788 1.1665 18.8337 5.12132 18.8337 9.99984C18.8337 13.27 17.0563 16.1246 14.4174 17.6511C14.1783 17.7894 13.8725 17.7077 13.7342 17.4687C13.5959 17.2297 13.6776 16.9238 13.9166 16.7855C16.2593 15.4303 17.8337 12.8986 17.8337 9.99984C17.8337 5.67361 14.3266 2.1665 10.0003 2.1665ZM9.64677 6.31301C9.84203 6.11775 10.1586 6.11775 10.3539 6.31301L13.6872 9.64634C13.8825 9.8416 13.8825 10.1582 13.6872 10.3534C13.4919 10.5487 13.1754 10.5487 12.9801 10.3534L10.5003 7.87367V18.3332C10.5003 18.6094 10.2765 18.8332 10.0003 18.8332C9.72418 18.8332 9.50032 18.6094 9.50032 18.3332V7.87367L7.02054 10.3534C6.82528 10.5487 6.5087 10.5487 6.31344 10.3534C6.11818 10.1582 6.11818 9.8416 6.31344 9.64634L9.64677 6.31301Z"
        fill="#495ECA"
      />
    </svg>
  </Box>
);

export const UncheckedStep = () => (
  <Box
    sx={{
      zIndex: 1,
      color: "#fff",
      width: 45,
      minHeight: 45,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      background: "var(--neutral-50, #F9F9FA)",
    }}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 1.16675C10.2765 1.16675 10.5003 1.39061 10.5003 1.66675V12.1263L12.9801 9.64653C13.1754 9.45127 13.492 9.45127 13.6872 9.64653C13.8825 9.84179 13.8825 10.1584 13.6872 10.3536L10.3539 13.687C10.1586 13.8822 9.84203 13.8822 9.64677 13.687L6.31344 10.3536C6.11818 10.1584 6.11818 9.84179 6.31344 9.64653C6.5087 9.45127 6.82528 9.45127 7.02055 9.64653L9.50033 12.1263V1.66675C9.50033 1.39061 9.72418 1.16675 10.0003 1.16675ZM6.26646 2.53124C6.40473 2.77027 6.32305 3.07613 6.08402 3.2144C3.74133 4.56958 2.16699 7.10127 2.16699 10.0001C2.16699 14.3263 5.6741 17.8334 10.0003 17.8334C14.3266 17.8334 17.8337 14.3263 17.8337 10.0001C17.8337 7.10127 16.2593 4.56958 13.9166 3.2144C13.6776 3.07613 13.5959 2.77027 13.7342 2.53124C13.8725 2.29221 14.1783 2.21053 14.4174 2.3488C17.0563 3.87537 18.8337 6.72987 18.8337 10.0001C18.8337 14.8786 14.8788 18.8334 10.0003 18.8334C5.12181 18.8334 1.16699 14.8786 1.16699 10.0001C1.16699 6.72987 2.94431 3.87537 5.5833 2.3488C5.82233 2.21053 6.12819 2.29221 6.26646 2.53124Z"
        fill="#495ECA"
      />
    </svg>
  </Box>
);

const Stepper = () => {
  const { recentTransactions } = useSelector((state) => state?.dashboard);
  return (
    <>
      <Box
        sx={{ p: "32px", borderTop: "1px solid var(--neutral-200, #E3E3E8)" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "24px",
          }}
        >
          <Typography
            sx={{
              color: "var(--neutral-900, #131316)",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "27.9px",
            }}
          >
            Recent Transaction
          </Typography>
          <Typography
            component={Link}
            sx={{
              color: "#495ECA",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "16.2px",
              letterSpacing: "0.06px",
              textDecoration: "none",
            }}
            to="/transactions/"
          >
            See all
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            maxHeight: "250px",
            height: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "10px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "var(--neutral-50, #F9F9FA)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "var(--neutral-200, #E3E3E8)",
              borderRadius: "24px!important",
            },
          }}
        >
          {/* icon boxes */}
          <Box
            sx={{
              height: "100%",
            }}
          >
            {recentTransactions?.length > 0 &&
              recentTransactions?.map((item, index) => (
                <Box
                  sx={{
                    height: "100px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  key={item?.createdAt + index + 'y'}
                >
                  {item?.type === "Payment" ? (
                    <UncheckedStep />
                  ) : (
                    <CheckedStep />
                  )}
                  {recentTransactions?.length > 0 && index < 4 && (
                    <div
                      style={{
                        height: "100%",
                        background: "#E3E3E8",
                        width: "2px",
                      }}
                    ></div>
                  )}
                </Box>
              ))}
          </Box>
          {/* end icon boxes */}

          {/* text boxes */}
          <Box
            sx={{
              height: "100%",
              pl: "20px",
              width: "100%",
            }}
          >
            {recentTransactions?.length > 0 &&
              recentTransactions?.map((item, index) =>
                item?.type.toLowerCase() === "withdraw" ? (
                  <Box
                    sx={{
                      height: "100px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      mr: "5px",
                    }}
                    key={item?.createdAt + index + 'x'}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--neutral-500, #717184)",
                          fontFeatureSettings: `'ss06' on`,
                          fontFamily: "SF Pro Display",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "16.2px",
                        }}
                      >
                        {moment(item?.createdAt).format("lll")}
                      </Typography>
                      <Typography
                        sx={{
                          color: "var(--neutral-500, #717184)",
                          fontFeatureSettings: `'ss06' on`,
                          fontFamily: "SF Pro Display",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "16.2px",
                          letterSpacing: "0.06px",
                        }}
                      >
                        -{item?.currency + " " + item?.requestedfiatamount}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--neutral-800, #2F2F37)",
                          fontFeatureSettings: `'ss06' on`,
                          fontFamily: "SF Pro Display",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "20.3px",
                        }}
                      >
                        {item?.type + " " + item?.token}
                      </Typography>

                      <StatusShow status={item?.status} />
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: "100px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      mr: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--neutral-500, #717184)",
                          fontFeatureSettings: `'ss06' on`,
                          fontFamily: "SF Pro Display",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "16.2px",
                        }}
                      >
                        {moment(item?.createdAt).format("lll")}
                      </Typography>
                      <Typography
                        sx={{
                          color: "var(--neutral-500, #717184)",
                          fontFeatureSettings: `'ss06' on`,
                          fontFamily: "SF Pro Display",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "16.2px",
                          letterSpacing: "0.06px",
                        }}
                      >
                        +{item?.currency} {item?.requestedfiatamount}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--neutral-800, #2F2F37)",
                          fontFeatureSettings: `'ss06' on`,
                          fontFamily: "SF Pro Display",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "20.3px",
                        }}
                      >
                        {item?.type} {item?.token}
                      </Typography>

                      <StatusShow status={item?.status} />
                    </Box>
                  </Box>
                )
              )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Stepper;
