import React from "react";
import { Box, Typography } from "@mui/material";
import WatchlistListComponent from "./watchlistCryptos";
import { useSelector } from "react-redux";
import { tokenHelpers } from "../../../../utilities";
export default function WatchlistComponent() {
  const { balancesData, showCurrency } = useSelector(
    (state) => state?.dashboard
  );

  return (
    <Box
      sx={{
        p: "24px",
        borderRadius: " 8px",
        border: "1px solid var(--neutral-200, #E3E3E8)",
        background: "var(--main-colors-white, #FFF)",
        boxShadow: "0px 0px 30px 0px rgba(161, 145, 243, 0.10)",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        height: "100%",
      }}
    >
      <Typography
        sx={{
          color: "var(--neutral-900, #131316)",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "25.6px",
          letterSpacing: "0.08px",
        }}
      >
        My Watchlist
      </Typography>
      <Box
        sx={{
          borderTop: "1px solid var(--neutral-200, #E3E3E8)",
          overflowY: "scroll",
          height: "283.75px",
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "var(--neutral-50, #F9F9FA)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "var(--neutral-200, #E3E3E8)",
            borderRadius: "24px!important",
          },
        }}
      >
        {balancesData?.balances?.length > 0 &&
          balancesData?.balances?.map((item) => (
            <WatchlistListComponent
              icon={item?.logo}
              cryptoName={tokenHelpers[item?.token]}
              cryptoShortName={item?.token}
              amount={
                showCurrency === "usd"
                  ? Number(item?.realTimePrice?.usd).toFixed(2)
                  : Number(item?.realTimePrice?.aed).toFixed(2)
              }
              showCurrency={showCurrency}
            />
          ))}
      </Box>
    </Box>
  );
}
