import { TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const PrimaryInputComponents = ({
  id,
  name,
  onChange,
  value,
  placeholder,
  type,
  disabled,
  error,
}) => (
  <TextField
    error={error || false}
    id={id}
    inputProps={{
      style: {
        height: "17px",
      },
    }}
    variant="outlined"
    sx={{
      width: "100%",
      "&:hover fieldset": {
        border: "1px solid #495ECA!important",
      },
      "&:focus-within fieldset, &:focus-visible fieldset": {
        border: "1px solid #495ECA!important",
        boxShadow: "0 0 0 2px #495eca6b",
      },
    }}
    name={name}
    onChange={onChange}
    value={value}
    placeholder={placeholder}
    type={type || "text"}
    disabled={disabled || false}
  />
);

export const PrimaryInputComponentsWithInputProps = ({
  id,
  name,
  onChange,
  value,
  placeholder,
  type,
  InputProps,
}) => (
  <TextField
    id={id}
    InputProps={{ ...InputProps, style: { height: "48px" } }}
    variant="outlined"
    sx={{
      width: "100%",
      "&:hover fieldset": {
        border: "1px solid #495ECA!important",
      },
      "&:focus-within fieldset, &:focus-visible fieldset": {
        border: "1px solid #495ECA!important",
        boxShadow: "0 0 0 2px #495eca6b",
      },
    }}
    name={name}
    onChange={onChange}
    value={value}
    placeholder={placeholder}
    type={type || "text"}
  />
);

export const FooterHeader = ({ title }) => (
  <Typography
    sx={{
      color: "var(--neutral-900, #131316)",
      fontFeatureSettings: " 'ss06' on",
      fontFamily: "SF Pro Display",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "25.6px",
      letterSpacing: "0.08px",
      mb: "8px",
    }}
  >
    {title}
  </Typography>
);

export const MenuLink = ({ onClick, to, title }) => (
  <Typography
    to={to || "/"}
    component={Link}
    onClick={onClick}
    sx={{
      color: "var(--neutral-500, #717184)",
      fontFeatureSettings: " 'ss06' on",
      fontFamily: "SF Pro Display",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20.6px",
      mt: "8px",
      textDecoration: "none!important",
    }}
  >
    {title}
  </Typography>
);

export const BoldLabel = ({ labelFor }) => (
  <Typography
    sx={{
      textAlign: "left",
      mb: "10px",
      color: "#242331",
      fontFamily: "SF Pro Display",
      fontSize: "16.2px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "150%" /* 24.3px */,
      textTransform: "capitalize",
    }}
  >
    {labelFor}
  </Typography>
);
