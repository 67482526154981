import { Box } from "@mui/material";
import React from "react";
import {
  AuthHeader2,
  AuthPRight,
} from "../../../components/Helpers/helperComponents";
import recentActivite from "../../../assets/img/signup/Recent_Activity.png";
import dasboardImg from "../../../assets/img/signup/Dashboard.png";
const RightComponent1 = () => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    <Box>
      <Box sx={{ display: "flex", gap: "10px", px: "80px", pt: "40px" }}>
        <AuthHeader2 text="~" />
        <AuthHeader2 text="With Gafah, multi-signature digital wallet, you can start saving." />
      </Box>
      <Box sx={{ px: "80px", pl: "110px", pt: "15px" }}>
        <AuthPRight text="If you're interested in getting involved in the booming cryptocurrency industry and want to own your own digital coins, then sign up with us today!" />
      </Box>
    </Box>
    <Box
      sx={{
        pl: "40px",
        pt: "80px",
        display: "flex",
        justifyContent: "end",
        height: "60vh",
      }}
    >
      <Box sx={{ mt: "20px" }}>
        <Box
          component="img"
          src={recentActivite}
          alt="recentActivite"
          sx={{
            transform: "scale(0.7)",
            width: "45vh",
            "@media (max-width:1360px)": {
              display: "none",
            },
            "@media (min-width:1160px) and  (max-width:1360px) and (max-height:1022px)": {
              width: "26vh !important",
            },
            "@media (min-width:1360px) and (max-width:1670px) and (max-height:1022px)": {
              width: "34vh !important",
            },
            "@media (min-width:1600px) and (max-width:2300px) and (max-height:1022px)": {
              width: "42vh !important",
            },
            "@media (min-height:1022px)": {
              width: "28vh !important",
            },
          }}
        />
      </Box>
      <Box>
        <Box
          component="img"
          src={dasboardImg}
          alt="dasboard"
          sx={{
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
            width: "50vh",
            "@media (max-width:1360px)  OR (min-height:1022px)": {
              width: "31vh !important",
            },
            "@media (min-width:1360px) and (max-width:1670px)  and (max-height:1022px)": {
              width: "45vh !important",
            },
            "@media (min-width:1600px) and (max-width:2300px)  and (max-height:1022px)": {
              width: "55vh !important",
            },
          }}
        />
      </Box>
    </Box>
  </Box>
);

export default RightComponent1;
