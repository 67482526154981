import { Box } from "@mui/material";
import React from "react";

export const StatusShowConfig = {
  Processing: {
    color: "#000",
    background: "#f2f21c6b",
  },
  Initiated: {
    color: "",
    background: "#f2f21c85",
  },
  "Completed (Manually)": {
    color: "#4e9b05",
    background: "#0a96761a",
  },
  Completed: {
    color: "#4e9b05",
    background: "#0a96761a",
  },
  Declined: {
    color: "#f80303",
    background: "#f3040426",
  },
  Failed: {
    color: "#f80303",
    background: "#f3040426",
  },
  Expired: {
    color: "#f80303",
    background: "#f3040426",
  },
};

const StatusShow = ({ status }) => {
  return (
    <Box
      sx={{
        color: StatusShowConfig[status]?.color,
        background: StatusShowConfig[status]?.background,
        width: "max-content",
        padding: "1px 10px",
        borderRadius: "10px",
        fontSize: "12px",
        fontWeight: 500,
      }}
    >
      {status}
    </Box>
  );
};

export default StatusShow;
