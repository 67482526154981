import React from "react";
import { Box, Typography } from "@mui/material";

export default function PricesList(props) {
  const {
    cryptoName,
    cryptoShortName,
    icon,
    type,
    // network,
    fiatValue,
    fiatCurrency,
    key,
  } = props;
  return (
    <Box
      key={key}
      sx={{
        width: "100%",
        borderBottom: "1px solid var(--neutral-200, #E3E3E8)",
        py: "16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
        <img src={icon} alt={icon} width="30px" style={{minWidth: '30px'}} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Box>
              <Typography
                sx={{
                  color: "var(--neutral-900, #131316)",
                  fontFeatureSettings: `'ss06' on`,
                  fontFamily: "SF Pro Display",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20.9px",
                  textTransform: "capitalize",
                }}
              >
                {cryptoName}
              </Typography>
              <Typography sx={{
                fontSize: '12px',
                mt: '5px'
              }}>{type}</Typography>
            </Box>
            <Typography
              sx={{
                color: "var(--neutral-400, #9C9CAB)",
                textAlign: "center",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "16.2px",
                letterSpacing: "0.06px",
              }}
            >
              {cryptoShortName}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "6px",
        }}
      >
        <Typography
          sx={{
            color: "var(--neutral-500, #717184)",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "17px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "16.2px",
          }}
        >
          Change
        </Typography>
        <Box sx={{ display: "flex", gap: "7px", alignItems: "center" }}>
          {" "}
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2011_5633)">
              <path
                d="M12 10L8 6L4 10H12Z"
                fill="#11CF8B"
                stroke="#11CF8B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_2011_5633">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <Typography
            sx={{
              color: "var(--main-colors-black, #0E0637)",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "16.2px",
            }}
          >
            {increaseAmount}%
          </Typography>
        </Box>
      </Box> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "4px",
        }}
      >
        {/* <Typography
          sx={{
            color: "var(--neutral-800, #2F2F37)",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20.2px",
          }}
        >
          {cryptoValue + " " + cryptoShortName}
        </Typography> */}

        <Typography
          sx={{
            color: "var(--neutral-500, #717184)",
            fontFeatureSettings: `'ss06' on`,
            textAlign: "right",
            fontFamily: "SF Pro Display",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "16.2px",
            textTransform: "uppercase",
          }}
        >
          {fiatCurrency} {Number(fiatValue).toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
}
