import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
// import { CircularProgress } from "@mui/material";

export default function HorizontalExternalQRPageStepper({ paymentData }) {
  const [activeStep, setActiveStep] = React.useState(0);
  // const [loading, setLoading] = React.useState(true);
  const [steps, setSteps] = React.useState([
    "Awaiting Payment",
    "Processing",
    "Confirmed",
  ]);
  React.useEffect(() => {
    if (/Waiting Confirmations/.test(paymentData?.status)) setActiveStep(1);
    if (paymentData?.status === "priceChange") setActiveStep(0);
    let k = steps;
    k[0] = /Partial Sent/.test(paymentData?.status)
      ? "Awaiting Partial Payment"
      : "Awaiting Payment";
    setSteps(k);
  }, [paymentData, steps]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "30px",
        justifyContent: "center",
      }}
    >
      <Stepper
        activeStep={activeStep}
        sx={{ maxWidth: "480px", widht: "100%" }}
      >
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {/* {!loading && <CircularProgress sx={{ color: "#495ECA" }} size="1.7rem" />} */}
    </Box>
  );
}
