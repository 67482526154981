import { Box, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PostHandler } from "../../../Redux/RequestHandlers";
import { InputLabels } from "../../../components/Helpers/label";
import { PrimaryInputComponents } from "../../../components/Style";
import { ButtonLoader, PrimaryButton2 } from "../../../components/Buttons";
import KYCPending from "./Pending";

const CryptoVerification = () => {
  const { kycStatus } = useSelector((state) => state?.dashboard);
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsDisable(
      inputs?.tron_address &&
        inputs?.ethereum_address &&
        inputs?.binance_address
        ? false
        : true
    );
  }, [inputs]);

  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (isDisable) return setLoading(false);
      const req = {
        url: "cryptoVerification",
        payload: inputs,
        action: "ACTION_CHANGE_KYC_CRYPTO",
      };

      await dispatch(PostHandler(req));
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!kycStatus?.company) navigate("/settings/kyc/company-verification/");
  }, [kycStatus, navigate]);

  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            py: "20px",
          }}
        >
          <Grid container>
            <Grid item xs="12" lg="12">
              {/* get apis */}
              <Box
                sx={{
                  p: {
                    xs: "15px",
                    md: "30px",
                  },
                  borderRadius: "8px",
                  border: "1px solid var(--neutral-200, #E3E3E8)",
                  background: "var(--main-colors-white, #FFF)",
                }}
              >
                {kycStatus?.crypto ? (
                  <>
                    <Box>
                      <KYCPending
                        kyc={kycStatus?.status}
                        to="/dashboard/"
                        title="Ok, Thanks"
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={12}>
                        <InputLabels label="Tron Address" mb="5px" />
                        <PrimaryInputComponents
                          id="tron_address"
                          type="text"
                          onChange={handleChange}
                          placeholder="Enter tron address"
                          name="tron_address"
                          value={inputs?.tron_address || ""}
                        />
                      </Grid>
                      <Grid item xs="12" lg="12">
                        <InputLabels label="Binance Network Address" mb="5px" />
                        <PrimaryInputComponents
                          id="binance_address"
                          type="text"
                          onChange={handleChange}
                          placeholder="Enter Binance Address"
                          name="binance_address"
                          value={inputs?.binance_address || ""}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: "10px" }}>
                      <Grid item xs={12} lg={12}>
                        <InputLabels
                          label="Ethereum Network Address"
                          mb="5px"
                        />
                        <PrimaryInputComponents
                          id="ethereum_address"
                          type="text"
                          onChange={handleChange}
                          placeholder="Enter Ethereum Address"
                          name="ethereum_address"
                          value={inputs?.ethereum_address || ""}
                        />
                      </Grid>
                    </Grid>

                    <Box sx={{ mt: "20px" }}>
                      {loading ? (
                        <ButtonLoader maxWidth="100px" />
                      ) : (
                        <PrimaryButton2
                          title="Save"
                          onClick={handleSubmit}
                          disabled={isDisable}
                        />
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default CryptoVerification;
