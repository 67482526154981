import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler } from "../../Redux/RequestHandlers";
import TopPanel from "./components/TopPanel";
import TransactionTable from "./components/transactionTable";
import { getTotalPage } from "../../utilities";
export default function TransactionComponent() {
  const { transactions } = useSelector((state) => state?.transactions);
  const dispatch = useDispatch();
  const [pages, setPages] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [title, setTitle] = useState("All Transactions");
  const [isFetching, setIsFetching] = useState(false);
  const [params, setParams] = useState({});
  const perPage = 10;

  const init2 = async (parametars) => {
    try {
      setIsFetching(true);
      const req = {
        url: "transactions",
        action: "ACTION_GET_TRANSACTIONS",
        params: params
          ? {
              pageNumber: pages,
              ...params,
              ...parametars,
            }
          : {
              pageNumber: pages,
            },
      };
      const response = await dispatch(GetHandler(req));
      if (response?.data?.data?.transactions?.count)
        setTotalPages(getTotalPage(Number(response?.data?.data?.transactions?.count), perPage));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    if (pages > 0) init2({ pageNumber: pages });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages]);

  useEffect(() => {
    if (Object.keys(params)?.length > 1) init2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  return (
    <Grid container>
     
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{
          borderRight: "1px solid var(--Neutral-100, #F4F4F6)",
          background: "var(--Main-Colors-White, #FFF)!important",
          boxShadow: "10px 0px 30px 0px rgba(161, 145, 243, 0.10)",
          p: "16px",
          overflow: "scroll",
          height: "90px",
          display: "flex",
          position: "sticky",
        }}
      >
        <TopPanel
          tokens={transactions?.tokens}
          init={init2}
          setTitle={setTitle}
          title={title}
          params={params}
          setParams={setParams}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <TransactionTable
          title={title}
          totalPages={totalPages}
          setPage={setPages}
          page={pages}
          callback={init2}
          params={params}
          isFetching={isFetching}
          setParams={setParams}
        />
      </Grid>
    </Grid>
  );
}
