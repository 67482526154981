import React from "react";
import { Box } from "@mui/material";
import PricingTableComponent from "./CTwoTable";
export default function PricingComponentTwo() {
  return (
    <Box
      sx={{
        p: "100px 160px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection:"column",
        gap: "16px",
        '@media (max-width:1050px)':{
          px:"40px"
        }
      }}
    >
      {/* <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%", '@media (max-width:800px)':{flexDirection:"column", gap:"20px"} }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <Button
            component={Link}
            // to="/contactus"
            sx={{
              borderRadius: "8px",
              border: "1px solid var(--primary-shades-100, #EFEDFD)",
              background: "var(--primary-shades-50, #F7F6FE)",
              color: "#000",
              display: "flex",
              textTransform: "none",
              fontFamily: "SF Pro Display",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "25.6px",
              p: "11px 24px",
              "&:hover": {
                background: "var(--primary-shades-50, #F7F6FE)",
                color: "#000",
              },
            }}
          >
            All Assets
          </Button>
          <Button
            component={Link}
            // to="/contactus"
            sx={{
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #E3E3E8)",
              background: "var(--main-colors-white, #FFF)",
              color: "#000",
              display: "flex",
              textTransform: "none",
              fontFamily: "SF Pro Display",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "25.6px",
              p: "11px 24px",
              "&:hover": {
                background: "var(--main-colors-white, #FFF)",
                color: "#000",
              },
            }}
          >
            New listings
          </Button>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <SearchBox />
          <Button
            component={Link}
            // to="/contactus"
            sx={{
              borderRadius: "8px",
              border: "1px solid var(--primary-shades-400, #D6D1FA)",
              background: "var(--main-colors-white, #FFF)",
              color: "#5235E8!important",
              display: "flex",
              gap: "8px",
              textTransform: "none",
              fontFamily: "SF Pro Display",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "25.6px",
              p: "11px 24px",
              "&:hover": {
                background: "var(--main-colors-white, #FFF)",
                color: "#5235E8!important",
              },
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.6647 2.47037L12.6647 2.47061L14.2192 7.46392L14.2194 7.46457C14.3597 7.9138 14.6342 8.31096 15.0096 8.59564C15.3855 8.88072 15.8423 9.03723 16.3148 9.03759H16.3154H21.3448C21.6661 9.03759 21.9108 9.2392 22.0102 9.55873C22.1102 9.88048 22.0275 10.2214 21.7452 10.4359L17.6785 13.5203C17.3023 13.8052 17.0273 14.2031 16.887 14.6531C16.7468 15.1029 16.7465 15.5866 16.8863 16.0365L16.8864 16.0369L18.4409 21.0302L18.441 21.0306C18.5573 21.4035 18.4163 21.7236 18.1762 21.9057C17.9411 22.084 17.6446 22.1113 17.373 21.9055C17.3729 21.9055 17.3729 21.9054 17.3728 21.9054L13.3053 18.8203L13.3051 18.8202C12.9288 18.5349 12.4715 18.3785 11.9986 18.3785C11.5256 18.3785 11.0683 18.5349 10.692 18.8202L10.6918 18.8203L6.62415 21.9055L6.62391 21.9057C6.35322 22.1112 6.05776 22.0841 5.82293 21.9059C5.58306 21.7238 5.44171 21.4033 5.55778 21.03L7.11214 16.0369L7.11227 16.0365C7.25206 15.5866 7.25181 15.1029 7.11155 14.6531C6.97127 14.2032 6.69642 13.8055 6.32043 13.5206C6.32034 13.5205 6.32025 13.5205 6.32016 13.5204C6.32013 13.5204 6.32011 13.5203 6.32008 13.5203L2.25484 10.4374C2.25478 10.4374 2.25471 10.4373 2.25464 10.4373C1.97279 10.2229 1.89016 9.88233 1.99033 9.56045C2.0899 9.24047 2.33486 9.03911 2.65522 9.03911H7.68297C7.68304 9.03911 7.68311 9.03911 7.68317 9.03911C8.15599 9.03919 8.61325 8.88292 8.98958 8.59783C9.36542 8.31311 9.64023 7.91568 9.78064 7.46609L9.78084 7.46544L11.3353 2.47213L11.3525 2.4169C11.5982 1.77571 12.455 1.79611 12.6647 2.47037Z"
                fill="white"
                stroke="#5235E8"
                strokeWidth="1.5"
              />
            </svg>
            Watchlist
          </Button>
        </Box>
      </Box> */}
      <PricingTableComponent />
    </Box>
  );
}
