import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import RefCards from "./components/RefCards";
import ReferralTable from "./components/ReferralTable";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler } from "../../Redux/RequestHandlers";
import InfoCards from "./components/InfoCards";

const Referral = () => {
  const { affdashboard } = useSelector((state) => state?.affiliate);
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);

  const totalPages = 0;
  const [page, setPage] = useState(1);
  const init = async () => {
    try {
      setIsFetching(true);

      const req = {
        url: "referralDashboard",
        action: "ACTION_GET_REFDASHBOARD",
      };
      await dispatch(GetHandler(req));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container sx={{ overflow: "hidden!important" }}>
        <Grid
          item
          xs={12}
          md={6}
          lg={8}
          sx={{
            p: "32px",
            borderRight: "1px solid var(--neutral-200, #E3E3E8)",
          }}
        >
          <RefCards
            balance={affdashboard?.affiliateBalance}
            volume={affdashboard?.volume}
          />
          <ReferralTable
            data={
              affdashboard?.referredUsers?.rows?.length > 0
                ? affdashboard?.referredUsers?.rows
                : []
            }
            isFetching={isFetching}
            totalPages={totalPages}
            page={page}
            setPage={setPage}
          />
          {/* <LeftGridComponentThree /> */}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <InfoCards
            totalRef={affdashboard?.totalReferrels}
            affiliateTransactions={affdashboard?.affiliateTransactionCount}
          />
          {/* <Stepper /> */}
          {/* <RightGridComponentThree /> */}
        </Grid>
      </Grid>
    </>
  );
};

export default Referral;
