export const ACTION_GET_TRANSACTIONS = "ACTION_GET_TRANSACTIONS"
export const ACTION_GET_ALLTOKENS = "ACTION_GET_ALLTOKENS"
export const ACTION_GET_ALL_PRICES = "ACTION_GET_ALL_PRICES"
export const ACTION_GET_KYC_INFO = "ACTION_GET_KYC_INFO"
export const ACTION_GET_WR = "ACTION_GET_WR"
export const ACTION_GET_WR_RETURNED = "ACTION_GET_WR_RETURNED"

const initialState = {
    transactions: null,
    alltokens: [],
    prices: [],
    tokens: [],
    networks: [],
    minimumWithDraw: null,
    kycinfo: null,
    withdrawalRequestList: null,
    // countByStatus: []
}

export const TransactionReducer = (state = initialState, action) => {
    // const returnObj = { ...state }
    switch (action.type) {
        case ACTION_GET_WR:

            return {
                ...state,
                withdrawalRequestList: action?.payload?.data
            }

        case ACTION_GET_WR_RETURNED:
            let tempwi = state.withdrawalRequestList;
            tempwi.withdrawalRequests = action.payload?.withdrawalRequests;
            return {
                ...state,
                withdrawalRequestList: tempwi
            }

        case ACTION_GET_TRANSACTIONS:
            return {
                ...state,
                transactions: action?.payload?.data,
            }
        case ACTION_GET_KYC_INFO:
            return {
                ...state,
                kycinfo: action?.payload?.data,
            }
        case ACTION_GET_ALLTOKENS:
            return {
                ...state,
                alltokens: action?.payload?.data,
            }
        case ACTION_GET_ALL_PRICES:
            return {
                ...state,
                prices: action?.payload?.data,
            }

        default:
            return state
    }
}

