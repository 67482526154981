import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import SignUpFormComponent from "./components/FormComponentSignup";
import { logout } from "../../Redux/RequestHandlers/Auth.action";
import {
  AuthHeader2,
  AuthPRight,
  LogoText,
} from "../../components/Helpers/helperComponents";
import Personal from "../../assets/img/signup/personal.svg";
import RightComponent1 from "./components/RightComponent1";
import { useLocation } from "react-router-dom";

export default function SignUpComponent() {
  const [radioInput, setRadioInput] = useState("bussiness");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refCode = queryParams.get("code");
  useEffect(() => {
    logout();
  }, []);
  return (
    <Box className="main" sx={{ height: "100vh!important" }}>
      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          md={6}
          lg={5}
          sx={{
            p: "80px",
            pt: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            "@media (max-width:600px)": { p: "40px" },
          }}
        >
          <LogoText />

          <SignUpFormComponent
            radioInput={radioInput}
            setRadioInput={setRadioInput}
            refCode={refCode}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          sx={{
            borderRadius: "20px 0px 0px 20px",
            background:
              "linear-gradient(270deg, #495ECA 0%, rgba(73, 94, 202, 0.00) 100%)",
            "@media (max-width:900px)": { display: "none" },
          }}
        >
          {radioInput === "bussiness" ? (
            <RightComponent1 />
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box>
                <Box
                  sx={{ display: "flex", gap: "10px", px: "80px", pt: "40px" }}
                >
                  <AuthHeader2 text="~" />
                  <AuthHeader2 text="Welcome to the world of crypto! Log in for your Gafah personal wallet." />
                </Box>
                <Box sx={{ px: "80px", pl: "110px", pt: "15px" }}>
                  <AuthPRight text="If you're interested in getting involved in the booming cryptocurrency industry and want to own your own digital coins, then sign up with us today!" />
                </Box>
              </Box>
              <Box sx={{ pl: "50px", pt: "30px" }}>
                <img
                  src={Personal}
                  alt="personal"
                  style={{
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    width: "100vh",
                  }}
                />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
