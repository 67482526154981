import React from "react";
import { Box, Typography } from "@mui/material";
import FAQAccordionComponent from "./accordianComponent";

export default function QnAComponent(props) {
  const {heading, id, array} = props;
  return (
    <Box
    id={id}
      sx={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        gap: "20px",
        width:"100%"
      }}
    >
      <Typography
        sx={{
          color: "var(--neutral-400, #9C9CAB)",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20.6px",
        }}
      >
        {heading}
      </Typography>
      <FAQAccordionComponent qaArray={array} />
    </Box>
  );
}