import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { CircularProgress } from "@mui/material";
import StatusShow from "../../../components/Tables/StatusShow";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e3dfdf21",
    color: "#000",
    fontFamily: "SF Pro Display",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "SF Pro Display!important",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function OngoingTicketTable({
  tickets,
  isFetching,
}) {
  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
              <StyledTableCell align="center">Subject</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : tickets?.rows?.length > 0 ? (
              tickets?.rows?.map((row, index) => (
                <StyledTableRow
                  key={row?.id}
                  
                >
                  <StyledTableCell sx={{ textTransform: "capitalize" }}>
                    {row?.id}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row?.name}</StyledTableCell>
                  <StyledTableCell align="center">{row?.email}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.subject}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <StatusShow status={row?.status} />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>{/* <NoRecordFound /> */}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
    </>
  );
}
