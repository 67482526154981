export const ACTION_GET_REFDASHBOARD = "ACTION_GET_REFDASHBOARD"

const initialState = {
  affdashboard: null,
}

export const affiliateReducer = (state = initialState, action) => {
  // const returnObj = { ...state }
  switch (action.type) {
    case ACTION_GET_REFDASHBOARD:
      return {
        ...state,
        affdashboard: action?.payload?.data,
      }

    default:
      return state
  }
}

