import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AuthPRight, Header5 } from "../../components/Helpers/helperComponents";
import { PrimaryButton2 } from "../../components/Buttons";
import { InputLabels } from "../../components/Helpers/label";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { PostHandler } from "../../Redux/RequestHandlers";
import { PasswordValidation } from "../../Validation/formValidations";
import { PrimaryInputComponentsWithInputProps } from "../../components/Style";
import { hasAccess } from "../../utilities";
const ChangePassword = () => {
  const { accessLists } = useSelector((state) => state.auth);
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const { num, lc, uc, sp, len } = PasswordValidation(inputs.newPassword || "");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConPassword = () => setShowConPassword((show) => !show);
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const dispatch = useDispatch();
  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "changePassword",
        payload: { ...inputs },
      };

      await dispatch(PostHandler(req));
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisable(
      inputs?.newPassword &&
        inputs?.confirmPassword &&
        inputs?.newPassword === inputs?.confirmPassword &&
        !errors?.criteria &&
        !errors?.matchPass
        ? false
        : true
    );
  }, [errors?.criteria, errors?.matchPass, inputs]);

  useEffect(() => {
    if (inputs?.newPassword && inputs.confirmPassword)
      setErrors((prev) => ({
        ...prev,
        matchPass: inputs.newPassword === inputs.confirmPassword ? false : true,
      }));
  }, [inputs?.newPassword, inputs?.confirmPassword]);

  useEffect(() => {
    if (inputs.newPassword?.length > 0)
      setErrors((prev) => ({
        ...prev,
        criteria: num && lc && uc && sp && len ? false : true,
      }));
  }, [inputs?.newPassword, num, lc, uc, len, sp]);

  return (
    <Box
      sx={{
        display: "flex",
        p: "20px",
      }}
    >
      <Grid container>
        <Grid item lg="4">
          <Box
            sx={{
              pt: "32px",
              mb: "15px",
            }}
          >
            <Header5 text="Change password" fs="16px" />
            <AuthPRight
              text="Remember not to store your password in your email or cloud and don't share it with anyone"
              fs="16px"
              lh="22px"
              mt="10px"
            />

            <Header5 text="Password Criteria" fs="16px" mt="30px" />

            <AuthPRight
              text="Password must have one number, one uppercase letter, one lowercase letter, one special charecter and minimum length should be 8."
              fs="16px"
              lh="22px"
              mt="10px"
            />
          </Box>
        </Grid>
        <Grid item xs="12" lg="8">
          {/* get apis */}
          <Box
            sx={{
              p: {
                xs: "15px",
                md: "30px",
              },
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #E3E3E8)",
              background: "var(--main-colors-white, #FFF)",
            }}
          >
            <InputLabels label="Old Password" mb="5px" />
            <PrimaryInputComponentsWithInputProps
              id="oldPassword"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowOldPassword}
                      edge="end"
                    >
                      {showOldPassword ? (
                        <Tooltip title="Hide" placement="left-start" arrow>
                          <VisibilityOff />
                        </Tooltip>
                      ) : (
                        <Tooltip title="show" placement="left-start" arrow>
                          <Visibility />
                        </Tooltip>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showOldPassword ? "text" : "password"}
              onChange={handleChange}
              placeholder="Enter old password"
              name="oldPassword"
              value={inputs?.oldPassword || ""}
            />

            <InputLabels label="New Password" mt="30px" mb="5px" />

            <PrimaryInputComponentsWithInputProps
              id="newPassword"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Tooltip title="Hide" placement="left-start" arrow>
                          <VisibilityOff />
                        </Tooltip>
                      ) : (
                        <Tooltip title="show" placement="left-start" arrow>
                          <Visibility />
                        </Tooltip>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showPassword ? "text" : "password"}
              variant="outlined"
              onChange={handleChange}
              placeholder="Enter new password"
              name="newPassword"
              value={inputs?.newPassword || ""}
            />

            {errors?.criteria && (
              <Typography sx={{ fontSize: "14px", color: "red" }}>
                Password criteria not matched
              </Typography>
            )}
            <InputLabels label="Confirm New Password" mt="30px" mb="5px" />
            <PrimaryInputComponentsWithInputProps
              id="confirmPassword"
              placeholder="Enter confirm password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConPassword}
                      edge="end"
                    >
                      {showConPassword ? (
                        <Tooltip title="Hide" placement="left-start" arrow>
                          <VisibilityOff />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Show" placement="left-start" arrow>
                          <Visibility />
                        </Tooltip>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showConPassword ? "text" : "password"}
              variant="outlined"
              onChange={handleChange}
              value={inputs?.confirmPassword || ""}
              name="confirmPassword"
            />
            {errors?.matchPass && (
              <Typography sx={{ fontSize: "14px", color: "red" }}>
                Password and Confirm password not matched
              </Typography>
            )}

            {hasAccess(["Settingschangepassword-update"], accessLists) && (
              <Box sx={{ mt: "30px" }}>
                {isLoading ? (
                  <Button variant="contained" title="submit" disabled>
                    <CircularProgress size="1.7rem" />
                  </Button>
                ) : (
                  <PrimaryButton2
                    title="Change Password"
                    onClick={handleSubmit}
                    disabled={isDisable}
                  />
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChangePassword;
