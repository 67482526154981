import {
  Box,
  Container,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputLabels } from "../../../components/Helpers/label";
import KYCPending from "./Pending";
import StepMenu from "./StepMenu";
import CryptoVerification from "./CryptoVerification";
import BankVerification from "./BankVerification";

const LastStep = () => {
  const { kycStatus } = useSelector((state) => state?.dashboard);
  const [radioInput, setRadioInput] = useState("crypto");
  const navigate = useNavigate();
  useEffect(() => {
    if (!kycStatus?.company) navigate("/settings/kyc/company-verification/");
  }, [kycStatus, navigate]);

  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            py: "20px",
          }}
        >
          <Grid container>
            <Grid item lg="3" sx={{ pr: "15px" }}>
              <StepMenu />
            </Grid>

            <Grid item xs="12" lg="9">
              {/* get apis */}
              <Box
                sx={{
                  p: {
                    xs: "15px",
                    md: "30px",
                  },
                  borderRadius: "8px",
                  border: "1px solid var(--neutral-200, #E3E3E8)",
                  background: "var(--main-colors-white, #FFF)",
                }}
              >
                {/Requested|Pending|Completed/.test(kycStatus?.status) ||
                (kycStatus?.crypto || kycStatus?.bank) ? (
                  <>
                    <Box>
                      <KYCPending
                        kyc={kycStatus?.status}
                        to="/dashboard"
                        title="Ok, Thanks"
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: "15px",
                      }}
                    >
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="crypto"
                        name="radio-buttons-group"
                      >
                        <Box
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="crypto"
                          name="radio-buttons-group"
                          sx={{
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <FormControlLabel
                            value="crypto"
                            onChange={() => setRadioInput("crypto")}
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 20,
                                  },
                                }}
                              />
                            }
                            label={
                              <InputLabels
                                label="Withdraw via crypto"
                                mt="0px"
                              />
                            }
                            sx={{
                              ".MuiFormControlLabel-label": {
                                color: "var(--Neutral-900, #131316)",
                                fontFeatureSettings: `'ss06' on`,
                                fontFamily: "SF Pro Display",
                                fontSize: "15px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                letterSpacing: " -0.4px",
                              },
                            }}
                          />
                          <FormControlLabel
                            value="bank"
                            onChange={() => setRadioInput("bank")}
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 20,
                                  },
                                }}
                              />
                            }
                            label={
                              <InputLabels
                                label="Withdraw to Bank Account"
                                mt="0px"
                              />
                            }
                            sx={{
                              ".MuiFormControlLabel-label": {
                                color: "var(--Neutral-900, #131316)",
                                fontFeatureSettings: `'ss06' on`,
                                fontFamily: "SF Pro Display",
                                fontSize: "15px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                letterSpacing: " -0.4px",
                              },
                            }}
                          />
                        </Box>
                      </RadioGroup>
                    </Box>
                    {radioInput === "crypto" && <CryptoVerification />}
                    {radioInput === "bank" && <BankVerification />}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default LastStep;
