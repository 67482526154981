import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import logo from "../../assets/img/logo2.png";
import bank from "../../assets/img/bank.png";
import moment from "moment";
import {
  calculateRecievedFiatBalance,
  Numberz,
  tableHeadBreakPoint,
} from "../../utilities";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    padding: 30,
    fontSize: 12,
    backgroundColor: "#d5e5ee30",
  },
  title: {
    fontSize: 34,
    textAlign: "right",
  },
  section: {
    marginBottom: 10,
    marginTop: 70,
  },
  logo: {
    width: 120,
  },
  table: {
    display: "table",
    width: "auto",
    marginBottom: 10,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    padding: 5,
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  cell: {
    width: "25%",
    padding: 5,
    fontSize: "8px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  cell1: {
    width: "35%",
    padding: 5,
    fontSize: "8px",
  },
  bold: {
    fontWeight: "bold",
  },
  alignRight: {
    textAlign: "right",
  },
  container: {
    width: "100%",
    position: "relative",
  },
  container2: {
    // marginTop: 130,
    width: "100%",
    position: "relative",
  },
  leftBox: {
    width: "45%",
    position: "absolute",
    left: 0,
  },
  rightBox: {
    width: "45%",
    position: "absolute",
    right: 0,
  },
  invoiceTopInfo: {
    marginTop: 100,
    marginBottom: 30,
    width: "100%",
    position: "relative",
    fontSize: "12px",
  },
  text: {
    marginTop: 5,
  },
  payInfoText: {
    marginTop: 5,
  },
  billTo: {
    fontSize: "10px",
    fontWeight: "bold",
    wordBreak: "break-word",
  },
  dateCell: {
    width: "18%",
    fontSize: "11px",
  },
  idCell: {
    width: "50%",
    fontSize: "11px",
  },
  amountCell: {
    width: "12%",
    fontSize: "11px",
  },
  amountCell2: {
    width: "17%",
    fontSize: "11px",
  },
});

export const TableDataComponent = ({
  date,
  id,
  amount,
  fee,
  vat,
  receivedAmount,
  backgroundColor,
}) => (
  <View
    style={{ ...styles.row, backgroundColor: backgroundColor || "#eff6faa6" }}
  >
    <View style={styles.dateCell}>
      <Text style={styles.bold}>{date}</Text>
    </View>
    <View style={styles.idCell}>
      <Text style={styles.bold}>{id}</Text>
    </View>
    <View style={styles.amountCell2}>
      <Text style={styles.bold}>{amount}</Text>
    </View>
    <View style={styles.amountCell}>
      <Text style={styles.bold}>{fee}</Text>
    </View>
    <View style={styles.amountCell}>
      <Text style={styles.bold}>{vat}</Text>
    </View>
    <View style={styles.amountCell}>
      <Text style={{ textAlign: "right", fontWeight: "bold" }}>
        {receivedAmount}
      </Text>
    </View>
  </View>
);

export const PdfFooterComponent = () => (
  <View
    style={{
      marginTop: "50px",
      backgroundColor: "#7575751a",
      width: "100%",
      height: "60px",
      justifyContent: "center",
      alignItems: "center",
      color: "#646497",
    }}
  >
    <Text
      style={{
        fontSize: 10,
      }}
    >
      Withdrawal statement generated by Gafah.
    </Text>
    <Text
      style={{
        fontSize: 10,
      }}
    >
      For any queries please contact - info@gafah.com
    </Text>
  </View>
);

const WithDrawalStatement = ({ wrDetails, statements, kycInfo }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View
        style={{
          position: "relative",
          height: "140px",
        }}
      >
        <View
          style={{
            left: "0px",
            position: "absolute",
            maxWidth: "100%",
          }}
        >
          <Text style={{ fontSize: "20px" }}>{kycInfo?.businessname}</Text>
          <Text>{kycInfo?.country}</Text>
          <Text style={{ fontSize: "14px", marginTop: "20px" }}>
            Withdrawal Statement
          </Text>
          <Text
            style={{ fontWeight: "bold", marginTop: "5px", color: "#495ECA" }}
          >
            Id: {wrDetails?.id}{" "}
          </Text>
          <Text style={{ fontWeight: "bold" }}>
            {moment(wrDetails?.createdAt).format("DD/MM/YYYY")}
          </Text>
        </View>
        <View style={{ position: "absolute", right: "0px" }}>
          <View
            style={{
              position: "relative",
              height: "30px",
            }}
          >
            <Image
              src={logo}
              style={{ width: "120px", position: "absolute", right: "0px" }}
            />
          </View>
          <View
            style={{
              marginTop: "25px",
              padding: "10px",
              backgroundColor: "#eff6faa6",
              borderRadius: "5px",
              display: "table",
              flexDirection: "row",
            }}
          >
            {/* <Text>Bank Name</Text> */}
            <Image src={bank} style={{ width: "40px", paddingRight: "10px" }} />
            <View>
              <Text style={{ fontWeight: "bold" }}>
                Bank Name: {kycInfo?.kycdetails?.fullnamebank}
              </Text>
              {/* <Text style={{ marginTop: "5px" }}>Account Number</Text> */}
              <Text style={{ fontWeight: "bold", marginTop: "3px" }}>
                Account No: {kycInfo?.kycdetails?.accountnumber}
              </Text>
              {/* <Text style={{ marginTop: "5px" }}>IFSC</Text> */}
              <Text style={{ fontWeight: "bold", marginTop: "3px" }}>
                IBAN: {kycInfo?.kycdetails?.ifsc}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View
        style={{
          ...styles.section,
          marginTop: "10px",
        }}
      >
        <Text style={{ ...styles.billTo, marginBottom: "10px" }}>
          Transaction Details:
        </Text>

        {/* {} */}

        {statements?.map((itm, index) => (
          <>
            {(index === 0 ||
              index === 13 ||
              tableHeadBreakPoint(13, index, 17)) && (
                <TableDataComponent
                  date="Date"
                  id="#ID"
                  amount="Amount (AED)"
                  fee="Fee"
                  vat="Vat"
                  receivedAmount="Received (AED)"
                  backgroundColor="#e3dfdf6b"
                />
              )}
            <TableDataComponent
              date={moment(itm?.createdAt).format("DD/MM/YYYY")}
              id={itm?.id}
              vat={itm?.vat ? "Yes" : "No"}
              fee={itm?.fee || 0 + "%"}
              amount={
                itm?.currency.toLowerCase() === "aed"
                  ? Numberz(itm?.fiatamount)
                  : Numberz(itm?.fiatamount * 3.67).toFixed(2)
              }
              receivedAmount={Numberz(
                calculateRecievedFiatBalance(
                  itm?.requestedfiatamount || itm?.fiatamount,
                  itm?.currency,
                  itm?.vat,
                  itm?.fee
                )
              ).toFixed(2)}
            />

            {index + 1 === statements?.length && (
              <TableDataComponent
                amount={
                  <Text style={{ fontWeight: "bold", fontSize: "11px" }}>
                    TOTAL
                  </Text>
                }
                receivedAmount={
                  wrDetails?.processedamount &&
                  wrDetails?.currency +
                  " " +
                  wrDetails?.processedamount
                }
                backgroundColor="#80797921"
              />
            )}

            {index + 1 === statements?.length && (
              <TableDataComponent
                amount={
                  <Text style={{ fontWeight: "bold", fontSize: "11px" }}>
                    Fee
                  </Text>
                }
                fee={`${wrDetails?.fee || 0}%`}
                receivedAmount={<> - {wrDetails?.currency + " " + wrDetails?.feeamount || 0}</>}
                backgroundColor="#80797921"
              />
            )}

            {index + 1 === statements?.length && (
              <TableDataComponent
                amount={
                  <Text style={{ fontWeight: "bold", fontSize: "11px" }}>
                    RECEIVED
                  </Text>
                }
                receivedAmount={wrDetails?.currency + " " + wrDetails?.withdrawalamount || 0}
                backgroundColor="#80797921"
              />
            )}

          </>
        ))}
      </View>

      <PdfFooterComponent />
    </Page>
  </Document>
);

export default WithDrawalStatement;
