import { Box } from "@mui/material";
import React from "react";
import {
  AuthPRight,
  Header5,
  StepTypo,
} from "../../../components/Helpers/helperComponents";
import { useLocation } from "react-router-dom";

const StepMenu = () => {
  const { pathname } = useLocation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          md: "row",
          lg: "column",
        },
        gap: "5px",
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          py: "15px",
          mb: "15px",
          background: /identity-verification/.test(pathname) && "#F7F6FE",
          display: "flex",
          gap: "10px",
          borderRadius: "10px",
        }}
      >
        <StepTypo title="1" />
        <Box>
          <Header5 text="Identity Verification" fs="16px" mt="0px" />
          <AuthPRight
            text="Please complete your kyc."
            fs="16px"
            lh="22px"
            mt="10px"
          />
        </Box>
      </Box>

      <Box
        sx={{
          py: "15px",
          mb: "15px",
          background: /company-verification/.test(pathname) && "#F7F6FE",
          display: "flex",
          gap: "10px",
          borderRadius: "10px",
        }}
      >
        <StepTypo title="2" />

        <Box>
          <Header5 text="Company Verification" fs="16px" mt="0px" />
          <AuthPRight
            text="Please complete your kyc."
            fs="16px"
            lh="22px"
            mt="10px"
          />
        </Box>
      </Box>

      <Box
        sx={{
          py: "15px",
          mb: "15px",
          background: /withdrawal-verification/.test(pathname) && "#F7F6FE",
          display: "flex",
          gap: "10px",
          borderRadius: "10px",
        }}
      >
        <StepTypo title="3" />
        <Box>
          <Header5 text="Withdrawal Verification" fs="16px" mt="0px" />
          <AuthPRight
            text="Please complete your kyc."
            fs="16px"
            lh="22px"
            mt="10px"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default StepMenu;
