export const PasswordValidation = (pass) => {
    let checks = {
        num: false,
        lc: false,
        uc: false,
        sp: false,
        len: false,
    }
    checks.len = pass.length >= 8 ? true : false
    checks.num = /(?=.*\d)/.test(pass) ? true : false
    checks.lc = /(?=.*[a-z])/.test(pass) ? true : false
    checks.uc = /(?=.*[A-Z])/.test(pass) ? true : false
    checks.sp = /(.*\W.*)/.test(pass) ? true : false

    return checks
}

// eslint-disable-next-line no-useless-escape
export const validEmail = email => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) ? true : false