import React, { useEffect, useState } from "react";
import AlertDialog from "../../../components/Dialog";
import { Box, Grid, TextareaAutosize } from "@mui/material";
import { validEmail } from "../../../Validation/formValidations";
import { useDispatch } from "react-redux";
import { PostHandler } from "../../../Redux/RequestHandlers";
import { BoldLabel, PrimaryInputComponents } from "../../../components/Style";
import { ButtonLoader, PrimaryButton2 } from "../../../components/Buttons";
export default function ContactMailComponent() {
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [showModal, setShowModal] = useState();
  const dispatch = useDispatch();
  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "contactUs",
        payload: inputs,
      };
      const res = await dispatch(PostHandler(req));
      setIsLoading(false);
      if (res?.data?.success) {
        setInputs({});
        setShowModal("We have received your query. We are working on it!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisable(
      validEmail(inputs?.email) &&
        inputs?.subject &&
        inputs?.fullname &&
        inputs?.company &&
        inputs?.message
        ? false
        : true
    );
  }, [inputs]);

  return (
    <Box
      sx={{
        px: "123px",
        mt: "43px",
        pb: "23px",
        background:
          "linear-gradient(0deg, #F7F6FE -10.28%, #EFEDFD 34.89%, #FFF 57.57%)",
        "@media (max-width:800px)": {
          px: "34px!important",
          mt: "23px",
          pb: "17px",
        },
      }}
    >
      <Box
        sx={{
          background: "#fff",
          boxShadow:
            "0px 10.770000457763672px 35.63999938964844px rgba(179, 179, 179, 0.25)",
          borderRadius: "20px",
          p: "75px 87px",
          zIndex: "1000",
          "@media (max-width:600px)": { px: "30px!important" },
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} md={6} lg={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <BoldLabel labelFor="Full Name *" />
              <PrimaryInputComponents
                id="fullname"
                name="fullname"
                placeholder="Full Name"
                onChange={handleChange}
                value={inputs?.fullname || ""}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <BoldLabel labelFor="Email *" />
              <PrimaryInputComponents
                id="email"
                type="email"
                name="email"
                onChange={handleChange}
                value={inputs?.email || ""}
                placeholder="Email"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ mt: "32.12px" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <BoldLabel labelFor="Company *" />
              <PrimaryInputComponents
                id="company"
                type="string"
                name="company"
                onChange={handleChange}
                value={inputs?.company || ""}
                placeholder="Company"
                // sx={{ width: "100%", mb: "32.12px" }}
                required
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ mt: "32.12px" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <BoldLabel labelFor="Subject *" />
              <PrimaryInputComponents
                id="subject"
                name="subject"
                onChange={handleChange}
                value={inputs?.subject || ""}
                placeholder="Subject"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sx={{ mt: "32.12px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", mb: "15px" }}>
              <BoldLabel labelFor="Message *" />
              <TextareaAutosize
                // onChange={e=>setKK(e.target.value)}
                // value={kk + 'df'}
                onChange={handleChange}
                name="message"
                value={inputs?.message || ""}
                minRows={16}
                placeholder="Hello there,I would like to talk about how to..."
                style={{
                  padding: "15px",
                  borderRadius: "5px",
                  fontFamily: "SF Pro Display",
                  fontSize: "15px",
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {!isLoading ? (
              <PrimaryButton2
                title="Send Message"
                disabled={isDisable}
                onClick={handleSubmit}
              />
            ) : (
              <ButtonLoader width="140px" />
            )}
          </Grid>
        </Grid>
      </Box>
      {showModal && (
        <AlertDialog
          btnTitle="Okay"
          title="Status"
          content={showModal}
          controlled={true}
          controlModal={showModal ? true: false}
          okayComponent={true}
          execute={()=>{setShowModal("")}}
        />
      )}
    </Box>
  );
}
