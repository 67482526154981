import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import LoginFormComponent from "./components/FormComponent";
import { logout } from "../../Redux/RequestHandlers/Auth.action";
import {
  AuthHeader2,
  AuthPRight,
  LogoText,
} from "../../components/Helpers/helperComponents";
import recentActivite from "../../assets/img/signup/Recent_Activity.png";
import dasboardImg from "../../assets/img/signup/Dashboard.png";
export default function LoginComponent() {
  useEffect(() => {
    logout();
  }, []);
  return (
    <Box className="main" sx={{ height: "100vh!important" }}>
      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          md={6}
          lg={5}
          sx={{
            p: "80px",
            pt: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            "@media (max-width:600px)": { p: "40px" },
          }}
        >
          <LogoText />
          <LoginFormComponent />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          sx={{
            borderRadius: "20px 0px 0px 20px",
            background:
              "linear-gradient(270deg, #495ECA 0%, rgba(73, 94, 202, 0.00) 100%)",
            "@media (max-width:900px)": { display: "none" },
          }}
          //   className="LoginRightComponent"
        >
          <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
            <Box
              sx={{
                position: "absolute",
                bottom: "40px",
              }}
            >
              <Box
                sx={{ display: "flex", gap: "10px", px: "40px", pt: "40px" }}
              >
                <AuthHeader2 text="~" />
                <AuthHeader2 text="Welcome to the world of digital assets! Log in for your Gafah personal wallet." />
              </Box>
              <Box sx={{ px: "80px", pl: "70px", pt: "15px" }}>
                <AuthPRight text="We're the first digital assets locker that's secure, simple, and easy to use. Use it on your phone or in web browser with no downloads or installation required." />
              </Box>
            </Box>
            <Box
              sx={{
                mt: "60px",
                pl: "10px",
                pt: "40px",
                display: "flex",
                justifyContent: "end",
                height: "60vh",
              }}
            >
              <Box sx={{ mt: "20px" }}>
                <Box
                  component="img"
                  src={recentActivite}
                  alt="recentActivite"
                  sx={{
                    transform: "scale(0.7)",
                    width: "45vh",
                    "@media (min-width:1100px) and  (max-width:1260px) and (max-height:1022px)" : {
                      width: "26vh !important",
                    },

                    "@media (min-width:1260px) and (max-width:2300px) and (max-height:1022px)": {
                      width: "42vh !important",
                    },
                    "@media (min-width:900px) and (max-width:1100px) and (max-height:1022px)": {
                      width: "21vh !important",
                    },
                    "@media (min-height:1022px)": {
                      width: "31vh !important",
                    },
                    "@media (max-width:1360px)": {
                      display: "none",
                    },
                  }}
                />
              </Box>
              <Box>
                <Box
                  component="img"
                  src={dasboardImg}
                  alt="dasboard"
                  sx={{
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    width: "50vh",
                    "@media (max-width:1360px) OR (min-height:1022px)": {
                      width: "31vh !important",
                    },
                    "@media (min-width:1360px) and (max-width:1600px) and (max-height:1022px)": {
                      width: "45vh !important",
                    },
                    "@media (min-width:1600px) and (max-width:2300px) and (max-height:1022px)": {
                      width: "55vh !important",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
