import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';

const PermissionDenied = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
        textAlign: 'center',
      }}
    >
      <LockIcon sx={{ fontSize: 80, color: 'red' }} />
      <Typography variant="h4" sx={{ marginTop: 2 }}>
        Permission Denied
      </Typography>
      <Typography variant="body1" sx={{ marginTop: 1 }}>
        You do not have the necessary permissions to access this page.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoBack}
        sx={{ marginTop: 3 }}
      >
        Go Back
      </Button>
    </Box>
  );
};

export default PermissionDenied;
