import React, { useEffect } from "react";
import PricesList from "./PricesList";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler } from "../../Redux/RequestHandlers";
import { AuthHeader } from "../../components/Helpers/helperComponents";

const Prices = () => {
  const { prices } = useSelector((state) => state?.transactions);
  const { showCurrency } = useSelector((state) => state?.dashboard);
  const dispatch = useDispatch();

  const init = async () => {
    try {
      const req = {
        url: "prices",
        action: "ACTION_GET_ALL_PRICES",
      };
      await dispatch(GetHandler(req));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box
      sx={{
        p: "20px 50px",
      }}
    >
      <Box sx={{ mb: "15px" }}>
        <AuthHeader text="Real time Prices" />
      </Box>
      {prices?.length > 0 &&
        prices?.map((item, index) => (
          <PricesList
            key={item?.id}
            icon={item?.logo}
            cryptoName={item?.token}
            fiatValue={
              showCurrency === "usd"
                ? item?.realTimePrice?.usd
                : item?.realTimePrice?.aed
            }
            fiatCurrency={showCurrency}
            type={item?.type}
          />
        ))}
    </Box>
  );
};

export default Prices;
