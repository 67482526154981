import { combineReducers } from "redux"
import { AuthReducer } from "./auth.reducer"
import { ApiKeyReducer } from "./apikey.reduce"
import { settingsReducer } from "./settgings.reducer"
import { DashboardReducer } from "./dashboard.reducer"
import { TransactionReducer } from "./transaction.reducer"
import { POSReducer } from "./pos.reducer"
import { ticketReducer } from "./ticket.reducer"
import { affiliateReducer } from "./affiliate.reducer"
import { RolesReducer } from "./roles.reducer"
export const appReducer = combineReducers({
    auth: AuthReducer,
    apikey: ApiKeyReducer,
    settings: settingsReducer,
    dashboard: DashboardReducer,
    transactions: TransactionReducer,
    pos: POSReducer,
    ticket: ticketReducer,
    affiliate: affiliateReducer,
    roles: RolesReducer,
})
export const rootReducer = (state, action) => {
    if (
        action.type === 'RESET_STORE' &&
        typeof window !== "undefined" &&
        !!window.sessionStorage
    ) {
        state = undefined
        window.sessionStorage.clear()
    }
    return appReducer(state, action)
}