import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import ResponsiveAppBar from "../../components/appbar";
import Logo from "../../assets/img/logo2.png";
import { useNavigate } from "react-router-dom";
import FAQLeftGridComponent from "./components/leftComponent";
import FAQRightGridComponent from "./components/rightComponent";
import ComponentEight from "../ContactUs/components/componentThree";

export default function FAQComponent() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/", { replace: true });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main">
      <Box
        sx={{
          margin: "20px 15px 0px",
          borderRadius: "30px 0px 30px 30px",
          zIndex: 2,
          "@media (max-width:800px)": {
            p: "0!important",
            margin: "10px 8px 0px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 0px 0px 80px ",
            "@media (max-width:800px)": { p: "0!important" },
          }}
        >
          <Box
            onClick={handleClick}
            component="img"
            src={Logo}
            sx={{
              height: "50px",
              mt: "12px",
              "@media (max-width:800px)": {
                pl: "40px!important",
              },
              "@media (max-width:500px)": {
                height: "40px!important",
                mt: "18px",
              },
              cursor: "pointer",
            }}
          />
          <Box
            sx={{
              background: "#FFFFFF",
              color: "black!important",
              maxWidth: "800ox",
              maxHeight: "65px",
              borderRadius: "0px 0px 0px 30px!important",

              "@media (max-width:800px)": {
                pl: 0,
              },
              "@media (max-width:500px)": {
                height: "60px!important",
              },
            }}
          >
            <ResponsiveAppBar />
          </Box>
        </Box>
      </Box>
      <Grid container sx={{p:"64px 160px","@media (max-width:900px)":{p:"30px 100px"},"@media (max-width:600px)":{px:"40px"}}}>
        <FAQLeftGridComponent />
        <FAQRightGridComponent />
      </Grid>
      <ComponentEight />
    </div>
  );
}