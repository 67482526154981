import React from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Box, Paper, Typography } from "@mui/material";
const NotFound = () => {
  return (
    <>
      <Paper
        sx={{
          p: "30px",
          display: "grid",
          placeContent: "center",
          boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.08)",
          textAlign: "center !important",
          fontFamily: "Inter !important",
        }}
      >
        
        <Box>
          <BarChartIcon
            sx={{
              color: "#ffb800",
              fontSize: "60px",
            }}
          />
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: 600,
            }}
          >
            No Records Found
          </Typography>
          <Typography
            sx={{
              mt: "10px",
            }}
          >
            We could not find any related data
          </Typography>
        </Box>
      </Paper>
    </>
  );
};

export default NotFound;
