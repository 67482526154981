import React from "react";
import { Box, Typography } from "@mui/material";
import { DescriptionComponent } from "../../../components/Helpers/helperComponents";

export default function CSevenCardComponent(props) {
  const { img, head, name, position } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent:"center",
        gap: "48px",
        background: "#000",
        boxShadow: "10px 10px 10px 0px rgba(179, 186, 250, 0.60)",
        borderRadius: "16px",
        padding: "48px 48px 52px",
        maxWidth: "370px",
        minHeight:"304px",
        '@media (max-width:800px)':{px:"30px!important"}
      }}
    >
      <Box sx={{ display: "flex", gap: "12px" }}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4344 2.0958L10.4344 2.09604L11.7298 6.25713L11.73 6.25778C11.8545 6.65633 12.0982 7.00932 12.4323 7.26267L12.8854 6.66508L12.4323 7.26267C12.7669 7.51643 13.174 7.65604 13.5954 7.65637H13.596H17.7872C17.9912 7.65637 18.1529 7.77961 18.2223 8.00276C18.2923 8.22811 18.2327 8.45457 18.0452 8.59707C18.0452 8.59709 18.0452 8.5971 18.0452 8.59711L14.6564 11.1673C14.6563 11.1674 14.6562 11.1675 14.656 11.1676C14.3215 11.4212 14.0775 11.7746 13.953 12.1737C13.8286 12.5727 13.8284 13.0017 13.9524 13.4009L13.9525 13.4013L15.2479 17.5624L15.248 17.5628C15.328 17.8193 15.2327 18.0327 15.0712 18.1552C14.9146 18.274 14.7294 18.2888 14.5528 18.155C14.5528 18.155 14.5528 18.155 14.5527 18.1549L11.1631 15.5841L11.1629 15.5839C10.828 15.33 10.4204 15.1905 9.99864 15.1905C9.57683 15.1905 9.1693 15.33 8.83433 15.5839L8.83417 15.5841L5.44442 18.155L5.44418 18.1552C5.26841 18.2886 5.08421 18.274 4.92785 18.1554C4.76646 18.0329 4.67081 17.819 4.75068 17.5621L6.04598 13.4013L6.0461 13.4009C6.17011 13.0017 6.16989 12.5727 6.04546 12.1737C5.92101 11.7746 5.67699 11.4212 5.34239 11.1676C5.34228 11.1675 5.34216 11.1674 5.34204 11.1673L1.95451 8.59838C1.95446 8.59834 1.95441 8.5983 1.95436 8.59826C1.76726 8.45595 1.70762 8.22974 1.7778 8.00423C1.84738 7.78063 2.00935 7.65763 2.21252 7.65763H6.40228C6.40235 7.65763 6.40241 7.65763 6.40248 7.65763C6.82419 7.6577 7.23167 7.51829 7.56663 7.26454L7.11375 6.66671L7.56663 7.26454C7.90112 7.01115 8.14512 6.65791 8.26969 6.25904L8.26989 6.2584L9.56525 2.0973L9.57819 2.05574C9.74325 1.63391 10.2957 1.64981 10.4344 2.0958Z"
            fill="#495ECA"
            stroke="#495ECA"
            strokeWidth="1.5"
          />
        </svg>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4344 2.0958L10.4344 2.09604L11.7298 6.25713L11.73 6.25778C11.8545 6.65633 12.0982 7.00932 12.4323 7.26267L12.8854 6.66508L12.4323 7.26267C12.7669 7.51643 13.174 7.65604 13.5954 7.65637H13.596H17.7872C17.9912 7.65637 18.1529 7.77961 18.2223 8.00276C18.2923 8.22811 18.2327 8.45457 18.0452 8.59707C18.0452 8.59709 18.0452 8.5971 18.0452 8.59711L14.6564 11.1673C14.6563 11.1674 14.6562 11.1675 14.656 11.1676C14.3215 11.4212 14.0775 11.7746 13.953 12.1737C13.8286 12.5727 13.8284 13.0017 13.9524 13.4009L13.9525 13.4013L15.2479 17.5624L15.248 17.5628C15.328 17.8193 15.2327 18.0327 15.0712 18.1552C14.9146 18.274 14.7294 18.2888 14.5528 18.155C14.5528 18.155 14.5528 18.155 14.5527 18.1549L11.1631 15.5841L11.1629 15.5839C10.828 15.33 10.4204 15.1905 9.99864 15.1905C9.57683 15.1905 9.1693 15.33 8.83433 15.5839L8.83417 15.5841L5.44442 18.155L5.44418 18.1552C5.26841 18.2886 5.08421 18.274 4.92785 18.1554C4.76646 18.0329 4.67081 17.819 4.75068 17.5621L6.04598 13.4013L6.0461 13.4009C6.17011 13.0017 6.16989 12.5727 6.04546 12.1737C5.92101 11.7746 5.67699 11.4212 5.34239 11.1676C5.34228 11.1675 5.34216 11.1674 5.34204 11.1673L1.95451 8.59838C1.95446 8.59834 1.95441 8.5983 1.95436 8.59826C1.76726 8.45595 1.70762 8.22974 1.7778 8.00423C1.84738 7.78063 2.00935 7.65763 2.21252 7.65763H6.40228C6.40235 7.65763 6.40241 7.65763 6.40248 7.65763C6.82419 7.6577 7.23167 7.51829 7.56663 7.26454L7.11375 6.66671L7.56663 7.26454C7.90112 7.01115 8.14512 6.65791 8.26969 6.25904L8.26989 6.2584L9.56525 2.0973L9.57819 2.05574C9.74325 1.63391 10.2957 1.64981 10.4344 2.0958Z"
            fill="#495ECA"
            stroke="#495ECA"
            strokeWidth="1.5"
          />
        </svg>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4344 2.0958L10.4344 2.09604L11.7298 6.25713L11.73 6.25778C11.8545 6.65633 12.0982 7.00932 12.4323 7.26267L12.8854 6.66508L12.4323 7.26267C12.7669 7.51643 13.174 7.65604 13.5954 7.65637H13.596H17.7872C17.9912 7.65637 18.1529 7.77961 18.2223 8.00276C18.2923 8.22811 18.2327 8.45457 18.0452 8.59707C18.0452 8.59709 18.0452 8.5971 18.0452 8.59711L14.6564 11.1673C14.6563 11.1674 14.6562 11.1675 14.656 11.1676C14.3215 11.4212 14.0775 11.7746 13.953 12.1737C13.8286 12.5727 13.8284 13.0017 13.9524 13.4009L13.9525 13.4013L15.2479 17.5624L15.248 17.5628C15.328 17.8193 15.2327 18.0327 15.0712 18.1552C14.9146 18.274 14.7294 18.2888 14.5528 18.155C14.5528 18.155 14.5528 18.155 14.5527 18.1549L11.1631 15.5841L11.1629 15.5839C10.828 15.33 10.4204 15.1905 9.99864 15.1905C9.57683 15.1905 9.1693 15.33 8.83433 15.5839L8.83417 15.5841L5.44442 18.155L5.44418 18.1552C5.26841 18.2886 5.08421 18.274 4.92785 18.1554C4.76646 18.0329 4.67081 17.819 4.75068 17.5621L6.04598 13.4013L6.0461 13.4009C6.17011 13.0017 6.16989 12.5727 6.04546 12.1737C5.92101 11.7746 5.67699 11.4212 5.34239 11.1676C5.34228 11.1675 5.34216 11.1674 5.34204 11.1673L1.95451 8.59838C1.95446 8.59834 1.95441 8.5983 1.95436 8.59826C1.76726 8.45595 1.70762 8.22974 1.7778 8.00423C1.84738 7.78063 2.00935 7.65763 2.21252 7.65763H6.40228C6.40235 7.65763 6.40241 7.65763 6.40248 7.65763C6.82419 7.6577 7.23167 7.51829 7.56663 7.26454L7.11375 6.66671L7.56663 7.26454C7.90112 7.01115 8.14512 6.65791 8.26969 6.25904L8.26989 6.2584L9.56525 2.0973L9.57819 2.05574C9.74325 1.63391 10.2957 1.64981 10.4344 2.0958Z"
            fill="#495ECA"
            stroke="#495ECA"
            strokeWidth="1.5"
          />
        </svg>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4344 2.0958L10.4344 2.09604L11.7298 6.25713L11.73 6.25778C11.8545 6.65633 12.0982 7.00932 12.4323 7.26267L12.8854 6.66508L12.4323 7.26267C12.7669 7.51643 13.174 7.65604 13.5954 7.65637H13.596H17.7872C17.9912 7.65637 18.1529 7.77961 18.2223 8.00276C18.2923 8.22811 18.2327 8.45457 18.0452 8.59707C18.0452 8.59709 18.0452 8.5971 18.0452 8.59711L14.6564 11.1673C14.6563 11.1674 14.6562 11.1675 14.656 11.1676C14.3215 11.4212 14.0775 11.7746 13.953 12.1737C13.8286 12.5727 13.8284 13.0017 13.9524 13.4009L13.9525 13.4013L15.2479 17.5624L15.248 17.5628C15.328 17.8193 15.2327 18.0327 15.0712 18.1552C14.9146 18.274 14.7294 18.2888 14.5528 18.155C14.5528 18.155 14.5528 18.155 14.5527 18.1549L11.1631 15.5841L11.1629 15.5839C10.828 15.33 10.4204 15.1905 9.99864 15.1905C9.57683 15.1905 9.1693 15.33 8.83433 15.5839L8.83417 15.5841L5.44442 18.155L5.44418 18.1552C5.26841 18.2886 5.08421 18.274 4.92785 18.1554C4.76646 18.0329 4.67081 17.819 4.75068 17.5621L6.04598 13.4013L6.0461 13.4009C6.17011 13.0017 6.16989 12.5727 6.04546 12.1737C5.92101 11.7746 5.67699 11.4212 5.34239 11.1676C5.34228 11.1675 5.34216 11.1674 5.34204 11.1673L1.95451 8.59838C1.95446 8.59834 1.95441 8.5983 1.95436 8.59826C1.76726 8.45595 1.70762 8.22974 1.7778 8.00423C1.84738 7.78063 2.00935 7.65763 2.21252 7.65763H6.40228C6.40235 7.65763 6.40241 7.65763 6.40248 7.65763C6.82419 7.6577 7.23167 7.51829 7.56663 7.26454L7.11375 6.66671L7.56663 7.26454C7.90112 7.01115 8.14512 6.65791 8.26969 6.25904L8.26989 6.2584L9.56525 2.0973L9.57819 2.05574C9.74325 1.63391 10.2957 1.64981 10.4344 2.0958Z"
            fill="#495ECA"
            stroke="#495ECA"
            strokeWidth="1.5"
          />
        </svg>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4344 2.0958L10.4344 2.09604L11.7298 6.25713L11.73 6.25778C11.8545 6.65633 12.0982 7.00932 12.4323 7.26267L12.8854 6.66508L12.4323 7.26267C12.7669 7.51643 13.174 7.65604 13.5954 7.65637H13.596H17.7872C17.9912 7.65637 18.1529 7.77961 18.2223 8.00276C18.2923 8.22811 18.2327 8.45457 18.0452 8.59707C18.0452 8.59709 18.0452 8.5971 18.0452 8.59711L14.6564 11.1673C14.6563 11.1674 14.6562 11.1675 14.656 11.1676C14.3215 11.4212 14.0775 11.7746 13.953 12.1737C13.8286 12.5727 13.8284 13.0017 13.9524 13.4009L13.9525 13.4013L15.2479 17.5624L15.248 17.5628C15.328 17.8193 15.2327 18.0327 15.0712 18.1552C14.9146 18.274 14.7294 18.2888 14.5528 18.155C14.5528 18.155 14.5528 18.155 14.5527 18.1549L11.1631 15.5841L11.1629 15.5839C10.828 15.33 10.4204 15.1905 9.99864 15.1905C9.57683 15.1905 9.1693 15.33 8.83433 15.5839L8.83417 15.5841L5.44442 18.155L5.44418 18.1552C5.26841 18.2886 5.08421 18.274 4.92785 18.1554C4.76646 18.0329 4.67081 17.819 4.75068 17.5621L6.04598 13.4013L6.0461 13.4009C6.17011 13.0017 6.16989 12.5727 6.04546 12.1737C5.92101 11.7746 5.67699 11.4212 5.34239 11.1676C5.34228 11.1675 5.34216 11.1674 5.34204 11.1673L1.95451 8.59838C1.95446 8.59834 1.95441 8.5983 1.95436 8.59826C1.76726 8.45595 1.70762 8.22974 1.7778 8.00423C1.84738 7.78063 2.00935 7.65763 2.21252 7.65763H6.40228C6.40235 7.65763 6.40241 7.65763 6.40248 7.65763C6.82419 7.6577 7.23167 7.51829 7.56663 7.26454L7.11375 6.66671L7.56663 7.26454C7.90112 7.01115 8.14512 6.65791 8.26969 6.25904L8.26989 6.2584L9.56525 2.0973L9.57819 2.05574C9.74325 1.63391 10.2957 1.64981 10.4344 2.0958Z"
            fill="#495ECA"
            stroke="#495ECA"
            strokeWidth="1.5"
          />
        </svg>
      </Box>
      <DescriptionComponent
      text={head}
      color="#fff"
      ta="center"
      fs="18px"
      />
      
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "12px",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={img}
          sx={{ height: "40px", width: "auto", borderRadius: "50px" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "5px",
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              textAlign: "center",
              fontFeatureSettings: " 'ss06' on",
              fontFamily: "SF Pro Display",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "20.3px"
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              color: "var(--neutral-500, #717184)",
              textAlign: "center",
              fontFeatureSettings: " 'ss06' on",
              fontFamily: "SF Pro Display",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "16.2px"
            }}
          >
            {position}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
