import { React, useEffect, useState } from "react";
import {
  Badge,
  IconButton,
  Menu,
  Box,
  Typography,
} from "@mui/material";
// import { Notifications as NotificationsIcon } from "@mui/icons-material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
// import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";

const NotificationMenu = () => {
  // const [notifications, setNotifications] = useState([]);
  // Notifications API call
  // const navigate = useNavigate();
  
  // Notifications close and open
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    // getAllNotifications();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // const notifications = [
  //   { id: 1, message: "Notification 1" },
  //   { id: 2, message: "Notification 2" },
  //   { id: 3, message: "Notification 3" },
  // ];
  useEffect(() => {
  }, []);
  return (
    <div>
      <IconButton
        sx={{ color: "black", mx: "0.5rem" }}
        onClick={handleOpenMenu}
      >
        <Badge
          // badgeContent={notifications?.length ?? 0}
          badgeContent={0}
          variant="dot"
          color="primary"
          // color="error"
        >
          <NotificationsNoneOutlinedIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          square: true,
          sx: { borderRadius: "15px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mx: "15px",
            my: "10px",
            width: "370px!important",
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontSize: "18px",
              fontWeight: "500",
              textAlign: "left",
            }}
          >
            {" "}
            Notifications
          </Typography>
          {/* <Typography
            sx={{
              color: "#05B2FF",
              fontSize: "15px",
              fontWeight: "500",
              textAlign: "right",
            }}
          >
            Mark all as read
          </Typography> */}
        </Box>
        <Divider sx={{}} />
        {/* {notifications.map((item) => (
          <MenuItem
            key={item.id}
            onClick={handleCloseMenu}
            sx={{
              borderBottom: "solid 1px #E3E3E3",
              py: "10px",
              background: item.read ? "#fff":"#F2FBFF",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" , width:"100%"}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom:"10px"
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    textAlign: "left",
                    
                  }}
                >
                  {item.notification.message}
                  
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "400",
                    textAlign: "right",
                    
                  }}
                >
                  {item.time}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", width: "100%" , alignItems:"center"}}>
                {/* <Box component="img" src={Img1} sx={{height:"30px", marginRight:"8px"}} />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    textAlign: "left",
                    color:"#626262"
                  }}
                >
                  {item.notification.amount} ETH
                </Typography>
                <Box component="img" src={Img3} sx={{height:"20px", mx:"20px"}} />
                <Box component="img" src={Img2} sx={{height:"30px", marginRight:"8px"}} /> 
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    textAlign: "right",
                    color:"#626262"
                  }}
                >
                  R {item.notification.amountInRand}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        ))} */}
      </Menu>
    </div>
  );
};

export default NotificationMenu;
