import React from "react";
import { Box } from "@mui/material";
import PricingCOneCard from "./COneCard";
import PricingCOneCard2 from "./COneCard2";
import PricingCOneCard3 from "./COneCard3";

export default function PricingComponentOne() {
  return (
    <Box
      sx={{
        mt: "180px",
        display: "flex",
        gap: "42px",
        alignItems: "center",
        justifyContent: "center",
        '@media (max-width:1300px)':{
          mt:"80px",
          px:"40px",
          gap:"21px",
          pb:"30px"
        }
        , '@media (max-width:950px)':{
          flexDirection:"column",
        }
      }}
    >
        <PricingCOneCard plan='E-commerce Integration' price='Free' />
        <PricingCOneCard2 plan='POS' price='Contact Sales' />
        <PricingCOneCard3 plan='E-commerce Enterprises' price='Contact Sales' />
    </Box>
  );
}
