import React from "react";
import { Grid } from "@mui/material";
import SupportCOneCard from "./SupportCOneCard";
import { FundingSvg, GettingStartedSvg, ManagingMyAccountSvg, OtherTopicsSvg, PrivacyAndSecuritySvg, TransactionsSvg } from "./SupportSvg";
import { Link } from "react-router-dom";


const data = [
  {
    head: "Getting started",
    content: "Everything you need to start using Gafahpay.",
    to: "getting-started",
    svg: <GettingStartedSvg />
  },
  {
    head: "Managing my account",
    content: "Manage your account, settings, and more.",
    to: "managing-account",
    svg: <ManagingMyAccountSvg />
  },
  {
    head: "Transactions",
    content: "Learn Buy, sell, send, or receive digital assets.",
    to: "transactions",
    svg: <TransactionsSvg />
  },
  {
    head: "Funding",
    content: "Learn how to transfer funds to and from your account.",
    to: "funding",
    svg: <FundingSvg />
  },
  {
    head: "Privacy and security",
    content: "Help secure and protect your account.",
    to: "privacy-security",
    svg: <PrivacyAndSecuritySvg />
  },
  {
    head: "Other topics",
    content: "Learn about API, common scams, referral program, and more.",
    to: "other",
    svg: <OtherTopicsSvg />
  }
]

export default function SupportComponentOne({ mb }) {
  return (
    <Grid
      container
      spacing={2}
      sx={{ position: "relative", mt: "-3.2rem!important", px: "160px", mb: mb || "80px", '@media (max-width:800px)': { px: "40px", mb: "40px" } }}
    >
      {data?.map((row, index) => <Grid
        item
        key={row?.head + index}
        xs={12}
        md={6}
        lg={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: "20px",
          textDecoration: 'none'
        }}
        component={Link}
        to={`/support/${row?.to}/`}
      >
        <SupportCOneCard
          svg={row?.svg}
          head={row?.head}
          content={row?.content}
        />
      </Grid>)}
    </Grid>
  );
}
