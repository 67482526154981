import React from "react";
import { Box, Typography } from "@mui/material";
import HorizontalGafahQRPageStepper from "./posQRGafahStepper";
import { QRCode } from "react-qrcode-logo";
import qrlogo from "../../../assets/img/pos/logo.png";
import { ResourceLoader } from "../../../components/Helpers/svg";

export default function POSQRGafah({
  status,
  loading,
  currency,
  amount,
  paymentUrl,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "33px",
        my: "33px",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          color: "var(--Neutral-800, #170F49)",
          textAlign: "center",
          fontFeatureSettings: `'clig' off, 'liga' off`,
          fontFamily: "SF Pro Display",
          fontSize: "34px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "46px",
          //   mb:"33px"
        }}
      >
        {currency} {amount}
      </Typography>
      <Box
        sx={{
          borderRadius: "15px",
          background: "#FFF",
          // boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.18)",
          p: "20px",
          display: "flex",
          justifyContent: "center",
          width: "fit-content",
        }}
      >
        {loading ? (
          <Box sx={{ m: "50px" }}>
            <ResourceLoader />
          </Box>
        ) : (
          <QRCode
            value={paymentUrl}
            logoImage={qrlogo}
            padding={0}
            size={260}
            // logoOpacity={0.9}
            logoWidth={60}
          />
        )}
      </Box>
      <HorizontalGafahQRPageStepper status={status} />
    </Box>
  );
}
