import React from "react";
import { Box, Typography, Button, Backdrop } from "@mui/material";

export default function RightGridComponentThree() {
  return (
    <Box
      sx={{
        p: "32px",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        borderTop: "1px solid var(--neutral-200, #E3E3E8)",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            p: "9px 16px",
            borderRadius: " 8px",
            border: "1px solid var(--primary-shades-200, #E7E3FC)",
            background: "var(--primary-shades-50, #F7F6FE)",
          }}
        >
          <Typography
            sx={{
              color: "#495ECA",
              textAlign: "center",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20.3px",
            }}
          >
            Buy
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "var(--neutral-400, #9C9CAB)",
            textAlign: "center",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20.3px",
          }}
        >
          Sell
        </Typography>
        <Typography
          sx={{
            color: "var(--neutral-400, #9C9CAB)",
            textAlign: "center",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20.3px",
          }}
        >
          Convert
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "70px" }}></Box>
          <Typography
            sx={{
              color: "var(--neutral-300, #C8C8D0)",
              textAlign: "center",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "56px",
            }}
          >
            AED 0
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <Box
              sx={{
                borderRadius: "50px",
                backgroundColor: "#fff",
                p: "14px",
                display: "flex",
                alignItems: "center",
                boxShadow: "0px 0px 30px rgba(161, 145, 243, 0.20)",
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1667 3.33325V16.6666M14.1667 16.6666L10.8333 13.3333M14.1667 16.6666L17.5 13.3333M5.83333 16.6666V3.33325M5.83333 3.33325L2.5 6.66659M5.83333 3.33325L9.16667 6.66659"
                  stroke="#131316"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Box>

            <Typography
              sx={{
                color: "var(--neutral-400, #9C9CAB)",
                textAlign: "center",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "16.2px",
                letterSpacing: "0.06px",
              }}
            >
              USD
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            color: "var(--neutral-400, #717184)",
            textAlign: "center",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20.3px",
          }}
        >
          You can buy up to AED 25,000
        </Typography>
        <Box
          sx={{
            border: "1px solid var(--neutral-200, #E3E3E8)",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              p: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid var(--neutral-200, #E3E3E8)",
            }}
          >
            <Typography
              sx={{
                color: "var(--neutral-400, #717184)",
                textAlign: "center",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20.3px",
              }}
            >
              Buy
            </Typography>
            <Box
              sx={{
                borderLeft: "1px solid var(--neutral-200, #E3E3E8)",
                pl: "16px",
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                alignItems: "flex-start",
              }}
            >
              <Box sx={{ display: "flex", gap: "8px" }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="10" cy="10" r="10" fill="#495ECA" />
                  <path
                    d="M10.2214 2.5213V2.5284C10.2285 2.5639 10.2285 2.6065 10.2285 2.6491V7.7681C10.2214 7.7965 10.2001 7.8036 10.1788 7.8178C10.0297 7.88879 9.88774 7.95269 9.73864 8.01659C9.53275 8.10889 9.31975 8.20829 9.11386 8.30059L8.36837 8.64138C8.16247 8.73368 7.95658 8.82598 7.75778 8.91827C7.51639 9.03187 7.26789 9.13837 7.0265 9.25197C6.8206 9.34427 6.61471 9.44366 6.40171 9.53596C6.23131 9.61406 6.06092 9.68506 5.89762 9.76316C5.88342 9.77026 5.86922 9.77736 5.85502 9.77736C5.84792 9.77736 5.84792 9.77736 5.84082 9.77026L6.03252 9.45076C6.40171 8.84018 6.7638 8.23669 7.133 7.6261C7.52349 6.97291 7.92108 6.31973 8.31157 5.66654C8.67366 5.06305 9.04286 4.45956 9.40495 3.85607C9.66765 3.41588 9.93744 2.97569 10.2001 2.5355C10.2072 2.5213 10.2143 2.5142 10.2143 2.5H10.2214C10.2143 2.5071 10.2214 2.5142 10.2214 2.5213Z"
                    fill="#F3F3F3"
                  />
                  <path
                    d="M14.5796 9.77017L14.5867 9.77727L13.543 10.395L10.2629 12.3332C10.2487 12.3403 10.2345 12.3474 10.2274 12.3545C10.2061 12.3545 10.2061 12.3332 10.2061 12.3261V12.2622V7.87451C10.2061 7.85321 10.2061 7.82481 10.2132 7.80351C10.2203 7.77511 10.2416 7.78221 10.2629 7.78931C10.3552 7.83191 10.4545 7.87451 10.5468 7.91711C10.8237 8.0449 11.1006 8.1727 11.3775 8.2934C11.6189 8.3999 11.8532 8.51349 12.0946 8.61999C12.336 8.72649 12.5774 8.84009 12.8188 8.94659C13.0247 9.03888 13.2377 9.13828 13.4436 9.23058C13.6495 9.32288 13.8625 9.42228 14.0684 9.51458C14.2317 9.58557 14.395 9.66367 14.5583 9.73467C14.5583 9.75597 14.5654 9.76307 14.5796 9.77017Z"
                    fill="#8A8DA7"
                  />
                  <path
                    d="M10.2205 16.7637C10.2205 16.7708 10.2134 16.7779 10.2134 16.785H10.2063C10.2063 16.7708 10.1921 16.7637 10.185 16.7495C9.74477 16.1318 9.30458 15.507 8.86439 14.8893C8.41709 14.2574 7.9627 13.6185 7.51541 12.9866C7.08232 12.376 6.64213 11.7583 6.20904 11.1477C6.09544 10.9844 5.98184 10.8282 5.86824 10.6649C5.86114 10.6507 5.85404 10.6436 5.83984 10.6223C5.86114 10.6223 5.87534 10.6365 5.88244 10.6436C6.50013 11.0057 7.11072 11.3678 7.72841 11.7299C8.43839 12.1488 9.14128 12.5677 9.85127 12.9866L10.2134 13.1996C10.2276 13.2138 10.2276 13.228 10.2276 13.2422V16.6643C10.2276 16.6998 10.2276 16.7353 10.2205 16.7637Z"
                    fill="#F3F3F3"
                  />
                  <path
                    d="M5.83301 9.78439V9.77729C6.0602 9.67789 6.2803 9.57139 6.50749 9.47199C6.79859 9.3371 7.08968 9.2093 7.38078 9.0744C7.60087 8.975 7.82807 8.86851 8.04816 8.76911C8.37476 8.62001 8.69425 8.47801 9.02085 8.32892C9.24094 8.22952 9.46104 8.13012 9.68823 8.02362C9.84443 7.95262 10.0077 7.88162 10.1639 7.81063C10.1781 7.80353 10.1994 7.79643 10.2065 7.78223C10.2136 7.78223 10.2136 7.78933 10.2065 7.79643V12.3048C10.2065 12.3261 10.1994 12.3474 10.2136 12.3616C10.1994 12.3829 10.1852 12.3616 10.1781 12.3545C10.1142 12.319 10.0503 12.2835 9.98643 12.2409C8.61615 11.4316 7.23878 10.6151 5.86851 9.80569C5.86141 9.79859 5.84721 9.79149 5.83301 9.78439Z"
                    fill="#C5C8D9"
                  />
                  <path
                    d="M14.5661 10.6223H14.5732C14.5732 10.6365 14.559 10.6507 14.5519 10.6649C13.2455 12.5038 11.9391 14.3497 10.6328 16.1886C10.4979 16.3803 10.3559 16.572 10.221 16.7637C10.2139 16.7566 10.2139 16.7495 10.2139 16.7424V16.6998V13.2564V13.1925C10.5121 13.015 10.8032 12.8446 11.1014 12.6671C12.2515 11.9855 13.4017 11.311 14.5448 10.6294C14.5519 10.6365 14.559 10.6294 14.5661 10.6223Z"
                    fill="#C5C8D9"
                  />
                  <path
                    d="M10.2134 7.79642V7.78222V7.73962V2.58512C10.2134 2.56382 10.2063 2.54962 10.2205 2.52832C11.6618 4.92097 13.1031 7.30653 14.5372 9.69918C14.5514 9.72048 14.5727 9.74888 14.5798 9.77728C14.4804 9.74178 14.3881 9.69208 14.2958 9.64948C14.1822 9.59978 14.0615 9.54298 13.9479 9.49329C13.8769 9.45779 13.7988 9.42939 13.7278 9.39389C13.6072 9.33709 13.4865 9.28739 13.3658 9.23059C13.2948 9.20219 13.2238 9.16669 13.1528 9.13119L12.6842 8.9182C12.6061 8.8827 12.528 8.8472 12.4428 8.8117L12.102 8.6626C12.031 8.6342 11.96 8.5987 11.889 8.5632L11.4204 8.35021C11.3423 8.31471 11.2642 8.27921 11.179 8.24371L10.8382 8.09461C10.7601 8.05911 10.6891 8.02361 10.611 7.98812C10.4761 7.92422 10.3412 7.86032 10.1992 7.80352C10.2205 7.79642 10.2134 7.79642 10.2134 7.79642Z"
                    fill="#C5C8D9"
                  />
                </svg>
                <Typography
                  sx={{
                    color: "var(--neutral-900, #131316)",
                    textAlign: "center",
                    fontFeatureSettings: `'ss06' on`,
                    fontFamily: "SF Pro Display",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "20.3px",
                  }}
                >
                  Ethereum
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "var(--neutral-400, #9C9CAB)",
                  fontFeatureSettings: `'ss06' on`,
                  fontFamily: "SF Pro Display",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16.3px",
                  letterSpacing: "0.06px",
                }}
              >
                ETH Balance
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                alignItems: "flex-end",
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.66699 12.6666L10.3337 7.99992L5.66699 3.33325"
                  stroke="#9C9CAB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <Typography
                sx={{
                  color: "var(--neutral-400, #9C9CAB)",
                  fontFeatureSettings: `'ss06' on`,
                  fontFamily: "SF Pro Display",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16.3px",
                  letterSpacing: "0.06px",
                }}
              >
                0.36477 ETH
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              p: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "var(--neutral-400, #717184)",
                textAlign: "center",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20.3px",
              }}
            >
              Pay with
            </Typography>
            <Box
              sx={{
                borderLeft: "1px solid var(--neutral-200, #E3E3E8)",
                pl: "16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                position: "relative",
                left: "-56px",
              }}
            >
              <Box sx={{ display: "flex", gap: "8px" }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="2" fill="#E7E3FC" />
                </svg>

                <Typography
                  sx={{
                    color: "var(--neutral-900, #131316)",
                    textAlign: "center",
                    fontFeatureSettings: `'ss06' on`,
                    fontFamily: "SF Pro Display",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "20.3px",
                  }}
                >
                  Paypal
                </Typography>
              </Box>
            </Box>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.66699 12.6666L10.3337 7.99992L5.66699 3.33325"
                stroke="#9C9CAB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Box>
          <Button
            sx={{
              width: "100%",
              padding: "11px 24px",
              backgroundColor: "#495ECA",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#495ECA",
              },
            }}
          >
            {" "}
            <Typography
              sx={{
                color: "#fff",
                textAlign: "center",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20.3px",
                textTransform: "none",
              }}
            >
              Buy
            </Typography>
          </Button>
        </Box>
      </Box>
      <Backdrop
        open={true}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          background: "rgba(255, 255, 255, 0.7)",
          backdropFilter: " blur(2px)",
          // webkitBackdropFilter: "blur(5px)",
          height: "100%",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            p: "10px 25px",
            color: "#fff",
            background: "#495ECA",
            borderRadius: "5px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "SF Pro Display",
              fontSize: "18px",
              color: "#fff",
            }}
            component="div"
            color="textPrimary"
          >
            Coming Soon...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}
