import React, { useEffect, useState } from "react";
import OngoingTicketTable from "./components/ongoingTicketTable";
import { Box, Grid, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { AuthHeader } from "../../components/Helpers/helperComponents";
import LeftToRightModal from "../../components/Dialog/LeftToRightModal";
import NewTicket from "./components/NewTicket";
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import { InputLabels } from "../../components/Helpers/label";
import { GetHandler } from "../../Redux/RequestHandlers";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import TicketView from "./components/TicketView";
import TablePaginations from "../../components/Tables/TablePagination";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getTotalPage, hasAccess } from "../../utilities";
import ControlPointDuplicateOutlinedIcon from '@mui/icons-material/ControlPointDuplicateOutlined';
// import BackButtonHandler from "../../components/Helpers/BackButtonHandler";
// import { useNavigate } from 'react-router-dom';

export const EmailList = ({ data, key, onClick, active }) => <Box sx={{
  p: '15px',
  borderBottom: '1px solid #0002',
  background: active ? '#bcddf64a' : "#fff",
  cursor: 'pointer'
}}
  key={key}
  onClick={onClick}
>
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      gap: '5px',
      justifyContent: 'space-between'
    }}>
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      gap: '5px',
    }}>
      {/Initiated/.test(data?.status) && <MarkEmailUnreadOutlinedIcon sx={{ fontSize: '20px', color: "#ffc107" }} />}
      {/* <EmailOutlinedIcon color="primary" sx={{ fontSize: '20px' }} /> */}
      {/Resolved/.test(data?.status) && <MarkEmailReadOutlinedIcon sx={{ fontSize: '20px', color: 'green' }} />}
      <InputLabels label="Email" mt="0px" />
    </Box>
    <InputLabels
      mt="0px"
      fw="900"
      fs="9px"
      label={moment(data?.createdAt).format('llll')} />

  </Box>



  <InputLabels
    fw="600"
    mt="10px"
    label={data?.subject?.length <= 60 ? data?.subject : (data?.subject?.substr(0, 60) + "...")}
  />

  <Box sx={{
    fontSize: '12px',
    // mt: '5px'
  }}>
    {data?.description?.length <= 140 ? data?.description : (data?.description?.substr(0, 140) + "...")}
  </Box>
</Box>
export default function OngoingTicketComponent() {
  const { accessLists } = useSelector(state => state.auth)
  const { tickets } = useSelector((state) => state?.ticket);
  const [pages, setPages] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const perPage = 10;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [rightPanelView, setRightPanelView] = useState('newticket');

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleViewChange = (view) => {
    setRightPanelView(view);
  };

  const init = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "tickets",
        action: "ACTION_GET_TICKET",
        params: {
          pageNumber: pages,
        },
      };
      const response = await dispatch(GetHandler(req));
      if (response?.data?.data?.count)
        setTotalPages(getTotalPage(Number(response?.data?.data?.count), perPage));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (pages > 0) init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages]);

  // const navigate = useNavigate();

  // const handleBackNavigation = async () => {
  //   // Perform the API call here
  //   try {
  //     await fetch('/api/cancel-transaction', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ action: 'cancel' }),
  //     });
  //     // Redirect to the previous page (assuming the user was on a specific route)
  //     navigate(-1); // Go back to the previous page
  //   } catch (error) {
  //     console.error('API call error:', error);
  //   }
  // };

  return (
    <Box
      sx={{
        m: "25px",
      }}
    >
      {/* <BackButtonHandler /> */}
      {/* <BackButtonHandler onConfirm={handleBackNavigation} /> */}

      {/* <BackButtonHandler onConfirm={handleConfirm} onCancel={handleCancel} /> */}
      <AuthHeader text="Ongoing Tickets" />
      <Box>

        <Box sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'end',
          pb: '15px'
        }}>
          <LeftToRightModal
            openButton={
              <Tooltip title="Control Points." arrow>
                <IconButton color="primary">
                  <ControlPointDuplicateOutlinedIcon />
                </IconButton>
              </Tooltip>
            }
            width="70%"
            modalTitle="Tickets"
            modalBody={
              isMobile ?
                <>
                  {rightPanelView === 'ticketlist' && (
                    <Box>
                      <Box sx={{
                        p: '5px',
                        px: '15px',
                        display: 'flex',
                        gap: '5px',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                        <InputLabels label="Ongoing Tickets" mt="0px" />
                        {hasAccess(["Ticket-create"], accessLists) &&
                          <Tooltip title="Create New Ticket" onClick={() => handleViewChange("newticket")} arrow>
                            <IconButton aria-label="small" size="small" color="primary">
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        }
                      </Box>
                      <Box sx={{ height: 'calc(100vh - 150px)', overflowY: 'scroll' }}>
                        {tickets?.rows?.length > 0 && tickets?.rows?.map((item, index) => (
                          <EmailList
                            onClick={() => {
                              handleViewChange("ticketdescription");
                              setSelectedIndex(index);
                            }}
                            active={selectedIndex === index}
                            key={item?.id + index + 's'}
                            data={item}
                          />
                        ))}
                      </Box>
                      <Box>
                        {pages > 0 && (
                          <TablePaginations
                            pages={totalPages}
                            page={pages}
                            setPage={setPages}
                          />
                        )}
                      </Box>
                    </Box>
                  )}

                  {rightPanelView === 'ticketdescription' && (
                    <Box sx={{
                      p: '5px'
                    }}>
                      <IconButton onClick={() => handleViewChange("ticketlist")} aria-label="back" size="small" color="primary">
                        <ArrowBackIcon />
                      </IconButton>
                      <Box sx={{
                        px: '15px',
                        mt: '20px'
                      }}>
                        <TicketView data={tickets?.rows[selectedIndex] || {}} />
                      </Box>
                    </Box>
                  )}

                  {rightPanelView === 'newticket' && (
                    <Box
                      sx={{
                        p: '5px'
                      }}>
                      <IconButton onClick={() => handleViewChange("ticketlist")} aria-label="back" size="small" color="primary">
                        <ArrowBackIcon />
                      </IconButton>
                      {hasAccess(["Ticket-create"], accessLists) &&
                        <Box sx={{
                          px: '25px',
                          mt: '20px'
                        }}>
                          <NewTicket init={init} />
                        </Box>
                      }
                    </Box>
                  )}
                </> :
                <Grid container sx={{
                  maxWidth: '100%'
                }}>
                  <Grid item xs={12} lg={5} md={5} sx={{
                    borderRight: '1px solid #0002',
                    display: {
                      xs: 'none',
                      md: 'block'
                    }
                  }}>
                    <Box sx={{
                      p: '5px',
                      px: '15px',
                      display: 'flex',
                      gap: '5px',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                      <InputLabels label="Ongoing Tickets" mt="0px" />
                      {hasAccess(["Ticket-create"], accessLists) &&
                        <Tooltip title="Create New Ticket" onClick={() => setRightPanelView("newticket")} arrow>
                          <IconButton aria-label="small" size="small" color="primary">
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </Box>
                    <Box
                      sx={{
                        height: 'calc(100vh - 150px)',
                        overflowY: 'scroll',
                      }}>
                      {tickets?.rows?.length > 0 &&
                        tickets?.rows?.map((item, index) => <EmailList
                          onClick={() => {
                            setRightPanelView("ticketdescription")
                            setSelectedIndex(index)
                          }}
                          active={selectedIndex === index ? true : false}
                          key={item?.id + index + 's'}
                          data={item}
                          description="Duis mollis, est non commodo luctus, nisi erat porttitor. Duis mollis, est non commodo luctus, nisi erat porttitor. Duis mollis, est non commodo luctus, nisi erat porttitor. Duis mollis, est non commodo luctus, nisi erat porttitor."
                        />)}


                    </Box>
                    <Box>
                      {pages > 0 && (
                        <TablePaginations
                          pages={totalPages}
                          page={pages}
                          setPage={setPages}
                        // totalSelectedItem={totalSelectedItem}
                        />
                      )}
                    </Box>
                  </Grid>

                  <Grid item xs={12} lg={7} md={7} >

                    {hasAccess(["Ticket-create"], accessLists) && /newticket/.test(rightPanelView) && <Box sx={{
                      px: '25px',
                      mt: '20px'
                    }}>
                      <NewTicket init={init} />
                    </Box>
                    }


                    {/ticketdescription/.test(rightPanelView) && <Box sx={{
                      px: {
                        lg: '25px',
                        xs: '15px'
                      },
                      p: '15px',
                      height: 'calc(100vh - 40px)',
                      overflowY: 'scroll'
                    }}>
                      <TicketView data={tickets?.rows[selectedIndex] || {}} />
                    </Box>
                    }
                  </Grid>
                </Grid>

            }
          />
        </Box>

        <OngoingTicketTable
          tickets={tickets}
          isFetching={isFetching}
          setSelectedIndex={setSelectedIndex}
          setRightPanelView={setRightPanelView}
        />
      </Box>


    </Box>
  );
}
