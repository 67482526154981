import { Box, Typography } from "@mui/material";
import React from "react";

export default function ComponentOne() {
  return (
    <Box
      sx={{
        p: "100px 160px 80px!important",
        "@media (max-width:800px)": { p: "70px 30px 30px!important" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems:"center",
          "@media (max-width:1000px)": {
            flexDirection: "column",
            alignItems: "center",
            gap: "24px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            "@media (max-width:1000px)": { alignItems: "center" },
          }}
        >
          <Typography
            sx={{
              color: "#0E0637",
              fontFamily: "SF Pro Display",
              fontSize: "40px",
              fontWeight: 500,
              lineHeight: "56px",
              letterSpacing: "-0.4px",
            }}
          >
            Our Mission
          </Typography>

          <Typography
            sx={{
              color: "#717184",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "25.6px",
              letterSpacing: "0.08px",
              width: "574px",
              textAlign:"justify",
              "@media (min-width:1000px) and (max-width:1400px)": {
                maxWidth: "370px",
              },
              "@media (max-width:1000px)": {
                maxWidth: "auto",
                textAlign: "center",
              },
              "@media (max-width:600px)": {
                width: "100%",
                textAlign: "justify",
              },
            }}
          >
            Payments are the lifeblood of business—and when they improve,
            everyone gains. We live in an age where it can be done cheaper,
            faster, and better, but with digital assets usually limited use case for
            trading, there’s always been a barrier until now. Based in the UAE,
            Gafah offers a solution that’s long been wanted by digital assets users—and
            in return, presents a whole new opportunity for businesses to grow
            their customer base. Focusing on simplicity in design and ease of
            use, we’ve brought to life a user wallet, digital assets POS machine,
            merchant portal, and e-comm integration—so payments can finally be
            done the truly modern way.
          </Typography>
          {/* <Typography
            sx={{
              color: "#495ECA",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20.3px",
              mb: "30px",
            }}
          >
            Learn more
          </Typography> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              p: "32px",
              display: "flex",
              justifyContent: "space-between",
              width: "448px",
              alignItems: "center",
              borderRadius: "16px",
              border: "1px solid var(--primary-shades-200, #E7E3FC)",
              background: "var(--main-colors-white, #FFF)",
              "@media (max-width:1400px)": { width: "290px" },
              "@media (max-width:600px)": { width: "250px" },
            }}
          >
            <Typography
              sx={{
                color: "#0E0637",
                fontFamily: "SF Pro Display",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "36px",
                letterSpacing: "-0.24px",
                textAlign: "center",
              }}
            >
              5+
            </Typography>
            <Typography
              sx={{
                color: "var(--neutral-500, #717184)",
                fontFamily: "SF Pro Display",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "25.6px",
                letterSpacing: "0.08px",
              }}
            >
              Assets supported
            </Typography>
          </Box>
          <Box
            sx={{
              p: "32px",
              display: "flex",
              justifyContent: "space-between",
              width: "448px",
              alignItems: "center",
              borderRadius: "16px",
              border: "1px solid var(--primary-shades-200, #E7E3FC)",
              background: "var(--main-colors-white, #FFF)",
              "@media (max-width:1400px)": { width: "290px" },
              "@media (max-width:600px)": { width: "250px" },
            }}
          >
            <Typography
              sx={{
                color: "#0E0637",
                fontFamily: "SF Pro Display",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "36px",
                letterSpacing: "-0.24px",
                textAlign: "center",
                "@media (max-width:1400px)": { textAlign: "left" },
              }}
            >
              1 platform
            </Typography>
            <Typography
              sx={{
                color: "var(--neutral-500, #717184)",
                fontFamily: "SF Pro Display",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "25.6px",
                letterSpacing: "0.08px",
                "@media (max-width:1400px)": { textAlign: "right" },
              }}
            >
              For digital assets wallet & payments
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
