import * as React from "react";
import { useSelector } from "react-redux";
import {
  BasicDataTable,
  NoRecordInTable,
  StyledTableCell,
  StyledTableRow,
  TableDataLoad,
} from "../../../components/Tables";
import { IconButton, Typography } from "@mui/material";
import moment from "moment";
import StatusShow from "../../../components/Tables/StatusShow";
import { useState } from "react";
import TransactionModal from "./TransactionModal";
import TransactionPdfViewer from "./TransactionPdfViewer";
import { hasAccess, Numberz } from "../../../utilities";
import { AdvanceColumns, BasicColumns } from "./transactionColumns";

export const DTCell = ({ show, onClick, data }) => (
  <StyledTableCell
    align="center"
    sx={{
      display: show,
      fontFamily: "SF Pro Display",
      fontSize: "11.2px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px",
    }}
    onClick={onClick}
  >
    {data}
  </StyledTableCell>
);

export default function TransactionTable(props) {
  const { page, setPage, totalPages, setParams, params, isFetching } = props;
  const { accessLists } = useSelector(state => state.auth)
  const { transactions } = useSelector((state) => state?.transactions);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [checked, setChecked] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [primeSelect, setPrimeSelect] = useState({
    checked: false,
    indeterminate: false,
  });
  const [columns, setColumns] = useState(BasicColumns);

  const handleModalClick = (item) => {
    setModalShow(true);
    setSelectedItem(item);
  };

  const handlePrimeSelectClicked = () => {
    let y = [];

    if (primeSelect?.checked) {
      setPrimeSelect((prev) => ({
        ...prev,
        checked: false,
        indeterminate: false,
      }));
      if (transactions?.transactions?.rows?.length > 0) {
        transactions?.transactions?.rows?.map((i) => y.push(false));
        setSelectedIndex([]);
      }
      setChecked((prev) => [...y]);
    }

    if (!primeSelect?.checked) {
      setPrimeSelect((prev) => ({
        ...prev,
        checked: true,
        indeterminate: false,
      }));
      if (transactions?.transactions?.rows?.length > 0) {
        let k = [];
        for (let i = 0; i < transactions?.transactions?.rows?.length; i++) {
          y.push(true);
          k.push(i);
        }
        setSelectedIndex(k);
      }
      setChecked((prev) => [...y]);
    }
  };

  const handleCheckClick = (index) => {
    let arr = checked;
    arr[index] = !checked[index];
    setChecked((prev) => [...arr]);
    if (!checked[index]) {
      let k = selectedIndex;
      let ind = k.indexOf(index);
      if (ind !== -1) {
        k.splice(ind, 1);
      }
      return setSelectedItem([...k]);
    }
    setSelectedIndex((prev) => [...prev, index]);
  };

  React.useEffect(() => {
    let y = [];
    if (transactions?.transactions?.rows?.length > 0) {
      transactions?.transactions?.rows?.map((i) => y.push(false));
      setChecked([...y]);
    }
  }, [transactions]);

  React.useEffect(() => {
    setPrimeSelect((prev) => ({
      ...prev,
      checked:
        selectedIndex?.length === transactions?.transactions?.rows?.length ? true : false,
      indeterminate:
        selectedIndex?.length > 0 &&
          selectedIndex?.length < transactions?.transactions?.rows?.length
          ? true
          : false,
    }));
  }, [selectedIndex, selectedIndex?.length, transactions?.transactions?.rows?.length]);

  React.useEffect(() => {

    hasAccess(["TransactionAdvance-read"], accessLists) &&
      setColumns(AdvanceColumns)

  }, [accessLists])

  return (
    <>
      <BasicDataTable
        tableHead={columns}
        setColoumns={setColumns}
        callback={setParams}
        sortShowParams={params}
        totalSelectedItem={selectedIndex?.length}
        primeCheck={primeSelect}
        handlePrimeSelectClicked={handlePrimeSelectClicked}
        tableBody={
          <>
            {isFetching ? (
              <TableDataLoad colSpan={12} />
            ) : transactions?.transactions?.rows?.length < 1 ? (
              <NoRecordInTable />
            ) : (
              transactions?.transactions?.rows?.map((row, index) => (
                <StyledTableRow
                  key={row?.id}
                  sx={{
                    background: checked[index] && "#3498db1f",
                    fontFamily: "SF Pro Display",
                    fontSize: "11.2px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "16px",
                  }}
                >
                  {/* //  "#3498db1f"  */}
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{
                      display: columns["checkbox"]?.show ? "" : "none",
                      fontFamily: "SF Pro Display",
                    }}
                  >
                    <IconButton onClick={() => handleCheckClick(index)}>
                      <input
                        type="checkbox"
                        key={index}
                        checked={checked[index]}
                        style={{ cursor: "pointer" }}
                      />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{
                      display: columns["Order Id"]?.show ? "" : "none",
                      cursor: "pointer",
                      color: "#495ECA",
                      fontFamily: "SF Pro Display",
                      fontSize: "11.2px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                    onClick={() => handleModalClick(row)}
                  >
                    <Typography
                      className="text-ellipsis"
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      {row?.id}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{
                      display: columns["Crypto"]?.show ? "" : "none",
                      fontFamily: "SF Pro Display",
                      fontSize: "11.2px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    {row?.token} {row?.network && `(${row?.network})`}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      display: columns["Type"]?.show ? "" : "none",
                      fontFamily: "SF Pro Display",
                      fontSize: "11.2px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    {row.type}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      display: columns["Amount(crypto)"]?.show ? "" : "none",
                      fontFamily: "SF Pro Display",
                      fontSize: "11.2px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                    }}
                  >
                    {row.amount}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      display: columns["Amount"]?.show ? "" : "none",
                      fontFamily: "SF Pro Display",
                      fontSize: "11.2px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "16px",
                      textAlign: "center",
                    }}
                  >
                    {row?.currency} {row.fiatamount}
                  </StyledTableCell>
                  <DTCell
                    show={columns["Real Time Price"]?.show ? "" : "none"}
                    data={
                      row?.realtimeprice &&
                      `${row?.currency} ${Numberz(row?.realtimeprice)}`
                    }
                  />
                  <DTCell
                    show={columns["Status"]?.show ? "" : "none"}
                    data={<StatusShow status={row?.status} />}
                  />
                  <DTCell
                    show={columns["Mode"]?.show ? "" : "none"}
                    data={row?.mode}
                  />
                  <DTCell
                    show={columns["Fee (%)"]?.show ? "" : "none"}
                    data={row?.fee}
                  />
                  <DTCell
                    show={columns["Created At"]?.show ? "" : "none"}
                    data={moment(row.createdAt).format("lll")}
                  />

                  <DTCell
                    show={columns["Invoice"]?.show ? "" : "none"}
                    data={
                      /Completed/.test(row.status) ? (
                        <TransactionPdfViewer data={row} />
                      ) : (
                        <></>
                      )
                    }
                  />
                </StyledTableRow>
              ))
            )}
          </>
        }
        pages={totalPages}
        page={page}
        setPage={setPage}
      />
      <TransactionModal
        opens={modalShow}
        close={() => setModalShow(false)}
        isShow={true}
        data={selectedItem}
      />
    </>
  );
}
