import { Box, Typography } from "@mui/material";
import React from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Numberz } from "../../utilities";
const UpDownShowComponent = ({ sign, diff }) => {
  return (
    <Box
      sx={{
        borderRadius: "100px",
        border: sign === "+" ? "1px solid #DAFBEF" : "1px solid red",
        background: sign === "+" ? "#ECFDF7" : "#f3040426",
        p: "4px 6px!important",
        display: "flex",
        gap: "0px",
        height: "34px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {sign === "+" ? (
        <ArrowDropUpIcon sx={{ color: "#11CF8B" }} />
      ) : (
        <ArrowDropDownIcon sx={{ color: "red" }} />
      )}
      <Typography
        sx={{
          color: sign === "+" ? "#11CF8B" : "red",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "16.2px",
          letterSpacing: "0.06px",
        }}
      >
        {Numberz(diff)} %
      </Typography>
    </Box>
  );
};

export default UpDownShowComponent;
