import React from "react";
import { Box, Typography } from "@mui/material";
import PercentageSlider from "./slider";
import { Numberz } from "../../../../utilities";

export default function PortfolioRowComponent(props) {
  const {
    cryptoName,
    cryptoShortName,
    icon,
    percentage,
    cryptoValue,
    fiatValue,
    fiatCurrency,
  } = props;
  return (
    <Box
      sx={{
        width: "100%",
        borderBottom: "1px solid var(--neutral-200, #E3E3E8)",
        py: "16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      key={cryptoName + percentage}
    >
      <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
        <img src={icon} alt={icon} width="25px" style={{ minWidth: "25px" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography
              sx={{
                color: "var(--neutral-900, #131316)",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20.9px",
                textTransform: "capitalize",
              }}
            >
              {cryptoName}
            </Typography>
            <Typography
              sx={{
                color: "var(--neutral-400, #9C9CAB)",
                textAlign: "center",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "16.2px",
                letterSpacing: "0.06px",
              }}
            >
              {cryptoShortName}
            </Typography>
          </Box>
          <PercentageSlider percent={Numberz(percentage).toFixed(2)} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "4px",
        }}
      >
        <Typography
          sx={{
            color: "var(--neutral-800, #2F2F37)",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20.2px",
          }}
        >
          {Numberz(cryptoValue).toFixed(6) + " " + cryptoShortName}
        </Typography>

        <Typography
          sx={{
            color: "var(--neutral-500, #717184)",
            fontFeatureSettings: `'ss06' on`,
            textAlign: "right",
            fontFamily: "SF Pro Display",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "16.2px",
          }}
        >
          {fiatCurrency} {fiatValue}
        </Typography>
      </Box>
    </Box>
  );
}
