import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import LogoImg from "../../assets/img/logo2.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  AuthHeader,
  AuthPRight,
  Failed,
} from "../../components/Helpers/helperComponents";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";
import { ResourceLoader } from "../../components/Helpers/svg";
import { hasObjectPropertyExist } from "../../utilities";
import { ButtonLoader, PrimaryButton } from "../../components/Buttons";

export default function SmartLink() {
  const { smartLinkDetail } = useSelector((state) => state?.pos);
  const { code, businessname } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // api calls
  const init = async () => {
    try {
      setIsFetching(true);
      let req = {
        url: "smartlinkDetails",
        action: "ACTION_GET_SMARTLINK_DETAIL",
        params: {
          code: code,
          businessname: businessname
        },
        state: {
          code: code,
        },
      };
      let result = await dispatch(GetHandler(req));
      setIsExpired(!result?.data?.success);
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      let req = {
        url: "smartlinkInitiatePayment",
        payload: {
          code: code,
        },
      };
      const res = await dispatch(PostHandler(req));
      if (res?.data?.success && res?.data?.data?.id)
        navigate(`/invoice?id=${res?.data?.data?.id}`);
      // setIsExpired(!result?.data?.success);
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (code) init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);
  return (
    <Box
      sx={{
        background: "#e5e7eb12",
        height: "90vh",
        p: "30px",
        "@media (max-width:1000px)": { p: "30px" },
      }}
    >
      <Box
        component="img"
        src={LogoImg}
        sx={{ height: "40px", width: "auto" }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          pt: "40px",
        }}
      >
        <Box
          sx={{
            borderRadius: "34px",
            border: "1px solid var(--Neutral-300, #EFF0F6)",
            background: "var(--Neutral-100, #FFF)",
            boxShadow: "0px 3px 16px 0px rgba(73, 94, 202, 0.18)",
            p: "37px 76px",
            maxWidth: "500px",
            width: "60%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            mt: "15px",
            "@media (max-width:570px)": { width: "60%" },
          }}
        >
          {isFetching ? (
            <Box sx={{ my: "120px" }}>
              {" "}
              <ResourceLoader />
            </Box>
          ) : isExpired ? (
            <Failed
              my="100px"
              text="Looks like this payment link has been expired, please try with other links to Restart the Payment"
            />
          ) : (
            hasObjectPropertyExist(smartLinkDetail, code) && (
              <>
                <AuthPRight text="Pay" fs="24px" fw="700" />
                <AuthHeader
                  ta="center"
                  text={smartLinkDetail[code]?.merchantName || "Noon LLC"}
                  fw="700"
                />
                <Box
                  sx={{
                    pt: "33px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      background: "#D9DBE9",
                      maxWidth: "420px",
                      width: "100%",
                      height: "1px",
                      mb: "25px",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--Neutral-800, #170F49)",
                        textAlign: "center",
                        fontFeatureSettings: `'clig' off, 'liga' off`,
                        fontFamily: "SF Pro Display",
                        fontSize: "34px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "46px",
                        //   mb:"33px"
                      }}
                    >
                      {smartLinkDetail[code]?.currency}{" "}
                      {smartLinkDetail[code]?.amount}
                    </Typography>
                    {!isSubmitting ? (
                      <PrimaryButton
                        onClick={handleSubmit}
                        title="Continue to Pay"
                      />
                    ) : (
                      <ButtonLoader />
                    )}
                  </Box>
                </Box>
              </>
            )
          )}
        </Box>
      </Box>

      {!isFetching && (
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              //   textAlign: "center",
              fontSize: "12px",
              fontWeight: "700",
              mt: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "SF Pro Display",
              gap: "3px",
            }}
          >
            <Box
              component="img"
              src={LogoImg}
              sx={{ height: "20px", width: "auto" }}
            />
            <Box
              sx={{
                // background:'red'
                fontFamily: "SF Pro Display",
              }}
            >
              Secure Payment
            </Box>
          </Grid>
        </Grid>
      )}
      {/* <POSStepper /> */}
    </Box>
  );
}
