import React, { useEffect, useState } from "react";
import LogoImg from "../../assets/img/logo2.png";
import { Box, CircularProgress } from "@mui/material";
import RefundComponent from "./Refund";
import { useLocation } from "react-router-dom";
import RefundSuccess from "./RefundSuccess";
const InitiateRefund = () => {
  const location = useLocation();
  const [refundData, setRefundData] = useState({});
  const [refundSubmit, setRefundSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const refundToken = queryParams.get("refundToken");
    const amount = queryParams.get("amount");

    if (token && refundToken && amount) {
      setIsLoading(false);
      setRefundData({
        refundAmount: amount,
        refundToken: refundToken,
        token: token,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box
      sx={{
        p: "30px",
        pt: "15px",
        "@media (max-width:1000px)": { p: "5px" },
      }}
    >
      <Box
        component="img"
        src={LogoImg}
        sx={{ height: "40px", width: "auto" }}
      />
      {isLoading ? (
        <Box
          sx={{
            pt: "0px",
            display: "grid",
            height: "90vh",
            placeContent: "center",
            "@media (max-width:600px)": { p: "20px" },
          }}
        >
          <CircularProgress />
        </Box>
      ) : !refundSubmit ? (
        refundData && (
          <RefundComponent
            refundData={refundData}
            setRefundSubmit={setRefundSubmit}
          />
        )
      ) : (
        <RefundSuccess />
      )}
    </Box>
  );
};

export default InitiateRefund;
