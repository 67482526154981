import axios from "axios"
import config from "./config"
import api from "./api"

const http = axios.create({
  baseURL: config.API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
    // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
  },
})

http.interceptors.request.use(function (config) {
  const token = typeof window !== "undefined" && localStorage.getItem("accessToken")
  config.headers.Authorization = token ? `Bearer ${token}` : ""
  return config
})

http.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config
    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true

      // Perform token refresh and update the access token
      const refreshToken = localStorage.getItem('refreshToken') // Replace with your actual refresh token
      const newAccessToken = await refreshAccessToken(refreshToken)

      // Retry the original request with the new access token
      originalRequest.headers.Authorization = newAccessToken ? `Bearer ${newAccessToken}` : ""
      return http(originalRequest)
    }
    return Promise.reject(error)
  }
)

// Define a function to refresh the access token
async function refreshAccessToken(refreshToken) {
  try {
    const response = await axios.post(config?.USER_API_URL + api?.user?.refreshToken, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${refreshToken}`
      }
    })
    const newAccessToken = response.data.data.access_token

    // Update the access token in Redux state
    // setAccessToken(newAccessToken)
    localStorage.setItem('accessToken', newAccessToken)

    return newAccessToken
  } catch (error) {
    // Handle the error, e.g., logout the user
    console.error("Error refreshing token:", error)
    window.location.replace("/login/");
    throw error
  }
}

// // ... Rest of your code ...


const Network = {
  http: http,
  socialLogin: (url) => {
    return http.post(url, { withCredentials: true })
  },
  get: (url, params, header) => {
    if (header) http.get(url, { params: params }, header)
    return http.get(url, { params: params })
  },
  post: (url, data, header = null) => {
    if (header) return http.post(url, data, header)
    else return http.post(url, data)
  },
  put: (url, data, header = null) => {
    if (header) return http.put(url, data, header)
    else return http.put(url, data)
  },
  patch: (url, data, header = null) => {
    if (header) return http.patch(url, data, header)
    else return http.patch(url, data)
  },
  delete: (url, data) => {
    return http.delete(url, data)
  },
  upload: (data, options = null) => {
    if (options) return http.post("/util/file-upload", data, options)
    else return http.post("/util/file-upload", data)
  },
  uploads: (data, options = null) => {
    return options ?
      http.post("/util/file-uploads", data, options)
      : http.post("/util/file-uploads", data)
  },
}
export default Network
