import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { PostHandler } from "../../Redux/RequestHandlers";
import AddIcon from "@mui/icons-material/Add";
import { InputLabels } from "../../components/Helpers/label";

const AddRoles = (props) => {
  const { init } = props;
  const [inputs, setInputs] = useState({
    readonly: false,
  });
  const [isLoadings, setIsLoadings] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInputs({});
  };
  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  const handleSubmit = async () => {
    try {
      setIsLoadings(true);
      const req = {
        url: "roles",
        payload: inputs,
      };
      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) init();
      setIsLoadings(false);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setIsDisable(inputs?.name ? false : true);
  }, [inputs]);
  return (
    <Box>
      <Tooltip title="Add new role" arrow>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{ textTransform: "capitalize" }}
        >
          <AddIcon /> Add Roles
        </Button>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontWeight: 500,
                textAlign: "left",
                fontSize: "14px",
                textTransform: "uppercase",
              }}
            >
              Add New Role
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <InputLabels label="Role name" />
          <TextField
            id="Name"
            name="name"
            variant="outlined"
            onChange={handleChange}
            value={inputs?.name || ""}
            sx={{
              width: "100%",
              mt: "5px",
              // mb: "1rem",
              "@media (max-width:600px)": {
                width: "100%",
              },
            }}
            required
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              mt: "10px",
            }}
          >
            <input
              //   disabled={isReadOnly}
              type="checkbox"
              //   checked={inputs?.includes(permissionList[obkey][1]?.id)}
              onChange={(e) =>
                setInputs((prev) => ({ ...prev, readonly: !inputs?.readonly }))
              }
              style={{ marginTop: "10px" }}
            />
            <InputLabels label="Read Only" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="error">
            Cancel
          </Button>
          {isLoadings ? (
            <Button
              variant="contained"
              //    color="error"
            >
              <CircularProgress size="1.5rem" />
            </Button>
          ) : (
            <Button
              disabled={isDisable}
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddRoles;
