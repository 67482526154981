import React, { useEffect, useState } from "react";
import { Box, Typography, Button, MenuItem, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import {
  CopyToClipboardButton,
  Header5,
} from "../../../components/Helpers/helperComponents";
import { CloseIcon2 } from "../../../components/Helpers/svg";
import VerifiedIcon from "@mui/icons-material/Verified";
import PendingIcon from "@mui/icons-material/Pending";
import StatusShow from "../../../components/Tables/StatusShow";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { hasAccess, Numberz } from "../../../utilities";
import BasicTableWithBody, {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/Tables";
import { useSelector } from "react-redux";
// import { CopyToClipboardButton } from "../../components";
const Tcell = ({ title }) => (
  <Typography
    sx={{
      color: "var(--text-active, #1D283D)",
      fontFamily: "SF Pro Display",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px" /* 125% */,
      minWidth: "100px",
    }}
    className="hide-scroll"
  >
    {title}
  </Typography>
);

const InnerTableCell = ({ content }) => (
  <Typography
    style={{
      color: "var(--text-active, #1D283D)",
      fontFamily: "SF Pro Display",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "20px" /* 125% */,
      textAlign: "right",
      maxWidth: "380px",
      wordWrap: "break-word",
    }}
    className="hide-scroll"
  >
    {content}
  </Typography>
);

const ContentRow = ({ left, right, isCopy }) => (
  <Box
    sx={{
      my: "20px",
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    }}
  >
    <Tcell title={left} />
    <Box
      sx={{
        maxWidth: "100%",
        display: "flex",
        gap: "5px",
        wordWrap: "break-word",
        overflow: "auto",
      }}
    >
      <InnerTableCell content={right} />
      {isCopy && <CopyToClipboardButton content={right} />}
    </Box>
  </Box>
);

const TransactionModal = (props) => {
  const { data, opens, close, isShow } = props;
  const { accessLists } = useSelector(state => state.auth)

  const [open, setOpen] = useState(false);
  const [viewmore, setViewmore] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (props?.close) close();
  };

  useEffect(() => {
    setOpen(opens);
  }, [opens]);

  return (
    <>
      {!isShow && (
        <MenuItem variant="contained" onClick={handleClickOpen}>
          Account Info{" "}
        </MenuItem>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            // width: 500px
            height: "60px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "var(--mono-white, #FFF)",
            boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.03)",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Header5 text="Order ID - " mt="0px" fs="16px" />
            <Typography
              sx={{
                fontWeight: 500,
                textAlign: "left",
                fontSize: "16px",
                color: "#495ECA",
              }}
            >
              {data?.id}
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon2 />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Header5 text="Transaction Details" fs="18px" />

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/confirmed|completed/.test(data?.status?.toLowerCase()) && (
              <VerifiedIcon
                sx={{
                  color: "green",
                  fontSize: "100px",
                }}
              />
            )}

            {/initiated|processing/.test(data?.status?.toLowerCase()) && (
              <PendingIcon
                sx={{
                  color: "#f6b819",
                  fontSize: "100px",
                }}
              />
            )}

            {/declined|failed/.test(data?.status?.toLowerCase()) && (
              <AnnouncementIcon
                sx={{
                  color: "red",
                  fontSize: "100px",
                }}
              />
            )}
            <StatusShow status={data?.status} />
          </Box>

          <Box
            sx={{
              width: "100%",
              borderRadius: "5px",
              p: {
                xs: "0px",
                md: "15px",
              },
              // my: "4rem",
            }}
          >
            {/* <ContentRow left="Name" right={data?.name} /> */}
            {data?.mode === "Gafah" && data?.fromuserDetails && (
              <ContentRow
                left="Payment From"
                right={
                  <>{`${data?.fromuserDetails?.firstname} ${data?.fromuserDetails?.lastname}`}</>
                }
              />
            )}

            <ContentRow
              left="Crypto Token"
              right={
                <>
                  {Numberz(data?.amount)} {data?.token} ({data?.network})
                </>
              }
            />
            <ContentRow left="Type" right={data?.type} />
            {["Gafah", "External"].includes(data?.mode) && (
              <ContentRow left="Mode" right={data?.mode} />
            )}

            <ContentRow
              left="Amount"
              right={
                <>{`${data?.currency} ${Numberz(data?.requestedfiatamount)}`}</>
              }
            />
            {hasAccess(["TransactionAdvance-read"], accessLists) &&
              data?.fee && (
                <ContentRow
                  left="Fee"
                  right={
                    <>
                      {`- ${data?.currency} ${Numberz(
                        data?.requestedfiatamount * (data?.fee / 100)
                      ).toFixed(4)} (${data?.fee}%)`}
                    </>
                  }
                />
              )}
            {data?.vat && (
              <ContentRow
                left="Vat"
                right={
                  <>
                    {`+ ${data?.currency} ${Numberz(
                      data?.requestedfiatamount * (5 / 100)
                    ).toFixed(4)} (5 %)`}
                  </>
                }
              />
            )}
            {hasAccess(["TransactionAdvance-read"], accessLists) &&
              <ContentRow
                left="Received Amount"
                right={
                  <>
                    {`${data?.currency} ${data?.vat
                      ? Numberz(
                        data?.requestedfiatamount -
                        data?.requestedfiatamount * (data?.fee / 100) +
                        data?.requestedfiatamount * (5 / 100)
                      ).toFixed(4)
                      : Numberz(
                        data?.requestedfiatamount -
                        data?.requestedfiatamount * (data?.fee / 100)
                      ).toFixed(4)
                      }`}
                  </>
                }
              />
            }
            <ContentRow
              left="Transaction Initiate Timing"
              right={moment(data?.createdAt).format("llll")}
            />
            {data?.status === "Completed" && (
              <ContentRow
                left="Transaction Complete Timing"
                right={moment(data?.updatedAt).format("llll")}
              />
            )}

            {data?.mode === "External" && data?.externalTransactions && (
              <Box
                sx={{
                  cursor: "pointer",
                  color: "green",
                  textAlign: "right",
                }}
                onClick={() => setViewmore(!viewmore)}
              >
                View {viewmore ? "less" : "more"} details about external
                transactions
              </Box>
            )}
            {viewmore && data?.externalTransactions && (
              <Box>
                External Transaction Details:
                <BasicTableWithBody
                  tableHead={[
                    "From Publickey",
                    "Transactionhash",
                    "Amount",
                    "Status",
                  ]}
                  tableBody={
                    <>
                      {data?.externalTransactions?.map((row) => (
                        <StyledTableRow key={row?.transactionhash}>
                          <StyledTableCell component="th" scope="row">
                            {row?.frompublickey}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row?.transactionhash}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row?.amount}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <StatusShow status={row?.status} />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </>
                  }
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              px: "15px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{
                background: "#495ECA",
                textTransform: "none",
                height: "39px",
                color: "var(--mono-white, #FFF)",
                textAlign: "center",
                fontFamily: "SF Pro Display",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                width: "100%",
              }}
            >
              Done
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TransactionModal;
