import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  CircularProgress,
  MenuItem,
  Select,
} from "@mui/material";
import { AuthPRight, Header5 } from "../../components/Helpers/helperComponents";
import { PrimaryButton2 } from "../../components/Buttons";
import { InputLabels } from "../../components/Helpers/label";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";

import SmartLinkList from "./SmartLinkList";
import KYCBLOCK from "../../components/Kyc";
import { hasAccess } from "../../utilities";

export default function SmartLinks() {
  const { accessLists } = useSelector((state) => state.auth);
  const { smartLinks } = useSelector((state) => state?.apikey);
  const { kycStatus } = useSelector((state) => state?.dashboard);
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);
  const [inputs, setInputs] = useState({
    currency: "USD",
    expiryAt: "1",
  });

  const handleChange = (e) => {
    if (e.target.name === "amount" && isNaN(e.target.value)) return;
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const genarateSmartLink = async () => {
    try {
      setIsSubmitting(true);
      const req = {
        url: "smartLink",
        action: "ACTION_GET_RETURNED_SMARTLINK",
        payload: inputs,
      };
      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) {
        setInputs({
          currency: "USD",
          expiryAt: "1",
        });
        setIsShow(false);
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  // api calls
  const init = async () => {
    try {
      setIsFetching(true);
      let req = {
        url: "smartLink",
        action: "ACTION_GET_SMARTLINKS",
      };
      await dispatch(GetHandler(req));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycStatus]);

  useEffect(() => {
    setIsDisableSubmit(
      inputs?.currency &&
        inputs?.amount &&
        inputs?.amount > 0 &&
        inputs?.expiryAt
        ? false
        : true
    );
  }, [inputs]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          p: "20px",
        }}
      >
        <Grid container>
          <Grid item lg="3">
            <Box
              sx={{
                pt: "32px",
                mb: "15px",
              }}
            >
              <Header5 text="Smart Links" fs="16px" />

              <AuthPRight
                text="Smart Links let you use Gafah from within other tools or your own software."
                fs="16px"
                lh="22px"
                mt="10px"
              />
            </Box>
          </Grid>
          <Grid item xs="12" lg="9">
            {kycStatus?.status !== "Completed" ? (
              <Box
                sx={{
                  p: {
                    xs: "15px",
                    md: "30px",
                  },
                  display: "grid",
                  placeContent: "center",
                  borderRadius: "8px",
                  border: "1px solid var(--neutral-200, #E3E3E8)",
                  background: "var(--main-colors-white, #FFF)",
                }}
              >
                <KYCBLOCK />
              </Box>
            ) : (
              <>
                {/* add apikey */}
                {isFetching ? (
                  <CircularProgress />
                ) : (
                  isShow && (
                    <Box
                      sx={{
                        mb: "15px",
                        p: "20px",
                        borderRadius: "8px",
                        border: "1px solid var(--neutral-200, #E3E3E8)",
                        background: "var(--main-colors-white, #FFF)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Header5
                          text="Generate New Smart Link"
                          fs="16px"
                          mt="0px"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "row",
                          },
                        }}
                      >
                        <Grid item xs="12" lg="6">
                          <InputLabels label="Currency" />
                          <Select
                            // label="Currency"
                            defaultValue="USD"
                            name="currency"
                            value={inputs?.currency}
                            onChange={handleChange}
                            sx={{
                              width: "100%",
                              mt: "5px",
                            }}
                          >
                            <MenuItem value="USD">USD</MenuItem>
                            <MenuItem value="AED">AED</MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs="12" lg="6">
                          <InputLabels label="Amount" />
                          <TextField
                            id="amount"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            sx={{
                              width: "100%",
                              mt: "5px",
                              "&.Mui-focused fieldset": {
                                borderColor: "#C52328",
                                borderWidth: "2px",
                              },
                            }}
                            name="amount"
                            onChange={handleChange}
                            value={inputs?.amount || ""}
                            placeholder="Enter Amount"
                            required
                          />
                        </Grid>

                        <Grid item xs="12" lg="6">
                          <InputLabels label="Expire At" />
                          <Select
                            defaultValue="1"
                            name="expiryAt"
                            value={inputs?.expiryAt}
                            onChange={handleChange}
                            sx={{
                              width: "100%",
                              mt: "5px",
                            }}
                          >
                            <MenuItem value="1">1 Day</MenuItem>
                            <MenuItem value="10">10 Day</MenuItem>
                            <MenuItem value="20">20 Day</MenuItem>
                            <MenuItem value="30">30 Day</MenuItem>
                            <MenuItem value="90">90 Day</MenuItem>
                            <MenuItem value="180">180 Day</MenuItem>
                            <MenuItem value="270">270 Day</MenuItem>
                            <MenuItem value="365">1 Year</MenuItem>
                          </Select>
                        </Grid>
                      </Box>
                      <Box sx={{ display: "flex", gap: "10px", mt: "20px" }}>
                        {isSubmitting ? (
                          <Button variant="contained" title="submit" disabled>
                            <CircularProgress size="1.7rem" />
                          </Button>
                        ) : (
                          <PrimaryButton2
                            variant="contained"
                            title="Submit"
                            onClick={genarateSmartLink}
                            disabled={isDisableSubmit}
                          />
                        )}

                        <Button onClick={() => setIsShow(false)}>Cancel</Button>
                      </Box>
                    </Box>
                  )
                )}
                <Box
                  sx={{
                    p: "20px",
                    borderRadius: "8px",
                    border: "1px solid var(--neutral-200, #E3E3E8)",
                    background: "var(--main-colors-white, #FFF)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Header5 text="Your Smart Links" fs="16px" mt="0px" />
                    </Box>

                    {hasAccess(["Smartlink-create"], accessLists) && (
                      <PrimaryButton2
                        onClick={() => setIsShow(true)}
                        title={<> + Generate New Smart Links</>}
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    p: "20px",
                    borderRadius: "8px",
                    border: "1px solid var(--neutral-200, #E3E3E8)",
                    background: "var(--main-colors-white, #FFF)",
                    mt: "10px",
                  }}
                >
                  {smartLinks?.length > 0 &&
                    smartLinks?.map((item, index) => (
                      <SmartLinkList item={item} />
                    ))}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
