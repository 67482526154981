import React from "react";
import { Box, Typography } from "@mui/material";
import SearchBox from "./searchbox";
import SelectBoxWallet from "./selectBox";
import WalletTable from "./walletTable";

export default function WalletComponentTwo() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <Typography
        sx={{
          color: "#000",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "27.9px",
        }}
      >
        Your Holdings
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <SearchBox />
        <SelectBoxWallet item1="All assets" item2="One asset" />
      </Box>
      <WalletTable />
    </Box>
  );
}
