import { Box, Typography } from "@mui/material";
import React from "react";
import StatusShow from "../../../components/Tables/StatusShow";
import { InputLabels } from "../../../components/Helpers/label";
import moment from "moment";

const TicketView = ({ data }) => (
  <Box>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "5px",
      }}
    >
      <InputLabels fs="11px" label={<>Ticket Id: <span style={{color: '#1976d2'}}>{data?.id}</span></>} mt="0px" fw="900" />
      <StatusShow status={data?.status} />
    </Box>
    <InputLabels label={`Email: ${data?.email}`} mt="0px" fw="900" />
    <InputLabels label={`${data?.name}`} mt="25px" fw="900" />
    <InputLabels
      label={moment(data?.createdAt).format("llll")}
      mt="0px"
      fs="12px"
    />
    <InputLabels label={`Subject: ${data?.subject}`} mt="25px" fw="900" />
    <Typography
      sx={{
        my: "50px",
      }}
    >
      {data?.description}
    </Typography>
  </Box>
);

export default TicketView;
