import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/'); // Navigate to the home page
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          textAlign: 'center',
        }}
      >
        <ErrorOutlineIcon sx={{ fontSize: 120, color: '#FF6F61' }} />
        <Typography variant="h1" sx={{ fontSize: 80, fontWeight: 'bold', mt: 2 }}>
          404
        </Typography>
        <Typography variant="h4" sx={{ mt: 2, color: '#757575' }}>
          Oops! The page you're looking for doesn't exist.
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, color: '#757575' }}>
          It might have been moved or deleted.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGoHome}
          sx={{
            mt: 4,
            px: 4,
            py: 1.5,
            backgroundColor: '#FF6F61',
            '&:hover': {
              backgroundColor: '#FF3D33',
            },
          }}
        >
          Go to Homepage
        </Button>
      </Box>
    </Container>
  );
};

export default NotFound;
