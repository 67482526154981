import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import logo from "../../../assets/img/logo2.png";
import moment from "moment";
import { Numberz } from "../../../utilities";


const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    padding: 60,
    fontSize: 12,
    backgroundColor: "#fff",
  },
  title: {
    fontSize: 34,
    textAlign: "right",
  },
  section: {
    marginBottom: 10,
    marginTop: 70,
  },
  logo: {
    width: 120,
  },
  table: {
    display: "table",
    width: "auto",
    marginBottom: 10,
  },
  row: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderBottomColor: "#000",
    alignItems: "center",
    padding: 5,
    borderLeft: "0.5px solid #000",
    borderRight: "0.5px solid #000",
    borderTop: "0.5px solid #000",
  },
  cell: {
    width: "25%",
    padding: 5,
    fontSize: "8px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  cell1: {
    width: "35%",
    padding: 5,
    fontSize: "8px",
  },
  bold: {
    fontWeight: "bold",
  },
  alignRight: {
    textAlign: "right",
  },
  container: {
    width: "100%",
    position: "relative",
  },
  container2: {
    // marginTop: 130,
    width: "100%",
    position: "relative",
  },
  leftBox: {
    width: "45%",
    position: "absolute",
    left: 0,
  },
  rightBox: {
    width: "45%",
    position: "absolute",
    right: 0,
  },
  invoiceTopInfo: {
    marginTop: 100,
    marginBottom: 30,
    width: "100%",
    position: "relative",
    fontSize: "12px",
  },
  text: {
    marginTop: 5,
  },
  payInfoText: {
    marginTop: 5,
  },
  billTo: {
    fontSize: "10px",
    fontWeight: "bold",
    wordBreak: "break-word",
  },
  table2Cell1: {
    fontSize: "6px",
    width: "32%",
  },
  table2Cell2: {
    fontSize: "6px",
    width: "52%",
  },
  table2Cell3: {
    fontSize: "6px",
    width: "8%",
  },
  table2Cell4: {
    fontSize: "6px",
    width: "8%",
  },
});

const InvoicePDF = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.container}>
        <View style={styles.leftBox}>
          <Image src={logo} style={styles.logo} />
        </View>
        <View style={styles.rightBox}>
          <Text style={styles.title}>INVOICE</Text>
          <Text style={{ fontSize: 10, textAlign: "right" }}>
            Merchant Invoice
          </Text>
        </View>
      </View>

      <View style={styles.invoiceTopInfo}>
        <View style={styles.leftBox}>
          {data?.fromuserDetails && (
            <Text style={styles.billTo}>
              Bill To:{" "}
              {data?.fromuserDetails &&
                data?.fromuserDetails?.firstname +
                  " " +
                  data?.fromuserDetails?.lastname}
            </Text>
          )}
        </View>
        <View style={{ ...styles.rightBox, textAlign: "right" }}>
          <Text style={styles?.billTo}>Invoice Id : {data?.id}</Text>
          <Text style={styles?.billTo}>
            {moment(data?.createdAt).format("lll")}
            {data?.externalTransactions?.lenght}
          </Text>
        </View>
      </View>

      {data?.externalTransactions && data?.mode === "External" && (
        <>
          {/* New Table Section */}
          <Text style={styles.billTo}>Bill To:</Text>
          <View
            style={{
              marginTop: "10px",
            }}
          >
            <View
              style={{
                display: "table",
                width: "auto",
              }}
            >
              <View style={styles.row}>
                <View style={styles.table2Cell1}>
                  <Text style={styles.bold}>From Publickey</Text>
                </View>
                <View style={styles.table2Cell2}>
                  <Text style={styles.bold}>Transactionhash</Text>
                </View>
                <View style={styles.table2Cell3}>
                  <Text style={styles.bold}>Amount</Text>
                </View>
                <View style={styles.table2Cell4}>
                  <Text style={styles.bold}>Status</Text>
                </View>
              </View>
              {data?.externalTransactions?.map((transaction, index) => (
                <View key={index} style={styles.row}>
                  <View style={styles.table2Cell1}>
                    <Text>{transaction.frompublickey}</Text>
                  </View>
                  <View style={styles.table2Cell2}>
                    <Text>{transaction.transactionhash}</Text>
                  </View>
                  <View style={styles.table2Cell3}>
                    <Text>{Numberz(transaction.amount)}</Text>
                  </View>
                  <View style={styles.table2Cell4}>
                    <Text>{transaction.status}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </>
      )}

      <View
        style={{
          ...styles.section,
          marginTop:
            data?.externalTransactions && data?.mode === "External"
              ? "20px"
              : "70px",
        }}
      >
        <Text style={{ ...styles.billTo, marginBottom: "10px" }}>
          Transaction Details:
        </Text>

        <View style={styles.table}>
          <View style={styles.row}>
            <View style={styles.cell1}>
              <Text style={styles.bold}>Crypto</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.bold}>Amount</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.bold}>Fee</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.bold}>Vat({data?.vat ? "5%" : "0%"})</Text>
            </View>
            <View style={styles.cell}>
              <Text style={{ textAlign: "right", fontWeight: "bold" }}>
                Total
              </Text>
            </View>
          </View>
          <View key={data?.id} style={styles.row}>
            <View style={styles.cell1}>
              <Text>
                {Numberz(data?.amount)} {data?.token} ({data?.network})
              </Text>
            </View>
            <View style={styles.cell}>
              <Text>
                {`${data?.currency} ${Numberz(data?.requestedfiatamount)}`}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text>
                {`- ${data?.currency} ${Numberz(
                  data?.requestedfiatamount * (data?.fee / 100)
                ).toFixed(4)} (${Numberz(data?.fee)}%)`}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text>
                {data?.vat &&
                  `+ ${data?.currency} ${Numberz(
                    data?.requestedfiatamount * (5 / 100)
                  ).toFixed(4)} (5 %)`}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text
                style={{
                  textAlign: "right",
                  fontWeight: "bold",
                  fontSize: "10px",
                }}
              >
                {`${data?.currency} ${
                  data?.vat
                    ? Numberz(
                        data?.requestedfiatamount -
                          data?.requestedfiatamount * (data?.fee / 100) +
                          data?.requestedfiatamount * (5 / 100)
                      ).toFixed(4)
                    : Numberz(
                        data?.requestedfiatamount -
                          data?.requestedfiatamount * (data?.fee / 100)
                      ).toFixed(4)
                }`}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginTop: 60 }}>
          <Text style={{ fontSize: 29 }}>Thank You!</Text>
        </View>
      </View>

      <View
        style={{
          ...styles.container2,
          marginTop:
            data?.externalTransactions && data?.mode === "External"
              ? "80px"
              : "120px",
        }}
      >
        <View style={styles.leftBox}>
          <Text
            style={{
              fontSize: 16,
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            Payment Information
          </Text>
          <Text style={{ marginTop: "10px" }}>
            {data?.userDetails?.businessname}
          </Text>
          <Text style={styles?.payInfoText}>Payment Mode: {data?.mode}</Text>
          <Text style={styles?.payInfoText}>
            Payment Method: {data?.method}
          </Text>
          <Text style={styles?.payInfoText}>Payment Type: {data?.type}</Text>
          <Text style={styles?.payInfoText}>
            Completed By: {moment(data?.updatedAt).format("lll")}
          </Text>
          {data?.refundDetails?.refundrequired && (
            <Text style={styles?.payInfoText}>
              {`Opted for refund amount :- ${Numberz(
                data?.refundDetails?.refundamount
              ).toFixed(4)}`}
            </Text>
          )}
        </View>
        <View style={styles.rightBox}>
          <Text
            style={{
              fontSize: 10,
              textAlign: "right",
              marginTop:
                data?.externalTransactions && data?.mode === "External"
                  ? 140
                  : 190,
            }}
          >
            © Invoice generated by Gafah
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default InvoicePDF;
