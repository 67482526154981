import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import HorizontalExternalQRPageStepper from "./posQRExternalStepper";
import { QRCode } from "react-qrcode-logo";
import qrlogo from "../../../assets/img/pos/logo.png";
import { CopyToClipboardButton } from "../../../components/Helpers/helperComponents";
import { CountdownComponentWithLoader } from "../../../components/Helpers/countdown";
import { ResourceLoader } from "../../../components/Helpers/svg";
import { InputLabels } from "../../../components/Helpers/label";
import { addBigNumber, getSecondsDifference, Numberz } from "../../../utilities";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PendingAmountShow from "../Component/PendingAmountShow";
import BasicPopover from "../../../components/Style/PopOver";
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
export default function POSQRExternal({ loading, showLog, paymentData }) {
  const [partialShow, setPartialShow] = useState(false);
  useEffect(() => {
    setPartialShow(paymentData?.status === "Partial Sent" ? true : false);
  }, [paymentData?.status]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "33px",
        mb: "33px",
        alignItems: "center",
        width: "100%",
        p: "15px",
      }}
    >
      <Box
        sx={{
          borderRadius: "15px",
          background: "#FFF",
          p: "20px",
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          gap: "10px",
          width: "100%",
        }}
      >
        {loading ? (
          <Box sx={{ m: "50px" }}>
            <ResourceLoader />
          </Box>
        ) : (
          <Box
            sx={{
              display: "grid",
              placeContent: "center",
            }}
          >
            <QRCode
              value={paymentData?.publickey}
              logoImage={qrlogo}
              padding={0}
              size={260}
              logoWidth={60}
            />
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              mt: "10px",
              justifyContent: "space-between",
            }}
          >
            <InputLabels label="Amount" mt="0px" />
            <Typography
              sx={{
                color: "var(--Neutral-800, #170F49)",
                textAlign: "center",
                fontFeatureSettings: `'clig' off, 'liga' off`,
                fontFamily: "SF Pro Display",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 700,
                //   mb:"33px"
              }}
            >
              ~{paymentData?.currency} {Number(paymentData?.amount).toFixed(2)}
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              mt: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  sx={{
                    fontSize: "24px",
                  }}
                >
                  {paymentData?.status === "priceChange" ||
                    (Numberz(paymentData?.newAmount) > 0 && Numberz(paymentData?.cryptoAmount) !== Numberz(paymentData?.newAmount))
                    ? paymentData?.exchangeFee ? addBigNumber(paymentData?.exchangeFee, paymentData?.newAmount) : paymentData?.newAmount
                    : paymentData?.cryptoAmountWithFee || paymentData?.cryptoAmount}{" "}
                  {paymentData?.token}
                </Typography>
              </Box>


              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    background: "#eeeaea",
                    p: "5px 5px",
                    fontSize: "10px",
                    fontWeight: 600,
                    display: "inline-block",
                    borderRadius: "5px",
                  }}
                >
                  {paymentData?.network}
                </Typography>

                {paymentData?.exchange &&
                  <BasicPopover
                    title="click to view breakup"
                    openButton={
                      <Typography
                        sx={{
                          background: "#495eca",
                          p: "5px 5px",
                          fontSize: "10px",
                          display: "inline-block",
                          borderRadius: "5px",
                          color: '#fff',

                        }}
                      >
                        View breakup
                      </Typography>
                    }
                    body={<Box sx={{ p: '10px', borderRadius: '5px' }}>
                      <Box sx={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                        {paymentData?.amount + " " + paymentData?.currency} <SwapHorizOutlinedIcon sx={{ fontSize: '12px' }} /> {paymentData?.cryptoAmount + " " + paymentData?.token}
                      </Box>
                      <Typography sx={{ fontSize: '12px' }}>Exchange: {paymentData?.exchange} </Typography>

                      <Typography sx={{ fontSize: '12px' }}>{paymentData?.exchange} Withdrawal Fee: {paymentData?.exchangeFee + " " + paymentData?.token}</Typography>
                      <Typography sx={{ fontSize: '12px' }}>Total: {paymentData?.cryptoAmountWithFee + " " + paymentData?.token}</Typography>
                    </Box>}
                  />
                }
                {(paymentData?.status === "priceChange" ||
                  (paymentData?.newAmount &&
                    paymentData?.cryptoAmount !== paymentData?.newAmount)) && (
                    <Box
                      sx={{
                        background: "#495ECA",
                        p: "3px 5px",
                        fontWeight: 600,
                        borderRadius: "5px",
                        fontSize: "9px",
                        color: "#FFF",
                        cursor: "pointer",
                        display: "flex",
                        gap: "3px",
                        alignItems: "center",
                      }}
                      onClick={() => showLog()}
                    >
                      Price change detected
                      <InfoOutlinedIcon
                        sx={{
                          color: "#FFF",
                          fontSize: "11px !important",
                        }}
                      />
                    </Box>
                  )}
              </Box>
            </Box>
            { }
            <CopyToClipboardButton
              content={paymentData?.status === "priceChange" || (Numberz(paymentData?.newAmount) > 0 && Numberz(paymentData?.cryptoAmount) !== Numberz(paymentData?.newAmount))
                ? paymentData?.exchangeFee ? addBigNumber(paymentData?.exchangeFee, paymentData?.newAmount) : paymentData?.newAmount
                : paymentData?.cryptoAmountWithFee || paymentData?.cryptoAmount}
              background="#fff"
              fontsize="20px!important"
            />
          </Box>

          <InputLabels label="Address" mt="30px" />

          {!loading && paymentData?.publickey && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                mt: "10px",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "rgba(69, 69, 69, 0.85)",
                    textAlign: "left",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    wordBreak: "break-word",
                  }}
                >
                  {paymentData?.publickey}
                </Typography>{" "}
              </Box>

              <CopyToClipboardButton
                content={paymentData?.publickey}
                background="#fff"
                fontsize="20px!important"
              />
            </Box>
          )}
          {partialShow && (
            <PendingAmountShow
              pendingAmount={paymentData?.exchangeFee ? addBigNumber(paymentData?.pendingAmount, paymentData?.exchangeFee) : paymentData?.pendingAmount}
              paymentStatus={paymentData?.status}
              token={paymentData?.token}
              network={paymentData?.network}
            />
          )}
        </Box>
      </Box>

      {!loading && paymentData?.expireDate && (
        <CountdownComponentWithLoader
          expireIn={getSecondsDifference(new Date(), paymentData?.expireDate)}
          colour="red"
          content="Your Transaction will expire in "
        />
      )}
      {!loading && <HorizontalExternalQRPageStepper paymentData={paymentData} />}
    </Box>
  );
}
