import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQAccordionComponent({qaArray}) {
  return (
    <div style={{ width: "100%" }}>
      {qaArray.map((item, index) => (
        <Accordion sx={{ p: "12px 15px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#5235E8" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              color: "var(--main-colors-black, #0E0637)",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "25.6px",
            }}
          >
            {item.ques}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              color: "var(--neutral-500, #717184)",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "20.6px",
            }}
          >
            {item.answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
      ))}
      
    </div>
  );
}