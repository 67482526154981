
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, signOut } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC4BXWneHP_qf2wf9hGTC4OFhQTbav0LqA",
  authDomain: "gafah-5a825.firebaseapp.com",
  projectId: "gafah-5a825",
  appId: "1:367911919810:web:36d977a84ca1f3407a1b5b",
  measurementId: "G-FKZ57LPH4E"
};
// firebaseConfig.authDomain = "www.gafah.com"


// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);

export const FirebaseSignOut = () => {
  signOut(auth).then(() => {
    // Sign-out successful.

    console.log("Sign-out successful")

  }).catch((error) => {
    console.log("An error happened.", error)
  });

}


