import React, { useEffect } from 'react';

export default function CookiesPolicyComponent() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);  return (
    <div
      name="termly-embed"
      data-id="1353a2fb-9ce4-4fee-a818-90591dcb4f71"
      data-type="iframe"
    ></div>
  )

};