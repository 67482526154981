import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import LeftGridComponentOne from "./components/Left/LeftGridComponentOne";
import LeftGridComponentTwo from "./components/Left/LeftGridComponentTwo";
import LeftGridComponentThree from "./components/Left/LeftGridComponentThree";
import RightGridComponentOne from "./components/Right/RightGridComponentOne";
import Stepper from "./components/Right/RightGridComponentTwo";
import { GetHandler } from "../../Redux/RequestHandlers";
import { useDispatch } from "react-redux";
import RightGridComponentThree from "./components/Right/RightGridComponentThree";
// import LeftToRightModal from "../../components/Dialog/LeftToRightModal";

export default function DashboardComponent() {
  const dispatch = useDispatch();
  const init = async () => {
    try {
      let req = {
        url: "dashboard",
        action: "ACTION_GET_DASHBOARD",
      };
      await dispatch(GetHandler(req));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container sx={{ overflow: "hidden!important" }}>
      <Grid
        item
        xs={12}
        md={6}
        lg={8}
        sx={{ p: "32px", borderRight: "1px solid var(--neutral-200, #E3E3E8)" }}
      >
        <LeftGridComponentOne />
        <LeftGridComponentTwo />
        <LeftGridComponentThree />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        {/* <LeftToRightModal /> */}
        <RightGridComponentOne />
        <Stepper />
        <RightGridComponentThree />
      </Grid>
    </Grid>
  );
}
