import {
    Box,
    Typography,
    FormControl,
    Select,
    MenuItem,
  } from "@mui/material";
  import React from "react";
  
  export default function SelectBoxWallet(props) {
    const { item1, item2, item3 } = props;
    const [cur, setCur] = React.useState(item1);
  
    const handleChange = (event) => {
      setCur(event.target.value);
    };
    return (
      <FormControl>
        <Select
          id="demo-simple-select"
          value={cur}
          defaultValue="usd"
          sx={{
            borderRadius: "8px",
            px:"10px",
            py:"0px!important",
            borderColor: "var(--neutral-300, #C8C8D0)!important",
          }}
          onChange={handleChange}
        >
          <MenuItem value={item1} >
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              
              <Typography
                sx={{
                  marginTop: "2px",
                  color: "var(--main-colors-black, #0E0637)",
                  textAlign: "center",
                  fontFeatureSettings: `'ss06' on`,
                  fontFamily: "SF Pro Display",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16.2px",
                  letterSpacing: "0.06px",
                }}
              >
                {item1}
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem value={item2}>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              
              <Typography
                sx={{
                  marginTop: "2px",
                  color: "var(--main-colors-black, #0E0637)",
                  textAlign: "center",
                  fontFeatureSettings: `'ss06' on`,
                  fontFamily: "SF Pro Display",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16.2px",
                  letterSpacing: "0.06px",
                }}
              >
                {item2}
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem value={item3}>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              
              <Typography
                sx={{
                  marginTop: "2px",
                  color: "var(--main-colors-black, #0E0637)",
                  textAlign: "center",
                  fontFeatureSettings: `'ss06' on`,
                  fontFamily: "SF Pro Display",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16.2px",
                  letterSpacing: "0.06px",
                }}
              >
                {item3}
              </Typography>
            </Box>
          </MenuItem>
        </Select>
      </FormControl>
    );
  }
  