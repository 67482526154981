import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function RefundSuccess() {
  return (
    <>
      <Box sx={{ p: "30px 60px", "@media (max-width:600px)": { p: "20px" } }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            mt: "20px",
            gap: "100px",
            "@media (max-width:800px)": { gap: "60px", mt: "50px" },
            "@media (max-width:600px)": { gap: "0px" },
          }}
        >
          <Box
            sx={{
              borderRadius: "34px",
              border: "1px solid var(--Neutral-300, #EFF0F6)",
              background: "var(--Neutral-100, #FFF)",
              boxShadow: "0px 3px 16px 0px rgba(73, 94, 202, 0.18)",
              p: "76px",
              width: "100%",
              maxWidth: "700px",
              m: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "30px",
              height: "100%",
              maxHeight: "485px",
              "@media (max-width:600px)": {
                maxWidth: "300px!important",
                p: "40px",
              },
              "@media (max-width:800px)": { maxWidth: "500px", p: "40px" },
            }}
          >
            <svg
              width="134"
              height="123"
              viewBox="0 0 134 123"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M98.4522 18.2161C100.2 16.1413 99.9444 13.0249 97.7368 11.4487C87.8239 4.37052 75.997 0.373072 63.7477 0.0248373C49.8566 -0.370071 36.2413 3.95128 25.1205 12.2847C13.9997 20.618 6.02901 32.4722 2.50744 45.9152C-1.01413 59.3583 0.120996 73.5978 5.72784 86.3132C11.3347 99.0286 21.0827 109.47 33.3833 115.937C45.6839 122.403 59.8119 124.513 73.4649 121.922C87.118 119.331 99.4912 112.193 108.568 101.67C116.572 92.3913 121.597 80.9631 123.057 68.8702C123.382 66.1773 121.253 63.8871 118.543 63.7779C115.832 63.6687 113.571 65.7834 113.205 68.4712C111.861 78.3442 107.684 87.6566 101.13 95.2542C93.503 104.096 83.1061 110.094 71.6337 112.271C60.1614 114.448 48.2899 112.675 37.954 107.242C27.6181 101.808 19.4271 93.0344 14.7158 82.3499C10.0045 71.6655 9.05066 59.7004 12.0098 48.4045C14.9689 37.1086 21.6664 27.1478 31.011 20.1455C40.3555 13.1431 51.7962 9.51199 63.4685 9.84382C73.4982 10.129 83.1909 13.3257 91.3869 18.992C93.6182 20.5345 96.7049 20.2909 98.4522 18.2161Z"
                fill="#495ECA"
              />
              <path
                d="M123.5 19.1953L63.3772 79.6288L36.0488 52.1591"
                stroke="#495ECA"
                strokeWidth="20"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "SF Pro Display",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                textAlign: "center",
              }}
            >
              {" "}
              Refund Initiated
            </Typography>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "SF Pro Display",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  textAlign: "center",
                }}
              >
                If opted, refund will be processed in 24-48 hours.
              </Typography>
            </Box>

            <Button
              color="inherit"
              sx={{
                my: "20px",
                borderRadius: "10px",
                border: "1px solid var(--Neutral-300, #495ECA)",
                background: "#fff",
                p: "20px 40px",
                color: "#495ECA",
                textTransform: "none",
                "&:hover": {
                  background: "#fff",
                  color: "#495ECA",
                },
              }}
              component={Link}
              to="/"
            >
              <Typography
                sx={{
                  color: "#495ECA",
                  //   fontFamily: "SF Pro Display",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "20px",
                }}
              >
                Done
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
