import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, CircularProgress } from "@mui/material";
import POSCryptoSearchBoxes from "./posCryptoSearchBoxComponent";
import { useSelector } from "react-redux";
import { CountdownComponent2 } from "../../../components/Helpers/countdown";
import { hasObjectPropertyExist } from "../../../utilities";

export default function POSCryptoComponent({
  currency,
  amount,
  setNetwork,
  setToken,
  token,
  shouldContinue,
  setShouldContinue
}) {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { listofcurrencies } = useSelector((state) => state?.pos);
  const [totamount, setTotamount] = useState(amount);
  const [loadingAmount, setLoadingAmount] = useState(false);
  const [timer, setTimer] = useState(15);

  const handleClick = (index) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    if (hasObjectPropertyExist(listofcurrencies, selectedIndex)) {
      setNetwork(listofcurrencies[selectedIndex]?.network);
      setToken(listofcurrencies[selectedIndex]?.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  return (
    <Box
      key={currency + amount + token}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "33px",
        pt: "33px",
      }}
    >
      
      {shouldContinue ? <>
        <Grid container spacing={4} sx={{ width: "100%", mb: "33px" }}>
        {listofcurrencies?.map((item, index) => (
          <Grid
            key={item?.id + index + "3e" + index}
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            // onClick={()=>handleClick(index)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <POSCryptoSearchBoxes
              amount={amount}
              currency={currency}
              active={selectedIndex === index}
              itemIndex={index}
              handleActive={handleClick}
              icon={item.logo}
              crypto={item.token}
              cryptoshortname={item.network}
              pricesymbol={item.pricesymbol}
              totamount={totamount}
              setTotamount={setTotamount}
              loadingAmount={loadingAmount}
              setLoadingAmount={setLoadingAmount}
              setTimer={setTimer}
              shouldContinue={shouldContinue}
              setShouldContinue={setShouldContinue}
            />
          </Grid>
        ))}
      </Grid>
      <Typography
        sx={{
          color: "var(--Neutral-800, #170F49)",
          textAlign: "center",
          fontFeatureSettings: `'clig' off, 'liga' off`,
          fontFamily: "SF Pro Display",
          fontSize: "34px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "46px",
          //   mb:"33px"
        }}
      >
        {amount} {currency}
        {token && (
          <>
            {loadingAmount ? (
              <CircularProgress size="1.5rem" />
            ) : (
              <>
                ≈{" "}
                <span> {totamount ? Math.ceil(totamount * 1e6) / 1e6 : 0}</span>
                {" " + listofcurrencies[selectedIndex]?.token}
              </>
            )}
          </>
        )}
      </Typography>
      {token && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <CircularProgress size="1rem" />
          <CountdownComponent2
            content="refreshing in "
            colour="red"
            expireIn={timer}
            setTimer={setTimer}
          />
        </Box>
      )}
      </>
      :<Box sx={{
        my:'2rem',
        py: '2rem',
        background: 'red',
        px: '15px',
        borderRadius: '10px'
      }}>
        <Typography sx={{color: '#fff'}}>
          External payment service has down for some time due to maintainance. please Go Back and try with different method like gafah or try again later.
        </Typography>
      </Box>}
    </Box>
  );
}
