import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Numberz } from "../../../../utilities";

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        fontSize={9}
        textAnchor="end"
        fill="#666"
        // transform="rotate(-25)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const maxAdd = (value) =>
  Numberz(value) < 100
    ? Numberz(value + value * (40 / 100))
    : Numberz(value + value * (150 / 100));

export default function Chart() {
  const { previousBalances, showCurrency } = useSelector(
    (state) => state?.dashboard
  );
  const [usd, setUSD] = useState([]);
  const [aed, setAED] = useState([]);
  const [maxDomain, setMaxDomain] = useState({
    USD: 300,
    AED: 1000,
  });

  const extractAndBuildData = (obj) => {
    let data = [];
    let aedData = [];
    let maxUsd = 0;
    let maxAED = 0;

    // Iterate through the previousbalances object
    for (const [date, values] of Object.entries(obj)) {
      if (values.AED > maxAED) {
        maxAED = values.AED;
        maxUsd = values.USD;
      }

      // Format USD and AED values to two decimal places
      let k = Number(values.USD).toFixed(2);
      let a = Number(values.AED).toFixed(2);

      // Push formatted data into the arrays
      data.push({
        name: moment(date).format("YYYY-MM-DD HH:mm"),
        USD: k,
        pv: k,
        amt: k,
      });

      aedData.push({
        name: moment(date).format("YYYY-MM-DD HH:mm"),
        AED: a,
        pv: a,
        amt: a,
      });
    }
    
    return {
      USD: data,
      AED: aedData,
      maxDomains: {
        USD: parseInt(maxAdd(maxUsd)),
        AED: parseInt(maxAdd(maxAED)),
      },
    };
  };

  useEffect(() => {
    if (previousBalances) {
      const { USD, AED, maxDomains } = extractAndBuildData(previousBalances);
      setUSD(USD);
      setAED(AED);
      setMaxDomain(maxDomains);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousBalances]);

  return (
    <div style={{ width: "100%", height: 350 }}>
      <ResponsiveContainer>
        <AreaChart
          data={showCurrency === "aed" ? aed : usd}
          margin={{
            top: 10,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" height={40} tick={<CustomizedAxisTick />} />
          <YAxis
            width={35}
            tick={<CustomizedAxisTick />}
            domain={[
              0,
              showCurrency === "aed" ? maxDomain?.AED : maxDomain?.USD,
            ]}
          />
          <Tooltip />
          <Area
            type="monotone"
            dataKey={showCurrency === "aed" ? "AED" : "USD"}
            stroke="#495ECA"
            fill="rgba(82, 53, 232, 0.14)"
          >
            {/* <LabelList content={<CustomizedLabel />} /> */}
          </Area>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
