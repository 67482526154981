import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { Numberz } from "../../../utilities";
import { PortfoliosSVG } from "../../../components/Drawer/svgs";
import { VolumeSvg } from "../../../components/Helpers/svg";

export default function RefCards({ volume, balance }) {
  return (
    <Grid
      container
      sx={{
        py: "24px",
        width: "100%",
        borderTop: "solid 1px var(--neutral-200, #E3E3E8)",
        borderBottom: "solid 1px var(--neutral-200, #E3E3E8)",
      }}
    >
      <Grid item xs={12} md={12} lg={6} sx={{ pr: "32px", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                justifyContent: "flex-start",
              }}
            >
              <PortfoliosSVG />

              <Typography
                sx={{
                  color: "var(--neutral-400, #9C9CAB)",
                  fontFeatureSettings: `'ss06' on`,
                  fontFamily: "SF Pro Display",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20.3px",
                }}
              >
                TOTAL AFFILIATE BALANCE
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "var(--main-colors-black, #0E0637)",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "56px",
                textTransform: "uppercase",
              }}
            >
              {Numberz(balance?.AED)}
              {/* {!totalPortfolioValue ? <LoaderCircular /> : showCurrency === 'usd' ? showCurrency + ' ' + Numberz(totalPortfolioValue?.USD).toFixed(2) : showCurrency + ' ' + Numberz(totalPortfolioValue?.AED).toFixed(2)} */}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        sx={{
          px: "32px",
          width: "100%",
          borderLeft: "solid 1px var(--neutral-200, #E3E3E8)",
          "@media (max-width:1200px)": {
            borderLeft: "none",
            paddingLeft: "0!important",
          },
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <VolumeSvg />

            <Typography
              sx={{
                color: "var(--neutral-400, #9C9CAB)",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20.3px",
              }}
            >
              VOLUME (24H)
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "var(--main-colors-black, #0E0637)",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "56px",
                textTransform: "uppercase",
              }}
            >
              USD {Numberz(volume?.usd).toFixed(2)}
            </Typography>
            <Typography
              sx={{
                color: "var(--main-colors-black, #0E0637)",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "56px",
                textTransform: "uppercase",
              }}
            >
              AED {Numberz(volume?.aed).toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
