import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  AuthHeader,
  LogoText,
} from "../../components/Helpers/helperComponents";
import { country } from "../../components/Helpers/country";
import { InputLabels } from "../../components/Helpers/label";
import { PostHandler } from "../../Redux/RequestHandlers";
import { ButtonLoader, PrimaryButton } from "../../components/Buttons";
import RightComponent1 from "../SignUp/components/RightComponent1";
import { PrimaryInputComponents } from "../../components/Style";
export default function UpdateProfile() {
  const [inputs, setInputs] = useState({ country: "United Arab Emirates" });
  const [isLoading, setIsLoading] = useState(false);
  const [isComponentShow, setIsComponentShow] = useState(true);
  const [isDisable, setIsDisable] = useState(true);
  const [isNextDisable, setIsNextDisable] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "updateProfile",
        payload: inputs,
        action: "ACTION_ACCOUNT_SETUP",
      };
      const response = await dispatch(PostHandler(req));
      setIsLoading(false);
      if (response?.data?.success) {
        window.localStorage.setItem(
          "accessToken",
          response?.data?.data?.token?.access_token
        );
        window.localStorage.setItem(
          "refreshToken",
          response?.data?.data?.token?.refresh_token
        );
        return navigate("/dashboard/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisable(
      inputs?.firstname &&
        inputs?.lastname &&
        inputs?.dob &&
        inputs?.businessname &&
        inputs?.phone &&
        inputs?.country
        ? false
        : true
    );
  }, [inputs]);

  useEffect(() => {
    setIsNextDisable(
      inputs?.firstname && inputs?.lastname && inputs?.businessname
        ? false
        : true
    );
  }, [inputs]);

  return (
    <Box className="main" sx={{ height: "100vh!important" }}>
      <Grid container sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          md={6}
          lg={5}
          sx={{
            p: "80px",
            pt: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "@media (max-width:600px)": { p: "40px" },
          }}
        >
          <LogoText />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              width: "100%",
              maxWidth: "508px",
              height: "100%",
            }}
          >
            <AuthHeader text="Setup Profile" mt="25px" />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                mt: "20px",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Box
                sx={{
                  height: "10px",
                  width: "100%",
                  borderRadius: "5px",
                  background:
                    "linear-gradient(90deg, #495ECA 0%, rgba(73, 94, 202, 0.00) 100%)",
                }}
              />
              <Typography>{isComponentShow ? 1 : 2}/2</Typography>
            </Box>
            {isComponentShow ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    mt: "40px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "7px",
                    }}
                  >
                    <InputLabels label="First Name" />
                    <PrimaryInputComponents
                      id="firstname"
                      name="firstname"
                      onChange={handleChange}
                      value={inputs?.firstname || ""}
                      placeholder="Enter your firstname"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "7px",
                    }}
                  >
                    <InputLabels label="Last Name" />
                    <PrimaryInputComponents
                      id="lastname"
                      name="lastname"
                      onChange={handleChange}
                      value={inputs?.lastname || ""}
                      placeholder="Enter your lastname"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "7px",
                    }}
                  >
                    <InputLabels label="Bussiness Name" />
                    <PrimaryInputComponents
                      id="businessname"
                      name="businessname"
                      onChange={handleChange}
                      value={inputs?.businessname || ""}
                      placeholder="Enter your bussiness name"
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  mt: "40px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "7px",
                  }}
                >
                  <InputLabels label="Date Of Birth" />
                  <PrimaryInputComponents
                    id="dob"
                    type="date"
                    name="dob"
                    onChange={handleChange}
                    value={inputs?.dob || ""}
                    placeholder="Enter your firstname"
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "7px",
                  }}
                >
                  <InputLabels label="Phone" />
                  <PhoneInput
                    country={"ae"}
                    name="phone"
                    value={inputs?.phone || ""}
                    onChange={(phone) =>
                      setInputs((prev) => ({ ...prev, phone: phone }))
                    }
                    width="100%"
                    inputStyle={{
                      width: "100%",
                      marginTop: "1rem",
                      height: "48px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "7px",
                  }}
                >
                  <InputLabels label="Country" />
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleChange}
                    name="country"
                    value={inputs?.country || ""}
                    sx={{
                      height: "48px",
                      width: "100%",
                      "&:hover fieldset": {
                        border: "1px solid #495ECA!important",
                      },
                      "&:focus-within fieldset, &:focus-visible fieldset": {
                        border: "2px solid #495ECA!important",
                      },
                    }}
                  >
                    {country?.map((item) => (
                      <MenuItem key={item?.name} value={item?.name}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mt: "40px",
                width: "100%",
              }}
            >
              {!isComponentShow ? (
                isLoading ? (
                  <ButtonLoader />
                ) : (
                  <PrimaryButton
                    title="Setup Account"
                    onClick={handleSubmit}
                    disabled={isDisable}
                  />
                )
              ) : (
                <PrimaryButton
                  title="Next"
                  disabled={isNextDisable}
                  onClick={() => setIsComponentShow(false)}
                />
              )}
              {!isComponentShow && (
                <Typography
                  sx={{
                    mt: "20px",
                    fontFeatureSettings: `'ss06' on`,
                    fontFamily: "SF Pro Display",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "20.6px",
                    lineSpacing: "0.08px",
                    textAlign: "center",
                    width: "100%",
                    cursor: "pointer",
                    color: "var(--main-colors-primary, #5235E8)",
                  }}
                  onClick={() => setIsComponentShow(true)}
                >
                  Back
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          sx={{
            borderRadius: "20px 0px 0px 20px",
            background:
              "linear-gradient(270deg, #495ECA 0%, rgba(73, 94, 202, 0.00) 100%)",
            "@media (max-width:900px)": { display: "none" },
          }}
        >
          <RightComponent1 />
        </Grid>
      </Grid>
    </Box>
  );
}
