import React, { useState, useEffect } from "react";
import { Typography, Box, CircularProgress } from "@mui/material";
const CountdownComponent = ({ expireIn, colour, content }) => {
  const [countdown, setCountdown] = useState(expireIn);

  useEffect(() => {
    let intervalId;

    if (expireIn) {
      intervalId = setInterval(() => {
        if (countdown < 0) return clearInterval(intervalId);
        setCountdown((prevCountdown) =>
          prevCountdown > 0 ? prevCountdown - 1 : 0
        );
      }, 1000);
    }
    // setTime(countdown);
    return () => {
      clearInterval(intervalId);
    };
  }, [countdown, expireIn]);

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  return (
    expireIn && (
      <Typography
        sx={{
          color: "rgba(69, 69, 69, 0.85)",
          textAlign: "center",
          fontFamily: "SF Pro Display",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "26.4px",
        }}
      >
        {content}
        <span style={{ color: colour }}>
          {minutes > 0 ? `${minutes} minutes ` : ""}
          {seconds} seconds.
        </span>
      </Typography>
    )
  );
};

export default CountdownComponent;

export const CountdownComponent2 = ({
  expireIn,
  colour,
  content,
  setTimer,
}) => {
  const [countdown, setCountdown] = useState(expireIn);
  // Reset countdown when the expireIn prop changes
  useEffect(() => {
    setCountdown(expireIn);
  }, [expireIn]);
  useEffect(() => {
    let intervalId;

    if (expireIn) {
      intervalId = setInterval(() => {
        if (countdown < 0) return clearInterval(intervalId);
        setCountdown((prevCountdown) =>
          prevCountdown > 0 ? prevCountdown - 1 : 0
        );
        setTimer((prevCountdown) =>
          prevCountdown > 0 ? prevCountdown - 1 : 0
        );
      }, 1000);
    }
    // setTime(countdown);
    return () => {
      clearInterval(intervalId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown, expireIn]);

  // Convert seconds to minutes and remaining seconds
  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  return (
    expireIn && (
      <Typography
        sx={{
          color: "rgba(69, 69, 69, 0.85)",
          textAlign: "center",
          fontFamily: "SF Pro Display",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "26.4px",
        }}
      >
        {content}
        <span style={{ color: colour }}>
          {minutes > 0 ? `${minutes} minutes ` : ""}
          {seconds} seconds.
        </span>
      </Typography>
    )
  );
};

export const CountdownComponentWithLoader = ({ expireIn, colour, content }) => {
  const [countdown, setCountdown] = useState(expireIn);
  const [isComponentEnabled, setComponentEnabled] = useState(false);

  useEffect(() => {
    let intervalId;

    if (countdown) {
      intervalId = setInterval(() => {
        if (countdown < 0) return clearInterval(intervalId);
        setCountdown((prevCountdown) =>
          prevCountdown > 0 ? prevCountdown - 1 : 0
        );
      }, 1000);
    } else if (countdown === 0 || countdown <= 0) {
      clearInterval(intervalId);
      setComponentEnabled(true);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [countdown]);

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  return (
    expireIn && (
      <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
        <Typography
          sx={{
            color: "rgba(69, 69, 69, 0.85)",
            textAlign: "center",
            fontFamily: "SF Pro Display",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "26.4px",
          }}
        >
          {content}
          <span style={{ color: colour }}>
            {minutes > 0 ? `${minutes} minutes ` : ""}
            {seconds} seconds.
          </span>
        </Typography>
        {isComponentEnabled && (
          <CircularProgress size="1.2rem" sx={{ color: "#495ECA" }} />
        )}
      </Box>
    )
  );
};
