import { Box, Typography, Grid, Button } from "@mui/material";
import React from "react";
import NotificationForm from "./NotificationCheckboxForm";

export default function NotificationComponent() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        p: "32px",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          lg={4}
          sx={{
            pt: "32px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            mb: "32px",
          }}
        >
          <Typography
            sx={{
              color: "var(--neutral-900, #131316)",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "25.6px",
              letterSpacing: " 0.08px",
            }}
          >
            {" "}
            Manage
          </Typography>
          <Typography
            sx={{
              color: "var(--neutral-500, #717184)",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "20.6px",
            }}
          >
            Manage how you receive notifications
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #E3E3E8)",
              background: "var(--main-colors-white, #FFF)",
              p: "32px",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "var(--neutral-900, #131316)",
                  fontFeatureSettings: `'ss06' on`,
                  fontFamily: "SF Pro Display",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20.6px",
                }}
              >
                Email
              </Typography>
              <Typography
                sx={{
                  color: "#495ECA",
                  fontFeatureSettings: `'ss06' on`,
                  fontFamily: "SF Pro Display",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20.6px",
                }}
              >
                Clear all
              </Typography>
            </Box>
            <NotificationForm />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{
            p: "11px 24px",
            borderRadius: "8px",
            background: "#495ECA",
            color: "#fff",
            textTransform: "none",
            fontFamily: "SF Pro Display",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "25.6px",
            letterSpacing: "0.08px",
            height:"48px"
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
