export const AdvanceColumns = {
    checkbox: {
        index: 0,
        show: true,
    },
    "Order Id": {
        index: 1,
        show: true,
        col: "id",
    },
    Crypto: {
        index: 2,
        show: true,
        col: "token",
    },
    Type: {
        index: 3,
        show: true,
        col: "type",
    },
    "Amount(crypto)": {
        index: 4,
        show: true,
        col: "amount",
    },
    Amount: {
        index: 5,
        show: true,
        col: "fiatamount",
    },
    "Real Time Price": {
        index: 5,
        show: true,
        col: "realtimeprice",
    },
    Status: {
        index: 6,
        show: true,
        col: "status",
    },
    Mode: {
        index: 7,
        show: true,
        col: "mode",
    },
    "Fee (%)": {
        index: 8,
        show: true,
        col: "fee",
    },
    "Created At": {
        index: 9,
        show: true,
        col: "createdAt",
    },
    "Invoice": {
        index: 10,
        show: true,
    },
}

export const BasicColumns = {
    checkbox: {
        index: 0,
        show: true,
    },
    "Order Id": {
        index: 1,
        show: true,
        col: "id",
    },
    Crypto: {
        index: 2,
        show: true,
        col: "token",
    },
    Type: {
        index: 3,
        show: true,
        col: "type",
    },
    "Amount(crypto)": {
        index: 4,
        show: true,
        col: "amount",
    },
    Amount: {
        index: 5,
        show: true,
        col: "fiatamount",
    },

    Status: {
        index: 6,
        show: true,
        col: "status",
    },
    Mode: {
        index: 7,
        show: true,
        col: "mode",
    },

    "Created At": {
        index: 9,
        show: true,
        col: "createdAt",
    },

}