import React from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import DashboardLayout from '../Drawer'
import PermissionDenied from '../PermissionDenied'
import { checkLogin } from '../../Redux/RequestHandlers/Auth.action'

const AccessGuard = ({ accKey, component }) => {
    const { accessKey } = useSelector(state => state.auth)
    return accKey === accessKey ?
        component :
        <Navigate to="/login/" />
}

export const AccessGuardWithLayout = ({ accKey, component }) => {
    const { accessKey } = useSelector(state => state.auth)
    return accKey === accessKey ?
        <DashboardLayout component={component} /> :
        <Navigate to="/login/" />
}

export const PermissionGuard = ({ accKey, component }) => {
    const { accessLists } = useSelector(state => state.auth)
    const hasCommonElements = [...accKey, "superMerchant"].some(item => accessLists?.includes(item));
    const isLogin = checkLogin();

    return !isLogin ? <Navigate to="/login/" />
        : hasCommonElements ?
            <DashboardLayout component={component} /> :
            <DashboardLayout component={<PermissionDenied />} />
}

export default AccessGuard
