import { Numberz, calculatePriceDifferencePercentage } from "../../utilities";

export const ACTION_GET_DASHBOARD = "ACTION_GET_DASHBOARD";
export const ACTION_CHANGE_CURRENCY = "ACTION_CHANGE_CURRENCY";
export const ACTION_CHANGE_KYC_IDENTITY = "ACTION_CHANGE_KYC_IDENTITY";
export const ACTION_CHANGE_KYC_COMPANY = "ACTION_CHANGE_KYC_COMPANY";
export const ACTION_CHANGE_KYC_BANK = "ACTION_CHANGE_KYC_BANK";
export const ACTION_CHANGE_KYC_CRYPTO = "ACTION_CHANGE_KYC_CRYPTO";
const initialState = {
  totalPortfolioValue: {
    usd: 0,
    aed: 0,
  },
  volume: {
    usd: 0,
    aed: 0,
  },
  recentTransactions: null,
  showCurrency: "aed",
  cryptoTokens: null,
  balancesData: null,
  totalTransactions: 0,
  uniqueUserCount: 0,
  referralCode: null,
  kycStatus: null,
  previousBalances: null,
  portfolioChanges: {
    sign: null,
    Diff: null
  }
};

export const DashboardReducer = (state = initialState, action) => {
  // const returnObj = { ...state }
  switch (action.type) {
    case ACTION_GET_DASHBOARD:
      const data = action?.payload?.data;
      let usdVol = 0;
      let aedVol = 0;
      let portFolioValue = {
        USD: 0,
        AED: 0
      };
      if (data?.kycStatus?.crypto) {
        let usd = 0;
        let aed = 0;
        data?.balances?.length > 0 && data?.balances?.forEach(item => {
          usd += Numberz(item?.balance) * Numberz(item?.realTimePrice?.usd);
          aed += Numberz(item?.balance) * Numberz(item?.realTimePrice?.aed);
        })
        portFolioValue = {
          USD: usd,
          AED: aed
        };
      } else
        portFolioValue = action?.payload?.data?.totalBalance;


      const volumes = data?.volume;
      if (volumes.length > 0) {
        volumes?.map((item) => {
          if (item?.currency.toLowerCase() === "usd")
            usdVol += Number(item?.fiatamount);
          if (item?.currency.toLowerCase() === "aed")
            aedVol += Number(item?.fiatamount);
          return item?.fiatamount;
        });
      }

      const pv = data?.previousbalances
      const keys = Object.keys(pv);

      var [sign, diff] = calculatePriceDifferencePercentage(
        pv[keys[keys?.length - 2]]?.USD,
        pv[keys[keys?.length - 1]]?.USD
      );
      return {
        ...state,
        balancesData: data,
        totalPortfolioValue: portFolioValue,
        previousbalances: data?.previousbalances || {},
        recentTransactions: data?.recentTransactions,
        volume: {
          usd: usdVol,
          aed: aedVol,
        },
        totalTransactions: data?.transactionCount,
        uniqueUserCount: Numberz(data?.userCount),
        kycStatus: data?.kycStatus || null,
        previousBalances: data?.previousbalances,
        portfolioChanges: {
          sign,
          diff
        },
        referralCode: data?.referralCode
      };
    case ACTION_CHANGE_CURRENCY:
      return {
        ...state,
        showCurrency: action?.payload,
      };

    case ACTION_CHANGE_KYC_IDENTITY:
      return {
        ...state,
        kycStatus: {
          ...state?.kycStatus,
          identity: action?.payload?.data?.identity,
          // identity: true 
        },
      };

    case ACTION_CHANGE_KYC_COMPANY:
      return {
        ...state,
        kycStatus: {
          ...state?.kycStatus,
          company: action?.payload?.data?.company,
        },
      };

    case ACTION_CHANGE_KYC_BANK:
      return {
        ...state,
        kycStatus: { ...state?.kycStatus, bank: action?.payload?.data?.bank },
      };

    case ACTION_CHANGE_KYC_CRYPTO:
      return {
        ...state,
        kycStatus: { ...state?.kycStatus, crypto: action?.payload?.crypto },
      };

    default:
      return state;
  }
};
