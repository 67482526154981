import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
export default function PricingCOneCard3(props) {
  const { plan, price } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: "30px 29px",
        background: "#F5F5F5",
        borderRadius: "20px",
        gap: "27px",
        width: "306px",
        minHeight:"349px",
        '@media (max-width:1300px)':{
          width:"auto",
          py:"20px"
        },
        '@media (max-width:950px)':{
          width:"352px"
        },
        '@media (max-width:500px)':{
          width:"90%"
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          justifyContent: "center",
          alignItems: "center",
          pb: "27px",
          borderBottom: "solid 1px #D4D4D4",
          width:"100%"
        }}
      >
        <Typography
          sx={{
            color: "#4D4D4D",
            textAlign: "center",
            fontFamily: "SF Pro Display",
            fontSize: "30px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
          }}
        >
          {plan}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "3px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <svg
            width="9"
            height="11"
            viewBox="0 0 9 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M0.5 7.02996H1.98685C2.53083 9.71327 4.5689 11 7.07842 11C7.58613 11 8.19538 10.9372 8.5 10.8274V9.33666C8.19538 9.42297 7.58613 9.47004 7.10743 9.47004C5.4175 9.47004 4.12647 8.6933 3.65503 7.02996H7.59338V6.14337H3.49547C3.48096 5.94722 3.46646 5.74322 3.46646 5.52354V5.50785C3.46646 5.26462 3.48096 5.02924 3.50272 4.80956H7.59338V3.92297H3.67679C4.16274 2.29886 5.43926 1.52996 7.10743 1.52996C7.58613 1.52996 8.19538 1.59272 8.5 1.66334V0.172611C8.19538 0.0706134 7.58613 0 7.07842 0C4.59066 0 2.55984 1.27104 1.99411 3.92297H0.5V4.80956H1.87081C1.84905 5.02924 1.8418 5.25678 1.8418 5.49215V5.50785C1.8418 5.72753 1.84905 5.93937 1.86355 6.14337H0.5V7.02996Z"
              fill="#4D4D4D"
            />
          </svg> */}
          <Typography
            sx={{
              color: "#4D4D4D",
              textAlign: "center",
              fontFamily: "SF Pro Display",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            {price}
          </Typography>
          {/* <Typography
            sx={{
              color: "#4D4D4D",
              textAlign: "center",
              fontFamily: "SF Pro Display",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            /user
          </Typography> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "20px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "7px",
            justifyContent: "flex-start",
          }}
        >
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9.5" cy="9" r="9" fill="#5A5A5A" />
            <path
              d="M5.5 8.94545L8.34444 12L13.5 6"
              stroke="#D5D5D5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Typography
            sx={{
              color: "#AEAEAE",
              textAlign: "left",
              fontFamily: "SF Pro Display",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Personalize analytics.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "7px",
            justifyContent: "flex-start",
          }}
        >
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9.5" cy="9" r="9" fill="#5A5A5A" />
            <path
              d="M5.5 8.94545L8.34444 12L13.5 6"
              stroke="#D5D5D5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Typography
            sx={{
              color: "#AEAEAE",
              textAlign: "left",
              fontFamily: "SF Pro Display",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Enterprise Fees.
            {/* <span style={{ color: "#4D4D4D" }}>workflows.</span> */}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "7px",
            justifyContent: "flex-start",
          }}
        >
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9.5" cy="9" r="9" fill="#5A5A5A" />
            <path
              d="M5.5 8.94545L8.34444 12L13.5 6"
              stroke="#D5D5D5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Typography
            sx={{
              color: "#AEAEAE",
              textAlign: "left",
              fontFamily: "SF Pro Display",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            <span style={{ color: "#4D4D4D" }}>Monthly</span> charges applicable.
          </Typography>
        </Box>
      </Box>
      <Button
        component={Link}
        to="/contactus"
        sx={{
          borderRadius: "15px!important",
          background: "#495ECA",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "7px",
          textTransform: "none",
          fontFamily: "SF Pro Display",
          fontSize: "13px",
          fontWeight: 500,
          lineHeight: "normal",
          p: "11.5px 15px",
          "&:hover": {
            background: "#495ECA",
            color: "#fff",
          },
        }}
      >
        Choose this plan
        <ArrowForwardIcon />
      </Button>
    </Box>
  );
}
