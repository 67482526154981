import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function NotificationForm() {
  const labelStyles = {
    color: "var(--neutral-900, #131316)",
    fontFeatureSettings: `'ss06' on`,
    fontFamily: "SF Pro Display",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20.3px",
  };

  return (
    <FormGroup sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <FormControlLabel
        
        label={<span style={labelStyles}>When I send or receive digital asset</span>}
        control={<Checkbox defaultChecked />}
      />
      <FormControlLabel
        
        label={<span style={labelStyles}>When I receive merchant orders</span>}
        control={<Checkbox />}
      />
      <FormControlLabel
        
        label={
          <span style={labelStyles}>
            When there are recommended actions for my account
          </span>
        }
        control={<Checkbox />}
      />
    </FormGroup>
  );
}
