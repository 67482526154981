import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import Chart from "./chart";
import WatchlistComponent from "./watchlistComponent";
import { useSelector } from "react-redux";

export default function LeftGridComponentTwo() {
  const { previousbalances } = useSelector(
    (state) => state?.dashboard
  );
  return (
    <Grid container spacing={2} sx={{ py: "24px" }}>
      <Grid item xs={12} md={12} lg={7} xl={8}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mb: "24px",
          }}
        >
          <Typography
            sx={{
              color: "var(--neutral-900, #131316)",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "27.9px",
            }}
          >
            Portfolio Stats
          </Typography>
          {/* <Box sx={{ display: "flex", gap: "16px" }}>
            <SelectBox item1="Bitcoin" item2="Gafah" item3="Fiat" />
            <SelectBox2 item1="Yearly" item2="weekly" item3="Daily" />
          </Box> */}
        </Box>
        <Chart data={previousbalances} />
      </Grid>
      <Grid item xs={12} md={12} lg={5} xl={4}>
        <WatchlistComponent />
      </Grid>
    </Grid>
  );
}
