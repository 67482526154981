import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
import ResponsiveAppBar from "../../components/appbar";
import PricingComponentOne from "./components/componentOne";
import PricingComponentTwo from "./components/componentTwo";
import ComponentEight from "../ContactUs/components/componentThree";
export default function PricingComponent() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/", { replace: true });
  };
  useEffect(() => {
    // Reset the scroll position to the top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box
        sx={{
          margin: "20px 15px 0px",
          background: "black",
          pb: "88px",
          color: "white",
          borderRadius: "30px 0px 30px 30px",
          zIndex: 2,
          "@media (max-width:800px)": {
            p: "0!important",
            margin: "10px 8px 0px",
          },
          // "@media (max-width:600px)": { height: "400px!important" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 0px 0px 80px ",
            "@media (max-width:800px)": { p: "0!important" },
          }}
        >
          <Box
            onClick={handleClick}
            component="img"
            src={Logo}
            sx={{
              height: "50px",
              mt: "22px",
              "@media (max-width:800px)": {
                pl: "40px!important",
              },
              "@media (max-width:500px)": {
                height: "40px!important",
                mt: "18px",
              },
              cursor: "pointer",
            }}
          />
          <Box
            sx={{
              background: "#FFFFFF",
              color: "black!important",
              maxWidth: "800px",
              maxHeight: "65px",
              borderRadius: "0px 0px 0px 30px!important",

              "@media (max-width:800px)": {
                pl: 0,
              },
              "@media (max-width:500px)": {
                height: "60px!important",
              },
            }}
          >
            <ResponsiveAppBar />
          </Box>
        </Box>
        <Box
          sx={{
            mt: "109px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mx: "40px",
          }}
        >
          <Box sx={{ maxWidth: "1045px" }}>
            <Typography
              sx={{
                color: "#495ECA",
                textAlign: "left",
                fontFeatureSettings: " 'ss06' on",
                fontFamily: "SF Pro Display",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "19.6px",
                textTransform: "uppercase",
              }}
            >
              Prices
            </Typography>
            <Typography
              sx={{
                color: " #fff",
                textAlign: "left",
                fontFeatureSettings: " 'ss06' on",
                fontFamily: "Poppins",
                fontSize: "40px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "56px",
                letterSpacing: "-0.4px",
                mt: "16px",
                "@media (max-width:800px)": {
                  fontSize: "2.075rem",
                  lineHeight: "50px",
                },
              }}
            >
              The global digital assets market cap today is $971 Billion, a
              -0.81% change in the last 24 hours.
            </Typography>
          </Box>
        </Box>
        <PricingComponentOne />
      </Box>
      <PricingComponentTwo />
      <ComponentEight />
    </>
  );
}
