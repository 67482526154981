import { Box, Grid, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AuthHeader3,
  AuthPRight,
  Header5,
  LoaderCircular,
} from "../../components/Helpers/helperComponents";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { InputLabels } from "../../components/Helpers/label";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";
import {
  Btc,
  DangerSvg,
  DefaultAvatar,
  NextSvg,
} from "../../components/Helpers/svg";
import { country } from "../../components/Helpers/country";
import AlertDialog from "../../components/Dialog";
import { ButtonLoader, PrimaryButton2 } from "../../components/Buttons";
import { PrimaryInputComponents } from "../../components/Style";
import { Link } from "react-router-dom";
import moment from "moment";
import { hasAccess } from "../../utilities";
const Profile = () => {
  const { accessLists } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state?.settings);
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();
  const handleChange = (e) => {
    if (e.target.name === "businessname" && e.target.value?.length > 30) return;
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const init = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "profile",
        action: "ACTION_GET_PROFILE",
      };
      await dispatch(GetHandler(req));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      let data = {};
      if (inputs?.firstname !== profile?.firstname)
        data.firstname = inputs.firstname;
      if (inputs?.lastname !== profile?.lastname)
        data.lastname = inputs.lastname;
      if (inputs?.businessname !== profile?.businessname)
        data.businessname = inputs.businessname;
      if (inputs?.dob !== profile?.dob) data.dob = inputs.dob;
      if (inputs?.country !== profile?.country) data.country = inputs.country;

      if (inputs?.firstname !== profile?.firstname)
        data.firstname = inputs.firstname;
      const req = {
        url: "profile",
        payload: data,
      };

      await dispatch(PostHandler(req));
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteRequest = async () => {
    try {
      setIsDeleting(true);
      const req = {
        url: "deleteRequest",
      };
      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) init();
      setIsDeleting(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisable(inputs?.newEmail ? false : true);
  }, [inputs]);

  useEffect(() => {
    setInputs({
      email: profile?.email,
      phone: profile?.phone,
      firstname: profile?.firstname,
      lastname: profile?.lastname,
      businessname: profile?.businessname,
      dob: moment(profile?.dob).format("YYYY-MM-DD"),
      country: profile?.country,
    });
  }, [profile]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDisable(
      profile?.firstname === inputs?.firstname &&
        profile?.lastname === inputs?.lastname &&
        profile?.businessname === inputs?.businessname &&
        profile?.dob === inputs?.dob &&
        profile?.country === inputs?.country
        ? true
        : false
    );
  }, [profile, inputs]);

  return (
    <Box
      sx={{
        p: "20px",
      }}
    >
      <Grid container>
        <Grid item lg="4">
          <Box
            sx={{
              pt: "32px",
              mb: "15px",
            }}
          >
            <Header5 text="Contact Info" fs="16px" />
            <AuthPRight
              text="Manage your information"
              fs="16px"
              lh="22px"
              mt="10px"
            />
          </Box>
        </Grid>
        <Grid item xs="12" lg="8">
          {/* get apis */}
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #E3E3E8)",
              background: "var(--main-colors-white, #FFF)",
            }}
          >
            <Box
              sx={{
                p: {
                  xs: "15px",
                  md: "30px",
                },
                display: "flex",
                alignItems: "center",
              }}
            >
              <DefaultAvatar />
              <Box>
                <AuthHeader3
                  text={
                    <>
                      {profile?.firstname && profile?.firstname}{" "}
                      {profile?.lastname && profile?.lastname}
                    </>
                  }
                />
                <AuthPRight
                  mt="0px"
                  fs="16px"
                  text={profile?.email && profile?.email}
                />
              </Box>
            </Box>

            <Box
              sx={{
                p: {
                  xs: "15px",
                  md: "30px",
                },
                display: "flex",
                alignItems: "center",
                borderTop: "1px solid var(--neutral-200, #E3E3E8)",
                borderBottom: "1px solid var(--neutral-200, #E3E3E8)",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: "5px",
                  textDecoration: "none",
                  // color: '#000',
                  fontSize: "13px",
                  right: "15px",
                }}
                component={Link}
                to="/settings/security/"
              >
                click to change email, phone
              </Box>
              {isFetching ? (
                <LoaderCircular />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "100%",
                    pb: "10px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <InputLabels label="Email" mb="5px" />
                    <PrimaryInputComponents
                      id="email"
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      value={inputs?.email || ""}
                      disabled={true}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <InputLabels label="Phone" mb="5px" />
                    <PhoneInput
                      country={"ae"}
                      name="phone"
                      value={inputs?.phone || ""}
                      inputStyle={{
                        width: "100%",
                        height: "50px",
                      }}
                      disabled
                    />
                  </Box>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                p: "25px",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
              }}
            >
              <AuthPRight text="Crypto addresses" fs="14px" />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    pt: "6px",
                  }}
                >
                  <Box sx={{ ml: "-4px" }}>
                    <Btc />
                  </Box>
                  <Box sx={{ ml: "-4px" }}>
                    <Btc />
                  </Box>
                  <Box sx={{ ml: "-4px" }}>
                    <Btc />
                  </Box>
                  <Box sx={{ ml: "-4px" }}>
                    <Btc />
                  </Box>
                  <Box sx={{ ml: "-4px" }}>
                    <Btc />
                  </Box>
                </Box>
                <NextSvg />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ mt: "30px" }}>
        <Grid item lg="4">
          <Box
            sx={{
              pt: "32px",
              mb: "15px",
            }}
          >
            <Header5 text="Personal info" fs="16px" />
            <AuthPRight
              text="Manage your information"
              fs="16px"
              lh="22px"
              mt="10px"
            />
          </Box>
        </Grid>
        <Grid item xs="12" lg="8">
          {/* get apis */}
          <Box
            sx={{
              p: {
                xs: "15px",
                md: "30px",
              },
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #E3E3E8)",
              background: "var(--main-colors-white, #FFF)",
            }}
          >
            {isFetching ? (
              <LoaderCircular />
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <InputLabels label="First Name" mb="5px" />
                    <PrimaryInputComponents
                      id="firstname"
                      type="text"
                      onChange={handleChange}
                      placeholder="Enter first name"
                      name="firstname"
                      value={inputs?.firstname || ""}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <InputLabels label="Last Name" mb="5px" />
                    <PrimaryInputComponents
                      id="lastname"
                      type="text"
                      onChange={handleChange}
                      placeholder="Enter last name"
                      name="lastname"
                      value={inputs?.lastname || ""}
                    />
                  </Box>
                </Box>

                <InputLabels label="Bussiness Name" mt="15px" mb="5px" />
                <PrimaryInputComponents
                  id="businessname"
                  type="text"
                  onChange={handleChange}
                  placeholder="Enter bussiness name"
                  name="businessname"
                  value={inputs?.businessname || ""}
                />
                <AuthPRight
                  text="maximum length 15-30 charecter"
                  fs="14px"
                  lh="22px"
                />

                <InputLabels label="Date of Birth" mt="15px" mb="5px" />
                <PrimaryInputComponents
                  id="dob"
                  type="date"
                  onChange={handleChange}
                  placeholder="Enter email"
                  name="dob"
                  value={inputs?.dob || ""}
                  required
                />

                <InputLabels label="Country of residence" mt="15px" />
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleChange}
                  name="country"
                  value={inputs?.country || ""}
                  sx={{
                    mt: "5px",
                    height: "48px",
                    width: "100%",
                    "&:hover fieldset": {
                      border: "1px solid #495ECA!important",
                    },
                    "&:focus-within fieldset, &:focus-visible fieldset": {
                      border: "1px solid #495ECA!important",
                      boxShadow: "0 0 0 2px #495eca6b",
                    },
                  }}
                >
                  {country?.map((item) => (
                    <MenuItem key={item?.name} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>

                {hasAccess(["Profile-update"], accessLists) && (
                  <Box sx={{ mt: "20px" }}>
                    {isLoading ? (
                      <ButtonLoader />
                    ) : (
                      <PrimaryButton2
                        title="Save"
                        onClick={handleUpdate}
                        disabled={isDisable}
                      />
                    )}
                  </Box>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ mt: "2rem" }}>
        <Grid item lg="4">
          <Box
            sx={{
              pt: "32px",
              mb: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <DangerSvg />
              <Header5 text="Danger zone" fs="16px" mt="0px" />
            </Box>
            <AuthPRight
              text="Close your account"
              fs="16px"
              lh="22px"
              mt="10px"
            />
          </Box>
        </Grid>
        <Grid item xs="12" lg="8">
          {/* get apis */}
          <Box
            sx={{
              p: {
                xs: "15px",
                md: "30px",
              },
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #E3E3E8)",
              background: "var(--main-colors-white, #FFF)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Header5 text="Close account" fs="16px" mt="0px" />
                <AuthPRight
                  text="Closing your account can’t be undone. Please make sure your account balance is $0.00 before you begin."
                  fs="15px"
                  lh="20px"
                  mt="5px"
                />
              </Box>

              <Box>
                {/* {isLoading ? (
                  <Button variant="outlined" color="error" disabled>
                    <CircularProgress size="1.7rem" />
                  </Button>
                ) : ( */}
                <AlertDialog
                  btnTitle="Close Account"
                  title="Are your sure to delete?"
                  content="Are you sure to close your account"
                  execute={handleDeleteRequest}
                  executeLoading={isDeleting}
                />
                {/* )} */}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
