export const ACTION_GET_APIKEY = "ACTION_GET_APIKEY"
export const ACTION_GET_ECCOMERCE_APIKEY = "ACTION_GET_ECCOMERCE_APIKEY"
export const ACTION_GET_SMARTLINKS = "ACTION_GET_SMARTLINKS"
export const ACTION_GET_EXTERNAL = "ACTION_GET_EXTERNAL"
export const ACTION_GET_RETURNED_SMARTLINK = "ACTION_GET_RETURNED_SMARTLINK"
export const ACTION_GET_VAT = "ACTION_GET_VAT"
export const ACTION_GET_RETURNED_EXTERNAL = "ACTION_GET_RETURNED_EXTERNAL"

const initialState = {
  ecommerce: [],
  pos: [],
  vat: null,
  smartLinks: [],
  external: {
    choices: [],
    tokens: []
  }
}

export const ApiKeyReducer = (state = initialState, action) => {
  // const returnObj = { ...state }
  switch (action.type) {
    case ACTION_GET_EXTERNAL:
      return {
        ...state,
        external: action?.payload?.data,
      }

    case ACTION_GET_RETURNED_EXTERNAL:
      return {
        ...state,
        external: action?.payload,
      }

    case ACTION_GET_APIKEY:
      return {
        ...state,
        ecommerce: action?.payload?.data?.ecommerce,
        pos: action?.payload?.data?.pos,
      }

    case ACTION_GET_ECCOMERCE_APIKEY:
      return {
        ...state,
        ecommerce: action?.payload?.ecommerce,
      }

    case ACTION_GET_SMARTLINKS:
      return {
        ...state,
        smartLinks: action?.payload?.data,
      }

    case ACTION_GET_RETURNED_SMARTLINK:
      return {
        ...state,
        smartLinks: action?.payload?.smartlinks,
      }

    case ACTION_GET_VAT:
      return {
        ...state,
        vat: action?.payload?.data,
      }

    default:
      return state
  }
}

