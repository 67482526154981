import { Typography } from "@mui/material";

export const InputLabels = ({ label, mt, mb, fs, bg, fw }) => (
  <Typography
    sx={{
      mt: mt || "5px",
      color: "var(--neutral-900, #131316)",
      fontFeatureSettings: `'ss06' on`,
      fontFamily: "SF Pro Display",
      fontSize: fs || "14px",
      fontStyle: "normal",
      fontWeight: fw || 500,
      lineHeight: "20.3px",
      mb: mb || "0px",
      background: bg,
    }}
  >
    {label}
  </Typography>
);
