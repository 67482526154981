import React, { useState } from "react";
import LeftPanelListBox from "./leftPanelListBox";
import { TransactionSvg } from "../../../components/Drawer/svgs";
export default function TopPanel(props) {
  const { tokens, setTitle, setParams } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <>
      <LeftPanelListBox
        svg={<TransactionSvg />}
        crypto="All Transactions"
        onClick={() => {
          setParams({ pageNumber: 1, get: "all" });
          setTitle("All Transactions");
          setActiveIndex(0);
        }}
        active={activeIndex === 0 ? true : false}
      />

      {tokens?.length > 0 &&
        tokens?.map((item, index) => (
          <LeftPanelListBox
            key={item?.id}
            icon={item?.logo}
            crypto={item?.token}
            cryptoShortName={item?.type}
            onClick={() => {
              setParams({
                token: item?.token,
                network: item?.network,
                pageNumber: 1,
              });
              setTitle(item?.token);
              setActiveIndex(index + 1);
            }}
            active={activeIndex === index + 1 ? true : false}
          />
        ))}
    </>
  );
}
