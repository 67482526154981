import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextareaAutosize,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { BoldLabel, PrimaryInputComponents } from "../../components/Style";
import { useDispatch } from "react-redux";
// import { PostHandler } from "../../Redux/RequestHandlers";
import { ButtonLoader, PrimaryButton2 } from "../../components/Buttons";
import { InputLabels } from "../../components/Helpers/label";
import { ACTION_REFUND_SUBMIT } from "../../Redux/Reducers/pos.reducer";
import { validEmail } from "../../Validation/formValidations";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import axios from "axios";
import { findUrl } from "../../Redux/RequestHandlers";
import api from "../../config/api";

export default function RefundComponent({
  setRefundShow,
  setRedirect,
  setRefundSubmit,
  refundData
}) {

  const [inputs, setInputs] = useState({
    refund_required: "yes",
  });
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [errors, setErrors] = useState({});

  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async (isYes) => {
    setErrMsg("")
    try {
      let k = isYes || inputs?.refund_required;
      let payload =
        k === "no"
          ? {
            refund_required: k,
          }
          : inputs;

      setIsSubmitting(true);
      // const req = {
      //   url: "refund",
      //   payload: payload,
      // };
      const res = await axios.post(
        // `${config?.EXTERNAL_API_URL}api/paymentgateway/insertRefundDetails`,
        findUrl(api, "initiateRefundDetails"),
        payload,
        {
          headers: {
            Authorization: `Bearer ${refundData?.refundToken}`,
          },
        }
      );
      // const res = await dispatch(PostHandler(req));
      setIsSubmitting(false);

      if (res?.data?.success) {
        setInputs({});
        if (setRedirect) setRedirect(true);
        dispatch({ type: ACTION_REFUND_SUBMIT });
        if (setRefundShow) setRefundShow(false);
        if (setRefundSubmit) setRefundSubmit(true);
      }
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setErrMsg("Please try again later.")
    }
  };

  useEffect(() => {
    setIsDisable(
      inputs?.refund_required === "no" ||
        (inputs?.refund_required === "yes" &&
          inputs?.address &&
          validEmail(inputs?.email))
        ? false
        : true
    );
  }, [inputs]);

  useEffect(() => {
    if (inputs?.email) {
      let vEmail = validEmail(inputs?.email);
      setErrors((prev) => ({ ...prev, validEmail: !vEmail }));
      setErrMsg(vEmail ? "" : "invalid Email");
    }
  }, [inputs?.email]);

  return (
    <Box
      sx={{
        pt: "0px",
        "@media (max-width:600px)": { p: "20px" },
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            borderRadius: "34px",
            border: "1px solid var(--Neutral-300, #EFF0F6)",
            background: "var(--Neutral-100, #FFF)",
            boxShadow: "0px 3px 16px 0px rgba(73, 94, 202, 0.18)",
            p: "76px",
            pt: "30px",
            width: "100%",
            maxWidth: "700px",
            m: "30px",
            mt: "0px",
            height: "100%",
            "@media (max-width:600px)": {
              maxWidth: "300px!important",
              p: "40px",
            },
            "@media (max-width:800px)": { maxWidth: "500px", p: "40px" },
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontFamily: "SF Pro Display",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              mb: "40px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Refund Details
          </Typography>
          {errMsg && <Box sx={{color: 'red', textAlign: 'center', mb: '15px'}}>
            {errMsg}
          </Box>}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <Typography
              sx={{
                color: "#000",
                fontFamily: "SF Pro Display",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                textAlign: "left",
              }}
            >
              Refund Amount:{" "}
              <span style={{ color: "#495ECA" }}>
                {refundData?.refundAmount} {refundData?.token}
              </span>
            </Typography>
            <Typography
              sx={{
                color: "#000",
                fontFamily: "SF Pro Display",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                textAlign: "left",
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              Refund Required:
              <Box sx={{ display: "flex", gap: "30px" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={inputs?.refund_required}
                  name="radio-buttons-group"
                >
                  <Box sx={{ display: "flex" }}>
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      onChange={() =>
                        setInputs((prev) => ({
                          ...prev,
                          refund_required: "yes",
                        }))
                      }
                      label={<InputLabels label="Yes" mt="0px" />}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      onChange={() =>
                        setInputs((prev) => ({
                          ...prev,
                          refund_required: "no",
                        }))
                      }
                      label={<InputLabels label="No" mt="0px" />}
                    />
                  </Box>
                </RadioGroup>
              </Box>
            </Typography>
            {inputs?.refund_required === "yes" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <BoldLabel labelFor="Address *" />
                  <PrimaryInputComponents
                    id="address"
                    type="string"
                    name="address"
                    onChange={handleChange}
                    value={inputs?.address || ""}
                    placeholder="Enter your wallet address here"
                    sx={{ width: "100%" }}
                    required
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <InputLabels label="Email" mt="0px" />
                  <PrimaryInputComponents
                    error={errors?.validEmail}
                    id="email"
                    name="email"
                    onChange={handleChange}
                    value={inputs?.email || ""}
                    sx={{ width: "100%" }}
                    placeholder="Enter your email address"
                  />
                  {errors?.validEmail && (
                    <Box
                      sx={{
                        mt: "5px",
                        fontSize: "12px",
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        fontFamily: "SF Pro Display",
                        flexWrap: "wrap",
                        color: "red",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <HighlightOffIcon
                          fontSize="14px"
                          sx={{ color: "red" }}
                        />
                        {errMsg}
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <BoldLabel labelFor="Comments" />
                  <TextareaAutosize
                    name="Comments"
                    minRows={8}
                    placeholder="Please mention details about the refund."
                    style={{
                      padding: "15px",
                      borderRadius: "5px",
                      fontFamily: "SF Pro Display",
                      fontSize: "15px",
                    }}
                    onChange={handleChange}
                  />
                </Box>
              </>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: "30px",
              }}
            >
              {isSubmitting ? (
                <ButtonLoader width="100px" />
              ) : (
                <PrimaryButton2 disabled={isDisable} onClick={handleSubmit} />
              )}

            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
