import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { CardSvg } from "../../../../components/Helpers/svg";
import { CopyToClipboardButton } from "../../../../components/Helpers/helperComponents";
import config from "../../../../config/config";

export const KYCCard = ({ status }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      textDecoration: "none",
    }}
    component={Link}
    to="/settings/kyc/identity-verification/"
  >
    <Box
      sx={{
        display: "flex",
        gap: "8px",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <CardSvg />
      <Typography
        sx={{
          color: "var(--neutral-400, #9C9CAB)",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20.3px",
          textDecoration: "none",
        }}
      >
        KYC STATUS
      </Typography>
    </Box>
    <Typography
      sx={{
        color: "#4F5AED",
        fontFeatureSettings: `'ss06' on`,
        fontFamily: "SF Pro Display",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "56px",
        textTransform: "uppercase",
      }}
    >
      {status || "Incomplete"}
    </Typography>
  </Box>
);

export const ReferralCard = ({ refCode }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      textDecoration: "none",
    }}
  >
    <Box
      sx={{
        display: "flex",
        gap: "8px",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <CardSvg />
      <Typography
        sx={{
          color: "var(--neutral-400, #9C9CAB)",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20.3px",
          textDecoration: "none",
        }}
      >
        Referral Code
      </Typography>
    </Box>
    <Box
      sx={{
        color: "#4F5AED",
        fontFeatureSettings: `'ss06' on`,
        fontFamily: "SF Pro Display",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "56px",
        textTransform: "uppercase",
        display: "flex",
        alignItems: 'center',
        gap: '10px'
      }}
    >
      {refCode}
      <CopyToClipboardButton content={`${config?.SIGN_UP_URL}?code=${refCode}`} />
    </Box>
  </Box>
);
