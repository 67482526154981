import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
export default function PricingCOneCard2(props) {
  const { plan, price } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: "30px 29px",
        background: "#1E1E1E",
        borderRadius: "20px",
        color: "#fff",
        gap: "27px",
        width: "306px",
        minHeight: "411px",
        "@media (max-width:1300px)": {
          width: "auto",
          py: "20px",
        },
        "@media (max-width:950px)": {
          width: "352px",
        },
        "@media (max-width:500px)": {
          width: "90%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          justifyContent: "center",
          alignItems: "center",
          pb: "27px",
          borderBottom: "solid 1px #3F3F3F",
          width: "100%",
        }}
      >
        {/* <Button
          component={Link}
          // to="/contactus"
          sx={{
            borderRadius: "15px!important",
            background: "#495ECA",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "7px",
            textTransform: "none",
            fontFamily: "SF Pro Display",
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "normal",
            p: "5px",
            "&:hover": {
              background: "#495ECA",
              color: "#fff",
            },
          }}
        >
          -30 %
        </Button> */}
        <Typography
          sx={{
            color: "#FFF",
            textAlign: "center",
            fontFamily: "SF Pro Display",
            fontSize: "30px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
          }}
        >
          {plan}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "3px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <svg
            width="8"
            height="11"
            viewBox="0 0 8 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M0 7.02996H1.48685C2.03083 9.71327 4.0689 11 6.57842 11C7.08613 11 7.69538 10.9372 8 10.8274V9.33666C7.69538 9.42297 7.08613 9.47004 6.60743 9.47004C4.9175 9.47004 3.62647 8.6933 3.15503 7.02996H7.09338V6.14337H2.99547C2.98096 5.94722 2.96646 5.74322 2.96646 5.52354V5.50785C2.96646 5.26462 2.98096 5.02924 3.00272 4.80956H7.09338V3.92297H3.17679C3.66274 2.29886 4.93926 1.52996 6.60743 1.52996C7.08613 1.52996 7.69538 1.59272 8 1.66334V0.172611C7.69538 0.0706134 7.08613 0 6.57842 0C4.09066 0 2.05984 1.27104 1.49411 3.92297H0V4.80956H1.37081C1.34905 5.02924 1.3418 5.25678 1.3418 5.49215V5.50785C1.3418 5.72753 1.34905 5.93937 1.36355 6.14337H0V7.02996Z"
              fill="white"
            />
          </svg> */}

          <Typography
            sx={{
              color: "#fff",
              textAlign: "center",
              fontFamily: "SF Pro Display",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            {price}
          </Typography>
          {/* <Typography
            sx={{
              color: "#fff",
              textAlign: "center",
              fontFamily: "SF Pro Display",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              opacity: 0.5,
            }}
          >
            /user
          </Typography> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "20px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "7px",
            justifyContent: "flex-start",
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9" cy="9" r="9" fill="white" />
            <path
              d="M5 8.94545L7.84444 12L13 6"
              stroke="#3F3F3F"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Typography
            sx={{
              color: "#AEAEAE",
              textAlign: "left",
              fontFamily: "SF Pro Display",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Personalize analytics.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "7px",
            justifyContent: "flex-start",
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9" cy="9" r="9" fill="white" />
            <path
              d="M5 8.94545L7.84444 12L13 6"
              stroke="#3F3F3F"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Typography
            sx={{
              color: "#AEAEAE",
              textAlign: "left",
              fontFamily: "SF Pro Display",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Enterprise Fees.
            {/* <span style={{ color: "#fff" }}>workflows.</span> */}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "7px",
            justifyContent: "flex-start",
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9" cy="9" r="9" fill="white" />
            <path
              d="M5 8.94545L7.84444 12L13 6"
              stroke="#3F3F3F"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Typography
            sx={{
              color: "#AEAEAE",
              textAlign: "left",
              fontFamily: "SF Pro Display",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            <span style={{ color: "#fff" }}>Monthly</span> charges applicable.
          </Typography>
        </Box>
      </Box>
      <Button
        component={Link}
        to="/contactus"
        sx={{
          borderRadius: "15px!important",
          background: "#495ECA",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "7px",
          textTransform: "none",
          fontFamily: "SF Pro Display",
          fontSize: "13px",
          fontWeight: 500,
          lineHeight: "normal",
          p: "11.5px 15px",
          "&:hover": {
            background: "#495ECA",
            color: "#fff",
          },
        }}
      >
        Choose this plan
        <ArrowForwardIcon />
      </Button>
    </Box>
  );
}
