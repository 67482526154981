import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { KYCCard, ReferralCard } from "../../Dashboard/components/Right/Cards";
import { DashBoardBasicCardsBody } from "../../../components/Cards";
import { ChartSvg1, VolumeSvg } from "../../../components/Helpers/svg";
import { Numberz } from "../../../utilities";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
export default function InfoCards({ totalRef, affiliateTransactions }) {
  const { kycStatus, referralCode } = useSelector((state) => state?.dashboard);

  return (
    <Box
      sx={{ p: "20px", display: "flex", flexDirection: "column", gap: "20px" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "@media (min-width:900px) and (max-width:1600px)": {
            flexDirection: "column",
            justifyContent: "center",
            gap: "20px",
          },
          "@media (max-width:500px)": {
            flexDirection: "column",
            justifyContent: "center",
            gap: "20px",
          },
        }}
      >
        <Box
          sx={{
            width: "48%",
            p: "20px",
            border: "1px solid var(--neutral-200, #E3E3E8)",
            borderRadius: "10px",
            "@media (min-width:900px) and (max-width:1600px)": {
              width: "100%",
            },
            "@media (max-width:500px)": {
              width: "100%",
            },
          }}
        >
          <DashBoardBasicCardsBody
            icon={<ChartSvg1 />}
            titletext="TOTAL REFERRALS"
            datavalue={Numberz(totalRef?.totalCount)}
          />
        </Box>

        <Box
          sx={{
            width: "48%",
            p: "20px",
            border: "1px solid var(--neutral-200, #E3E3E8)",
            borderRadius: "10px",
            "@media (min-width:900px) and (max-width:1600px)": {
              width: "100%",
            },
            "@media (max-width:500px)": {
              width: "100%",
            },
          }}
        >
          <DashBoardBasicCardsBody
            icon={<VerifiedUserIcon color="success" />}
            titletext="TOTAL VERIFIED REFERRALS"
            datavalue={Numberz(totalRef?.totalVerifiedCount)}
          />
        </Box>

        <Box
          sx={{
            width: "48%",
            p: "20px",
            border: "1px solid var(--neutral-200, #E3E3E8)",
            borderRadius: "10px",
            "@media (min-width:900px) and (max-width:1600px)": {
              width: "100%",
            },
            "@media (max-width:500px)": {
              width: "100%",
            },
          }}
        >
          <DashBoardBasicCardsBody
            icon={<DoneAllIcon color="success" />}
            titletext="TOTAL KYC REFERRALS"
            datavalue={Numberz(totalRef?.totalkycCount)}
          />
        </Box>

        <Box
          sx={{
            width: "48%",
            p: "20px",
            border: "1px solid var(--neutral-200, #E3E3E8)",
            borderRadius: "10px",
            "@media (min-width:900px) and (max-width:1600px)": {
              width: "100%",
            },
            "@media (max-width:500px)": {
              width: "100%",
            },
          }}
        >
          <DashBoardBasicCardsBody
            icon={<VolumeSvg />}
            titletext="TOTAL AFFILIATE TRANSACTIONS"
            datavalue={Numberz(totalRef?.affiliateTransactions)}
          />
        </Box>


        <Box
          sx={{
            width: "48%",
            p: "20px",
            border: "1px solid var(--neutral-200, #E3E3E8)",
            borderRadius: "10px",
            "@media (min-width:900px) and (max-width:1600px)": {
              width: "100%",
            },
            "@media (max-width:500px)": {
              width: "100%",
            },
          }}
        >
          {/Completed/.test(kycStatus?.status) && referralCode ? (
            <ReferralCard refCode={referralCode} />
          ) : (
            <KYCCard status={kycStatus?.status} />
          )}
        </Box>
      </Box>
    </Box>
  );
}
