import React from "react";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { Box, Grid, Typography } from "@mui/material";
import { InputLabels } from "../Helpers/label";
import {
  CopyToClipboardButton,
  DescriptionComponent,
} from "../Helpers/helperComponents";
const BankAccountInfo = ({ bankName, name, accountNo, ifsc }) => {
  return (
    <Box
      sx={{
        mt: "10px",
        background: "#eff6faa6",
        borderRadius: "10px",
        p: "15px",
        fontFamily: "SF Pro Display",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: "10px",
          borderBottom: "1px solid #0001",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <AccountBalanceOutlinedIcon />
          <Typography
            sx={{
              fontFamily: "SF Pro Display",
              textTransform: "capitalize",
              fontWeight: 500,
            }}
          >
            {bankName}
          </Typography>
        </Box>

        <Box
          sx={{
            background: "green",
            p: "5px",
            fontWeight: 500,
            borderRadius: "5px",
            fontSize: "12px",
            color: "#FFF",
            width: "max-content",
          }}
        >
          Verified
        </Box>
      </Box>

      {/* info */}
      <Grid container sx={{ mt: "5px" }}>
        <Grid item xs={6}>
          <InputLabels label="Name" />
          <DescriptionComponent text={name} fs="16px" ff="SF Pro Display" />
        </Grid>
        <Grid item xs={6}>
          <InputLabels label="Account No" />
          <DescriptionComponent
            text={accountNo}
            fs="16px"
            ff="SF Pro Display"
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: "10px" }}>
          <InputLabels label="IBAN" />
          <DescriptionComponent text={ifsc} fs="16px" ff="SF Pro Display" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BankAccountInfo;

export const WalletInfo = ({ eth, bnb, tron }) => (
  <Box
    sx={{
      mt: "10px",
      background: "#eff6faa6",
      borderRadius: "10px",
      p: "15px",
      fontFamily: "SF Pro Display",
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pb: "10px",
        borderBottom: "1px solid #0001",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "10px",
        }}
      >
        <AccountBalanceOutlinedIcon />
        <Typography
          sx={{
            fontFamily: "SF Pro Display",
            textTransform: "capitalize",
            fontWeight: 500,
          }}
        >
          Crypto Address
        </Typography>
      </Box>

      <Box
        sx={{
          background: "green",
          p: "5px",
          fontWeight: 500,
          borderRadius: "5px",
          fontSize: "12px",
          color: "#FFF",
          width: "max-content",
        }}
      >
        Verified
      </Box>
    </Box>

    <InputLabels label="Ethereum Adress" mt="15px"/>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        background: "#fff",
        p: '10px',
        borderRadius: '5px',
        mt: '5px'
      }}
    >
      <Box>
        <Typography
          sx={{
            color: "rgba(69, 69, 69, 0.85)",
            textAlign: "left",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            wordBreak: "break-word",
          }}
        >
          {eth}
        </Typography>{" "}
      </Box>

      <CopyToClipboardButton
        content={eth}
        background="#fff"
        fontsize="25px!important"
      />
    </Box>
    
    <InputLabels label="Binance Adress" mt="15px"/>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        background: "#fff",
        p: '10px',
        borderRadius: '5px',
        mt: '5px'
      }}
    >
      <Box>
        <Typography
          sx={{
            color: "rgba(69, 69, 69, 0.85)",
            textAlign: "left",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            wordBreak: "break-word",
          }}
        >
          {bnb}
        </Typography>{" "}
      </Box>

      <CopyToClipboardButton
        content={bnb}
        background="#fff"
        fontsize="25px!important"
      />
    </Box>

    <InputLabels label="Tron Adress" mt="15px"/>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        background: "#fff",
        p: '10px',
        borderRadius: '5px',
        mt: '5px'
      }}
    >
      <Box>
        <Typography
          sx={{
            color: "rgba(69, 69, 69, 0.85)",
            textAlign: "left",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            wordBreak: "break-word",
          }}
        >
          {tron}
        </Typography>{" "}
      </Box>

      <CopyToClipboardButton
        content={tron}
        background="#fff"
        fontsize="25px!important"
      />
    </Box>
  </Box>
);
