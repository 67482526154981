import { Box, Button, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AuthPRight, Header5 } from "../../components/Helpers/helperComponents";
import { PrimaryButton2 } from "../../components/Buttons";
import { InputLabels } from "../../components/Helpers/label";
import { useDispatch, useSelector } from "react-redux";
import { PostHandler } from "../../Redux/RequestHandlers";
import { PrimaryInputComponents } from "../../components/Style";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const ChangePhone = () => {
  const { changePhone, pcid } = useSelector((state) => state?.settings);
  const [inputs, setInputs] = useState({});
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);
  const [isVerifyShow, setIsVerifyShow] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (e.target.name === "verificationOTP" && e.target.value?.length > 6)
      return;
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "initiatePhoneChange",
        action: "ACTION_INITIATE_CHANGE_PHONE",
        payload: {
          newPhone: inputs?.newPhone,
        },
        reqPayload: {
          changePhone: inputs?.newPhone,
        },
      };

      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) setIsVerifyShow(true);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const resendOtp = async () => {
    try {
      setIsOtpLoading(true);
      const req = {
        url: "resendPhoneChangeOTP",
        payload: {
          newPhone: changePhone,
        },
      };

      await dispatch(PostHandler(req));
      setIsOtpLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerifyAndChange = async () => {
    try {
      setIsSubmitting(true);
      const req = {
        url: "changePhone",
        payload: {
          verificationOTP: inputs?.verificationOTP,
          pcid: pcid,
        },
      };

      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) setIsVerifyShow(false);
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisable(inputs?.newPhone ? false : true);
  }, [inputs?.newPhone]);

  useEffect(() => {
    setIsSubmitDisable(inputs?.verificationOTP ? false : true);
  }, [inputs?.verificationOTP]);

  return (
    <Box
      sx={{
        display: "flex",
        p: "20px",
      }}
    >
      <Grid container>
        <Grid item lg="4">
          <Box
            sx={{
              pt: "32px",
              mb: "15px",
            }}
          >
            <Header5 text="Change Phone" fs="16px" />
            <AuthPRight
              text="Keep your phone number up-to-date"
              fs="16px"
              lh="22px"
              mt="10px"
            />
          </Box>
        </Grid>
        <Grid item xs="12" lg="8">
          {/* get apis */}
          <Box
            sx={{
              p: {
                xs: "15px",
                md: "30px",
              },
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #E3E3E8)",
              background: "var(--main-colors-white, #FFF)",
            }}
          >
            {!isVerifyShow ? (
              <>
                <InputLabels label="New Phone Number" mb="5px" />

                <PhoneInput
                  country={"ae"}
                  name="newPhone"
                  onChange={(phone) =>
                    setInputs((prev) => ({ ...prev, newPhone: phone }))
                  }
                  value={inputs?.newPhone || ""}
                  inputStyle={{
                    width: "100%",
                    height: "50px",
                  }}
                />

                <Box sx={{ mt: "30px" }}>
                  {isLoading ? (
                    <Button variant="contained" title="submit" disabled>
                      <CircularProgress size="1.7rem" />
                    </Button>
                  ) : (
                    <PrimaryButton2
                      onClick={handleSubmit}
                      disabled={isDisable}
                      title="Sent Request"
                    />
                  )}
                </Box>
              </>
            ) : (
              <>
                <InputLabels label="Verify Account" mt="30px" />
                <AuthPRight
                  text="We send six digit OTP to your email. Pleasse verify your account to continue."
                  fs="16px"
                  lh="22px"
                  mt="10px"
                />
                <PrimaryInputComponents
                  id="verifyAccount"
                  placeholder="Enter OTP"
                  type="number"
                  onChange={handleChange}
                  value={inputs?.verificationOTP || ""}
                  name="verificationOTP"
                />

                {!isOtpLoading ? (
                  <Box
                    sx={{ display: "flex", alignItems: "center", py: "20px" }}
                  >
                    <AuthPRight
                      text={`Did not get otp? `}
                      fs="16px"
                      lh="22px"
                      mt="0px"
                    />
                    <Box
                      onClick={resendOtp}
                      style={{
                        color: "var(--main-colors-primary, #5235E8)",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      Resend OTP
                    </Box>
                  </Box>
                ) : (
                  <CircularProgress />
                )}

                <Box>
                  {isSubmitting ? (
                    <Button variant="contained" title="submit" disabled>
                      <CircularProgress size="1.7rem" />
                    </Button>
                  ) : (
                    <PrimaryButton2
                      title="Submit"
                      onClick={handleVerifyAndChange}
                      disabled={isSubmitDisable}
                    />
                  )}
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChangePhone;
