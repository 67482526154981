export const ACTION_GET_ROLES = "ACTION_GET_ROLES"
export const ACTION_GET_ROLES_PERMISSIONS = "ACTION_GET_ROLES_PERMISSIONS"
export const ACTION_GET_PERMISSIONS = "ACTION_GET_PERMISSIONS"
export const ACTION_GET_ROLES_PAGE = "ACTION_GET_ROLES_PAGE"
export const ACTION_GET_SUBUSERS = "ACTION_GET_SUBUSERS"
export const ACTION_GET_USERS_RETURNED = "ACTION_GET_USERS_RETURNED"
export const ACTION_VERIFY_USER = "ACTION_VERIFY_USER"
export const ACTION_GET_USERS_RETURNED_ADDED = "ACTION_GET_USERS_RETURNED_ADDED"

const initialState = {
    users: [],
    roles: [],
    permissionList: [],
    rolesPermissions: [],
    verifyUser: null
}

export const RolesReducer = (state = initialState, action) => {
    // const returnObj = { ...state }
    switch (action.type) {
        case ACTION_VERIFY_USER:
            return {
                ...state,
                verifyUser: action?.payload?.user,
            }
        case ACTION_GET_ROLES:
            return {
                ...state,
                roles: action?.payload?.data,
            }

        case ACTION_GET_SUBUSERS:
            return {
                ...state,
                users: action?.payload?.data?.rows,
            }

        case ACTION_GET_USERS_RETURNED:
            return {
                ...state,
                users: action?.payload?.subMerchants?.rows,
            }

        case ACTION_GET_USERS_RETURNED_ADDED:
            return {
                ...state,
                users: action?.payload?.subMerchants?.rows,
                verifyUser: null
            }

        case ACTION_GET_ROLES_PAGE:
            return {
                ...state,
                users: action?.payload?.data?.users?.rows,
                roles: action?.payload?.data?.roles,
            }

        case ACTION_GET_ROLES_PERMISSIONS:
            return {
                ...state,
                rolesPermissions: action?.payload?.data?.data,
            }
        case ACTION_GET_PERMISSIONS:
            return {
                ...state,
                permissionList: action?.payload?.data,
            }

        default:
            return state
    }
}

