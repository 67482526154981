import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { PortfolioSvg, VolumeSvg } from '../../../../components/Helpers/svg';
import UpDownShowComponent from '../../../../components/Helpers/UpDownShowComponent';
import { Numberz } from '../../../../utilities';
import { LoaderCircular } from '../../../../components/Helpers/helperComponents';

export default function LeftGridComponentOne() {
  const { totalPortfolioValue, showCurrency, volume, portfolioChanges } = useSelector(
    (state) => state?.dashboard
  );
  return (
    <Grid
      container
      sx={{
        py: "24px",
        width: "100%",
        borderTop: "solid 1px var(--neutral-200, #E3E3E8)",
        borderBottom: "solid 1px var(--neutral-200, #E3E3E8)",
      }}
    >
      <Grid item xs={12} md={12} lg={6} sx={{ pr: "32px", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                justifyContent: "flex-start",
              }}
            >
              <PortfolioSvg />


              <Typography
                sx={{
                  color: "var(--neutral-400, #9C9CAB)",
                  fontFeatureSettings: `'ss06' on`,
                  fontFamily: "SF Pro Display",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20.3px",
                }}
              >
                PORTFOLIO VALUE
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "var(--main-colors-black, #0E0637)",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "56px",
                textTransform: 'uppercase'
              }}
            >
              {!totalPortfolioValue ? <LoaderCircular /> : showCurrency === 'aed' || !showCurrency ? showCurrency + ' ' + Numberz(totalPortfolioValue?.AED).toFixed(2) : showCurrency + ' ' + Numberz(totalPortfolioValue?.USD).toFixed(2)}
            </Typography>
          </Box>
          <UpDownShowComponent sign={portfolioChanges?.sign} diff={portfolioChanges?.diff} />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        sx={{
          px: "32px",
          width: "100%",
          borderLeft: "solid 1px var(--neutral-200, #E3E3E8)",
          "@media (max-width:1200px)": {
            borderLeft: "none",
            paddingLeft: "0!important"
          }
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              justifyContent: "flex-start",
              width: '100%'
            }}
          >
            <VolumeSvg />

            <Typography
              sx={{
                color: "var(--neutral-400, #9C9CAB)",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20.3px",
              }}
            >
              VOLUME (24H)
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'space-between',
            width: '100%',
          }}>
            <Typography
              sx={{
                color: "var(--main-colors-black, #0E0637)",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "56px",
                textTransform: 'uppercase'
              }}
            >
              USD {Number(volume?.usd).toFixed(2)}
            </Typography>
            <Typography
              sx={{
                color: "var(--main-colors-black, #0E0637)",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "56px",
                textTransform: 'uppercase'
              }}
            >
              AED {Number(volume?.aed).toFixed(2)}
            </Typography>
          </Box>

        </Box>
      </Grid>
    </Grid>
  );
}