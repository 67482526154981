import { Box } from "@mui/material";
import React, { useState } from "react";
import { AuthPRight, CopyToClipboardButton, Header5 } from "../../components/Helpers/helperComponents";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { PostHandler } from "../../Redux/RequestHandlers";

import AlertDialog from "../../components/Dialog";
import config from "../../config/config";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { hasAccess, joinStringAndConvertLowerCase } from "../../utilities";
const SmartLinkList = ({ item }) => {
  const { accessLists } = useSelector(state => state.auth)

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = async (id) => {
    try {
      setLoading(true)
      const req = {
        url: "removeSmartLink",
        action: "ACTION_GET_RETURNED_SMARTLINK",
        payload: {
          sid: id,
        },
      };
      await dispatch(PostHandler(req));
      setLoading(false)
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      key={item?.id}
      sx={{
        mt: "15px",
        display: "flex",
        width: "100%",
        p: "16px",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "8px",
        border: "1px solid var(--neutral-200, #E3E3E8)",
        background: "var(--main-colors-white, #FFF)",
      }}
    >
      <Box sx={{
        maxWidth: '93%'
      }}>
        <Header5
          text={item?.currency + " " + item?.amount}
          fs="16px"
          lh="22px"
          mt="5px"
        />
        <Header5 text={`Code:- ${item?.code}`} fs="16px" mt="0" />

        <AuthPRight
          text={`Expire At: ${moment(item?.expiryAt).format('llll')}`}
          fs="14px"
          lh="22px"
          mt="0px"
        />
      </Box>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
      }}>
        {item?.merchantDetail?.businessname && <CopyToClipboardButton content={`${config.APP_URL}smartlink/${joinStringAndConvertLowerCase(item?.merchantDetail?.businessname)}/${item?.code}/`} background="#fff" />}

        {hasAccess(["Smartlink-delete"], accessLists) &&
          <AlertDialog
            customComponent={
              <DeleteOutlineIcon sx={{
                mt: '6px',
                color: 'red',
                borderRadius: "5px",
                fontSize: "20px !important",
                cursor: "pointer",
              }} />
            }
            title={"Are sure want to delete"}
            execute={() => handleDelete(item?.id)}
            executeLoading={loading}
          />
        }

      </Box>

    </Box>
  );
};

export default SmartLinkList;
