import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Img1 from "../../../assets/img/home/ComponentFour/3.png";
import Img2 from "../../../assets/img/home/ComponentFour/4.png";
import Img3 from "../../../assets/img/home/ComponentFour/5.png";
import Img4 from "../../../assets/img/home/ComponentFour/6.png";
import Img5 from "../../../assets/img/home/ComponentFour/7.png";
import Img6 from "../../../assets/img/home/ComponentFour/8.png";
import { DescriptionComponent } from "../../../components/Helpers/helperComponents";
export default function ComponentFour() {
  const carouselStyle = {
    height: "200px",
    "@media (max-width:1200px)": { width: "100px!important" }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isDesktop = windowWidth <= 1200;
  return (
    <div id="componentFour">
      <Box
        sx={{
          mt: "107px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "544px",
            "@media and (max-width:600px)": { maxWidth: "300px" },
          }}
        >
          <Typography
            sx={{
              color: "var(--main-colors-primary, #5235E8)",
              textAlign: "center",
              fontFeatureSettings: " 'ss06' on",
              fontFamily: "SF Pro Display",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "19.6px",
              textTransform: "uppercase",
              letterSpacing: "0.56px",
              mt: "100px",
            }}
          >
            Features
          </Typography>
          <Typography
            sx={{
              color: "var(--main-colors-black, #0E0637)",
              textAlign: "center",
              fontFeatureSettings: " 'ss06' on",
              fontFamily: "Poppins",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "56px",
              letterSpacing: "-0.4px",
              mt: "16px",
              "@media (max-width:800px)": {
                px: "20px!important",
                fontSize: "2.075rem",
                lineHeight: "50px",
              },
            }}
          >
            Major Digital Assets Supported
          </Typography>

          <DescriptionComponent
            text="Seamlessly transact with confidence - our platform supports a diverse range of major digital assets, ensuring flexibility and accessibility for all users."
            fs="16px"
            ta="center"
            mt="16px"
            fw="400"
          />
          
        </Box>
        <Box
          sx={{
            mt: "80px",
            p: "0px",
            "@media (max-width:800px)": {
              px: "40px!important",
              mt: "30px!important",
            },
            mb: "48px",
          }}
        >
          <Box
            sx={{
              borderRadius: "1000px",
              background:
                "linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)",
              padding: "28px 80px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              gap: "64px",
              "@media (max-width:1200px)": { width: "100px" },
            }}
          >
            {isDesktop ? <>
              <Carousel
                style={carouselStyle}
                infiniteLoop
                showStatus={false}
                showArrows={false}
                useKeyboardArrows
                autoPlay
                showThumbs={false}
              >
                <Box
                  component="img"
                  src={Img1}
                  sx={{ height: "auto", width: "80px!important" }}
                />
                <Box
                  component="img"
                  src={Img2}
                  sx={{ height: "auto", width: "80px!important" }}
                />
                <Box
                  component="img"
                  src={Img3}
                  sx={{ height: "auto", width: "80px!important" }}
                />
                <Box
                  component="img"
                  src={Img4}
                  sx={{ height: "auto", width: "80px!important" }}
                />
                <Box
                  component="img"
                  src={Img5}
                  sx={{ height: "auto", width: "80px!important" }}
                />
                <Box
                  component="img"
                  src={Img6}
                  sx={{ height: "auto", width: "80px!important" }}
                />
              </Carousel></> : <><Box
                component="img"
                src={Img1}
                sx={{ height: "auto", width: "80px!important" }}
              />
              <Box
                component="img"
                src={Img2}
                sx={{ height: "auto", width: "80px!important" }}
              />
              <Box
                component="img"
                src={Img3}
                sx={{ height: "auto", width: "80px!important" }}
              />
              <Box
                component="img"
                src={Img4}
                sx={{ height: "auto", width: "80px!important" }}
              />
              <Box
                component="img"
                src={Img5}
                sx={{ height: "auto", width: "80px!important" }}
              />
              <Box
                component="img"
                src={Img6}
                sx={{ height: "auto", width: "80px!important" }}
              /></>}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
