import * as React from "react";
import { styled } from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";

// const theme = createTheme();

const BorderLinearProgress = styled(LinearProgress)({
  height: 10,
  width: "100px",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#495ECA",
  },
});

export default function SliderTableWalletComponent({ percent }) {
  return <BorderLinearProgress variant="determinate" value={percent} />;
}
