import React from "react";
import { Box } from "@mui/material";
import LogoImg from "../../assets/img/logo2.png";
import POSStepper from "./posStepper";

export default function POSSelect() {
  return (
    <Box
      sx={{
        p: "30px",
        "@media (max-width:1000px)": { p: "30px" },
      }}
    >
      <Box
        component="img"
        src={LogoImg}
        sx={{ height: "40px", width: "auto" }}
      />

      <POSStepper />
    </Box>
  );
}
