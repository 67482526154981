import React, { useEffect, useState } from "react";
import { Box, Typography, Select, MenuItem } from "@mui/material";
import { InputLabels } from "../../../components/Helpers/label";
import { useDispatch } from "react-redux";
import { PostHandler } from "../../../Redux/RequestHandlers";
import { ButtonLoader, PrimaryButton } from "../../../components/Buttons";
import {
  PasswordValidation,
  validEmail,
} from "../../../Validation/formValidations";
import { PrimaryInputComponents } from "../../../components/Style";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
export default function AddUser(props) {
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isDisable, setIsDisable] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();
  const { num, lc, uc, sp, len } = PasswordValidation(inputs.password || "");
  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "merchantUsers",
        action: "ACTION_GET_USERS_RETURNED",
        payload: inputs,
      };
      const res = await dispatch(PostHandler(req));
      setIsLoading(false);
      if (!res?.data?.success) {
        setErrors((prev) => ({ validEmail: true }));
        setErrMsg(res?.data?.message[0]?.message);
      }
      if (res?.data?.success) {
        setInputs({});
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisable(
      validEmail(inputs?.email) &&
        inputs?.password &&
        !errors?.criteria &&
        !errors?.matchPass
        ? false
        : true
    );
  }, [errors?.criteria, errors?.matchPass, inputs]);

  useEffect(() => {
    if (inputs?.email) {
      let vEmail = validEmail(inputs?.email);
      setErrors((prev) => ({ ...prev, validEmail: !vEmail }));
      setErrMsg(vEmail ? "" : "invalid Email");
    }
  }, [inputs?.email]);

  useEffect(() => {
    if (inputs.password?.length > 0)
      setErrors((prev) => ({
        ...prev,
        criteria: num && lc && uc && sp && len ? false : true,
      }));
  }, [inputs?.password, num, lc, uc, len, sp]);

  return (
    <>
      <Box sx={{ p: "15px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
            <InputLabels label="Email" mt="0px" />
            <PrimaryInputComponents
              error={errors?.validEmail}
              id="email"
              name="email"
              onChange={handleChange}
              value={inputs?.email || ""}
              placeholder="-"
            />
            {errors?.validEmail && (
              <Box
                sx={{
                  fontSize: "12px",
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  fontFamily: "SF Pro Display",
                  flexWrap: "wrap",
                  color: "red",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <HighlightOffIcon fontSize="14px" sx={{ color: "red" }} />
                  {errMsg}
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
            <InputLabels label="Password" mt="0px" />
            <PrimaryInputComponents
              error={errors?.criteria}
              id="password"
              type="password"
              name="password"
              onChange={handleChange}
              value={inputs?.password || ""}
              placeholder="-"
            />
            <Box
              sx={{
                fontSize: "12px",
                display: "flex",
                gap: "5px",
                alignItems: "center",
                fontFamily: "SF Pro Display",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {len ? (
                  <CheckCircleOutline fontSize="14px" sx={{ color: "green" }} />
                ) : (
                  <HighlightOffIcon fontSize="14px" sx={{ color: "red" }} />
                )}
                8 Characters
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {uc ? (
                  <CheckCircleOutline fontSize="14px" sx={{ color: "green" }} />
                ) : (
                  <HighlightOffIcon fontSize="14px" sx={{ color: "red" }} />
                )}
                1 Uppercase
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {lc ? (
                  <CheckCircleOutline fontSize="14px" sx={{ color: "green" }} />
                ) : (
                  <HighlightOffIcon fontSize="14px" sx={{ color: "red" }} />
                )}
                1 Lowercase
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {sp ? (
                  <CheckCircleOutline fontSize="14px" sx={{ color: "green" }} />
                ) : (
                  <HighlightOffIcon fontSize="14px" sx={{ color: "red" }} />
                )}
                1 Special Character
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {num ? (
                  <CheckCircleOutline fontSize="14px" sx={{ color: "green" }} />
                ) : (
                  <HighlightOffIcon fontSize="14px" sx={{ color: "red" }} />
                )}
                1 Number
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
            <InputLabels label="Role" />
            <Select
              labelId="demo-simple-select-label"
              id="selectRoleA"
              onChange={handleChange}
              name="roleid"
              value={inputs?.roleid || "-"}
              sx={{
                height: "48px",
                width: "100%",
                "&:hover fieldset": {
                  border: "1px solid #495ECA!important",
                },
                "&:focus-within fieldset, &:focus-visible fieldset": {
                  border: "1px solid #495ECA!important",
                  boxShadow: "0 0 0 2px #495eca6b",
                },
              }}
            >
              <MenuItem key={324} value="-">
                <Typography sx={{ color: "grey" }}>-</Typography>
              </MenuItem>

              {props?.roles?.map((item) => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            mt: "20px",
            width: "100%",
          }}
        >
          {isLoading ? (
            <ButtonLoader />
          ) : (
            <PrimaryButton
              title="Create User"
              onClick={handleSubmit}
              disabled={isDisable}
              maxWidth="150px"
            />
          )}
        </Box>
      </Box>
    </>
  );
}
