import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import NotificationMenu from "./notification";
import Tooltip from "@mui/material/Tooltip";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { MainListItems, SettingList } from "./listItems";
import Avatar from "@mui/material/Avatar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo2.png";
import CurrencyBtn from "./CurrencyBtn";
import AlertDialog from "../Dialog";

const drawerWidth = 250;
const settings = ["Profile", "Wallet", "Dashboard"];

function DashboardDrawer() {
  // const { register, kyc, accountCreation, fundDeposit } = useSelector(state => state?.dashboard)
  // const { role } = useSelector(state => state?.auth)
  const navigate = useNavigate();
  const handleNavigation = (setting) => {
    // Navigate to the appropriate route based on the clicked setting
    if (setting === "Profile")
      return navigate("/settings/profile", { replace: true });
    if (setting === "Wallet")
      return navigate("/wallet/", { replace: true });
    if (setting === "Dashboard")
      return navigate("/dashboard/", { replace: true });
  };
  const { pathname } = useLocation();

  const handleMainLogoClick = () => {
    navigate("/");
  };
  const [mobileOpen, setMobileOpen] = React.useState();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>

      <AppBar position="fixed" component="nav" sx={{
        background: "white",
        boxShadow: "none!important", borderBottom: "solid 1px var(--neutral-200, #E3E3E8)"
      }}>
        <Toolbar
          sx={{
            // p: "20px 15px 24px",
            height: '80px',
            backgroundColor: "white",
            display: "flex",
            alignItems: "center"
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              display: { xs: "block", sm: "block", lg: "none" },
              color: "black!important",
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            sx={{
              color: "var(--neutral-900, #131316)",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "36px",
              letterSpacing: "-0.24px",
              ml: "308px"
              ,
              "@media (max-width:900px)": { ml: 0 }
            }}
          >
            Dashboard
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%", gap: "24px" }}
          >
            <CurrencyBtn />
            <NotificationMenu />

            {/* Settings */}
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar sx={{ color: "#fff", background: "#ADA3F5", height: "40px!important" }} alt="Remy Sharp" />
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings?.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => handleNavigation(setting)}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}

                <AlertDialog
                  typoTitle="Log out"
                  title="Sure you want to logout?"
                  content="Are you sure you want to log out?"
                  execute={() => navigate("/login/", { replace: true })}
                />
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          open={mobileOpen}
          variant="temporary"
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              px: [1],
            }}
            onClick={handleDrawerToggle}
          >
            <Box
              component="img"
              sx={{
                height: "38px",
              }}
              alt="The house from the offer."
              style={{ cursor: "pointer" }}
              onClick={handleMainLogoClick}
              src={Logo}
            />
          </Toolbar>
          <Divider />
          {/settings/.test(pathname) ? <SettingList /> : <MainListItems />}

          {/* <List component="nav">{<MainListItems />}</List> */}
        </Drawer>
      </Box>
    </Box>
  );
}

DashboardDrawer.propTypes = {
  window: PropTypes.func,
};

export default DashboardDrawer;
