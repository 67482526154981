import React from "react";
import { Box, Typography } from "@mui/material";
import BasicTableWithBody, {
  TableDataLoad,
  StyledTableRow,
  StyledTableCell,
  NoRecordInTable,
} from "../../../components/Tables";
import moment from "moment";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import { Numberz } from "../../../utilities";
// import { Link } from "react-router-dom";
export default function ReferralTable({
  data,
  isFetching,
  totalPages,
  setPage,
  page,
}) {
  return (
    <Box
      sx={{
        py: "23px",
        borderTop: "1px solid var(--neutral-200, #E3E3E8)",
        // borderBottom: "1px solid var(--neutral-200, #E3E3E8)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          mb: "15px",
        }}
      >
        <Typography
          sx={{
            color: "var(--neutral-900, #131316)",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "27.9px",
          }}
        >
          My Referrals
        </Typography>
      </Box>
      <BasicTableWithBody
        tableHead={["Bussiness Name", "volume", "iskyc", "Created At"]}
        tableBody={
          <>
            {isFetching ? (
              <TableDataLoad />
            ) : data?.length > 0 ? (
              data?.map((row) => (
                <StyledTableRow key={row?.id}>
                  <StyledTableCell component="th" scope="row">
                    {row?.businessname}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    <Box>
                      AED:{" "}
                      {row?.merchant?.volume &&
                        Numberz(JSON.parse(row?.merchant?.volume)?.AED)}
                    </Box>
                    USD:{" "}
                    {row?.merchant?.volume &&
                      Numberz(JSON.parse(row?.merchant?.volume)?.USD)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row?.iskyc ? (
                      <Box>
                        <DoneAllIcon color="success" />
                      </Box>
                    ) : (
                      <Box>
                        <CloseIcon color="error" />
                      </Box>
                    )}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {moment(row?.createdAt).format("llll")}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <NoRecordInTable colSpan="7" />
            )}
          </>
        }
        pages={totalPages}
        page={page}
        setPage={setPage}
        //   recordLimit={
        //     <LimitBox onChange={handleFilterChange} limit={filters?.limit} />
        //   }
      />
    </Box>
  );
}
