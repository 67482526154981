import React, { useState, useEffect } from "react";
import BasicTableWithBody, {
  NoRecordInTable,
  StyledTableCell,
  StyledTableRow,
  TableDataLoad,
} from "../../components/Tables";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";
import moment from "moment";
import StatusShow from "../../components/Tables/StatusShow";
import { ButtonLoader, PrimaryButton2 } from "../../components/Buttons";
import { getTotalPage, hasAccess } from "../../utilities";

import { Box, Grid } from "@mui/material";
import { AuthPRight, Header5 } from "../../components/Helpers/helperComponents";
import KYCBLOCK from "../../components/Kyc";
import BankAccountInfo, {
  WalletInfo,
} from "../../components/Kyc/BankAccountInfo";
import WithdrawalInvoice from "./WithdrawalInvoice";

const WithdrawalRequests = () => {
  const { accessLists } = useSelector((state) => state.auth);
  const [totalPages, setTotalPages] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [page, setPage] = useState(1);
  const perPage = 10;
  const { withdrawalRequestList } = useSelector((state) => state?.transactions);
  const dispatch = useDispatch();

  const init = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "withdrawalRequest",
        action: "ACTION_GET_WR",
        params: { pageNumber: page },
      };

      const res = await dispatch(GetHandler(req));
      if (res?.data?.data?.withdrawalRequests?.count)
        setTotalPages(
          parseInt(
            getTotalPage(res?.data?.data?.withdrawalRequests?.count, perPage)
          )
        );
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const newWithdrawalRequest = async () => {
    try {
      setIsSubmitting(true);
      const req = {
        url: "withdrawalRequest",
        action: "ACTION_GET_WR_RETURNED",
        payload: {
          type: withdrawalRequestList?.userDetails?.kycdetails?.tron_address
            ? "crypto"
            : "bank",
        },
      };

      await dispatch(PostHandler(req));
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  return (
    <Box
      sx={{
        display: "flex",
        p: "20px",
      }}
    >
      <Grid container>
        <Grid item lg="3">
          <Box
            sx={{
              pt: "32px",
              mb: "15px",
            }}
          >
            <Header5 text="Withdrawal Request" fs="16px" />

            <AuthPRight
              text="Initiate withdrawal request we will send you the amount."
              fs="16px"
              lh="22px"
              mt="10px"
            />
          </Box>
        </Grid>
        <Grid item xs="12" lg="9">
          {withdrawalRequestList?.userDetails?.kycdetails?.status !==
          "Completed" ? (
            <Box
              sx={{
                p: {
                  xs: "15px",
                  md: "30px",
                },
                display: "grid",
                placeContent: "center",
                borderRadius: "8px",
                border: "1px solid var(--neutral-200, #E3E3E8)",
                background: "var(--main-colors-white, #FFF)",
              }}
            >
              <KYCBLOCK />
            </Box>
          ) : (
            <Box
              sx={{
                px: "15px",
                border: "1px solid #0001",
                borderRadius: "5px",
              }}
            >
              {hasAccess(["Withdrawalrequest-create"], accessLists) && (
                <>
                  {isSubmitting ? (
                    <ButtonLoader maxWidth="100px" />
                  ) : (
                    <PrimaryButton2
                      title="New Withdrawal Request"
                      my="20px"
                      onClick={newWithdrawalRequest}
                    />
                  )}
                </>
              )}

              {withdrawalRequestList?.userDetails?.kycdetails?.tron_address ? (
                <WalletInfo
                  eth={
                    withdrawalRequestList?.userDetails?.kycdetails
                      ?.ethereum_address
                  }
                  tron={
                    withdrawalRequestList?.userDetails?.kycdetails?.tron_address
                  }
                  bnb={
                    withdrawalRequestList?.userDetails?.kycdetails
                      ?.binance_address
                  }
                />
              ) : (
                <BankAccountInfo
                  bankName={
                    withdrawalRequestList?.userDetails?.kycdetails?.bankname
                  }
                  name={
                    withdrawalRequestList?.userDetails?.kycdetails?.fullnamebank
                  }
                  accountNo={
                    withdrawalRequestList?.userDetails?.kycdetails
                      ?.accountnumber
                  }
                  ifsc={withdrawalRequestList?.userDetails?.kycdetails?.ifsc}
                />
              )}

              <Box sx={{ mt: "20px" }}>
                <BasicTableWithBody
                  minWid="100%"
                  tableHead={["Created At", "Status", "Invoice"]}
                  tableBody={
                    <>
                      {isFetching ? (
                        <TableDataLoad />
                      ) : withdrawalRequestList?.withdrawalRequests?.rows
                          ?.length > 0 ? (
                        withdrawalRequestList?.withdrawalRequests?.rows?.map(
                          (row) => (
                            <StyledTableRow key={row?.id}>
                              <StyledTableCell component="th" scope="row">
                                {moment(row.createdAt).format("llll")}
                              </StyledTableCell>
                              {/* <StyledTableCell align="left">${row.amount}</StyledTableCell> */}
                              <StyledTableCell align="left">
                                <StatusShow status={row?.status} />
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                {row?.status === "Completed" && (
                                  <WithdrawalInvoice data={row} />
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          )
                        )
                      ) : (
                        <NoRecordInTable colSpan="7" />
                      )}
                    </>
                  }
                  pages={totalPages}
                  page={page}
                  setPage={setPage}
                />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default WithdrawalRequests;
