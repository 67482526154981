const otherConfig = {
  per_page: 10,
  max_file_upload: 6,
  max_video_upload_size: 200 * 1024 * 1024,
  env: process.env.NODE_ENV === "production" ? "prod" : "local",
}
// production app-service
const config = {
  API_URL: "https://gafah-user-main.azurewebsites.net/api/", //main
  USER_API_URL: "https://gafah-user-main.azurewebsites.net/api/", //main
  BLOCKCHAIN_API_URL: "https://api.blockchain-app.gafah.com/api/", //main
  EXTERNAL_API_URL: "https://gafah-external-wallet.azurewebsites.net/api/", //main
  USER_SOCKET_URL: "https://gafah-user-kafka.azurewebsites.net/", //main
  USER_SOCKET_PATH: "", //main
  EXTERNAL_SOCKET_URL: "https://gafah-external-confirmation.azurewebsites.net/",
  EXTERNAL_SOCKET_PATH: "", //main
  APP_URL: "https://www.gafah.com/",
  SIGN_UP_URL: "https://www.gafah.com/signup",
  ...otherConfig
}

// production app-service
// const config = {
//   API_URL: process.env.REACT_APP_API_URL || "https://gafah-user-main.azurewebsites.net/api/",
//   USER_API_URL: process.env.REACT_APP_USER_API_URL || "https://gafah-user-main.azurewebsites.net/api/", //main
//   BLOCKCHAIN_API_URL: process.env.REACT_APP_BLOCKCHAIN_API_URL || "https://api.blockchain-app.gafah.com/api/", //main
//   EXTERNAL_API_URL: process.env.REACT_APP_EXTERNAL_API_URL || "https://gafah-external-wallet.azurewebsites.net/api/", //main
//   USER_SOCKET_URL: process.env.REACT_APP_USER_SOCKET_URL || "https://gafah-user-kafka.azurewebsites.net/", //main
//   USER_SOCKET_PATH: process.env.REACT_APP_USER_SOCKET_PATH || "", //main
//   EXTERNAL_SOCKET_URL: process.env.REACT_APP_EXTERNAL_SOCKET_URL || "https://gafah-external-confirmation.azurewebsites.net/",
//   EXTERNAL_SOCKET_PATH: process.env.REACT_APP_EXTERNAL_SOCKET_PATH || "", //main
//   APP_URL: process.env.REACT_APP_APP_URL || "https://www.gafah.com/",
//   SIGN_UP_URL: process.env.REACT_APP_SIGN_UP_URL || "https://www.gafah.com/signup",
//   ...otherConfig
// }

export default config


export const paymentConfig = {
  usdToAedPrice: Number(3.6725),
  fiatDecimals: Number(4),
  cryptoDecimals: Number(6),
  vatPercent: Number(5)
};