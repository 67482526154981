import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { AuthPRight, Header5 } from "../../components/Helpers/helperComponents";
import { ButtonLoader, PrimaryButton2 } from "../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";
import KYCBLOCK from "../../components/Kyc";
import { hasAccess } from "../../utilities";

export default function ExternalCurrency() {
  const { accessLists } = useSelector((state) => state.auth);

  const { external } = useSelector((state) => state?.apikey);
  const { kycStatus } = useSelector((state) => state?.dashboard);
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputs, setInputs] = useState([]);

  const handleChange = (id) => {
    if (inputs.includes(id)) {
      let temp = inputs;
      const idToRemove = id;
      const filteredArray = temp.filter((item) => item !== idToRemove);
      return setInputs(filteredArray);
    }
    setInputs((prev) => [...prev, id]);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const req = {
        url: "externalCurrency",
        action: "ACTION_GET_RETURNED_EXTERNAL",
        payload: {
          choices: inputs,
        },
      };
      await dispatch(PostHandler(req));

      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  // api calls
  const init = async () => {
    try {
      setIsFetching(true);
      let req = {
        url: "externalCurrency",
        action: "ACTION_GET_EXTERNAL",
      };
      await dispatch(GetHandler(req));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    kycStatus?.status === "Completed" && init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycStatus]);

  useEffect(() => {
    if (external?.choices?.length > 0) setInputs([...external?.choices]);
  }, [external?.choices]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          p: "20px",
        }}
      >
        <Grid container>
          <Grid item lg="3">
            <Box
              sx={{
                pt: "32px",
                mb: "15px",
              }}
            >
              <Header5 text="External Currency" fs="16px" />

              <AuthPRight
                text="Select what you want to recieve as external payment currencies."
                fs="16px"
                lh="22px"
                mt="10px"
              />
            </Box>
          </Grid>
          <Grid item xs="12" lg="9">
            {kycStatus?.status !== "Completed" ? (
              <Box
                sx={{
                  p: {
                    xs: "15px",
                    md: "30px",
                  },
                  display: "grid",
                  placeContent: "center",
                  borderRadius: "8px",
                  border: "1px solid var(--neutral-200, #E3E3E8)",
                  background: "var(--main-colors-white, #FFF)",
                }}
              >
                <KYCBLOCK />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    p: "20px",
                    borderRadius: "8px",
                    border: "1px solid var(--neutral-200, #E3E3E8)",
                    background: "var(--main-colors-white, #FFF)",
                    mt: "10px",
                  }}
                >
                  {/* {choices?.} */}
                  {isFetching ? (
                    <>
                      <CircularProgress />
                    </>
                  ) : (
                    external?.tokens?.length > 0 &&
                    external?.tokens?.map((item, index) => (
                      <Box key={item?.id + index}>
                        <Box
                          sx={{
                            borderTop: "1px solid var(--neutral-200, #E3E3E8)",
                          }}
                        >
                          <Box
                            sx={{
                              py: "16px",
                              borderBottom:
                                "1px solid var(--neutral-200, #E3E3E8)",
                              display: "flex",
                              gap: "12px",
                              alignItems: "center",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <input
                                  type="checkbox"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  value={true}
                                  onChange={() => handleChange(item?.id)}
                                  checked={inputs.includes(item?.id)}
                                />
                              }
                              sx={{
                                p: "5px",
                                borderRadius: "5px",
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                            />
                            <Box
                              sx={{
                                width: "40px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={item?.logo}
                                alt={item?.logo}
                                height="35px"
                                //   style={{ minWidth: "25px" }}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                                alignItems: "flex-start",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "8px",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "var(--neutral-900, #131316)",
                                    fontFeatureSettings: `'ss06' on`,
                                    fontFamily: "SF Pro Display",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "27.9px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item?.token}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "var(--neutral-400, #9C9CAB)",
                                    textAlign: "center",
                                    fontFeatureSettings: `'ss06' on`,
                                    fontFamily: "SF Pro Display",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "16.2px",
                                    letterSpacing: "0.06px",
                                  }}
                                >
                                  {item?.network}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))
                  )}

                  {hasAccess(["Externalcurrency-update"], accessLists) && (
                    <>
                      {isSubmitting ? (
                        <ButtonLoader maxWidth="100px" />
                      ) : (
                        <PrimaryButton2
                          title="Save"
                          my="20px"
                          onClick={handleSubmit}
                        />
                      )}
                    </>
                  )}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
