import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AuthPRight,
  Header5,
  LoaderCircular,
} from "../../components/Helpers/helperComponents";
import { PrimaryButton2 } from "../../components/Buttons";
import { InputLabels } from "../../components/Helpers/label";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";
import ApiKeyList from "./ApiKeyList";
import PosMachineApiKeys from "./PosMachineApiKeys";
import KYCBLOCK from "../../components/Kyc";
import { hasAccess } from "../../utilities";
const APIKeyLists = () => {
  const { ecommerce, pos } = useSelector((state) => state?.apikey);
  const { kycStatus } = useSelector((state) => state?.dashboard);
  const { accessLists } = useSelector((state) => state.auth);
  const [componentShow, setComponentShow] = useState("");
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const init = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "apiKey",
        action: "ACTION_GET_APIKEY",
      };
      await dispatch(GetHandler(req));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const req = {
        url: "apiKey",
        action: "ACTION_GET_ECCOMERCE_APIKEY",
        payload: inputs,
      };
      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) {
        setInputs({});
        setComponentShow("");
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisableSubmit(inputs?.keyname ? false : true);
  }, [inputs?.keyname]);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDisableUpdate, setIsDisableUpdate] = useState(true);
  const [updateInputs, setUpdateInputs] = useState({});
  const [updateData, setUpdateData] = useState({});
  // const handleUpdateChange = (e) =>
  // setUpdateInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  const handleUpdate = async () => {
    try {
      setIsUpdating(true);
      const req = {
        url: "updateApiKeyCallback",
        payload: { ...updateInputs, apiKeyId: updateData?.id },
      };
      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) {
        setUpdateData({});
        setUpdateInputs({});
        setComponentShow("");
        init();
      }
      setIsUpdating(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setIsDisableUpdate(updateInputs?.callbackurl ? false : true);
  }, [updateInputs?.callbackurl]);

  useEffect(() => {
    setUpdateInputs((prev) => ({
      ...prev,
      callbackurl: updateData?.callbackurl,
    }));
  }, [updateData]);
  useEffect(() => {
    if (kycStatus?.status === "Completed") init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycStatus]);

  return (
    <Box
      sx={{
        display: "flex",
        p: "20px",
      }}
    >
      <Grid container>
        <Grid item lg="3">
          <Box
            sx={{
              pt: "32px",
              mb: "15px",
            }}
          >
            <Header5 text="API Keys" fs="16px" />

            <AuthPRight
              text="API Keys let you use Gafah from within other tools or your own software."
              fs="16px"
              lh="22px"
              mt="10px"
            />
          </Box>
        </Grid>
        <Grid item xs="12" lg="9">
          {kycStatus?.status !== "Completed" ? (
            <Box
              sx={{
                p: {
                  xs: "15px",
                  md: "30px",
                },
                display: "grid",
                placeContent: "center",
                borderRadius: "8px",
                border: "1px solid var(--neutral-200, #E3E3E8)",
                background: "var(--main-colors-white, #FFF)",
              }}
            >
              <KYCBLOCK />
            </Box>
          ) : (
            <>
              {/* add apikey */}
              {componentShow === "add" && (
                <Box
                  sx={{
                    mb: "15px",
                    p: "20px",
                    borderRadius: "8px",
                    border: "1px solid var(--neutral-200, #E3E3E8)",
                    background: "var(--main-colors-white, #FFF)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Header5 text="Add New API Keys" fs="16px" mt="0px" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                      },
                    }}
                  >
                    <Grid item xs="12" lg="5">
                      <InputLabels label="Name" />
                      <TextField
                        id="keyname"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        sx={{
                          width: "100%",
                          mt: "5px",
                          "&.Mui-focused fieldset": {
                            borderColor: "#C52328",
                            borderWidth: "2px",
                          },
                        }}
                        name="keyname"
                        onChange={handleChange}
                        value={inputs?.keyname || ""}
                        placeholder="Enter API key name"
                        required
                      />
                    </Grid>
                    {/* <Grid item xs="12" lg="7">
                      <InputLabels label="Callback Url (optional)" />
                      <TextField
                        id="callbackurl"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        sx={{ width: "100%", mt: "5px" }}
                        name="callbackurl"
                        onChange={handleChange}
                        value={inputs?.callbackurl || ""}
                        placeholder="Enter callback url"
                        required
                      />
                    </Grid> */}
                  </Box>
                  <Box sx={{ display: "flex", gap: "10px", mt: "20px" }}>
                    {isSubmitting ? (
                      <Button variant="contained" title="submit" disabled>
                        <CircularProgress size="1.7rem" />
                      </Button>
                    ) : (
                      <PrimaryButton2
                        variant="contained"
                        title="Submit"
                        onClick={handleSubmit}
                        disabled={isDisableSubmit}
                      />
                    )}

                    <Button onClick={() => setComponentShow("")}>Cancel</Button>
                  </Box>
                </Box>
              )}

              {/* update apikey */}
              {componentShow === "update" && (
                <Box
                  sx={{
                    mb: "15px",
                    p: "20px",
                    borderRadius: "8px",
                    border: "1px solid var(--neutral-200, #E3E3E8)",
                    background: "var(--main-colors-white, #FFF)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space",
                      gap: "5px",
                    }}
                  >
                    <Header5 text="Update API Keys" fs="16px" mt="0px" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                      },
                    }}
                  >
                    <Grid item xs="12" lg="5">
                      <InputLabels label="Name" />
                      <TextField
                        id="keynamex"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        sx={{ width: "100%", mt: "5px" }}
                        placeholder="API key name"
                        value={updateData?.name}
                        disabled
                      />
                    </Grid>

                    {/* <Grid item xs="12" lg="7">
                      <InputLabels label="Callback Url (required)" />
                      <TextField
                        id="callbackurl"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        sx={{ width: "100%", mt: "5px" }}
                        name="callbackurl"
                        onChange={handleUpdateChange}
                        value={updateInputs?.callbackurl || ""}
                        placeholder="Enter callback url"
                        required
                      />
                    </Grid> */}
                  </Box>
                  <Box sx={{ display: "flex", gap: "10px", mt: "20px" }}>
                    {isUpdating ? (
                      <Button variant="contained" title="submit" disabled>
                        <CircularProgress size="1.7rem" />
                      </Button>
                    ) : (
                      <PrimaryButton2
                        variant="contained"
                        title="Update"
                        onClick={handleUpdate}
                        disabled={isDisableUpdate}
                      />
                    )}
                    <Button onClick={() => setComponentShow("")}>Cancel</Button>
                  </Box>
                </Box>
              )}

              {/* ecommerce apis */}
              <Box
                sx={{
                  p: "20px",
                  borderRadius: "8px",
                  border: "1px solid var(--neutral-200, #E3E3E8)",
                  background: "var(--main-colors-white, #FFF)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: "20px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <Header5 text="Your API Keys" fs="16px" mt="0px" />
                    <Typography
                      sx={{
                        color: "#4e9b05",
                        background: "#0001",
                        width: "max-content",
                        padding: "1px 10px",
                        borderRadius: "10px",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      Ecommerce
                    </Typography>
                  </Box>
                  {hasAccess(["Apikey-create"], accessLists) &&
                    componentShow === "" && (
                      <PrimaryButton2
                        onClick={() => setComponentShow("add")}
                        title={<> + New API Key</>}
                      />
                    )}
                </Box>

                {/* Accounts Lists */}
                {isFetching ? (
                  <LoaderCircular />
                ) : ecommerce?.length > 0 ? (
                  ecommerce?.map((item) => (
                    <ApiKeyList
                      item={item}
                      setUpdateData={setUpdateData}
                      setComponentShow={setComponentShow}
                      init={init}
                    />
                  ))
                ) : (
                  <Box
                    key="not found"
                    sx={{
                      mt: "15px",
                      display: "flex",
                      width: "100%",
                      p: "16px",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      borderRadius: "8px",
                      border: "1px solid var(--neutral-200, #E3E3E8)",
                      background: "var(--main-colors-white, #FFF)",
                    }}
                  >
                    <Header5 text="No record found" fs="16px" mt="0" />
                    <AuthPRight
                      text="Please create a api key for transaction"
                      fs="14px"
                      lh="22px"
                      mt="5px"
                    />
                  </Box>
                )}

                {/* END Accounts Lists */}
              </Box>

              {/* pos apis */}
              <Box
                sx={{
                  mt: "25px",
                  p: "20px",
                  borderRadius: "8px",
                  border: "1px solid var(--neutral-200, #E3E3E8)",
                  background: "var(--main-colors-white, #FFF)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      mt: "10px",
                    }}
                  >
                    <Header5 text="Your API Keys" fs="16px" mt="0px" />
                    <Typography
                      sx={{
                        color: "#4e9b05",
                        background: "#0a96761a",
                        width: "max-content",
                        padding: "1px 10px",
                        borderRadius: "10px",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      Pos Machine
                    </Typography>
                  </Box>
                </Box>

                {/* Accounts Lists */}
                {isFetching ? (
                  <LoaderCircular />
                ) : pos?.length > 0 ? (
                  pos?.map((item) => (
                    <PosMachineApiKeys
                      item={item}
                      setUpdateData={setUpdateData}
                      setComponentShow={setComponentShow}
                      init={init}
                    />
                  ))
                ) : (
                  <Box
                    key="not found"
                    sx={{
                      mt: "15px",
                      display: "flex",
                      width: "100%",
                      p: "16px",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      borderRadius: "8px",
                      border: "1px solid var(--neutral-200, #E3E3E8)",
                      background: "var(--main-colors-white, #FFF)",
                    }}
                  >
                    <Header5 text="No record found" fs="16px" mt="0" />
                    <AuthPRight
                      text="No Pos machine api keys found right now."
                      fs="14px"
                      lh="22px"
                      mt="5px"
                    />
                  </Box>
                )}

                {/* END Accounts Lists */}
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default APIKeyLists;
