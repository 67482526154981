import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { KYCCard, ReferralCard } from "./Cards";

export default function RightGridComponentOne() {
  const { totalTransactions, uniqueUserCount, kycStatus, referralCode } =
    useSelector((state) => state?.dashboard);

  return (
    <Box
      sx={{ p: "20px", display: "flex", flexDirection: "column", gap: "20px" }}
    >
      <Box sx={{ paddingTop: "12px" }}>
        <Box
          sx={{
            width: "100%",
            p: "24px",
            borderTop: "1px solid var(--neutral-200, #E3E3E8)",
            borderBottom: "1px solid var(--neutral-200, #E3E3E8)",
            // borderRadius: "10px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="20"
                id="users"
              >
                <g
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  transform="translate(1 1)"
                >
                  <path
                    stroke="#495ECA"
                    d="M16 18v-2a4 4 0 0 0-4-4H4a4 4 0 0 0-4 4v2"
                  ></path>
                  <circle stroke="#495ECA" cx="8" cy="4" r="4"></circle>
                  <path
                    stroke="#000"
                    d="M22 18v-2a4 4 0 0 0-3-3.87M15 .13a4 4 0 0 1 0 7.75"
                  ></path>
                </g>
              </svg>
              <Typography
                sx={{
                  color: "var(--neutral-400, #9C9CAB)",
                  fontFeatureSettings: `'ss06' on`,
                  fontFamily: "SF Pro Display",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20.3px",
                }}
              >
                UNIQUE USER COUNT
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "var(--main-colors-black, #0E0637)",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "56px",
                textTransform: "uppercase",
              }}
            >
              {uniqueUserCount}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "@media (min-width:900px) and (max-width:1600px)": {
            flexDirection: "column",
            justifyContent: "center",
            gap: "20px",
          },
          "@media (max-width:500px)": {
            flexDirection: "column",
            justifyContent: "center",
            gap: "20px",
          },
        }}
      >
        <Box
          sx={{
            width: "48%",
            p: "20px",
            border: "1px solid var(--neutral-200, #E3E3E8)",
            borderRadius: "10px",
            "@media (min-width:900px) and (max-width:1600px)": {
              width: "100%",
            },
            "@media (max-width:500px)": {
              width: "100%",
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="42"
                height="38"
                id="graph"
              >
                <path
                  fill="#495ECA"
                  stroke="#495ECA"
                  d="M39 0c-1.654 0-3 1.346-3 3 0 .888.396 1.679 1.011 2.229l-8.8 14.031A2.972 2.972 0 0 0 27 19c-.868 0-1.644.376-2.193.967l-9.073-5.745c.168-.374.266-.786.266-1.222 0-1.654-1.346-3-3-3s-3 1.346-3 3c0 .904.41 1.706 1.044 2.256L4.149 26.231A2.972 2.972 0 0 0 3 26c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-.888-.395-1.678-1.01-2.228l6.904-10.99c.343.138.715.218 1.106.218.859 0 1.629-.367 2.176-.947l9.078 5.748A2.981 2.981 0 0 0 24 22c0 1.654 1.346 3 3 3s3-1.346 3-3c0-.863-.371-1.636-.957-2.184l8.81-14.046c.354.147.741.23 1.147.23 1.654 0 3-1.346 3-3s-1.346-3-3-3zM5 29c0 1.103-.897 2-2 2s-2-.897-2-2 .897-2 2-2 2 .897 2 2zm8-14c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm14 9c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zM39 5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"
                ></path>
              </svg>
              <Typography
                sx={{
                  color: "var(--neutral-400, #9C9CAB)",
                  fontFeatureSettings: `'ss06' on`,
                  fontFamily: "SF Pro Display",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20.3px",
                }}
              >
                TOTAL TRANSACTIONS
              </Typography>
            </Box>
            <Typography
              sx={{
                color: "var(--main-colors-black, #0E0637)",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "56px",
                textTransform: "uppercase",
              }}
            >
              {totalTransactions !== null ? totalTransactions : 0}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "48%",
            p: "20px",
            border: "1px solid var(--neutral-200, #E3E3E8)",
            borderRadius: "10px",
            "@media (min-width:900px) and (max-width:1600px)": {
              width: "100%",
            },
            "@media (max-width:500px)": {
              width: "100%",
            },
          }}
        >
          {/Completed/.test(kycStatus?.status) && referralCode ? (
            <ReferralCard refCode={referralCode} />
          ) : (
            <KYCCard status={kycStatus?.status} />
          )}
        </Box>
      </Box>
    </Box>
  );
}
