export const ACTION_GET_TICKET = "ACTION_GET_TICKET"
export const ACTION_POST_TICKET_RETURN = "ACTION_POST_TICKET_RETURN"



const initialState = {
    tickets: [],
}

export const ticketReducer = (state = initialState, action) => {
    // const returnObj = { ...state }
    switch (action.type) {
        case ACTION_GET_TICKET:
            return {
                ...state,
                tickets: action?.payload?.data,
            }

        case ACTION_POST_TICKET_RETURN:
            return {
                ...state,
                tickets: action?.payload?.tickets,
            }


        default:
            return state
    }
}

