import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
import ResponsiveAppBar from "../../components/appbar";
import SupportSearchBox from "./components/searchbox";
import SupportComponentOne from "./components/componentOne";
import ComponentEight from "../ContactUs/components/componentThree";

export default function SupportComponent() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/", { replace: true });
  };
  useEffect(() => {
    // Reset the scroll position to the top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main">
      <Box
        sx={{
          margin: "20px 15px 0px",
          background: "black",
          height: "500px",
          color: "white",
          borderRadius: "30px 0px 30px 30px",
          zIndex: 2,
          "@media (max-width:800px)": {
            p: "0!important",
            margin: "10px 8px 0px",
          },
          "@media (max-width:600px)": { height: "400px!important" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 0px 0px 80px ",
            "@media (max-width:800px)": { p: "0!important" },
          }}
        >
          <Box
            onClick={handleClick}
            component="img"
            src={Logo}
            sx={{
              height: "50px",
              mt: "22px",
              "@media (max-width:800px)": {
                pl: "40px!important",
              },
              "@media (max-width:500px)": {
                height: "40px!important",
                mt: "18px",
              },
              cursor: "pointer",
            }}
          />
          <Box
            sx={{
              background: "#FFFFFF",
              color: "black!important",
              maxWidth: "800px",
              maxHeight: "65px",
              borderRadius: "0px 0px 0px 30px!important",

              "@media (max-width:800px)": {
                pl: 0,
              },
              "@media (max-width:500px)": {
                height: "60px!important",
              },
            }}
          >
            <ResponsiveAppBar />
          </Box>
        </Box>
        <Box
          sx={{
            mt: "109px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "48px",
            "@media (max-width:800px)": {
              gap: "20px",
            },
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              fontFeatureSettings: " 'ss06' on",
              fontFamily: "Poppins",
              fontSize: "4.375rem",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "77px",
              letterSpacing: "-0.7px",
              "@media (max-width:800px)": {
                textAlign: "center",
                fontSize: "2.075rem",
                lineHeight: "50px",
              },
            }}
          >
            How can we help?
          </Typography>
          <Box
            sx={{
              width: "700px!important",
              "@media (max-width:800px)": {
                width: "400px!important",
              },
              "@media (max-width:500px)": {
                width: "300px!important",
              },
            }}
          >
            <SupportSearchBox />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          background:
            "linear-gradient(0deg, #F7F6FE -10.28%, #EFEDFD 34.89%, #FFF 57.57%)",
        }}
      >
        <SupportComponentOne  mb="0px"/>
        {/* <SupportComponentTwo /> */}
      </Box>
      <ComponentEight />
    </div>
  );
}
