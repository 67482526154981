import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
// import { stringToArray } from "../Helpers/helperFunctions";
import { DataGrid } from "@mui/x-data-grid";
import AssetTableComponent from "./assetTableComponent";
import ChangePercentTableComponent from "./changePercentTableComponent";
import SliderTableWalletComponent from "./sliderTableComponent";
import { Numberz, getPercentage, tokenHelpers } from "../../../utilities";
import { AuthP } from "../../../components/Helpers/helperComponents";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e3dfdf21",
    color: "#000",
    fontFamily: "SF Pro Display",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "SF Pro Display!important",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function WalletTable(props) {
  const { totalPortfolioValue, showCurrency, balancesData } = useSelector(
    (state) => state?.dashboard
  );

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Asset</StyledTableCell>
              <StyledTableCell align="center">Amount</StyledTableCell>
              <StyledTableCell align="center">
                Price ({showCurrency === "usd" ? "USD" : "AED"})
              </StyledTableCell>
              <StyledTableCell align="center">Volume (24H)</StyledTableCell>
              <StyledTableCell align="center">Allocation</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {balancesData?.balances?.length > 0 ? (
              balancesData?.balances?.map((row) => (
                <StyledTableRow key={row?.id}>
                  <StyledTableCell component="th" scope="row">
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "30px",
                        }}
                      >
                        <Box
                          component="img"
                          src={row?.logo}
                          alt={row?.logo}
                          sx={{
                            minWidth: "30px",
                            maxWidth: "100%",
                            backgroundSize: "contain",
                            // width: "30px",
                            // height: "35px"
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            textTransform: "capitalize",
                            fontFamily: "SF Pro Display",
                          }}
                        >
                          {tokenHelpers[row?.token]}
                        </Typography>
                        <AuthP text={row?.token} fs="14px" mt="0px" />
                      </Box>
                    </Box>
                    {row?.platform}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {Numberz(row?.balance).toFixed(6)} {row?.token}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {showCurrency === "usd"
                      ? Numberz(row?.realTimePrice?.usd * row?.balance).toFixed(
                          2
                        )
                      : Numberz(row?.realTimePrice?.aed * row?.balance).toFixed(
                          2
                        )}
                  </StyledTableCell>
                  <StyledTableCell align="center">{0}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <SliderTableWalletComponent
                        percent={
                          getPercentage(
                            Numberz(Number(totalPortfolioValue?.USD)),
                            Numberz(Number(row?.realTimePrice?.usd)) *
                              Numberz(Number(row?.balance))
                          ).toFixed(2) || 0
                        }
                      />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10}>{/* <NoRecordFound /> */}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const columns = [
  {
    field: "asset",
    headerName: "Asset",
    flex: 1,
    renderCell: (params) => (
      <AssetTableComponent
        icon={params.row.icon}
        cryptoName={params.row.cryptoName}
        cryptoShortName={params.row.cryptoShortName}
      />
    ),
  },
  { field: "amount", headerName: "Amount", flex: 1 },
  { field: "price", headerName: "Price", flex: 1 },
  {
    field: "change",
    headerName: "Change %",
    flex: 1,
    renderCell: (params) => (
      <ChangePercentTableComponent
        changeValue={params.row.value}
        which={params.row.whichChange}
      />
    ),
  },
  { field: "volume", headerName: "Volume (24H)", flex: 1 },
  {
    field: "allocation",
    headerName: "Allocation",
    flex: 1,
    renderCell: (params) => (
      <SliderTableWalletComponent percent={params.row.valueAllocated} />
    ),
  },
];

const rows = [
  {
    id: 1,
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2442_12587)">
          <path
            d="M39.4017 24.8367C36.7295 35.5567 25.8684 42.0732 15.1637 39.4009C4.44342 36.7287 -2.07315 25.8681 0.599197 15.1635C3.27138 4.44354 14.1167 -2.07302 24.837 0.599321C35.5417 3.25588 42.074 14.1165 39.4017 24.8367Z"
            fill="url(#paint0_linear_2442_12587)"
          />
          <path
            d="M29.5225 17.5078C29.9131 14.8516 27.8975 13.4141 25.1162 12.4609L26.0225 8.85156L23.835 8.30469L22.96 11.8203C22.3818 11.6797 21.7881 11.5391 21.1943 11.4141L22.0693 7.88281L19.8818 7.33594L18.9912 10.9297C18.5068 10.8203 18.0381 10.7109 17.585 10.6016V10.5859L14.5537 9.83594L13.9756 12.1797C13.9756 12.1797 15.6006 12.5547 15.5693 12.5703C16.46 12.7891 16.6162 13.3828 16.585 13.8516L15.5537 17.9609C15.6162 17.9766 15.6943 17.9922 15.7881 18.0391C15.71 18.0234 15.6318 18.0078 15.5537 17.9766L14.1162 23.7266C14.0068 23.9922 13.7256 24.3984 13.1162 24.2422C13.1318 24.2734 11.5225 23.8516 11.5225 23.8516L10.4287 26.3672L13.2881 27.0859C13.8193 27.2266 14.335 27.3516 14.8506 27.4922L13.9443 31.1328L16.1318 31.6797L17.0381 28.0703C17.6318 28.2266 18.2256 28.3828 18.7881 28.5234L17.8975 32.1172L20.085 32.6641L20.9912 29.0234C24.7412 29.7266 27.5537 29.4453 28.7256 26.0547C29.6787 23.3359 28.6787 21.7578 26.71 20.7266C28.1631 20.3984 29.2412 19.4453 29.5225 17.5078ZM24.5068 24.5391C23.835 27.2578 19.2412 25.7891 17.7568 25.4141L18.96 20.5859C20.4443 20.9609 25.2256 21.6953 24.5068 24.5391ZM25.1943 17.4609C24.5693 19.9453 20.7568 18.6797 19.5225 18.3672L20.6162 13.9922C21.8506 14.3047 25.835 14.8828 25.1943 17.4609Z"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2442_12587"
            x1="19.9892"
            y1="-0.00947792"
            x2="19.9892"
            y2="39.9961"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F9AA4B" />
            <stop offset="1" stopColor="#F7931A" />
          </linearGradient>
          <clipPath id="clip0_2442_12587">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    cryptoName: "Bitcoin",
    cryptoShortName: "BTC",
    amount: "0.3464 BTC",
    price: "$1,658",
    volume: "$53,260.20",
    value: "0.35",
    whichChange: "increase",
    valueAllocated: "50",
  },
  {
    id: 2,
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="#495ECA" />
        <path
          d="M20.4438 5.0426V5.0568C20.458 5.1278 20.458 5.213 20.458 5.29819V15.5362C20.4438 15.593 20.4012 15.6072 20.3586 15.6356C20.0605 15.7776 19.7765 15.9054 19.4783 16.0332C19.0665 16.2178 18.6405 16.4166 18.2287 16.6012L16.7377 17.2828C16.3259 17.4674 15.9141 17.652 15.5165 17.8365C15.0338 18.0637 14.5368 18.2767 14.054 18.5039C13.6422 18.6885 13.2304 18.8873 12.8044 19.0719C12.4636 19.2281 12.1228 19.3701 11.7962 19.5263C11.7678 19.5405 11.7394 19.5547 11.711 19.5547C11.6968 19.5547 11.6968 19.5547 11.6826 19.5405L12.066 18.9015C12.8044 17.6804 13.5286 16.4734 14.267 15.2522C15.048 13.9458 15.8431 12.6395 16.6241 11.3331C17.3483 10.1261 18.0867 8.91912 18.8109 7.71215C19.3363 6.83176 19.8759 5.95138 20.4012 5.071C20.4154 5.0426 20.4296 5.0284 20.4296 5H20.4438C20.4296 5.0142 20.4438 5.0284 20.4438 5.0426Z"
          fill="#F3F3F3"
        />
        <path
          d="M29.1611 19.5406L29.1753 19.5548L27.0879 20.7902L20.5277 24.6667C20.4993 24.6809 20.4709 24.6951 20.4567 24.7093C20.4141 24.7093 20.4141 24.6667 20.4141 24.6525V24.5247V15.7493C20.4141 15.7067 20.4141 15.6499 20.4283 15.6073C20.4425 15.5505 20.4851 15.5647 20.5277 15.5789C20.7123 15.6641 20.9111 15.7493 21.0956 15.8345C21.6494 16.0901 22.2032 16.3456 22.757 16.587C23.2398 16.8 23.7084 17.0272 24.1912 17.2402C24.674 17.4532 25.1568 17.6804 25.6396 17.8934C26.0514 18.078 26.4773 18.2768 26.8891 18.4614C27.3009 18.646 27.7269 18.8448 28.1387 19.0294C28.4653 19.1714 28.7919 19.3276 29.1185 19.4696C29.1185 19.5122 29.1327 19.5264 29.1611 19.5406Z"
          fill="#8A8DA7"
        />
        <path
          d="M20.4429 33.5273C20.4429 33.5415 20.4287 33.5557 20.4287 33.5699H20.4145C20.4145 33.5415 20.3861 33.5273 20.3719 33.4989C19.4915 32.2635 18.6111 31.0139 17.7307 29.7785C16.8361 28.5148 15.9274 27.2368 15.0328 25.973C14.1666 24.7518 13.2862 23.5165 12.42 22.2953C12.1928 21.9687 11.9656 21.6563 11.7384 21.3297C11.7242 21.3013 11.71 21.2871 11.6816 21.2445C11.7242 21.2445 11.7526 21.2729 11.7668 21.2871C13.0022 22.0113 14.2234 22.7355 15.4588 23.4597C16.8787 24.2974 18.2845 25.1352 19.7045 25.973L20.4287 26.399C20.4571 26.4274 20.4571 26.4558 20.4571 26.4842V33.3285C20.4571 33.3995 20.4571 33.4705 20.4429 33.5273Z"
          fill="#F3F3F3"
        />
        <path
          d="M11.667 19.5689V19.5547C12.1214 19.3559 12.5616 19.1429 13.016 18.9441C13.5982 18.6743 14.1803 18.4187 14.7625 18.1489C15.2027 17.9501 15.6571 17.7371 16.0973 17.5383C16.7505 17.2401 17.3895 16.9561 18.0427 16.658C18.4829 16.4592 18.923 16.2604 19.3774 16.0474C19.6898 15.9054 20.0164 15.7634 20.3288 15.6214C20.3572 15.6072 20.3998 15.593 20.414 15.5646C20.4282 15.5646 20.4282 15.5788 20.414 15.593V24.6098C20.414 24.6524 20.3998 24.695 20.4282 24.7234C20.3998 24.766 20.3714 24.7234 20.3572 24.7092C20.2294 24.6382 20.1016 24.5672 19.9738 24.482C17.2333 22.8632 14.4785 21.2303 11.738 19.6115C11.7238 19.5973 11.6954 19.5831 11.667 19.5689Z"
          fill="#C5C8D9"
        />
        <path
          d="M29.1331 21.2445H29.1473C29.1473 21.2729 29.1189 21.3013 29.1047 21.3297C26.492 25.0074 23.8792 28.6994 21.2665 32.3771C20.9967 32.7605 20.7127 33.1439 20.4429 33.5273C20.4287 33.5131 20.4287 33.4989 20.4287 33.4847V33.3995V26.5126V26.3848C21.0251 26.0298 21.6073 25.689 22.2037 25.334C24.504 23.9709 26.8044 22.6219 29.0905 21.2587C29.1047 21.2729 29.1189 21.2587 29.1331 21.2445Z"
          fill="#C5C8D9"
        />
        <path
          d="M20.4288 15.593V15.5646V15.4794V5.17036C20.4288 5.12776 20.4146 5.09936 20.443 5.05676C23.3255 9.84207 26.2081 14.6132 29.0764 19.3985C29.1048 19.4411 29.1474 19.4979 29.1616 19.5547C28.9628 19.4837 28.7782 19.3843 28.5936 19.2991C28.3664 19.1997 28.125 19.0861 27.8978 18.9867C27.7558 18.9157 27.5997 18.8589 27.4577 18.7879C27.2163 18.6743 26.9749 18.5749 26.7335 18.4613C26.5915 18.4045 26.4495 18.3335 26.3075 18.2625L25.3703 17.8365C25.2141 17.7655 25.0579 17.6945 24.8875 17.6235L24.2059 17.3253C24.0639 17.2685 23.9219 17.1975 23.7799 17.1265L22.8427 16.7005C22.6865 16.6295 22.5303 16.5585 22.36 16.4875L21.6784 16.1893C21.5222 16.1183 21.3802 16.0474 21.224 15.9764C20.9542 15.8486 20.6844 15.7208 20.4004 15.6072C20.443 15.593 20.4288 15.593 20.4288 15.593Z"
          fill="#C5C8D9"
        />
      </svg>
    ),
    cryptoName: "Ethereum",
    cryptoShortName: "ETH",
    amount: "0.3464 BTC",
    price: "$1,658",
    volume: "$53,260.20",
    value: "0.35",
  },
  {
    id: 3,
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="#0E0637" />
        <path
          d="M28.2485 8.33337H31.6209L24.6027 16.7246C22.0611 19.7618 17.9404 19.7618 15.3969 16.7246L8.37598 8.33337H11.7511L17.0831 14.7077C17.86 15.6323 18.907 16.1506 19.9978 16.1506C21.0885 16.1506 22.1355 15.6323 22.9124 14.7077L28.2485 8.33337Z"
          fill="white"
        />
        <path
          d="M11.7077 31.6668H8.33301L15.3968 23.2238C17.9384 20.1866 22.0591 20.1866 24.6025 23.2238L31.6663 31.6668H28.2939L22.9163 25.2407C22.1395 24.3161 21.0925 23.7978 20.0017 23.7978C18.911 23.7978 17.864 24.3161 17.0871 25.2407L11.7077 31.6668Z"
          fill="white"
        />
      </svg>
    ),
    cryptoName: "XRP",
    cryptoShortName: "XRP",
    amount: "0.3464 BTC",
    price: "$1,658",
    volume: "$53,260.20",
    value: "0.35",
  },
  {
    id: 4,
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2442_12587)">
          <path
            d="M39.4017 24.8367C36.7295 35.5567 25.8684 42.0732 15.1637 39.4009C4.44342 36.7287 -2.07315 25.8681 0.599197 15.1635C3.27138 4.44354 14.1167 -2.07302 24.837 0.599321C35.5417 3.25588 42.074 14.1165 39.4017 24.8367Z"
            fill="url(#paint0_linear_2442_12587)"
          />
          <path
            d="M29.5225 17.5078C29.9131 14.8516 27.8975 13.4141 25.1162 12.4609L26.0225 8.85156L23.835 8.30469L22.96 11.8203C22.3818 11.6797 21.7881 11.5391 21.1943 11.4141L22.0693 7.88281L19.8818 7.33594L18.9912 10.9297C18.5068 10.8203 18.0381 10.7109 17.585 10.6016V10.5859L14.5537 9.83594L13.9756 12.1797C13.9756 12.1797 15.6006 12.5547 15.5693 12.5703C16.46 12.7891 16.6162 13.3828 16.585 13.8516L15.5537 17.9609C15.6162 17.9766 15.6943 17.9922 15.7881 18.0391C15.71 18.0234 15.6318 18.0078 15.5537 17.9766L14.1162 23.7266C14.0068 23.9922 13.7256 24.3984 13.1162 24.2422C13.1318 24.2734 11.5225 23.8516 11.5225 23.8516L10.4287 26.3672L13.2881 27.0859C13.8193 27.2266 14.335 27.3516 14.8506 27.4922L13.9443 31.1328L16.1318 31.6797L17.0381 28.0703C17.6318 28.2266 18.2256 28.3828 18.7881 28.5234L17.8975 32.1172L20.085 32.6641L20.9912 29.0234C24.7412 29.7266 27.5537 29.4453 28.7256 26.0547C29.6787 23.3359 28.6787 21.7578 26.71 20.7266C28.1631 20.3984 29.2412 19.4453 29.5225 17.5078ZM24.5068 24.5391C23.835 27.2578 19.2412 25.7891 17.7568 25.4141L18.96 20.5859C20.4443 20.9609 25.2256 21.6953 24.5068 24.5391ZM25.1943 17.4609C24.5693 19.9453 20.7568 18.6797 19.5225 18.3672L20.6162 13.9922C21.8506 14.3047 25.835 14.8828 25.1943 17.4609Z"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2442_12587"
            x1="19.9892"
            y1="-0.00947792"
            x2="19.9892"
            y2="39.9961"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F9AA4B" />
            <stop offset="1" stopColor="#F7931A" />
          </linearGradient>
          <clipPath id="clip0_2442_12587">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    cryptoName: "Bitcoin",
    cryptoShortName: "BTC",
    amount: "0.3464 BTC",
    price: "$1,658",
    volume: "$53,260.20",
    value: "0.35",
  },
  {
    id: 5,
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="20" cy="20" r="20" fill="#495ECA" />
        <path
          d="M20.4438 5.0426V5.0568C20.458 5.1278 20.458 5.213 20.458 5.29819V15.5362C20.4438 15.593 20.4012 15.6072 20.3586 15.6356C20.0605 15.7776 19.7765 15.9054 19.4783 16.0332C19.0665 16.2178 18.6405 16.4166 18.2287 16.6012L16.7377 17.2828C16.3259 17.4674 15.9141 17.652 15.5165 17.8365C15.0338 18.0637 14.5368 18.2767 14.054 18.5039C13.6422 18.6885 13.2304 18.8873 12.8044 19.0719C12.4636 19.2281 12.1228 19.3701 11.7962 19.5263C11.7678 19.5405 11.7394 19.5547 11.711 19.5547C11.6968 19.5547 11.6968 19.5547 11.6826 19.5405L12.066 18.9015C12.8044 17.6804 13.5286 16.4734 14.267 15.2522C15.048 13.9458 15.8431 12.6395 16.6241 11.3331C17.3483 10.1261 18.0867 8.91912 18.8109 7.71215C19.3363 6.83176 19.8759 5.95138 20.4012 5.071C20.4154 5.0426 20.4296 5.0284 20.4296 5H20.4438C20.4296 5.0142 20.4438 5.0284 20.4438 5.0426Z"
          fill="#F3F3F3"
        />
        <path
          d="M29.1611 19.5406L29.1753 19.5548L27.0879 20.7902L20.5277 24.6667C20.4993 24.6809 20.4709 24.6951 20.4567 24.7093C20.4141 24.7093 20.4141 24.6667 20.4141 24.6525V24.5247V15.7493C20.4141 15.7067 20.4141 15.6499 20.4283 15.6073C20.4425 15.5505 20.4851 15.5647 20.5277 15.5789C20.7123 15.6641 20.9111 15.7493 21.0956 15.8345C21.6494 16.0901 22.2032 16.3456 22.757 16.587C23.2398 16.8 23.7084 17.0272 24.1912 17.2402C24.674 17.4532 25.1568 17.6804 25.6396 17.8934C26.0514 18.078 26.4773 18.2768 26.8891 18.4614C27.3009 18.646 27.7269 18.8448 28.1387 19.0294C28.4653 19.1714 28.7919 19.3276 29.1185 19.4696C29.1185 19.5122 29.1327 19.5264 29.1611 19.5406Z"
          fill="#8A8DA7"
        />
        <path
          d="M20.4429 33.5273C20.4429 33.5415 20.4287 33.5557 20.4287 33.5699H20.4145C20.4145 33.5415 20.3861 33.5273 20.3719 33.4989C19.4915 32.2635 18.6111 31.0139 17.7307 29.7785C16.8361 28.5148 15.9274 27.2368 15.0328 25.973C14.1666 24.7518 13.2862 23.5165 12.42 22.2953C12.1928 21.9687 11.9656 21.6563 11.7384 21.3297C11.7242 21.3013 11.71 21.2871 11.6816 21.2445C11.7242 21.2445 11.7526 21.2729 11.7668 21.2871C13.0022 22.0113 14.2234 22.7355 15.4588 23.4597C16.8787 24.2974 18.2845 25.1352 19.7045 25.973L20.4287 26.399C20.4571 26.4274 20.4571 26.4558 20.4571 26.4842V33.3285C20.4571 33.3995 20.4571 33.4705 20.4429 33.5273Z"
          fill="#F3F3F3"
        />
        <path
          d="M11.667 19.5689V19.5547C12.1214 19.3559 12.5616 19.1429 13.016 18.9441C13.5982 18.6743 14.1803 18.4187 14.7625 18.1489C15.2027 17.9501 15.6571 17.7371 16.0973 17.5383C16.7505 17.2401 17.3895 16.9561 18.0427 16.658C18.4829 16.4592 18.923 16.2604 19.3774 16.0474C19.6898 15.9054 20.0164 15.7634 20.3288 15.6214C20.3572 15.6072 20.3998 15.593 20.414 15.5646C20.4282 15.5646 20.4282 15.5788 20.414 15.593V24.6098C20.414 24.6524 20.3998 24.695 20.4282 24.7234C20.3998 24.766 20.3714 24.7234 20.3572 24.7092C20.2294 24.6382 20.1016 24.5672 19.9738 24.482C17.2333 22.8632 14.4785 21.2303 11.738 19.6115C11.7238 19.5973 11.6954 19.5831 11.667 19.5689Z"
          fill="#C5C8D9"
        />
        <path
          d="M29.1331 21.2445H29.1473C29.1473 21.2729 29.1189 21.3013 29.1047 21.3297C26.492 25.0074 23.8792 28.6994 21.2665 32.3771C20.9967 32.7605 20.7127 33.1439 20.4429 33.5273C20.4287 33.5131 20.4287 33.4989 20.4287 33.4847V33.3995V26.5126V26.3848C21.0251 26.0298 21.6073 25.689 22.2037 25.334C24.504 23.9709 26.8044 22.6219 29.0905 21.2587C29.1047 21.2729 29.1189 21.2587 29.1331 21.2445Z"
          fill="#C5C8D9"
        />
        <path
          d="M20.4288 15.593V15.5646V15.4794V5.17036C20.4288 5.12776 20.4146 5.09936 20.443 5.05676C23.3255 9.84207 26.2081 14.6132 29.0764 19.3985C29.1048 19.4411 29.1474 19.4979 29.1616 19.5547C28.9628 19.4837 28.7782 19.3843 28.5936 19.2991C28.3664 19.1997 28.125 19.0861 27.8978 18.9867C27.7558 18.9157 27.5997 18.8589 27.4577 18.7879C27.2163 18.6743 26.9749 18.5749 26.7335 18.4613C26.5915 18.4045 26.4495 18.3335 26.3075 18.2625L25.3703 17.8365C25.2141 17.7655 25.0579 17.6945 24.8875 17.6235L24.2059 17.3253C24.0639 17.2685 23.9219 17.1975 23.7799 17.1265L22.8427 16.7005C22.6865 16.6295 22.5303 16.5585 22.36 16.4875L21.6784 16.1893C21.5222 16.1183 21.3802 16.0474 21.224 15.9764C20.9542 15.8486 20.6844 15.7208 20.4004 15.6072C20.443 15.593 20.4288 15.593 20.4288 15.593Z"
          fill="#C5C8D9"
        />
      </svg>
    ),
    cryptoName: "Ethereum",
    cryptoShortName: "ETH",
    amount: "0.3464 BTC",
    price: "$1,658",
    volume: "$53,260.20",
    value: "0.35",
  },
  { id: 6, amount: "0.3464 BTC", price: "$1,658", volume: "$53,260.20" },
  { id: 7, amount: "0.3464 BTC", price: "$1,658", volume: "$53,260.20" },
  { id: 8, amount: "0.3464 BTC", price: "$1,658", volume: "$53,260.20" },
  { id: 9, amount: "0.3464 BTC", price: "$1,658", volume: "$53,260.20" },
];

export function DataTable() {
  return (
    <div style={{ height: 319, width: "100%!important" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        hideFooter
        autoPageSize
        // autoHeight
        // initialState={{
        //   pagination: {
        //     paginationModel: { page: 0, pageSize: 5 },
        //   },
        // }}
        // pageSizeOptions={[5, 10]}
        // pageSize={6}
        // style={{border:"none"}}
        sx={{
          width: "100%",
          ".MuiDataGrid-columnHeader": {
            background: "var(--neutral-50, #F9F9FA)",
            color: "var(--neutral-500, #717184)",
            fontFamily: "SF Pro Display",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20.3px ",
          },
          "&, [class^=MuiDataGrid]": { border: "none" },
          "& .MuiDataGrid-row": {
            // py: "4px",
            color: "var(--neutral-700, #42424D)",
            fontFamily: "SF Pro Display",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20.3px ",
          },
        }}
      />
    </div>
  );
}
