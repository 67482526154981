export const ACTION_INITIATE_CHANGE_EMAIL = "ACTION_INITIATE_CHANGE_EMAIL"
export const ACTION_GET_PROFILE = "ACTION_GET_PROFILE"
export const ACTION_INITIATE_CHANGE_PHONE = "ACTION_INITIATE_CHANGE_PHONE"
export const REMOVE_CHANGE_EMAIL = "REMOVE_CHANGE_EMAIL"
export const REMOVE_CHANGE_PHONE = "REMOVE_CHANGE_PHONE"


const initialState = {
    changeEmailId: null,
    changeEmail: null,
    profile: null,
    changePhone: null,
    pcid: null
}

export const settingsReducer = (state = initialState, action) => {
    // const returnObj = { ...state }
    switch (action.type) {
        case ACTION_INITIATE_CHANGE_EMAIL:
            return {
                ...state,
                changeEmailId: action?.payload?.emailReqId,
                changeEmail: action?.reqPayload?.newEmail
            }
        case REMOVE_CHANGE_EMAIL:
            return action?.success ? {
                ...state,
                changeEmailId: null,
                changeEmail: null
            } : { ...state }
        case ACTION_INITIATE_CHANGE_PHONE:
            return {
                ...state,
                pcid: action?.payload?.pcid,
                changePhone: action?.reqPayload?.changePhone
            }
        case REMOVE_CHANGE_PHONE:
            return action?.success ? {
                ...state,
                pcid: null,
                changePhone: null
            } : { ...state }
        case ACTION_GET_PROFILE:
            return {
                ...state,
                profile: action?.payload?.data
            }

        default:
            return state
    }
}

