import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { IconButton, Tooltip } from '@mui/material';

export default function BasicPopover({ openButton, body, title }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            {openButton ?
                <Tooltip title={title} arrow>
                    <IconButton variant="contained" color='primary' size='small' aria-describedby={id} sx={{ p: '0px' }} onClick={handleClick}>
                        {openButton}
                    </IconButton>
                </Tooltip>
                :
                <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                    Open Popover
                </Button>
            }
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >

                {body ||
                    <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>}
            </Popover>
        </div>
    );
}
