import {
  InputAdornment,
  TextField,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
export default function SupportSearchBox() {
  const [value, setValue] = useState("");

  return (
    <TextField
      placeholder="Search questions, keywords, articles"
      type="text"
      variant="outlined"
      fullWidth
      size="small"
      onChange={(e) => setValue(e.target.value)}
      value={value}
      InputProps={{
        style: {
          // height: "48px",
          maxWidth: "700px",
          borderRadius: "12px",
          background: "var(--main-colors-white, #FFF)",
          padding:"15px 16px!important", 
          '@media (max-width:800px)':{
            width:"400px!important"
          }
          ,
          '@media (max-width:500px)':{
            width:"300px!important",
            padding:"20px 12px!important"
          }
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ color: "#9C9CAB" }} />
          </InputAdornment>
        ),

        endAdornment: value && (
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setValue("")}
          >
            <CancelIcon sx={{ color: "#9C9CAB" }} />
          </IconButton>
        ),
      }}
    />
  );
}
