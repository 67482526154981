import React from "react";
import { Box, Typography } from "@mui/material";

export const DashBoardBasicCardsBody = ({ icon, titletext, datavalue }) => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    <Box
      sx={{
        display: "flex",
        gap: "8px",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      {icon}
      <Typography
        sx={{
          color: "var(--neutral-400, #9C9CAB)",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20.3px",
        }}
      >
        {titletext}
      </Typography>
    </Box>
    <Typography
      sx={{
        color: "var(--main-colors-black, #0E0637)",
        fontFeatureSettings: `'ss06' on`,
        fontFamily: "SF Pro Display",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "56px",
        textTransform: "uppercase",
      }}
    >
      {datavalue}
    </Typography>
  </Box>
);
