import React from "react";
import { Box, Typography } from "@mui/material";
import SelectBoxWallet from "./selectBox";
import { useSelector } from "react-redux";
import UpDownShowComponent from "../../../components/Helpers/UpDownShowComponent";
import { PortfolioSvg } from "../../../components/Helpers/svg";
import { Numberz } from "../../../utilities";
export default function WalletComponentOne() {
  const { totalPortfolioValue, showCurrency, portfolioChanges } = useSelector(
    (state) => state?.dashboard
  );
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "5px",
          }}
        >
          <PortfolioSvg />
          <Typography
            sx={{
              color: "var(--neutral-400, #9C9CAB)",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20.3px",
            }}
          >
            PORTFOLIO VALUE
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <Typography
            sx={{
              color: "var(--main-colors-black, #0E0637)",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "56px",
              textTransform: "uppercase",
            }}
          >
            {showCurrency === "usd"
              ? showCurrency + " " + Numberz(totalPortfolioValue?.USD).toFixed(2)
              : showCurrency +
                " " +
                Numberz(totalPortfolioValue?.AED).toFixed(2)}
          </Typography>
          <UpDownShowComponent
            sign={portfolioChanges?.sign}
            diff={portfolioChanges?.diff}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-start", gap: "20px" }}>
        <SelectBoxWallet
          item1="All Coins"
          item2="All Coins"
          item3="All Coins"
        />
        <SelectBoxWallet item1="yearly" item2="weekly" item3="daily" />
      </Box>
    </Box>
  );
}
