import React from "react";
import { Box, Grid } from "@mui/material";
// import FAQSearchBox from "./searchbox";
import QnAComponent from "./faqQnAComponent";
const getting_started_array = [
  {
    ques: "What is Gafah?",
    answer: `Payments are the lifeblood of business—and when they improve, everyone gains. We live in an age where it can be done cheaper, faster, and better, but with assets usually limited use case for trading, there’s always been a barrier until now. 
  Based in the UAE, Gafah offers a solution that’s long been wanted by Assets users—and in return, presents a whole new opportunity for businesses to grow their customer base.
  Focusing on simplicity in design and ease of use, we’ve brought to life a user wallet, Assets POS machine, merchant portal, and e-comm integration—so payments can finally be done the truly modern way.  
  `,
  },
  {
    ques: "Why should I use Gafah? Is it secure?",
    answer: `In a time where 420 million people own some form of digital assets , we believe our daily lives should reflect this growing reality.
    Our UAE-based Merchant Assets solutions allows businesses to securely accept Assets in 0.2 seconds—so you get the reputation for being a forward-moving company, while expanding your revenue means. 
    `,
  },
  {
    ques: "Do I need a balance in my account to use it?",
    answer: "Yes, You need some balance to use it.",
  },
  { ques: "How much does it cost to use Gafah?", answer: "Anyone can use it at free of cost. You don't need any money for it." },
  { ques: "How to sign up on Gafah?", answer: "You can go to sign up page for registering with us and use Gafah for seamless services." },
  {
    ques: "I don’t have wallet, do I need to create one?",
    answer: "Yes, Gafah wallet already gets created when you register through Gafah and do your KYC.",
  },
  {
    ques: "Where can I go for additional help or questions?",
    answer: "You can send us your query by going to contact us page and sending us your query. We will try to reply ASAP.",
  },
  { ques: "How can I create merchant id?", answer: (<div>Please Follow The Instruction To Create a Merchant id :
<br/>
    Log in to your pallapay account
    <br/>Click on My Merchants
    <br/>Click on + New Merchants
    <br/>Enter your website information and click save</div>) },
  { ques: "Can I use Gafah without a website?", answer: "Yes, we have app also incase some user dont want to use the website." },
  { ques: "What are the supported digital assets ?", answer: "Lorem Ipsum" },
];

const withdrawals_array = [
  {
    ques: "How much maximum amount can be withdrawn at once?",
    answer: "Lorem Ipsum",
  },
  {
    ques: "What is the process for withdrawing amount?",
    answer: "Lorem Ipsum",
  },
  { ques: "How to setup withdraw?", answer: "Lorem Ipsum" },
  { ques: "From which all networks can I withdraw?", answer: "Lorem Ipsum" },
  {
    ques: "What are the things to keep in mind to withdraw?",
    answer: "Lorem Ipsum",
  },
  { ques: "Is KYC needed to withdraw?", answer: "Lorem Ipsum" },
];

const accounts_array = [
  {
    ques: "How do I activate two factor authentication?",
    answer: (<div>To connect two-factor authentication, go to "Settings" in your personal account, then to the 2FA section.
      <br/><br/>
    1. Download any authenticator application (for example, Google Authenticator) and open it.
    <br/>
    2. Add a new device by selecting the option to scan the QR code. Scan the QR code on your other device's screen.
    <br/>
    3. Enter the code from Email/SMS first and then the code from Google Authenticator, which is constantly updated.
    <br/>
    Two-factor authentication will be successfully enabled. To log in to your account, you will need to enter a six-digit code that is generated in the authenticator app.
    </div>)},
  {
    ques: "What should I do if I can’t access my device my device with 2FA authentication?",
    answer: "If your account has two-factor authentication connected and you can't log in, contact our support team. We will try to solve your problem.",
  },
  { ques: "How to get payment notifications?", answer: `We made it possible for you to configure settings as you like to receive notifications.
  You can receive notifications about incoming payments to your email and Telegram account. To allow this, go to "Settings" and go to the "Notifications" section.` },
  {
    ques: "How can I withdraw funds immediately to my wallets?",
    answer: (<div>If you want the funds you receive immediately and automatically withdraw to your wallet, use our feature.
<br/>
      1. Go to your account settings.
      <br/> 
      2. Select "Auto-withdrawal"
      <br/>
      3. After enabling 2FA, click "Add adress".
      <br/>
      4. You need to specify:
      <br/>
      digital assets  and network (each separately)
      <br/>
      Your wallet address
      <br/>Auto-withdrawal amount - you can check it to always withdraw maximum amount
      <br/>Set withdrawal frequency - every hour, 6 hours or 24 hours
      <br/><br/>
      Done! Now, when you accept payment from a client, the digital assets  will be automatically withdrawn to your specified wallet address.</div>),
  },
  { ques: "Can I allow withdrawals only to my wallet?", answer: `Yes, there is a White List Management Here you can add the address to withdraw funds only to it.

  You can also add IPs to whitelist so only trusted IPs can interact with your API keys.
  
  For example, if the API Whitelist is turned on, the IP is entered, and the key is given to your programmers, they wouldn't be able to create an invoice or withdraw money from their device because the trusted IP address is turned on. Or it is just used as protection, even if API keys are somehow leaked/stolen/lost, you will not lose money as nobody will be able to create payment by API from IP address not specified in the Whitelist.` },
  {
    ques: "What happens if someone tried to hack my account?",
    answer: `Don't worry, there is a Fraud system to protect users from hacking.

    For extra security after changing the password, email address, phone number, or API key the fraud system prohibits the withdrawal of funds for 24 hours.
    
    To minimize the chance of an unauthorized person gaining access to your wallet, after six hours of inactivity the session is reset and the account is logged out if the pin code is not enabled.
    
    You can also enable the PIN code in the settings. This feature requests you to enter the code you set after 15 minutes of inactivity.`,
  },
  {
    ques: "Can I use the service from my mobile device?",
    answer: `Yes. The mobile version of the personal account is fully adaptive and has the same functionality as the desktop version.

    We are constantly working to improve our service to make it more convenient to work with. Release of the app for smartphones is expected in the nearest future.`,
  },
  { ques: "How do I delete my account?", answer: `If for some reason you want to completely delete your account, go to your account settings and click "Delete".

  It is not possible to delete a merchant project, while this option is not provided.` },
  { ques: "How can I recover my username or password?", answer: "If you are having issues signing into your account because you forgot your username or password, we can help you recover this information here." },
];

const payments_array = [
  { ques: "How do I invoice for payment?", answer: (<div>To invoice, go to your project, click the "Invoice" button and specify the necessary amount of payment, as you like in fiat or digital assets . If you choose to invoice in fiat, your client chooses which digital assets  they want to pay in. The invoice will be available from 1 hour to 3 hours, depending on what time you set yourself.
<br/>
  This is done for you to have time to confirm the transaction.
  <br/>
  Payment status of the invoice will be possible to track in your project section.
  <br/>
  After clicking the "Create" button, you will receive a link to the invoice payment, which you can then send to your client.
  <br/>
  In case the invoice has expired after the customer has paid but before it has been confirmed by the network, the payment status will automatically change to "Paid" after several confirmations by the blockchain network.
<br/>
In case the issued invoice expires and has not yet been paid, we advise to create a new invoice for payment.
  </div>) },
  { ques: "At what exchange rate is the payment made?", answer: "The total sum of transaction is formed at the exchange rate at the moment of generating the invoice for payment. The invoice is valid up to 3 hours, depending on what time you choose after the creation." },
  { ques: "How long does the transaction take?", answer: (<div>Once a transaction is made by a customer, the information is instantly sent to the blockchain network. The speed of transaction processing directly depends on the chosen digital assets , network congestion and commission of the chosen network.
<br/>
    It usually takes from a few to twenty minutes to collect a sufficient number of transaction confirmations in the blockchain and then to credit the recipient's balance.
    <br/>
    Average transaction processing time:
    <br/><br/>
    BTC - 15 minutes to several hours;
    <br/>LTC - 5 to 20 minutes;
    <br/>ETH / USDT - 1 to 10 minutes.
    <br/>If the funds were sent in full and the transaction was not confirmed before the expiration of the invoice, the payment will still be credited after two network confirmations.</div>) },
  {
    ques: "What should I do if the invoice is not paid in full?",
    answer: (<div>If the issued invoice is not paid in full, the Digital assets  service will not receive the full amount of payment specified in the invoice. This may be due to the fact that the buyer did not take into account the wallet/exchange or blockchain network commission when sending the payment.<br/>You have an opportunity to get a surcharge on the account if the service is integrated via API. The client must pay the rest of the invoice to complete the transaction. In case the invoice was created, but the client didn't pay it in full, you won't be able to get the surcharge, but you can return the client's funds back. Enter the wallet address and check/uncheck to specify whose account will be charged the transaction commission.</div>),
  },
  {
    ques: "What should I do if payment has not been received?",
    answer: (<div>If for any reason the payment made by the buyer through Digital assets  service has not been transferred to your account, you need to request the following information from the client:
      <br/><br/>
            1) The number of the wallet to which the funds were transferred;
            <br/><br/>
            2) The txID of your transaction - it is specified in the wallet or exchange from which the transfer was made or direct link to the blockchain browser.
            <br/><br/>
            After receiving the data, contact our support team. We will determine the reason of unsuccessful transaction and help to solve the problem.</div>),
  },
  { ques: "Which networks are available for payment?", answer: "Lorem Ipsum" },
  {
    ques: "What is the network commission and who pays for it?",
    answer: (<div>Some blockchain networks are run by miners (validators) and are a chain of blocks made up of validated transactions. The blockchain network commission is the commission that the person who sends funds pays to make the digital assets  payment.
<br/>
    It is impossible to determine the exact amount of the network's commission, as it depends on various factors, such as the workload of the blockchain network, the importance of the transaction for miners, etc. You can read more about network commissions in our article.</div>),
  },
  { ques: "How fast is the withdrawal of funds?", answer: (<div>To withdraw money from your account you have to go to the Personal wallet and fill the form, specifying the currency and address of the wallet to receive funds.
<br/>
    <strong>Important:</strong> If your money is in Buisness wallet you have to withdraw it to Personal first or directly if you have API connected.
    <br/>
    This is very easy to do, just press the "Transfer" button and then choose the digital assets  and the amount of transfer to the Personal wallet.
    <br/>
    Transfers and withdrawals are made instantly. You will only have to wait for network confirmation when you make a withdrawal from Digital assets .
    </div>) },
  {
    ques: "Can you convert currencies between merchant balances or make a payment in another currency?",
    answer: "Yes, we will have this feature soon. You will be able to convert one digital assets  to another in your personal cabinet. It will be possible to convert all available currencies in Digital assets  service.",
  },
  { ques: "What is the minimum payment amount?", answer: "Lorem Ipsum" },
];

export default function FAQRightGridComponent() {
  return (
    <Grid item xs={12} md={9} lg={9} sx={{ mt: "120px","@media (max-width:900px)":{mt:"50px"} }}>
      {/* <FAQSearchBox /> */}
      <Box
        sx={{
          mt: "80px",
          display: "flex",
          flexDirection: "column",
          gap: "48px",
          overflowY: "scroll",
          height: "50vh",
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "var(--neutral-50, #F9F9FA)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "var(--neutral-200, #E3E3E8)",
            borderRadius: "24px!important",
          },
          "@media (max-width:900px)":{mt:"0px", height:"auto", overflowY:"auto"}
        }}
      >
        <QnAComponent
          array={getting_started_array}
          id="faq_getting_started"
          heading="GETTING STARTED"
        />
        <QnAComponent
          array={withdrawals_array}
          id="faq_withdrawals"
          heading="WITHDRAWALS"
        />
        <QnAComponent
          array={accounts_array}
          id="faq_accounts"
          heading="ACCOUNTS"
        />
        <QnAComponent
          array={payments_array}
          id="faq_payments"
          heading="PAYMENTS"
        />
      </Box>
    </Grid>
  );
}