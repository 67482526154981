import React from "react";
import { Box, Typography } from "@mui/material";
export default function SupportCOneCard(props) {
  const { svg, head, content } = props;
  return (
    <Box
      sx={{
        padding: "48px 40px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "32px",
        borderRadius: " 16px",
        background: "var(--main-colors-white, #FFF)",
        boxShadow: "0px 0px 20px 0px rgba(82, 53, 232, 0.10)",
        width: "100%",
        minHeight: "204px",
        "@media (min-width:800px)": { maxWidth: "352px" },
      }}
    >
      {svg}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Typography
          sx={{
            color: "var(--main-colors-black, #0E0637)",
            textAlign: "center",
            fontFeatureSettings: ` 'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "36px",
            letterSpacing: "-0.24px",
          }}
        >
          {head}
        </Typography>
        <Typography
          sx={{
            color: "var(--neutral-500, #717184)",
            textAlign: "center",
            fontFeatureSettings: ` 'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "25.6px",
            letterSpacing: "0.08px",
            maxWidth: "272px",
          }}
        >
          {content}
        </Typography>
      </Box>
    </Box>
  );
}
