import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import CFiveCardComponent from "./CFiveCard";
import Img1 from "../../../assets/img/home/ComponentFive/1.png";
import Img3 from "../../../assets/img/home/ComponentFive/3.png";
import { FasterTransaction } from "../../../components/Helpers/svg";
export default function ComponentFive() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "70px 160px 140px",
        '@media (max-width:1200px)':{px:"40px!important"}
      }}
    >
      <Typography
        sx={{
          color: "#495ECA",
          textAlign: "center",
          fontFeatureSettings: " 'ss06' on",
          fontFamily: "SF Pro Display",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "19.6px",
          textTransform: "uppercase",
        }}
      >
        Features
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          fontFeatureSettings: " 'ss06' on",
          fontFamily: "Poppins",
          fontSize: "40px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "56px",
          letterSpacing: "-0.4px",
          '@media (max-width:800px)':{
            fontSize:"2.075rem",
            lineHeight:"50px"
          }
        }}
      >
        The speed of decentralized, <br/>with the security of centralized

      </Typography>
      <Grid elevation={2} container sx={{ mt: "88px" }}>
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          sx={{ display: "flex", justifyContent: "center", '@media (max-width:1200px)':{mb:"80px"} }}
        >
          <CFiveCardComponent
            img={Img1}
            head="Bringing the best of both worlds"
            content="We uniquely offer both centralized & decentralized payment methods to have things your way."
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <CFiveCardComponent
            svg={<FasterTransaction />}
            head="Supporting major digital assets"
            content="We chose to enable transfer in major digital assets to keep things streamlined while being careful not to exclude users."
          />
        </Grid>
        <Grid
          xs={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center", mt:"74px", '@media (max-width:800px)':{ mt:"0px"} }}
        >
          <CFiveCardComponent
            img={Img3}
            head="Opening new horizons for businesses & users"
            content="Our vision is to enable fast, frictionless, and low-cost digital assets transfer for everyone."
          />
        </Grid>
      </Grid>
    </Box>
  );
}
