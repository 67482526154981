import api from "../../config/api";
import Network from "../../config/network";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Network2 from "../../config/networkPayment";
import config from "../../config/config";

export const findUrl = (obj, keyName) => {
  if (obj.user && obj.user.hasOwnProperty(keyName))
    return config["USER_API_URL"] + api["user"][keyName];
  if (obj.external && obj.external.hasOwnProperty(keyName))
    return config["EXTERNAL_API_URL"] + api["external"][keyName];

  return "Key not found in either user or external object.";
}

export const PostHandler = (req) => async (dispatch) => {
  try {

    const res = await Network.post(findUrl(api, req.url), req?.payload)
    if (req?.action) {
      dispatch({
        type: req?.action,
        payload: res?.data?.data,
        success: res?.data?.success,
        reqPayload: req?.reqPayload || req?.payload,
      });
    }
    toast.success(res?.data?.data?.message);
    return res;
  } catch (error) {
    console.error("Error post reqitems:", error);
    toast.error(error?.response?.data?.message);
    return error?.response;
  }
};

export const DeleteHandler = (req) => async (dispatch) => {
  try {
    const res = await Network.delete(findUrl(api, req.url), req?.payload)
    if (req?.action) {
      dispatch({
        type: req?.action,
        payload: res?.data,
        reqPayload: req?.payload,
      });
    }
    toast.success(res?.data?.message);
    return res;
  } catch (error) {
    console.error("Error post reqitems:", error);
    toast.error(error?.response?.data?.message);
    return error?.response;
  }
};

export const uploadHandler = (req) => async (dispatch) => {
  try {
    const res = await Network.post(findUrl(api, req.url), req?.payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (req?.action) {
      dispatch({
        type: req?.action,
        payload: res?.data,
        reqPayload: req?.payload,
      });
    }
    toast.success(res?.data?.message);
    return res;
  } catch (error) {
    console.error("Error post reqitems:", error);
    toast.error(error?.response?.data?.message);
    return error?.response;
  }
};

export const GetHandler = (req, headers) => async (dispatch) => {
  try {
    const res = await Network.get(findUrl(api, req.url), req?.params, headers);
    if (req?.action) {
      dispatch({
        type: req?.action,
        state: req?.state,
        payload: res?.data,
        page: req?.params?.page,
      });
    }
    toast.success(res.data.message);
    return res;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    // console.error("Error post reqitems:", error);
    return error?.response?.data;
  }
};

export const SocialLoginHandler = (req) => async (dispatch) => {
  try {
    const res = await Network.socialLogin(findUrl(api, req.url));
    if (res?.data?.success) {
      dispatch({
        type: res?.data?.detailsExistance
          ? "ACTION_LOGIN"
          : "ACTION_SOCIAL_SIGNUP",
        payload: res?.data,
        reqPayload: req?.payload,
      });
    }
    toast.success(res.data.message);
    return res;
  } catch (error) {
    toast.error(error.response.data.message);
    console.error("Error post reqitems:", error);
    return error?.response;
  }
};

export const PostHandlerForPaymentGateway = (req) => async (dispatch) => {
  try {
    const res = await Network2.post(findUrl(api, req.url), req?.payload, req?.headers)
    if (res?.data?.data?.jwt_token)
      window.localStorage.setItem("paymentToken", res?.data?.data?.jwt_token?.accessToken)
    if (req?.action) {
      dispatch({
        type: req?.action,
        payload: res?.data?.data,
        success: res?.data?.success,
        reqPayload: req?.reqPayload || req?.payload,
      });
    }
    toast.success(res?.data?.data?.message);
    return res;
  } catch (error) {
    console.error("Error post reqitems:", error);
    toast.error(error?.response?.data?.message);
    return error?.response;
  }
};

export const GetHandlerForPaymentGateway = (req) => async (dispatch) => {
  try {
    const res = await Network2.get(findUrl(api, req.url), req?.params, req?.headers);
    if (res?.data?.data?.jwt_token)
      window.localStorage.setItem("paymentToken", res?.data?.data?.jwt_token?.accessToken)
    if (req?.action) {
      dispatch({
        type: req?.action,
        state: req?.state,
        payload: res?.data,
        page: req?.params?.page,
      });
    }
    toast.success(res.data.message);
    return res;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    // console.error("Error post reqitems:", error);
    return error?.response?.data;
  }
};
