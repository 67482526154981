import {  InputAdornment, TextField ,IconButton} from "@mui/material";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from '@mui/icons-material/Cancel';
export default function SearchBox() {
  const [value, setValue] = useState("");

  return (
    <TextField
      placeholder="Search..."
      type="text"
      variant="outlined"
      fullWidth
      size="auto"
      onChange={(e) => setValue(e.target.value)}
      value={value}
      sx={{maxWidth:"960px", height:"100%" }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),

        endAdornment: value && (
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setValue("")}
          >
            <CancelIcon />
          </IconButton>
        )
      }}
    />
  );
}
