import { Box, IconButton, MenuItem, Paper } from "@mui/material";
import React, { useState } from "react";
import { AuthPRight, Header5 } from "../../components/Helpers/helperComponents";
import moment from "moment";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { PostHandler } from "../../Redux/RequestHandlers";
import DetailsModal from "./DetailsModal";
import { DeleteDialog } from "../../components/Dialog";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { hasAccess } from "../../utilities";

const ApiKeyList = ({ item, setUpdateData, setComponentShow }) => {
  const { accessLists } = useSelector((state) => state.auth);

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  // const handleUpdateClick = (item) => {
  //   setUpdateData(item);
  //   setComponentShow("update");
  //   handleClickAway();
  // };

  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => setOpen(false);

  const [handleModals, setHandleModals] = useState({
    seeDetails: false,
    delApiKey: false,
    lockApiKey: false,
  });

  const handleLock = async (id, lock) => {
    try {
      const req = {
        url: "lockApiKey",
        action: "ACTION_GET_ECCOMERCE_APIKEY",
        payload: {
          keyId: id,
          lock: lock,
        },
      };
      await dispatch(PostHandler(req));
      setHandleModals((prev) => ({ ...prev, lockApiKey: false }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const req = {
        url: "deleteApiKey",
        action: "ACTION_GET_ECCOMERCE_APIKEY",
        payload: {
          keyId: id,
        },
      };
      await dispatch(PostHandler(req));
      handleClickAway();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      key={item?.id}
      sx={{
        mt: "15px",
        display: "flex",
        width: "100%",
        p: "16px",
        justifyContent: "space-between",
        alignItems: "flexStart",
        borderRadius: "8px",
        border: "1px solid var(--neutral-200, #E3E3E8)",
        background: "var(--main-colors-white, #FFF)",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", gap: "3px" }}>
          {item?.islocked && (
            <LockOutlinedIcon color="primary" sx={{ fontSize: "20px" }} />
          )}
          <Header5 text={item?.name} fs="16px" mt="0" />
        </Box>
        <AuthPRight
          text={`Generated At: ${moment(item?.createdAt).format("llll")}`}
          fs="14px"
          lh="22px"
          mt="5px"
        />
      </Box>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={{ position: "relative" }}>
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>

          {open ? (
            <Paper
              sx={{
                display: open ? "block" : "none",
                position: "absolute",
                zIndex: "9999!important",
                right: "20px",
              }}
            >
              <MenuItem
                key="seeDetails"
                onClick={() => {
                  setHandleModals({ seeDetails: true });
                  handleClickAway();
                }}
              >
                See Details
              </MenuItem>

              {/* <MenuItem
                key="Changemaxleverage"
                onClick={() => handleUpdateClick(item)}
              >
                Update Callback Url
              </MenuItem> */}

              {hasAccess(["Apikey-update"], accessLists) && (
                <MenuItem
                  key="lockApiKey"
                  onClick={() => {
                    setHandleModals({ lockApiKey: true });
                    handleClickAway();
                  }}
                >
                  {item?.islocked ? "Unlock" : "Lock"} API Key
                </MenuItem>
              )}
              {hasAccess(["Apikey-delete"], accessLists) && (
                <MenuItem
                  key="delApiKey"
                  onClick={() => {
                    setHandleModals({ delApiKey: true });
                    handleClickAway();
                  }}
                >
                  Delete API Key
                </MenuItem>
              )}
            </Paper>
          ) : null}
        </Box>
      </ClickAwayListener>

      {handleModals?.seeDetails && (
        <DetailsModal
          isShow={true}
          accountInfo={item}
          opens={handleModals?.seeDetails}
          close={() => setHandleModals({ seeDetails: false })}
        />
      )}

      {handleModals?.delApiKey && (
        <DeleteDialog
          isShow={true}
          data={item}
          title="Sure you want to delete?"
          content="Are you sure you want to Delete this?"
          opens={handleModals?.delApiKey}
          execute={() => handleDelete(item?.id)}
          close={() => setHandleModals({ delApiKey: false })}
        />
      )}

      {handleModals?.lockApiKey && (
        <DeleteDialog
          isShow={true}
          data={item}
          title={`Sure you want to ${item?.islocked ? "Unlock" : "lock"}?`}
          content={`Are you sure you want to  ${
            item?.islocked ? "unlock" : "lock"
          } this?`}
          opens={handleModals?.lockApiKey}
          execute={() => handleLock(item?.id, item?.islocked ? false : true)}
          close={() => setHandleModals({ lockApiKey: false })}
        />
      )}
    </Box>
  );
};

export default ApiKeyList;
