import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import POSCryptoComponent from "./posCryptoCurrencyComponent";
import POSQRExternal from "./posQRExternalPage";
import { useDispatch, useSelector } from "react-redux";
import ExternalSocketService from "../../../utilities/ExternalSocketService";
import LogoImg from "../../../assets/img/logo2.png";
import axios from "axios";


import {
  AuthHeader,
  AuthPRight,
  Failed,
  GoDownAnimation,
  UrlReject,
} from "../../../components/Helpers/helperComponents";
import { useState } from "react";
import AlertDialog, {
  InfoDialog,
  CautionDialog,
} from "../../../components/Dialog";
import {
  ACTION_GET_LIST_OF_CURRENCIES,
  ACTION_PAYLOAD_SET,
  ACTION_SET_SOCKET_DATA,
} from "../../../Redux/Reducers/pos.reducer";
import { ResourceLoader } from "../../../components/Helpers/svg";
import moment from "moment";
import CountdownComponent from "../../../components/Helpers/countdown";
import { addBigNumber, hasObjectPropertyExist } from "../../../utilities";
import api from "../../../config/api";
import { findUrl } from "../../../Redux/RequestHandlers";
import { useEffect } from "react";

export const ComponentHeader = (props) => {
  const tokenText = props?.invoiceToken || "Select a Wallet";
  const networkText = props?.invoiceNetwork || "";

  return (
    <>
      <AuthHeader
        text={`${tokenText} ${networkText && `(${networkText})`}`}
        fs="34px"
        fw="700"
      />
      <AuthPRight
        text={
          networkText
            ? `Please send ${tokenText}(${networkText}) only to this address.`
            : "Please select a wallet from the options given below to proceed."
        }
      />
    </>
  );
};

export default function ExternalWallet() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { invoices } = useSelector((state) => state?.pos);
  const [canceled] = useState(false);
  const [showComponent, setShowComponent] = useState(true);
  const [network, setNetwork] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [loadingQR, setLoadingQR] = React.useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isRejected, setIsRejected] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isSocketConnected, setIsSocketConnected] = useState(true);
  const [hasShiftedToPolling, setHasShiftedToPolling] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const param1 = queryParams.get("id");
  const exchange = queryParams.get("exchange");
  const [isCanceled, setIsCanceled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [shouldContinue, setShouldContinue] = useState(true);


  // api calls
  const init = async () => {
    try {
      const res = await axios.get(findUrl(api, "getCurrencies"), {
        headers: {
          Authorization: `Bearer ${invoices[param1]?.jwt?.accessToken}`,
        },
      });
      if (res?.data?.success)
        await dispatch({
          type: ACTION_GET_LIST_OF_CURRENCIES,
          payload: res?.data,
        });
    } catch (error) {
      setIsRejected(true);
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (param1 && hasObjectPropertyExist(invoices, param1)) {
      if (!invoices[param1]?.paymentMethod) init();
      setShowComponent(invoices[param1]?.token ? false : true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, param1]);

  const handleExternalClick = async () => {
    setLoadingQR(true);
    try {
      const payload = {
        id: param1,
        mode: "External",
        network: network,
        token: token,
      };
      if (exchange) payload.exchange = exchange;
      const result = await axios.post(
        findUrl(api, "initiateInvoicePayment"),
        // config?.API_URL + api?.initiateInvoicePayment,
        payload,
        {
          headers: {
            Authorization: `Bearer ${invoices[param1]?.jwt?.accessToken}`,
          },
        }
      );
      if (result?.data?.success) {
        console.log("result", result);
        if (result?.data?.data?.jwt_token)
          window.localStorage.setItem("paymentToken", result?.data?.data?.jwt_token?.accessToken)
        const dx = result?.data?.data;
        let tempInv = invoices[param1];
        const kk = {
          ...tempInv,
          ...dx,
        };
        if (tempInv?.paymentMethod === "Gafah") {
          delete kk.url;
          delete kk.paymentMethod;
        }
        kk.paymentMethod = "External";

        // tempInv.expire = dx?.network;
        if (dx?.id === invoices[param1]?.id)
          dispatch({
            type: ACTION_PAYLOAD_SET,
            reqState: param1,
            payload: kk,
          });
      }
      setLoadingQR(false);
      setIsRejected(result?.data?.success ? false : true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelClick = async () => {
    try {
      if (!hasObjectPropertyExist(invoices, param1)) return;

      setLoadingQR(true);

      let payload = {
        id: invoices[param1]?.id,
      };
      const cancelres = await axios.post(
        findUrl(api, "cancelTransaction"),

        // config?.API_URL + api?.cancelTransaction,
        payload,
        {
          headers: {
            Authorization: `Bearer ${invoices[param1]?.jwt_token?.accessToken}`,
          },
        }
      );

      if (cancelres?.data?.success) {
        setIsRejected(true);
        setIsCanceled(true);
        dispatch({
          type: "ACTION_CLEAR_PAYMENT_DETAILS",
          payload: param1,
        });
        navigate("/");
      }
    } catch (error) {

      console.error(error);
    }
  };

  function isJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  React.useEffect(() => {
    // Connect to the socket when component mounts
    let clientId = localStorage.getItem("clientId");
    localStorage.setItem("clientId", param1);
    clientId = param1;
    ExternalSocketService.connect();
    ExternalSocketService.emitEvent("clientId", clientId);
    ExternalSocketService.emitEvent("client-message", "hello");
    ExternalSocketService.subscribeToEvent("serverMessage", (data) => {
      console.log("sockets data", data);
      if (isJSON(data)) {
        let result = JSON.parse(data);
        if (result?.data) {
          dispatch({
            type: ACTION_SET_SOCKET_DATA,
            reqState: param1,
            payload: result?.data,
          });
        }
      }
    });
    ExternalSocketService.subscribeToEvent("connect", () => {
      setIsSocketConnected(true);
      console.log("socket");
    });

    ExternalSocketService.subscribeToEvent("disconnect", () => {
      setIsSocketConnected(false);
      setHasShiftedToPolling(true);
    });

    setIsSocketConnected(ExternalSocketService.isConnected());

    return () => {
      // Disconnect from the socket when component unmounts
      ExternalSocketService.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transactionPolling = async () => {
    try {
      if (!hasObjectPropertyExist(invoices, param1)) return;

      const res = await axios.get(
        `${findUrl(api, "transactionPolling")}?id=${invoices[param1]?.id}`,
        // `${config?.API_URL + api?.transactionPolling}?id=`,
        {
          headers: {
            Authorization: `Bearer ${invoices[param1]?.jwt_token?.accessToken}`,
          },
        }
      );
      if (res?.data?.success) {
        const dx = res?.data?.data;
        dispatch({
          type: ACTION_SET_SOCKET_DATA,
          reqState: param1,
          payload: dx,
        });
      }

      if (!res?.data?.success) setIsRejected(true);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (!hasObjectPropertyExist(invoices, param1)) return;
    // Initialize intervalId variable to keep track of the interval
    let intervalId;

    // Function to set up the interval
    const setupInterval = () => {
      // Set up interval only when payment details are available, shifted to polling, and not rejected
      if (invoices[param1]?.token && hasShiftedToPolling && !isRejected) {
        intervalId = setInterval(transactionPolling, 15000);
      }
    };

    // Function to clear the interval
    const clearIntervalIfRejected = () => {
      // If rejected becomes true and intervalId exists (meaning the interval is set), clear the interval
      if (isRejected && intervalId) {
        clearInterval(intervalId);
      }
    };

    // Set up interval initially
    setupInterval();

    // Clear the interval if rejected becomes true after the interval has already been set up
    clearIntervalIfRejected();

    // Clean up the interval when the component unmounts or when the conditions for setting up the interval change
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, hasShiftedToPolling, isRejected]);

  React.useEffect(() => {
    // if invoice not exits return
    if (!hasObjectPropertyExist(invoices, param1))
      return navigate(`/invoice?id=${param1}/`);

    // if invoice completed return to success page
    if (invoices[param1]?.status === "Completed")
      return navigate(`/invoice/success/${invoices[param1]?.id}/`);

    // if invoice Expired return to failed page
    if (invoices[param1]?.status === "Expired")
      return navigate(`/invoice/failed/${invoices[param1]?.id}`, {
        replace: true,
      });

    // checking is transaction expire or not
    if (invoices[param1]?.expireDate) {
      const local = moment();
      const PAYMENTEXP = invoices[param1]?.expireDate;
      if (local.isAfter(PAYMENTEXP)) return setIsRejected(true);
      // Set the exact time when you want the event to occur
      const eventTime = new Date(PAYMENTEXP).getTime(); // Replace with your desired time

      // Calculate the delay until the event time
      const delay = eventTime - new Date().getTime();
      // Check if the event time has already passed
      if (delay > 0) {
        // Set a timeout to trigger the event
        const timeoutId = setTimeout(() => {
          // Your event logic here

          // handleExpireTransaction()
          setIsRejected(true);
        }, delay);

        // Clean up the timeout on component unmount (optional)
        return () => clearTimeout(timeoutId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, param1]);

  useEffect(() => {
    setIsSubmitDisabled(network && token && shouldContinue ? false : true);
  }, [network, token, shouldContinue]);

  return (
    <Box
      sx={{
        p: "30px",
        pt: "15px",
        "@media (max-width:1000px)": { p: "5px" },
      }}
    >
      <Box
        component="img"
        src={LogoImg}
        sx={{ height: "40px", width: "auto" }}
      />
      {hasObjectPropertyExist(invoices, param1) && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            pt: "0px",
          }}
        >
          <ComponentHeader
            invoiceToken={invoices[param1]?.token || token}
            invoiceNetwork={invoices[param1]?.network || network}
          />

          <Box
            sx={{
              borderRadius: "34px",
              border: "1px solid var(--Neutral-300, #EFF0F6)",
              background: "var(--Neutral-100, #FFF)",
              boxShadow: "0px 3px 16px 0px rgba(73, 94, 202, 0.18)",
              p: "30px 20px",
              maxWidth: "600px",
              width: "80%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              mt: "15px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "5px",
                right: "5px",
              }}
            >
              <InfoDialog description="The amount of digital assets is dependent on the market and is subject to change. In case of a major change in the asset price, the amount can be changed mid transaction also." />
            </Box>

            {/* <AuthPRight text /> */}
            {!param1 ? (
              <UrlReject
                my="100px"
                text={`url should be ${location?.pathname}?id=xxxxx-xxx-xxxx...`}
              />
            ) : isRejected ? (
              <Failed
                title={isCanceled ? "Canceled" : ""}
                my="100px"
                showComponent={
                  <CountdownComponent
                    content="This page will redirect in "
                    colour="red"
                    expireIn={3}
                  />
                }
                text={
                  isCanceled
                    ? "Looks like the transaction has been canceled, please try to Restart the Payment"
                    : "Looks like the transaction has expired, please try to Restart the Payment"
                }
              />
            ) : showComponent ? (
              <>
                <POSCryptoComponent
                  setNetwork={setNetwork}
                  setToken={setToken}
                  token={token}
                  amount={invoices[param1]?.amount}
                  currency={invoices[param1]?.currency}
                  shouldContinue={shouldContinue}
                  setShouldContinue={setShouldContinue}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    pt: 2,
                    width: "100%",
                  }}
                >
                  <Button
                    color="inherit"
                    component={Link}
                    to={`/invoice?id=${param1}`}
                    sx={{
                      mr: 1,
                      borderRadius: "10px",
                      border: "1px solid var(--Neutral-300, #495ECA)",
                      background: "#fff",
                      p: "20px 40px",
                      color: "#495ECA",
                      textTransform: "none",
                      "&:hover": {
                        background: "#fff",
                        color: "#495ECA",
                      },
                      height: "48px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#495ECA",
                        fontFamily: "SF Pro Display",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      Go Back
                    </Typography>
                  </Button>

                  <CautionDialog
                    disabled={isSubmitDisabled}
                    titleHeading="Important"
                    description="Exchange withdrawals may incur fees and they may deduct the fee from the entered withdrawal amount. Check your exchange's fee structure to ensure that you send the correct amount."
                    nextButton={
                      <>
                        <Button
                          color="inherit"
                          disabled={isSubmitDisabled}
                          onClick={() => {
                            handleExternalClick();
                            setShowComponent(false);
                          }}
                          sx={{
                            mr: 1,
                            borderRadius: "25px",
                            border: "1px solid var(--Neutral-300, #EFF0F6)",
                            background: isSubmitDisabled ? "grey" : "#495ECA",
                            color: "#fff",
                            px: "30px",
                            textTransform: "none",
                            "&:hover": {
                              background: "#495ECA",
                              color: "#fff",
                            },
                          }}
                        >
                          Continue
                        </Button>
                      </>
                    }
                  />
                </Box>
              </>
            ) : (
              <>
                {loadingQR ? (
                  <Box sx={{ m: "50px" }}>
                    <ResourceLoader />
                  </Box>
                ) : (
                  <POSQRExternal
                    loading={loadingQR}
                    showLog={() => setShowLog(!showLog)}
                    paymentData={invoices[param1]}
                  />
                )}
                {!loadingQR && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      // pt: 2,
                      width: "100%",
                      gap: "15px",
                    }}
                  >
                    <AlertDialog
                      btnTitle="Cancel"
                      title="Are your sure to cancel?"
                      content="Are you sure to cancel this transaction"
                      execute={handleCancelClick}
                      executeLoading={canceled}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>

          {invoices[param1]?.status === "priceChange" && (
            <>
              <Box
                sx={{
                  mt: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setShowLog(!showLog)}
              >
                <GoDownAnimation />
              </Box>
            </>
          )}

          {showLog && (
            <Box
              sx={{
                borderRadius: "34px",
                border: "1px solid var(--Neutral-300, #EFF0F6)",
                background: "var(--Neutral-100, #FFF)",
                boxShadow: "0px 3px 16px 0px rgba(73, 94, 202, 0.18)",
                p: "30px 20px",
                maxWidth: "600px",
                width: "80%",
                // display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mt: "15px",
                position: "relative",
                // backgroundColor: 'blue',
                // "@media (max-width:570px)": { width: "60%" },
              }}
            >
              {invoices[param1]?.socketLogs?.length > 0 &&
                invoices[param1]?.socketLogs?.map((item, index) => (
                  <Box
                    key={item?.id + index}
                    sx={{
                      mt: "10px",
                      border: "1px solid red",
                      p: "15px",
                      borderRadius: "5px",
                      fontSize: "10px !important",
                      color: "red !blue",
                    }}
                  >
                    Looks like real time price of {invoices[param1]?.token} (
                    {invoices[param1]?.network}) has changed to{" "}
                    {item?.newRealTimePrice} ({item?.percentChange}%). Now your
                    new amount is {invoices[param1]?.exchange ? addBigNumber(invoices[param1]?.exchangeFee, item?.newAmount) : item?.newAmount} and pending amount is{" "}
                    {item?.pendingAmount}.
                  </Box>
                ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
