import React, { useEffect } from 'react';

export default function PrivacyPolicyComponent() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);  return (
    <div
      name="termly-embed"
      data-id="8a5cae27-e60a-424d-8465-52a212eb03a7"
      data-type="iframe"
    ></div>
  )

};