import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { checkLogin } from "../Redux/RequestHandlers/Auth.action";
const pages = ["Features", "About Us", "Pricing", "Contact Us"];

export const HomeMenuItems = ({
  handleNavigation,
  handleCloseNavMenu,
  mobileMenu,
}) => {
  const isLogin = checkLogin();
  return (
    <>
      {pages.map((page) => (
        <Button
          key={page}
          onClick={() => {
            handleNavigation(page);
            handleCloseNavMenu();
          }}
          sx={{
            color: "#020312",
            width: mobileMenu ? "100%" : "max-content",
            display: mobileMenu ? "flex" : "block",
            textTransform: "none",
            fontSize: "15px",
            fontFamily: "SF Pro Display",
            fontWeight: 400,
            lineHeight: "24.56px",
            p: mobileMenu ? "2px 15px!important" : "0px 15px!important",
          }}
        >
          {page}
        </Button>
      ))}

      {isLogin ? (
        <Button
          component={Link}
          to="/dashboard/"
          sx={{
            zIndex: 1,
            color: "#fff",
            background: "#495ECA",
            borderRadius: "6.48px",
            display: "block",
            textTransform: "none",
            fontSize: "12.94px",
            fontWeight: 600,
            lineHeight: "20.736px",
            border: "0.81px solid var(--primary-shades-400, #D6D1FA)",
            padding: "8.91px 19.44px",
            ml: "22px",
            letterSpacing: "0.065px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            textAlign: "center",
            "&:hover": {
              background: "#495ECA",
              color: "#fff",
            },
            "@media (max-width:968px)": {
              ml: 0,
            },
          }}
        >
          Go to Dashboard
        </Button>
      ) : (
        <>
          <Button
            component={Link}
            to="/login/"
            // onClick={scrollToSection2}
            //  onClick={scrollToSection('HomeSignInComponent')}
            sx={{
              mt: mobileMenu && "5px",
              zIndex: 1,
              color: "#fff",
              borderRadius: "6.48px",
              display: "block",
              textTransform: "none",
              fontSize: "12.94px",
              fontWeight: 600,
              lineHeight: "20.736px",
              padding: "8.91px 19.44px",
              ml: "22px",
              letterSpacing: "0.065px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              border: "0.81px solid #020312",
              textAlign: "center",

              background: "#000",
              "&:hover": {
                background: "#000",
                color: "#fff",
              },
              "@media (max-width:968px)": {
                ml: 0,
              },
            }}
          >
            Sign In
          </Button>
          <Button
            component={Link}
            to="/signup/"
            sx={{
              mt: mobileMenu && "5px",
              zIndex: 1,
              color: "#fff",
              background: "#495ECA",
              borderRadius: "6.48px",
              display: "block",
              textTransform: "none",
              fontSize: "12.94px",
              fontWeight: 600,
              lineHeight: "20.736px",
              border: "0.81px solid var(--primary-shades-400, #D6D1FA)",
              padding: "8.91px 19.44px",
              ml: "22px",
              textAlign: "center",
              letterSpacing: "0.065px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              "&:hover": {
                background: "#495ECA",
                color: "#fff",
              },
              "@media (max-width:968px)": {
                ml: 0,
              },
            }}
          >
            Get Started
          </Button>
        </>
      )}
    </>
  );
};

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const scrollToSection = (id) => {
    const targetSection = document.getElementById(id);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  const handleNavigation = (setting) => {
    // Navigate to the appropriate route based on the clicked setting
    if (setting === "Features")
      return window.location.pathname === "/"
        ? scrollToSection("componentFour")
        : navigate("/", { replace: true });
    if (setting === "About Us") return navigate("/aboutus/", { replace: true });
    if (setting === "Contact Us")
      return navigate("/contactus/", { replace: true });
    if (setting === "Pricing") return navigate("/pricing/", { replace: true });
  };
  return (
    <>
      <AppBar
        elevation={0}
        sx={{
          background: "transparent",
          position: "static",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{ background: "transparent", maxHeight: "65px" }}
        >
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                color: "#020312",
                zIndex: 2,
                "@media (max-width:1024px)": { display: "flex!important" },
                "@media (min-width:1024px)": { display: "none!important" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{
                  "@media (max-width:500px)": {
                    pt: "0px",
                    pb: "0px",
                  },
                }}
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="menu-appbarx"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                className="kk"
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  "@media (max-width:1024px)": { display: "block!important" },
                  "@media (min-width:1024px)": { display: "none!important" },
                  color: "#020312",
                }}
              >
                <Box
                  sx={{
                    px: "15px",
                  }}
                >
                  <HomeMenuItems
                    handleCloseNavMenu={handleCloseNavMenu}
                    handleNavigation={handleNavigation}
                    mobileMenu={true}
                  />
                </Box>
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                color: "#020312",
                zIndex: 1,
                "@media (max-width:1024px)": { display: "none!important" },
                "@media (min-width:1024px)": { display: "flex!important" },
              }}
            >
              <HomeMenuItems
                handleCloseNavMenu={handleCloseNavMenu}
                handleNavigation={handleNavigation}
                mobileMenu={false}
              />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
export default ResponsiveAppBar;
