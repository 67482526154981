import React, { useEffect } from "react";
import "rapidoc"; // Import rapidoc library
import openApiSpec from "./openapi.json";
const RapidocExample = () => {
  useEffect(() => {
    const docEl = document.getElementById("thedoc");
    docEl.loadSpec(openApiSpec);

    // Clean-up function
    return () => {
      // Unload spec when component unmounts
      if (docEl?.unload) docEl.unload();
    };
  }, []);

  return (
    <rapi-doc
      id="thedoc"
      show-header="false"
      render-style="read"
      allow-try="false"
      allow-authentication="false"
      theme="light"
      primary-color="#495eca"
      text-color="#00000"
      heading-text="Gafah Ecommerce Integration API Documetation"
      style={{ height: "100vh", width: "100%" }}
      // regular-font="Open Sans"
      // mono-font="Roboto Mono"
    ></rapi-doc>
  );
};

export default RapidocExample;
