import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import COneLastSectionHeading from "./COneLastSection";
import { Link } from "react-router-dom";
import { checkLogin } from "../../../Redux/RequestHandlers/Auth.action";
import { HeroSvg } from "../../../components/Helpers/svg";
import { DescriptionComponent } from "../../../components/Helpers/helperComponents";
export default function ComponentOne() {
  const isLogin = checkLogin();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const carouselStyle = {
    height: "150px!important",
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isDesktop = windowWidth <= 800;
  const isDesktop1200 = windowWidth <= 1200;
  return (
    <Box
      sx={{
        pr: "80px!important",
        mt: "114px",
        "@media (max-width:800px)": { pr: "0!important" },
      }}
    >
      <Grid container sx={{ pb: "64px" }}>
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          xl={6}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              "@media  (max-width:1199px)": {
                alignItems: "center",
                width: "100%",
              },
              "@media  (max-width:800px)": {
                px: "20px",
              },
            }}
          >
            <Typography
              sx={{
                color: "#FFF",
                fontFeatureSettings: " 'ss06' on",
                fontFamily: "Poppins",
                fontSize: "3.575rem",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "77px",
                letterSpacing: "-0.7px",
                display: "flex",
                "@media (min-width:1200px) and (max-width:1350px)": {
                  fontSize: "3.375rem",
                  lineHeight: "70px",
                },
                "@media (max-width:800px)": {
                  fontSize: "2.075rem",
                  lineHeight: "50px",
                },
              }}
            >
              Unlocking{" "}
              <Box
                sx={{
                  color: "#495ECA!important",
                  ml: "10px",
                  "@media (max-width:800px)": { ml: "5px!important" },
                }}
              >
                Assets
              </Box>
            </Typography>
            <Typography
              sx={{
                color: "#FFF",
                fontFeatureSettings: " 'ss06' on",
                fontFamily: "Poppins",
                fontSize: "3.575rem",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "77px",
                letterSpacing: "-0.7px",
                "@media (min-width:1200px) and (max-width:1350px)": {
                  fontSize: "3.375rem",
                  lineHeight: "70px",

                },
                "@media (max-width:1200px)": { textAlign: "center" },
                "@media (max-width:800px)": {
                  textAlign: "center",
                  fontSize: "2.075rem",
                  lineHeight: "50px",
                },
              }}
            >
              For Merchants & Consumers

            </Typography>
            <DescriptionComponent
              ta={isDesktop1200 && "center"}
              text={<>
                Giving freedom to users to pay how they want to pay—and power to merchants to accept secure & easy payments in seconds.
              </>}
              mt="32px"
            />

            <Box
              sx={{
                display: "flex",
                mt: "48px",
                alignItems: "center",
                gap: "30px",
                "@media (max-width:1460px)": {
                  mb: "40px",
                },
              }}
            >
              {isLogin ? (
                <Button
                  component={Link}
                  to="/dashboard/"
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  sx={{
                    background: "#495ECA",
                    color: "#fff",
                    textTransform: "none",
                    border: " 3px solid #495ECA",
                    p: "11px 19px 11px 20px",
                    minHeight: "42px",
                    borderRadius: "8px",
                    transition: "0.4s",
                    "&:hover": {
                      background: "#495ECA",
                      color: "#fff",
                      transition: "0.4s",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      lineHeight: "25.6px",
                      fontFeatureSettings: " 'ss06' on",
                      fontSize: "16px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      "@media (max-width:600px)": {
                        fontSize: "12px!important",
                        fontWeight: "600!important",
                      },
                    }}
                  >
                    Go to Dashboard
                  </Typography>
                </Button>
              ) : (
                <Button
                  component={Link}
                  to="/signup/"
                  variant="contained"
                  color="primary"
                  disableElevation={true}
                  sx={{
                    background: "#495ECA",
                    color: "#fff",
                    textTransform: "none",
                    border: " 3px solid #495ECA",
                    p: "11px 19px 11px 20px",
                    minHeight: "42px",
                    borderRadius: "8px",
                    transition: "0.4s",
                    "&:hover": {
                      background: "#495ECA",
                      color: "#fff",
                      transition: "0.4s",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      lineHeight: "25.6px",
                      fontFeatureSettings: " 'ss06' on",
                      fontSize: "16px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      "@media (max-width:600px)": {
                        fontSize: "12px!important",
                        fontWeight: "600!important",
                      },
                    }}
                  >
                    Get Started
                  </Typography>
                </Button>
              )}
              <Button
                component={Link}
                to="/contactus/"
                variant="contained"
                color="primary"
                disableElevation={true}
                sx={{
                  background: "transparent",
                  color: "#fff",
                  textTransform: "none",
                  p: "11px 19px 11px 20px",
                  minHeight: "42px",
                  borderRadius: "8px",
                  border: " 3px solid #FFF",
                  transition: "0.4s",
                  "&:hover": {
                    background: "transparent",
                    color: "#fff",
                    transition: "0.4s",
                  },
                }}
              >
                <Typography
                  sx={{
                    lineHeight: "25.6px",
                    fontFeatureSettings: " 'ss06' on",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    "@media (max-width:600px)": {
                      fontSize: "12px!important",
                      fontWeight: "600!important",
                    },
                  }}
                >
                  Contact Us
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          xl={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HeroSvg height={isDesktop ? "370" : "606"} />
        </Grid>
      </Grid>
      <Box
        className="componentOneCarousel"
        sx={{
          mx: "112px",
          p: "38.88px 129.6px",
          borderRadius: " 30px 30px 0px 0px",
          background: "var(--main-colors-white, #FFF)",
          display: "flex",
          justifyContent: "space-around",
          "@media (min-width:800px ) and (max-width:1200px)": {
            mx: "56px!important",
            px: "27.6px",
          },
          "@media (min-width:600px ) and (max-width:800px)": {
            flexDirection: "column",
            gap: "30px",
            mx: "112px!important",
            px: "27.6px",
          },
          "@media (max-width:600px)": {
            flexDirection: "column",
            gap: "30px",
            mx: "60px!important",
            px: "27.6px",
          },
        }}
      >
        {isDesktop ? (
          <>
            <Carousel
              style={carouselStyle}
              infiniteLoop
              showStatus={false}
              showArrows={false}
              showIndicators={false}
              useKeyboardArrows
              autoPlay
              showThumbs={false}
            >
              <COneLastSectionHeading
                title=">1 s"
                subHeading="Transaction time"
              />
              <COneLastSectionHeading
                title="5+"
                subHeading="Digital sssets supported"
              />
              <COneLastSectionHeading
                title="1 platform"
                subHeading="For digital assets transfer & payments"
              />
            </Carousel>
          </>
        ) : (
          <>
            {" "}
            <COneLastSectionHeading
              title=">1 s"
              subHeading="Transaction time"
            />
            <COneLastSectionHeading
              title="5+"
              subHeading="Digital assets supported"
            />
            <COneLastSectionHeading
              title="1 platform"
              subHeading="For digital assets transfer & payments"
            />
          </>
        )}
      </Box>
    </Box>
  );
}
