import React from "react";
import { Grid } from "@mui/material";
import CSevenCardComponent from "./CSevenCard";
import Img1 from "../../../assets/img/home/componentSeven/1.png";
export default function CSevenGrid() {
  return (
    <Grid
      container
      sx={{
        mt: "64px",
        width: "100%",
        "@media (max-width:800px)": { px: "0px!important", mt: "32px" },
      }}
      spacing={2}
    >
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CSevenCardComponent
          img={Img1}
          name="Abel Sheldon"
          position="Entrepreneur"
          head="“This product has the ability to revolutionize all business. The seamless integration and robust security features will not only boost  confidence but also enhance the overall payment experience for customers. Highly recommended for anyone looking to elevate their business to the next level!”"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CSevenCardComponent
          img={Img1}
          name="Amilia Christ"
          position="Entrepreneur"
          head="“This product has exceeded my expectations, offering a comprehensive set of tools that adapt to our evolving needs. The easy integration and insightful analytics empower us to focus on growth, knowing our payment infrastructure is in capable hands. Truly a game-changer for emerging businesses!”"
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CSevenCardComponent
          img={Img1}
          name="Savanna Bridgers"
          position="Entrepreneur"
          head="“I've tried various digital assets transfer solutions, but this one stands out. The intuitive interface and broad digital assets support make it a go-to for my transactions. Plus, the team's commitment to security and responsive customer support have made every interaction smooth and worry-free. Trustworthy and innovative!”"
        />
      </Grid>
    </Grid>
  );
}
