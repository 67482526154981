import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetHandlerForPaymentGateway } from "../../Redux/RequestHandlers";
import {
  AuthHeader,
  AuthPRight,
  Failed,
  UrlReject,
} from "../../components/Helpers/helperComponents";
import { useState } from "react";
import { LogoSvg, ResourceLoader } from "../../components/Helpers/svg";
import { getSecondsDifference, hasObjectPropertyExist } from "../../utilities";
import { CountdownComponentWithLoader } from "../../components/Helpers/countdown";
import WalletIcon from "@mui/icons-material/Wallet";

export default function POSStepper() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { invoices } = useSelector((state) => state?.pos);
  const [loading, setLoading] = React.useState(true);
  const [isRejected, setIsRejected] = useState(null);
  const [expireDate, setExpireDate] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param1 = queryParams.get("id");
  const [isExchangesShow, setIsExchangesShow] = useState(false)
  // api calls
  const init = async () => {
    try {
      let req = {
        url: "invoiceDetails",
        action: "ACTION_INVOICE_DETAILS",
        params: {
          id: param1, //"ea448ecc-6c92-4a1d-91db-55b3be507c78",
        },
      };
      const res = await dispatch(GetHandlerForPaymentGateway(req));
      if (res?.data?.data?.jwt_token?.expiresIn)
        setExpireDate(res?.data?.data?.jwt_token?.expiresIn);

      setIsRejected(res?.data?.success ? false : true);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (
      hasObjectPropertyExist(invoices, param1) &&
      invoices[param1]?.id === param1 &&
      invoices[param1]?.paymentMethod === "External" &&
      invoices[param1]?.paymentDetails?.token
    )
      return navigate(`/invoice/external?id=${invoices[param1]?.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, param1]);

  React.useEffect(() => {
    param1 ? init() : setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        pt: "40px",
      }}
    >
      <AuthHeader text="Select a Wallet" fs="34px" fw="700" />
      <AuthPRight
        ta="center"
        text="Please select a wallet from the options given below to proceed."
      />

      <Box
        sx={{
          borderRadius: "34px",
          border: "1px solid var(--Neutral-300, #EFF0F6)",
          background: "var(--Neutral-100, #FFF)",
          boxShadow: "0px 3px 16px 0px rgba(73, 94, 202, 0.18)",
          p: "20px",
          maxWidth: "700px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          mt: "15px",
          "@media (max-width:570px)": { width: "100%" },
        }}
      >
        {/* <AuthPRight text /> */}
        {!param1 ? (
          <UrlReject
            my="100px"
            text={`url should be ${location?.pathname}?id=xxxxx-xxx-xxxx...`}
          />
        ) : loading ? (
          <Box sx={{ my: "130px" }}>
            {" "}
            <ResourceLoader />
          </Box>
        ) : isRejected ? (
          <Failed
            my="100px"
            text="Looks like the transaction has expired, please try to Restart the Payment"
          />
        ) : (
          hasObjectPropertyExist(invoices, param1) && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--Neutral-800, #170F49)",
                      textAlign: "center",
                      fontFeatureSettings: `'clig' off, 'liga' off`,
                      fontFamily: "SF Pro Display",
                      fontSize: "34px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "46px",
                      //   mb:"33px"
                    }}
                  >
                    {invoices[param1]?.currency} {invoices[param1]?.amount}
                  </Typography>
                  <Box
                    component={Link}
                    to={`/invoice/gafah?id=${param1}`}
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid var(--Neutral-300, #495ECA)",
                      // background: "#495ECA",
                      width: "90%",
                      maxWidth: "320px",
                      textAlign: "center",
                      boxShadow: "0px 2px 6px 0px rgba(19, 18, 66, 0.07)",
                      textDecoration: "none",
                    }}
                  >
                    <Box
                      sx={{
                        p: "16px",
                        color: "#000",
                        fontFamily: "SF Pro Display",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                        "@media (max-width:600px)": { fontSize: "16px" },
                      }}
                    >
                      <LogoSvg width={18} height={18} /> Pay with Gafah Wallet
                    </Box>
                  </Box>
                  {invoices[param1]?.exchangeDetails && JSON.parse(invoices[param1]?.exchangeDetails)?.length > 0 ?
                    <>
                      <Box
                        sx={{
                          overflow: 'hidden',
                          mt: '15px',
                          borderRadius: "10px",
                          border: "1px solid var(--Neutral-300, #EFF0F6)",
                          background: "#FFF",
                          width: "90%",
                          maxWidth: "320px",
                          textAlign: "center",
                          boxShadow: "0px 2px 6px 0px rgba(73, 94, 202, 0.18)",
                          textDecoration: "none",
                          mb: '50px',
                          height: isExchangesShow ? `${Number(JSON.parse(invoices[param1]?.exchangeDetails)?.length * 51 + 110)}px` : '55px',
                          transition: 'height 0.3s ease-out'
                        }}
                      >
                        <Box
                          sx={{
                            p: "16px",
                            cursor: 'pointer',
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            justifyContent: "center",
                            color: "#000",
                            fontFamily: "SF Pro Display",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "16px",
                            "@media (max-width:600px)": { fontSize: "14px" },
                          }}
                          onClick={() => setIsExchangesShow(!isExchangesShow)}
                        >
                          <WalletIcon sx={{ color: "#495ECA" }} /> Pay with any
                          External Wallets/Exchange.
                        </Box>

                        {isExchangesShow && <Box>
                          {JSON.parse(invoices[param1]?.exchangeDetails)?.length > 0 && JSON.parse(invoices[param1]?.exchangeDetails)?.map(item =>
                            <Box
                              key={item}
                              component={Link}
                              to={`/invoice/external?id=${param1}&&exchange=${item}`}

                              sx={{
                                display: 'flex',
                                borderTop: "1px solid var(--Neutral-300, #EFF0F6)",
                                width: "100%",
                                textAlign: "center",
                                textDecoration: 'none',
                                height: '50px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: "#000",

                                "&:hover": {
                                  background: '#495ECA',
                                  color: "#FFF"
                                }
                              }}
                            >
                              <Box
                                sx={{
                                  fontFamily: "SF Pro Display",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  "@media (max-width:600px)": { fontSize: "14px" },
                                }}
                              >
                                Pay With {item}
                              </Box>
                            </Box>
                          )}

                          <Box
                            component={Link}
                            to={`/invoice/external?id=${param1}`}
                            sx={{
                              display: 'flex',
                              borderTop: "1px solid var(--Neutral-300, #EFF0F6)",
                              width: "100%",
                              textAlign: "center",
                              textDecoration: 'none',
                              height: '50px',
                              alignItems: 'center',
                              color: "#000",
                              justifyContent: 'center',
                              "&:hover": {
                                background: '#495ECA',
                                color: "#FFF"
                              }
                            }}
                          >
                            <Box
                              sx={{
                                fontFamily: "SF Pro Display",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "16px",
                                "@media (max-width:600px)": { fontSize: "14px" },
                              }}
                            >
                              Pay with Other
                            </Box>
                          </Box>
                        </Box>}
                      </Box>


                    </>

                    :
                    <Box
                      component={Link}
                      to={`/invoice/external?id=${param1}`}
                      sx={{
                        mt: '15px',
                        borderRadius: "10px",
                        border: "1px solid var(--Neutral-300, #EFF0F6)",
                        background: "#FFF",
                        width: "90%",
                        maxWidth: "320px",
                        textAlign: "center",
                        mb: "60px",
                        boxShadow: "0px 2px 6px 0px rgba(73, 94, 202, 0.18)",
                        textDecoration: "none",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          p: "16px",
                          justifyContent: "center",
                          color: "#000",
                          fontFamily: "SF Pro Display",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "16px",
                          "@media (max-width:600px)": { fontSize: "14px" },
                        }}
                      >
                        <WalletIcon sx={{ color: "#495ECA" }} /> Pay with any
                        External Wallets/Exchange.
                      </Box>
                    </Box>
                  }
                </Box>
              </Box>

              {!loading && expireDate && (
                <CountdownComponentWithLoader
                  expireIn={getSecondsDifference(new Date(), expireDate)}
                  colour="red"
                  content="Your Transaction will expire in "
                />
              )}
            </>
          )
        )}
      </Box>
    </Box>
  );
}
